/*-----------------------------------------------------------------------------------

    Template Name: Bigdeal
    Template URI: themes.pixelstrap.com/bigdeal
    Description: This is E-commerce Theme
    Author: Pixelstrap
    Author URI: https://themeforest.net/user/pixelstrap
----------------------------------------------------------------------------------- */
/*-----------------------------------------------------------------------------------
1.General CSS start
  1.1.Button CSS start
2.Services CSS start
3.Instagram CSS start
4. Brand panel CSS start
5. Testimonial CSS start
6. Offer-banner CSS start
7. Deal banner CSS start
8. Discount banner CSS start
9. Contact banner CSS start
10. Rounded category CSS start
11. Box category CSS start
12.Blog CSS start
13. Collection banner CSS start
14. Product CSS start
15. Hot Deal CSS start
16. Header CSS start
17. Media Banner start
18. Footer start
19.  Slider start
20. Title css
21. Tab-product css
22. Newsleatter css
23. breadcrumb CSS start
24. payment offer CSS start
25.Inner pages CSS start
26.add to cart CSS start
27.add to account bar css start
28. Tab to top CSS start
29. Modal CSS start
30. Loader css start
31. masonory-banner css start
31. masonory-banner css start
----------------------------------------------------------------------------------- */

/*=====================
  1.General CSS start
==========================*/
html {
  scroll-behavior: smooth;
}
body {
  font-family: $font-1;
  font-size: 14px;
  color: $body-text;
  background-color: rgba($theme-color1, 0.5);
}
h1 {
  font-weight: 700;
  margin-bottom: 0;
  font-size: calc(30px + (72 - 30) * ((100vw - 320px) / (1920 - 320)));
}
h2 {
  font-weight: 400;
  margin-bottom: 0;
  font-size: calc(20px + (36 - 20) * ((100vw - 320px) / (1920 - 320)));
}
h3 {
  font-weight: 700;
  margin-bottom: 0;
  font-size: calc(18px + (30 - 18) * ((100vw - 320px) / (1920 - 320)));
}
h4 {
  font-weight: 700;
  margin-bottom: 0;
  font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
}
h5 {
  font-weight: 700;
  margin-bottom: 0;
  font-size: 16px;
}
h6 {
  font-size: 14px;
  margin-bottom: 0;
  font-weight: 400;
}
.form-group{
  margin-bottom:15px;
}
label {
  color:$font-color;
  margin-bottom:10px; 
}
textarea,
input {
  letter-spacing: 0.05em;
}
ul {
  padding-left: 0;
  margin-bottom: 0;
}
::selection {
  background-color: $theme-color1;
  color: $theme-color2;
}
.small-section {
  padding-top: 35px;
  padding-bottom: 35px;
}
li {
  display: inline-block;
}
.o-hidden {
  overflow: hidden;
}
p {
  font-size: 14px;
  line-height: 1.4;
  margin-bottom: 0;
  letter-spacing: 0.05em;
}
a {
  transition: 0.5s ease;
  text-decoration: none;
  &:hover {
    text-decoration: none;
    transition: 0.5s ease;
  }

  &:focus {
    outline: none;
  }
}

button:focus {
  outline: none;
}

.alert {
  border-radius: 0;
  background-color: lighten(#5cb85c, 60%);
  border: 1px solid #5cb85c;

  span {
    color: #5cb85c;
  }

  .progress {
    margin-top: 5px;
    Height: 10px;
  }

  .progress-bar {
    background-color: #5cb85c;
    Height: 10px;
  }
}
.media{
  display: flex; 
  .media-body{
    flex: 1;
  }
}
.btn-close{
  &:focus{
    box-shadow:none;
  }
}

.section-pt-space {
  padding-top: 15px;
}

.section-pb-space {
  padding-bottom: 15px;
}

.section-py-space {
  padding: 15px 0;
}

.section-big-pt-space {
  padding-top: 50px;
}

.section-big-pb-space {
  padding-bottom: 50px;
}

.section-big-py-space {
  padding-top: 50px;
  padding-bottom: 50px;
}

.section-mb-space {
  margin-bottom: 15px;
}

.section-big-my-space {
  margin-top: 50px;
  margin-bottom: 50px;
}

.section-my-space {
  margin: 15px 0;
}

.mb--5 {
  margin-bottom: -5px;
}

.mb--10 {
  margin-bottom: -10px;
}

.mt--5 {
  margin-top: -5px;
}

.section-mt-space {
  margin-top: 15px;
}

.section-big-mt-space {
  margin-top: 50px;
}

.section-big-mb-space {
  margin-bottom: 50px;
}

.gutter-15 {

  .col-sm-12,
  .col-sm-6,
  .col-md-12 {
    padding-right: 7.5px;
    padding-left: 7.5px;
  }

  .masonary-banner-block2 {
    .col-sm-6 {
      padding-left: 15px;
    }
  }
}

.bg-light {
  background-color: rgba($theme-color1, 0.05) !important;
}

.b-g-light {
  background-color: $bg-light1;
}

.no-arrow {

  >.slick-next,
  >.slick-prev {
    display: none !important;
  }
}


.arrow-1 {

  .slick-next,
  .slick-prev {
    background-color: $theme-color1;
    z-index: 1;
    height: 35px;
    font-weight: 700;
    opacity: 0;
    visibility: hidden;
    transition: all 0.5s ease;

    &::before {
      content: "\f105";
      color: $white;
      opacity: 1;
      font-family: $font-awesome;
    }

  }

  .slick-next {
    right: 0;
  }

  .slick-prev {
    left: 0;
  }

  &:hover {

    .slick-prev,
    .slick-next {
      opacity: 1;
      visibility: visible;
    }
  }
}




.no-dots {
  .slick-dots {
    display: none !important;
  }
}

.timer {
  span {
    display: inline-grid;
    background-color: $white;
    padding: 12px 0 18px;
    text-align: center;
    font-size: 24px;
    font-weight: 700;
    color: $black;
    margin: 0 8px;
    min-width: 70px;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }

    .padding-l {
      display: none;
    }

    .timer-cal {
      font-size: 12px;
      color: $icon-color;
    }

    >span {
      color: $light-gray;
      font-weight: 400;
      font-size: 14px;
      padding: 0;
      margin-left: 0;
      text-transform: capitalize;
    }
  }

  p {
    margin-bottom: 0;
  }
}



.txt-default {
  color: $theme-color1;
}

.bg-light0 {
  background-color: $pink;
}

.bg-light1 {
  background-color: $blue;
}

.bg-light2 {
  background-color: $gray;
}

.b-g-white {
  background-color: $white !important;
}

.bg-silver {
  background-color: $hot-deal-1 !important;
}



.fb-color {
  background-color: $fbcolor;
}

.twt-color {
  background-color: $twtcolor;
}

.indid-color {
  background-color: $indidcolor;
}

.print-color {
  background-color: $printcolor;
}

.insta-color {
  background-color: $instacolor;
}

.color-variant {
  li {
    margin-right: 5px;
    border-radius: 50%;
  }
}




// image ratio //
.ratio_40 {
  .bg-size {
    &:before {
      padding-top: 40%;
      content: "";
      display: block;
    }
  }
}

.ratio_45 {
  .bg-size {
    &:before {
      padding-top: 45%;
      content: "";
      display: block;
    }
  }
}

.ratio2_1 {
  .bg-size {
    &:before {
      padding-top: 50%;
      content: "";
      display: block;
    }
  }
}

.ratio2_3 {
  .bg-size {
    &:before {
      padding-top: 60%;
      content: "";
      display: block;
    }
  }
}

.ratio3_2 {
  .bg-size {
    &:before {
      padding-top: 66.66%;
      content: "";
      display: block;
    }
  }
}

.ratio_landscape {
  .bg-size {
    &:before {
      padding-top: 75%;
      content: "";
      display: block;
    }
  }
}

.ratio_square {
  .bg-size {
    &:before {
      padding-top: 100%;
      content: "";
      display: block;
    }
  }
}

.ratio_asos {
  .bg-size {
    &:before {
      padding-top: 127.7777778%;
      content: "";
      display: block;
    }
  }
}

.ratio_portrait {
  .bg-size {
    &:before {
      padding-top: 150%;
      content: "";
      display: block;
    }
  }
}

.ratio1_2 {
  .bg-size {
    &:before {
      padding-top: 200%;
      content: "";
      display: block;
    }
  }
}

.b-top {
  background-position: top !important;
}

.b-bottom {
  background-position: bottom !important;
}

.b-center {
  background-position: center !important;
}

.b_size_content {
  background-size: contain !important;
  background-repeat: no-repeat;
}

.light-layout {
  background-color: $gray1;
}

.b-g-white {
  background-color: $white;
}

.b-g-white1 {
  background-color: $white !important;
}


//1.1.Button CSS start
button {
  cursor: pointer;
}

.btn-block{
  width:100%;
}

.btn {
  text-transform: capitalize;

  &:focus {
    box-shadow: none;
  }
}

.btn-rounded {
  font-size: 14px;
  padding: 18px 50px;
  font-weight: 700;
  color: $white !important;
  background-color: $theme-color1;
  border-radius: 25px;
  position: relative;
  transition: all 0.3s;
  line-height: 1;
  display: inline-block;
  letter-spacing: 0.05em;

  &.btn-outline {
    background-color: $white;
    border: 1px solid $theme-color1;
    color: $dark !important;

    &:hover {
      background-color: $theme-color1;
      color: $white !important;
    }
  }

  &:hover {
    background-color: $black;
  }

  &.btn-xs {
    padding: 10px 15px;
    text-transform: capitalize;
  }

  &.btn-sm {
    padding: 10px 30px;
    text-transform: capitalize;
  }

  &.btn-md {
    padding: 15px 40px;
  }
}

.btn-white {
  font-size: 14px;
  padding: 16px 45px;
  font-weight: 700;
  color: $dark !important;
  background-color: $white;
  border-radius: 25px;
  position: relative;
  transition: all 0.3s;
  display: inline-block;
  line-height: 1;
  letter-spacing: 0.05em;

  &.btn-outline {
    background-color: transparent;
    border: 1px solid $theme-color1;

    &:hover {
      background-color: $theme-color1;
    }
  }

  &:hover {
    background-color: $black;
    color: $white !important;
  }
}

.btn-normal {
  font-size: 14px;
  padding: 18px 45px;
  font-weight: 700;
  color: $white !important;
  background-color: $theme-color1;
  position: relative;
  transition: all 0.3s;
  display: inline-block;
  line-height: 1;
  border-radius: 0;
  letter-spacing: 0.05em;

  &.btn-outline {
    background-color: $white;
    border: 1px solid $theme-color1;
    color: $dark !important;

    &:hover {
      background-color: $theme-color1;
      color: $white !important;
    }
  }

  &:hover {
    background-color: $black;
  }

  &.btn-md {
    padding: 15px 30px;
    text-transform: capitalize;
  }

  &.btn-xs {
    padding: 10px 15px;
    text-transform: capitalize;
  }
}

.btn-solid {
  font-size: 16px;
  padding: 18px 45px;
  font-weight: 700;
  color: $white !important;
  background-color: $theme-color1;
  position: relative;
  transition: all 0.3s;
  display: inline-block;
  line-height: 1;
  letter-spacing: 0.05em;
  border-radius: 5px;

  &.btn-sm {
    padding: 10px 20px;
  }

  &.btn-md {
    padding: 13px 25px;
  }

  &.btn-xs {
    padding: 5px 13px;
    font-size: 14px;
  }

  &.btn-outline {
    background-color: $white;
    border: 2px solid $theme-color1;
    color: $dark !important;

    &:hover {
      background-color: $theme-color1;
      color: $white !important;
    }
  }
}




/*=====================
  2.Services CSS start
==========================*/

.services {
  background-color: $theme-color2;

  &.services-inverse {
    background-color: $theme-color1;
  }

  .service-block {
    >div {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 23px 0;
    }

    .media {
      align-items: center;
      svg {
        width: 30px;
        height: 30px;
        fill: $white;
        margin-right: 12px;
        transition: 0.5s linear;
      }
      h5 {
        color: $white;
        text-transform: uppercase;
        line-height: 1;
        letter-spacing: 0.05em;
      }
      &:hover {
        svg {
          transform: scale(1.2);
        }
      }
    }
  }

  &.bg-light {
    .service-block {
      .media {
        svg {
          fill: $theme-color1;
        }

        h5 {
          color: $dark;
        }
      }
    }
  }
}

.services1 {
  .services-box {
    background-color: $white;
    padding-top: calc(20px + (35 - 20) * ((100vw - 320px) / (1920 - 320)));
    padding-bottom: calc(20px + (35 - 20) * ((100vw - 320px) / (1920 - 320)));
    margin-right: 15px;
    display: flex;
    justify-content: center;
    &:hover{
      .icon-wrraper{
        transform: scale(1.2);
        transition: all 0.3s ease;
      }
    }
    .media {
      .icon-wrraper {
        width: calc(35px + (60 - 35) * ((100vw - 320px) / (1920 - 320)));
        height: calc(35px + (60 - 35) * ((100vw - 320px) / (1920 - 320)));
        transition: all 0.3s ease;
        border-radius: 50%;
        background-color: $theme-color1;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 15px;

        svg {
          fill: $white;
          width: 35%;
          margin-bottom: 0 !important;
        }
      }

      svg {
        width: calc(35px + (40 - 35) * ((100vw - 320px) / (1920 - 320)));
        height: auto;
        fill: $theme-color1;
      }

      .media-body {
        margin-left: 15px;

        h4 {
          text-transform: capitalize;
          margin-bottom: 5px;
        }

        p {
          text-transform: capitalize;
        }

        .btn {
          text-transform: capitalize;
          color: $theme-color2;
          line-height: 1;
          padding: 0;
          margin-top: 15px;
          font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));

          &:focus {
            box-shadow: none;
            outline: none;
          }
        }
      }
    }
  }

  &.block {
    .services-box {
      .media {
        display: block;
        text-align: center;

        svg {
          margin-bottom: calc(5px + (15 - 5) * ((100vw - 320px) / (1920 - 320)));
        }

        .media-body {
          padding-right: 15px;
          padding-left: 15px;
          margin-left: 0;
        }
      }
    }
  }

  &.style2 {
    .services-box {
      padding-left: 10px;
      padding-right: 10px;

      .media {
        .icon-wrraper {
          width: calc(35px + (80 - 35) * ((100vw - 320px) / (1920 - 320)));
          height: calc(35px + (80 - 35) * ((100vw - 320px) / (1920 - 320)));

          svg {
            margin-bottom: 15px;
          }
        }

        .media-body {
          h4 {
            font-size: calc(16px + (22 - 16) * ((100vw - 320px) / (1920 - 320)));
            margin-bottom: 5px;
          }

          p {
            font-size: 14px;
            line-height: 1.6;
          }
        }
      }
    }
  }

  &.border-services {
    .services-box {
      border: 1px solid $light-border;
      margin-left: 15px;
      margin-right: 15px;

      .media {
        .icon-wrraper {
          background-color: $white;          
          height: auto;

          svg {
            fill: $theme-color1;
            width: calc(35px + (55 - 35) * ((100vw - 320px) / (1920 - 320)));
          }
        }
      }
    }
  }

  .service-block {
    >div {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &.services-transparent {
    .services-box {
      background-color: transparent;
    }
  }
}

.services2 {
  .services-box {
    display: flex;
    align-items: center;
    padding-top: calc(15px + (25 - 15) * ((100vw - 320px) / (1920 - 320)));
    padding-bottom: calc(15px + (25 - 15) * ((100vw - 320px) / (1920 - 320)));
    font-weight: 600;
    text-transform: capitalize;
    border-radius: 5px;
    background-color: $white;
    font-size: 16px;
    margin-right: 15px;
    justify-content: center;
    position: relative;
    z-index: 1;
    transition: all 0.3s ease;
    overflow: hidden;

    svg {
      width: calc(30px + (40 - 30) * ((100vw - 320px) / (1920 - 320)));
      height: calc(30px + (40 - 30) * ((100vw - 320px) / (1920 - 320)));
      margin-right: calc(10px + (25 - 10) * ((100vw - 320px) / (1920 - 320)));
    }

    &:hover {
      background-color: rgba($theme-color1, 0.8);
      color: $white;
    }
  }
}

/*=====================
   3.Instagram CSS start
==========================*/
.instagram {
  .insta-contant {
    border: 15px solid $white;
    line-height: 0;
    position: relative;

    .instagram-box {
      position: relative;
      overflow: hidden;

      img {
        width: 100%;
      }

      .insta-cover {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba($theme-color1, .7);
        justify-content: center;
        align-items: center;
        display: flex;
        transition: all 0.5s ease;
        opacity: 0;

        i {
          font-size: 20px;
          color: $white;
          transition: all 0.5s ease;
          transform: rotate(-217deg);
        }
      }

      &:hover {
        .insta-cover {
          opacity: 1;

          i {
            transform: scale(3) rotate(-360deg);
          }
        }
      }
    }

    .insta-sub-contant {
      position: absolute;
      margin: 0;
      top: 50%;
      left: 50%;
      margin-right: -50%;
      transform: translate(-50%, -50%);

      .insta-title {
        background-color: $white;
        padding: 20px 30px;
        width: auto;

        h4 {
          color: $dark;

          span {
            margin-right: 10px;
          }
        }
      }
    }

    &.insta-contant-inverse {
      border-color: $hot-deal-1;
    }
  }

  .insta-contant1 {
    line-height: 0;

    .instagram-box {
      position: relative;
      overflow: hidden;

      .insta-cover {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba($theme-color1, .7);
        justify-content: center;
        align-items: center;
        display: flex;
        transition: all 0.5s ease;
        opacity: 0;

        i {
          font-size: 20px;
          color: $white;
          transition: all 0.5s ease;
        }
      }

      &:hover {
        .insta-cover {
          opacity: 1;

          i {
            transform: scale(3) rotate(-360deg);
          }
        }
      }
    }

    .insta-sub-contant1 {
      margin: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      margin-right: -50%;
      transform: translate(-50%, -50%);

      .insta-title {
        background-color: $white;
        padding: 18px 80px;
        text-align: center;

        h4 {
          color: $dark;

          span {
            margin-right: 10px;
          }
        }
      }
    }
  }

  .insta-contant2 {
    border: 15px solid $white;
    position: relative;
    line-height: 0;

    .instagram-box {
      position: relative;
      overflow: hidden;

      .insta-cover {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba($theme-color1, .7);
        justify-content: center;
        align-items: center;
        display: flex;
        transition: all 0.5s ease;
        opacity: 0;

        i {
          font-size: 20px;
          color: $white;
          transition: all 0.5s ease;
        }

      }

      &:hover {
        .insta-cover {
          opacity: 1;

          i {
            transform: scale(3) rotate(-360deg);
          }
        }
      }
    }

    .insta-sub-contant2 {
      margin: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      margin-right: -50%;
      transform: translate(-50%, -50%);

      .insta-title {
        background-color: $white;
        padding: 12px 22px;
        width: auto;
        text-align: center;

        h4 {
          color: $dark;

          span {
            margin-right: 10px;
          }
        }
      }
    }
  }
}

/*=====================
   4. Brand panel CSS start
==========================*/
.brand-panel {
  .brand-panel-box {
    background-color: $white;

    .brand-panel-contain {
      padding: 30px 0;
      display: flex;
      justify-content: center;
      text-align: center;

      ul {
        li {
          padding: 0 10px;
          text-transform: uppercase;

          a {
            color: $icon-color;
            letter-spacing: 0.05em;
          }

          &:first-child {
            a {
              color: $theme-color1;
              font-weight: 700;
            }
          }

          &:hover {
            color: $theme-color1;
            cursor: pointer;
          }
        }
      }
    }
  }
}

.brand-block {
  >div {
    padding-left: 8px;
    padding-right: 8px;
  }
}

.brand-second {
  .brand-box {
    background-color: $white;
    padding: 30px;
    margin-right: 8px;
    margin-left: 8px;

    img {
      margin-left: auto;
      margin-right: auto;
    }
  }
}



/*===========================
   5. Testimonial CSS start
=============================*/
.testimonial-block {
  >div {
    padding-left: 0;
    padding-right: 0;
  }
}

.testimonial {
  background-color: $theme-color2;
  background-position: center;

  &.testimonial-inverse {
    background: $theme-color1;
  }

  .testimonial-contain {
    padding: 50px 0;
    max-width: 840px;
    margin: 0 auto;

    .media {
      .testimonial-img {
        width: 130px;
        height: 130px;
        border: 2px solid $white;
        padding: 5px;
        border-radius: 50%;
      }

      .media-body {
        color: $white;
        align-items: center;
        margin: 20px 0 0 40px;

        h5 {
          text-transform: uppercase;
          margin-bottom: 5px;
          line-height: 1;
          letter-spacing: 0.8px;
        }

        p {
          letter-spacing: 1px;
          line-height: 1.6;
        }
      }
    }
  }

  &.block {
    .testimonial-contain {
      .media {
        display: block;
        text-align: center;

        .testimonial-img {
          margin-left: auto;
          margin-right: auto;
        }
      }

      .media-body {
        margin-left: 0;
      }
    }
  }

  &.banner-style1 {
    .testimonial-contain {
      .media .media-body {
        h5 {
          color: $theme-color1
        }

        p {
          color: #000000;
          margin-top: 15px;
        }
      }
    }
  }
}

.testimonial2 {
  .testimonial-box {
    background-color: $white;
    padding-top: calc(20px + (50 - 20) * ((100vw - 320px) / (1920 - 320)));
    padding-bottom: calc(20px + (50 - 20) * ((100vw - 320px) / (1920 - 320)));
    padding-left: calc(20px + (40 - 20) * ((100vw - 320px) / (1920 - 320)));
    padding-right: calc(20px + (40 - 20) * ((100vw - 320px) / (1920 - 320)));
    margin-right: 15px;
    text-align: center;

    .img-wrapper {
      border-radius: 50%;
      overflow: hidden;
      width: calc(60px + (80 - 60) * ((100vw - 320px) / (1920 - 320)));
      height: calc(60px + (80 - 60) * ((100vw - 320px) / (1920 - 320)));
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 15px;

      img {
        width: 100%;
        height: auto;
      }
    }

    .testimonial-detail {
      h3 {
        text-transform: uppercase;
        font-weight: 600;
        color: $theme-color1;
        font-size: calc(18px + (22 - 18) * ((100vw - 320px) / (1920 - 320)));
        margin-bottom: 3px;
      }

      p {
        text-transform: capitalize;
        margin-bottom: 14px;
        line-height: 1.6;
        color: $dark;
      }

      h6 {
        color: $theme-color2;
        text-transform: capitalize;
        font-weight: 600;
        font-size: 16px;
        letter-spacing: 0.03em;
      }
    }
  }
}

.testimonial3 {
  .testimonial-box {
    background-color: $white;
    padding: calc(20px + (30 - 20) * ((100vw - 320px) / (1920 - 320)));
    margin-right: 15px;
    text-align: center;

    ul {
      li {
        i {
          color: $orange;
          font-size: calc(16px + (22 - 16) * ((100vw - 320px) / (1920 - 320)));
        }

        &:nth-child(n+2) {
          margin-left: 5px;
        }
      }
    }

    .img-wrapper {
      border-radius: 50%;
      overflow: hidden;
      width: calc(80px + (100 - 80) * ((100vw - 320px) / (1920 - 320)));
      height: calc(80px + (100 - 80) * ((100vw - 320px) / (1920 - 320)));
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 18px;

      img {
        width: 100%;
        height: auto;
      }
    }

    .testimonial-detail {
      p {
        text-transform: capitalize;
        margin-bottom: 10px;
        line-height: 1.8;
        color: $dark;
      }

      h3 {
        text-transform: uppercase;
        font-weight: 600;
        color: $theme-color1;
        font-size: calc(18px + (22 - 18) * ((100vw - 320px) / (1920 - 320)));
        margin-bottom: 10px;
      }

      h6 {
        color: $theme-color2;
        text-transform: capitalize;
        margin-bottom: 20px;
        font-size: calc(18px + (22 - 18) * ((100vw - 320px) / (1920 - 320)));
        font-weight: 600;
      }
    }
  }

  &.testimonial-border {
    .testimonial-box {
      border: 1px solid $light-border;
    }
  }
}

.testimonial4 {
  .testimonial-box {
    background-color: $white;
    padding: calc(20px + (30 - 20) * ((100vw - 320px) / (1920 - 320)));
    margin-right: 15px;
    text-align: center;

    .img-wrapper {
      border-radius: 50%;
      overflow: hidden;
      width: calc(80px + (100 - 80) * ((100vw - 320px) / (1920 - 320)));
      height: calc(80px + (100 - 80) * ((100vw - 320px) / (1920 - 320)));
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 15px;

      img {
        width: 100%;
        height: auto;
      }
    }

    .testimonial-detail {
      p {
        text-transform: capitalize;
        line-height: 1.6;
        color: $dark;
        margin-bottom: 13px;
      }

      h3 {
        text-transform: uppercase;
        font-weight: 600;
        color: $theme-color1;
        margin-bottom: 3px;
        font-size: calc(18px + (22 - 18) * ((100vw - 320px) / (1920 - 320)));
        line-height: 1;
      }

      h6 {
        color: $theme-color2;
        text-transform: capitalize;
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 18px;
      }

      ul {
        line-height: 1;

        li {
          i {
            color: $orange;
            font-size: calc(16px + (22 - 16) * ((100vw - 320px) / (1920 - 320)));
          }
        }
      }
    }
  }
}

.testimonial5 {
  padding-top: calc(30px + (100 - 30) * ((100vw - 320px) / (1920 - 320)));
  padding-bottom: calc(30px + (100 - 30) * ((100vw - 320px) / (1920 - 320)));
  position: relative;
  background-blend-mode: overlay;
  background-color: #3e3e3e;
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($black, 0.4);
  }

  .testimonial-detail {
    background-color: rgba($black, 0.3);
    width: 900px;
    margin-left: auto;
    margin-right: auto;
    padding: calc(20px + (30 - 20) * ((100vw - 320px) / (1920 - 320)));
    text-align: center;
    position: relative;
    margin-bottom: 30px;
    border-radius: 5px;

    &::before {
      content: "";
      position: absolute;
      top: 100%;
      left: 50%;
      margin-right: -50%;
      transform: translate(-50%, 0);
      width: 0;
      height: 0;
      border-left: 30px solid transparent;
      border-right: 30px solid transparent;
      border-top: 30px solid rgba($black, 0.3);
    }

    h3 {
      color: $white;
      text-transform: uppercase;

    }

    h4 {
      color: $white;
      text-transform: capitalize;
      margin-bottom: 10px;
      font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
    }

    p {
      color: $white;
      margin-bottom: 10px;
      font-size: 16px;
      line-height: 1.6;
    }

    ul {
      li {
        i {
          color: $rating-star;
        }
      }
    }
  }

  .img-wrraper-mian {
    width: 350px;
    margin-left: auto;
    margin-right: auto;
    margin-top: calc(15px + (30 - 15) * ((100vw - 320px) / (1920 - 320)));

    .img-wrraper {
      border-radius: 50%;
      overflow: hidden;
      margin-left: 10px;
      margin-right: 10px;
    }
  }

}

.testimonial6 {
  .testimonial-box {
    background-color: $bg-light;
    padding: calc(20px + (50 - 20) * ((100vw - 320px) / (1920 - 320)));
    margin-right: 15px;
    margin-left: 15px;
    text-align: center;
    position: relative;
    overflow: hidden;

    .img-wrapper {
      border-radius: 50%;
      width: calc(60px + (80 - 60) * ((100vw - 320px) / (1920 - 320)));
      height: calc(60px + (80 - 60) * ((100vw - 320px) / (1920 - 320)));
      margin-left: auto;
      margin-right: auto;
      overflow: hidden;
      margin-bottom: 15px;
    }

    .testimonial-detail {
      h3 {
        text-transform: uppercase;
        font-weight: 600;
        color: $theme-color1;
        font-size: calc(18px + (22 - 18) * ((100vw - 320px) / (1920 - 320)));
      }

      h6 {
        color: $theme-color2;
        text-transform: capitalize;
        font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
        font-weight: 600;
        margin-bottom: 11px;
      }

      .quate {
        position: absolute;
        bottom: 0;
        left: 0;
        transform: scale(3);
        opacity: 0.06;

        i {
          color: $white;
          width: calc(50px + (70 - 50) * ((100vw - 320px) / (1920 - 320)));
          height: calc(50px + (70 - 50) * ((100vw - 320px) / (1920 - 320)));
          border-radius: 50%;
          background-color: $theme-color2;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-left: auto;
          margin-right: auto;
          font-size: calc(18px + (30 - 18) * ((100vw - 320px) / (1920 - 320)));
        }
      }

      p {
        text-transform: capitalize;
        line-height: 1.6;
        color: $dark;
        margin-bottom: 14px;
      }

      ul {
        line-height: 1;

        li {
          i {
            color: $orange;
            font-size: calc(16px + (24 - 16) * ((100vw - 320px) / (1920 - 320)));
          }
        }
      }
    }
  }
}




/*=====================
   6. Offer-banner CSS start
==========================*/
.offer-banner {
  position: relative;
  overflow: hidden;

  .banner-contain {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    h5 {
      font-size: 18px;
      color: $white;
      font-family: $font-2, $font-1;
      letter-spacing: 0.05em;
      font-weight: 400;
      margin-top: 25px;
    }

    .discount-offer {
      display: flex;
      align-items: center;

      h1 {
        color: $white;
        line-height: 1;
        font-style: italic;
      }

      sup {
        font-size: 25px;
        color: $white;
        line-height: 1;
        font-style: italic;
        font-weight: 700;
      }
    }

  }
}

/*=====================
   7. Deal banner CSS start
==========================*/
.deal-banner {
  background-color: $theme-color1;
  padding: 40px 0;

  .deal-banner-containe {
    height: 100%;

    h2 {
      font-size: calc(14px + (24 - 14) * ((100vw - 320px) / (1920 - 320)));
      text-transform: uppercase;
      color: $white;
      font-weight: 400;
      line-height: 1;
      margin-top: -4px;
      margin-bottom: 5px;
    }

    h1 {
      font-size: calc(16px + (40 - 16) * ((100vw - 320px) / (1920 - 320)));
      font-weight: 700;
      color: $white;
      text-transform: uppercase;
      line-height: 1;
      letter-spacing: 0.5px;
    }

    .deal-btn {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }

  &.deal-banner-inverse {
    background-color: $theme-color2;
  }
}




/*=====================
   8. Discount banner CSS start
==========================*/
.discount-banner {
  background-color: $white;
  padding-top: 47px;
  padding-bottom: 47px;

  .discount-banner-contain {
    text-align: center;

    h2 {
      font-size: calc(14px + (24 - 14) * ((100vw - 320px) / (1920 - 320)));
      text-transform: capitalize;
      margin-bottom: 3px;
      margin-top: -5px;
    }

    h1 {
      font-size: calc(18px + (42 - 18) * ((100vw - 320px) / (1920 - 320)));
      font-weight: normal;
      text-transform: uppercase;
      margin-bottom: 10px;
      color: $dark;

      span {
        color: $theme-color1;

        +span {
          color: $theme-color2;
          font-weight: 700;
        }
      }
    }

    .rounded-contain {
      border: 2px dashed $theme-color2;
      border-radius: 50px;
      padding: 5px;

      .rounded-subcontain {
        background-color: $theme-color1;
        color: $white;
        text-transform: uppercase;
        padding: 18px 0;
        font-weight: 600;
        font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
        letter-spacing: .08em;
        line-height: 1;
        border-radius: 50px;
      }

      &.rounded-inverse {
        border: 2px dashed $theme-color1;

        .rounded-subcontain {
          background-color: $theme-color2;
        }
      }
    }
  }
}

/*============================
   9. Contact banner CSS start
==============================*/
.contact-banner {
  background-color: $theme-color1;
  padding: 20px 0;

  &.contact-banner-inverse {
    background-color: $theme-color2;
  }

  .contact-banner-contain {
    display: flex;
    justify-content: center;
    align-items: center;
    color: $white;

    .contact-banner-img {
      img {
        margin-right: 25px;
      }
    }

    h3 {
      font-size: 18px;
      font-weight: bold;
      margin-right: 25px;
      line-height: 1;
    }

    h2 {
      font-size: calc(20px + (36 - 20) * ((100vw - 320px) / (1920 - 320)));
      font-weight: 700;
    }
  }
}

.contact-banner2 {
  h2 {
    font-weight: 700;
    text-transform: uppercase;
    font-size: calc(16px + (22 - 16) * ((100vw - 320px) / (1920 - 320)));
    color: $theme-color1;
  }

  p {
    line-height: 1.6;
    font-size: 16px;
  }

  .contact-bannerbox {
    display: flex;
    align-items: center;
    height: 100%;
    background-color: $white;
    border: 1px solid $light-border;
    padding: calc(20px + (30 - 20) * ((100vw - 320px) / (1920 - 320)));

    h2 {
      margin-bottom: 9px;
    }

    p {
      margin-bottom: 15px;
    }

    ul {
      display: flex;
      align-items: center;

      li {
        svg {
          width: 34px;
          margin-right: 10px;
          fill: $theme-color1;
        }

        h3 {          
          font-size: 22px;
        }
      }
    }
  }

  .subscribe-box {
    display: flex;
    align-items: center;
    background-color: $white;
    border: 1px solid $light-border;
    padding: calc(20px + (30 - 20) * ((100vw - 320px) / (1920 - 320)));
    height: 100%;

    svg {
      width: 50px;
      margin-bottom: 5px;
    }

    h2 {
      margin-bottom: 9px;
    }

    p {
      margin-bottom: 17px;
    }

    .form-control {
      height: calc(38px + (48 - 38) * ((100vw - 320px) / (1920 - 320)));
      border-color: $light-border;
      padding-left: calc(12px + (17 - 12) * ((100vw - 320px) / (1920 - 320)));
      padding-right: calc(12px + (17 - 12) * ((100vw - 320px) / (1920 - 320)));
      margin-bottom: 22px;
      font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));

      &:focus {
        border-color: $light-border;
        box-shadow: none;
        outline: none;
      }
    }

    .btn {
      background-color: $theme-color2;
      text-transform: capitalize;
      width: fit-content;
      display: block;
    }
  }
}

.brand-third {
  padding: calc(15px + (30 - 15) * ((100vw - 320px) / (1920 - 320)));
  background-color: $white;
  border: 1px solid $light-border;
  display: flex;
  align-items: center;
  height: 100%;

  >div {
    display: block;
    width: 100%;
  }

  .brand-box {
    padding: 10px;
  }
}



/*=====================
   10. Rounded category CSS start
==========================*/
.rounded-category {
  background-color: $theme-color2;
  padding: 50px 0 40px;

  &.rounded-category-inverse {
    background-color: $theme-color1;

    .category-contain {
      &:hover {
        .img-wrapper {
          border: 1px solid $theme-color2;
        }

        .btn-rounded {
          background-color: $theme-color2;
        }
      }
    }
  }

  .category-contain {
    text-align: center;

    .img-wrapper {
      border: 1px solid $white;
      padding: 5px;
      width: 110px;
      height: 110px;
      border-radius: 50%;
      margin: 0 auto;
      transition: 0.5s ease;

      img {
        border-radius: 100%;
      }
    }

    .btn-rounded {
      padding: 10px 25px;
      font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
      display: inline-block;
      margin-top: 15px;
      background-color: transparent;
      text-transform: uppercase;
    }

    &:hover {
      .img-wrapper {
        border: 1px solid $theme-color1;
      }

      .btn-rounded {
        background-color: $theme-color1;
      }
    }

  }

  &.vagitable-category {
    .category-contain {
      .img-wrapper {
        background-color: $category-back;
        padding: calc(15px + (20 - 15) * ((100vw - 320px) / (1920 - 320)));
      }
    }
  }
}

/*=====================
   11. Box category CSS start
==========================*/
.category-block {
  >div {
    &:nth-child(n+2) {
      margin-top: 15px;
    }
  }
}

.box-category {
  .box-category-contain {
    padding: 30px 0;
    background-color: $white;
    text-align: center;
    margin-left: 15px;
    position: relative;
    transition: all 0.3s;
    color: $dark;
    text-transform: uppercase;

    h4 {
      line-height: 1;
      letter-spacing: 1px;
    }

    &:after {
      background: $theme-color1;
      content: '';
      position: absolute;
      z-index: -1;
      width: 100%;
      height: 0;
      right: 0;
      top: 0;
      transition: all 0.3s;
    }

    &:hover {
      color: $white;
      background: transparent;

      &:after {
        height: 100%;
      }
    }
  }

  &.big-category {
    .box-category-contain {
      background-color: $theme-color1;

      h4 {
        color: $white;
        font-size: calc(18px + (24 - 18) * ((100vw - 320px) / (1920 - 320)));
        font-style: italic;
      }
    }
  }
}

.category1 {
  padding-top: calc(25px + (60 - 25) * ((100vw - 320px) / (1920 - 320)));
  padding-bottom: calc(25px + (60 - 25) * ((100vw - 320px) / (1920 - 320)));
  background-color: $theme-color2;

  .category-box {
    text-align: center;
    margin-right: 15px;

    .img-wrapper {
      border-radius: 5px;
      overflow: hidden;

      img {
        width: 100%;
      }
    }

    .category-details {
      h4 {
        color: $white;
        text-transform: uppercase;
        margin-top: 15px;
      }
    }
  }

  &.rounded-category-inverse {
    background-color: $theme-color1;
  }
}

.category2 {
  .category-box {
    background-color: $white;
    padding: 30px;
    margin-right: 15px;
    display: flex;
    justify-content: center;
    text-align: center;
    border-radius: 5px;

    .icon-wrapper {
      svg {
        width: 25%;
        margin-bottom: 10px;
        height: auto;
        fill: $theme-color1;
        transition: all 0.5s ease;
      }
    }

    .category-details {
      h6 {
        display: block;
        color: $dark;
        font-weight: 600;
        text-transform: uppercase;
        font-size: 14px;
        margin-top: 20px;
        line-height: 1;
        transition: all 0.5s ease;
      }
    }

    &:hover {
      .icon-wrapper {
        svg {
          transform: scale(1.1);
        }
      }

      .category-details {
        h6 {
          color: $theme-color1
        }
      }
    }
  }
}

.category3 {
  .category-box {
    text-align: center;
    border-radius: 5px;
    overflow: hidden;
    margin-right: 15px;

    .img-wrraper {
      a {
        display: block;
        overflow: hidden;
      }

      img {
        transition: all 0.5s ease;
      }
    }

    .category-detail {
      background-color: $white;
      padding: 10px;
      text-transform: capitalize;
      transition: all 0.5s ease;
    }

    &:hover {
      .img-wrraper {
        img {
          transform: scale(1.1);
        }
      }

      .category-detail {
        background-color: rgba($theme-color1, 0.8);
        color: $white;
      }
    }
  }


}

.category4 {
  .category-box {
    background-color: $white;
    margin-right: 15px;
    border-radius: 5px;

    img {
      width: 100%;
    }

    .img-wrraper {
      img {
        width: 100%;
      }
    }

    .category-detail {
      padding: 20px;

      h2 {
        text-transform: uppercase;
        font-size: 18px;
        color: $black;
        font-weight: 700;
        margin-bottom: 10px;
      }

      ul {
        li {
          display: block;
          padding-left: 15px;
          position: relative;
          line-height: 1.2;

          &:nth-child(n+2) {
            margin-top: 10px;
          }

          &:before {
            content: "";
            position: absolute;
            top: 35%;
            left: 0;
            width: 5px;
            height: 5px;
            border-radius: 50%;
            background-color: $theme-color2;
          }

          a {
            font-size: 16px;
            text-transform: capitalize;
            color: $dark;
          }
        }
      }

      .btn {
        margin-top: calc(15px + (20 - 15) * ((100vw - 320px) / (1920 - 320)));
        text-transform: capitalize;
        font-size: 16px;
      }
    }
  }
}

.category5 {
  .category-box {
    margin-right: 15px;
    overflow: hidden;
    background-blend-mode: overlay;
    background-color: #4b4b4b;

    .contain-wrraper {
      padding: calc(20px + (30 - 20) * ((100vw - 320px) / (1920 - 320)));
      text-align: center;
      background-color: $white;
      transition: all 0.5s ease;

      svg {
        width: calc(35px + (55 - 35) * ((100vw - 320px) / (1920 - 320)));
        height: auto;
        margin-bottom: 5px;
      }

      h3 {
        font-size: calc(18px + (22 - 18) * ((100vw - 320px) / (1920 - 320)));
        text-transform: capitalize;
        color: $black;
        margin-bottom: 5px;
      }

      p {
        color: $dark;
        line-height: 1.6;
        letter-spacing: 1px;
      }
    }

    &:hover {
      .contain-wrraper {
        transform: translate(100%);
      }
    }
  }
}

/*=====================
    12.Blog CSS start
==========================*/
.blog {
  .blog-contain {
    margin-right: 15px;
    position: relative;

    .blog-img {
      overflow: hidden;
      border: 1px solid $white;

      img {
        transition: all 0.5s ease;
        width: 100%;
      }
    }

    .blog-details {
      background-color: $white;
      padding: calc(15px + (20 - 15) * ((100vw - 320px) / (1920 - 320)));

      h4 {
        color: $dark;
        margin-bottom: 10px;
        font-size: calc(17px + (18 - 17) * ((100vw - 320px) / (1920 - 320)));
        letter-spacing: 1px;
        font-weight: 600;
      }

      p {
        color: $gray-7;
        text-transform: capitalize;
        margin-bottom: 5px;
        letter-spacing: 0.5px;
      }

      span {
        a {
          color: $theme-color1;
          text-transform: capitalize;
          font-weight: 500;
          letter-spacing: 0.03em;
          line-height: 1;
          font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
        }
      }

    }

    .blog-details-2 {
      background-color: $white;
      padding: 20px;

      h4 {
        text-transform: capitalize;
        color: $theme-color1;
        line-height: 1;
        max-width: 100%;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
      }

      p {
        line-height: 1.6;
        margin-top: 5px;
        text-transform: capitalize;
      }

      ul {
        margin-top: 15px;
        line-height: 1;

        li {
          &:first-child {
            margin-right: 30px;
          }

          a {
            color: $gray-2;
            font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
            text-transform: capitalize;
            font-weight: 500;

            i {
              margin-right: 10px;
            }
          }
        }
      }

      .btn {
        margin-top: 15px;
        width: fit-content;
        display: block;
        padding: 10px 25px;
      }

      .btn-normal {
        border-radius: 5px;
        background-color: rgba($theme-color2, 0.05);
        color: $theme-color2 !important;
        border-color: $theme-color2;

        &:hover {
          background-color: $theme-color2;
          color: $white !important;
        }
      }

      &.text-center {
        .btn {
          margin-left: auto;
          margin-right: auto;
        }
      }
    }

    .blog-label {
      position: absolute;
      top: 5%;
      left: 0;
      background-color: $theme-color1;
      padding: 2px 10px;
      color: $white;
    }

    .blog-label1 {
      position: absolute;
      top: 10px;
      left: 10px;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      color: $white;
      font-size: 14px;
      font-weight: 700;
      text-align: center;
      text-transform: uppercase;
      background-color: $theme-color1;
      line-height: 1.2;

      &.square {
        border-radius: 2px;
        background-color: $theme-color2;
      }
    }

    &.blog-border {
      border: 1px solid $light-border;

      .blog-img {
        border: none;
      }
    }

    &:hover {
      img {
        transform: scale(1.2);
      }
    }
  }

  &.blog-inverce {
    .blog-contain {
      .blog-label1 {
        background-color: $theme-color2;
      }

      .blog-details-2 {
        .btn {
          background-color: $theme-color2;
        }
      }
    }
  }
}

/*============================
    13. Collection banner CSS start
==============================*/
.collection-banner {
  .padding-banner-2 {
    padding-bottom: 15px;
  }

  .collection-banner-main {
    position: relative;
    overflow: hidden;

    .collection-img {
      width: 100%;
      height: 272px;
      transition: all 0.5s ease;

      img {
        width: 100%;
      }
    }

    &.p-left {
      .collection-banner-contain {
        justify-content: flex-start;
      }
    }

    &.p-right {
      .collection-banner-contain {
        justify-content: flex-end;
      }
    }

    &.p-center {
      .collection-banner-contain {
        justify-content: center;
      }
    }

    &.p-top {
      .collection-banner-contain {
        align-items: flex-start;
      }
    }

    &.p-bottom {
      .collection-banner-contain {
        align-items: flex-end;
      }
    }

    .collection-banner-contain {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      align-items: center;
      display: flex;
      padding: 50px;

      h3 {
        color: $theme-color1;
        text-transform: uppercase;
        font-size: 30px;
        line-height: 1;
        margin-bottom: 8px;
      }

      h4 {
        color: $dark;
        font-size: 30px;
        text-transform: uppercase;
        line-height: 1;
      }

      .shop {
        margin-top: 13px;

        a {
          text-transform: capitalize;
          color: $theme-color2;
          font-weight: 700;

          i {
            margin-left: 8px;
          }
        }
      }
    }

    &.banner-1 {
      .collection-banner-contain {
        h3 {
          color: $theme-color1;
          text-transform: capitalize;
          font-size: 36px;
          font-weight: 500;
          margin-bottom: 0;
        }

        h4 {
          color: $theme-color2;
          font-weight: 600;
          font-size: 52px;
          text-transform: capitalize;
        }

        .shop {
          margin-top: 25px;

          a {
            color: $black;
          }

        }
      }
    }

    &.banner-2 {
      .collection-img {
        height: 225px;
      }

      .collection-banner-contain {
        padding: 20px;

        h3 {
          color: $dark;
          text-transform: capitalize;
          font-size: 24px;
          font-weight: 700;
        }

        h4 {
          color: $dark;
          text-transform: capitalize;
          font-size: 24px;
          font-weight: 700;
        }

        .shop {
          margin-top: 18px;

          a {
            text-transform: uppercase;
          }
        }
      }
    }

    &.banner-3 {
      .collection-img {
        height: 465px;
      }

      .collection-banner-contain {
        h3 {
          color: $theme-color2;
          font-size: 36px;
          font-weight: 600;
          margin-bottom: 0;
        }

        h4 {
          margin-top: 18px;
          color: $theme-color1;
          font-size: 18px;
          margin-bottom: 100px;
          text-transform: inherit;
        }
      }
    }

    &.banner-4 {
      .collection-img {
        height: 225px;
      }

      .collection-banner-contain {
        h3 {
          color: $theme-color1;
          text-transform: capitalize;
          font-size: 24px;
          font-weight: 400;
        }

        h4 {
          color: $dark;
          margin-bottom: 0;
          font-weight: 600;
          font-size: 30px;
          text-transform: capitalize;
        }

        .shop {
          margin-top: 18px;

          a {
            text-transform: uppercase;
            color: $theme-color2;
          }
        }
      }
    }

    &.banner-5 {
      .collection-img {
        height: 300px;
      }

      .collection-banner-contain {
        text-align: center;
        padding: 0;

        h3 {
          color: $icon-color;
          text-transform: capitalize;
          font-size: 36px;
          font-weight: 400;
          margin-bottom: 0;
        }

        h4 {
          color: $theme-color1;
          font-weight: 700;
          font-size: 72px;
          text-transform: capitalize;

          span {
            color: $theme-color2;
            font-weight: 500;
          }
        }

        .btn-normal {
          display: inline-block;
        }
      }
    }

    &.banner-6 {
      .collection-banner-contain {
        padding: 25px;

        h6 {
          color: $theme-color1;
          text-transform: capitalize;
          font-weight: 700;
        }

        h4 {
          color: $theme-color2;
          margin-bottom: 0;
          font-weight: 600;
          font-size: 24px;
          text-transform: capitalize;
          margin-top: 5px;
        }
      }
    }

    &.banner-7 {
      .collection-banner-contain {
        padding: 80px;

        h3 {
          color: $theme-color1;
          text-transform: uppercase;
          font-size: 24px;
          font-weight: 400;
        }

        h4 {
          color: $dark;
          margin-bottom: 0;
          font-weight: 600;
          font-size: 36px;
          text-transform: capitalize;
          margin-top: 5px;
        }

        .shop {
          margin-top: 30px;

          a {
            text-transform: uppercase;
            font-weight: 700;
            color: $theme-color2;
          }
        }
      }
    }

    &.banner-8 {
      background-color: $white;
      display: flex;
      align-items: center;
      height: 100%;

      .collection-banner-contain {
        display: flex;
        justify-content: center;
        text-align: center;
        position: initial;

        h3 {
          font-size: 24px;
          color: $dark;

          &:nth-child(2) {
            margin-bottom: 0;
          }
        }

        h1 {
          font-size: 72px;
          color: $theme-color1;
          text-transform: uppercase;
          line-height: 1;
          margin-bottom: 5px;
        }

        h6 {
          color: $icon-color;
          text-transform: uppercase;
          font-weight: 700;
          letter-spacing: 4px;
        }

        .shop {
          margin-top: 5px;
        }
      }
    }

    &.banner-9 {
      .collection-banner-contain {
        h3 {
          color: $theme-color2;
          font-weight: 400;
          text-transform: capitalize;
        }

        h4 {
          color: $theme-color1;
          font-weight: 400;
          text-transform: capitalize;
        }

        a {
          color: $dark;
        }
      }
    }

    //new banenr //
    &.banner-10 {
      .collection-img {
        height: 267px;
      }
    }

    &.banner-11 {
      .collection-img {
        height: 550px;
      }
    }

    &.banner-12 {
      .collection-img {
        height: 300px;
      }
    }

    &.banner-13 {
      .collection-img {
        height: 320px;
      }
    }

    &.banner-14 {
      .collection-img {
        height: 655px;
      }
    }

    &.banner-15 {
      .collection-img {
        height: 512px;
      }
    }

    &.banner-16 {
      .collection-img {
        height: 655px;
      }
    }

    &.banner-17 {
      .collection-img {
        height: 222px;
      }

    }

    &.banner-18 {
      .collection-img {
        height: 260px;
      }
    }







    // font & color css //
    &.collection-colortheme {
      .collection-banner-contain {
        h3 {
          color: $theme-color1;
        }
      }
    }

    &.collection-color1 {
      .collection-banner-contain {
        h3 {
          color: $chocolate;
        }

        .btn {
          background-color: $chocolate;
        }
      }
    }

    &.collection-color2 {
      .collection-banner-contain {
        h3 {
          color: $jade;
        }

        .btn {
          background-color: $jade;
        }
      }
    }

    &.collection-color2 {
      .collection-banner-contain {
        h3 {
          color: $jade;
        }

        .btn {
          background-color: $jade;
        }
      }
    }

    &.collection-color3 {
      .collection-banner-contain {
        h3 {
          color: $bluegreen;
        }

        .btn {
          background-color: $bluegreen;
        }
      }
    }

    &.collection-color4 {
      .collection-banner-contain {
        h3 {
          color: $bluegreen;
        }

        .btn {
          background-color: $bluegreen;
        }
      }
    }

    &.collection-color5 {
      .collection-banner-contain {
        h3 {
          color: $apricot;
        }

        .btn {
          background-color: $apricot;
        }
      }
    }



    &.collection-color6 {
      .collection-banner-contain {
        h3 {
          color: $bg-theme1;
        }

        .btn {
          background-color: $bg-theme1;
        }
      }
    }

    &.collection-color7 {
      .collection-banner-contain {
        h3 {
          color: $bg-theme2;
        }

        .btn {
          background-color: $bg-theme2;
        }
      }
    }

    &.collection-color8 {
      .collection-banner-contain {
        h3 {
          color: $bg-theme3;
        }

        .btn {
          background-color: $bg-theme3;
        }
      }
    }


    &.collection-color9 {
      .collection-banner-contain {
        h3 {
          color: $bg-theme4;
        }

        .btn {
          background-color: $bg-theme4;
        }
      }
    }

    &.collection-color10 {
      .collection-banner-contain {
        h3 {
          color: $bg-theme5;
        }

        .btn {
          background-color: $bg-theme5;
        }
      }
    }

    &.collection-color11 {
      .collection-banner-contain {
        h3 {
          color: $bg-theme6;
        }

        .btn {
          background-color: $bg-theme6;
        }
      }
    }

    &.collection-color12 {
      .collection-banner-contain {
        h3 {
          color: $bg-theme7;
        }

        .btn {
          background-color: $bg-theme7;
        }
      }
    }

    &.collection-color13 {
      .collection-banner-contain {
        h3 {
          color: $bg-theme8;
        }

        .btn {
          background-color: $bg-theme8;
        }
      }
    }

    &.collection-color14 {
      .collection-banner-contain {
        h3 {
          color: $bg-theme9;
        }

        .btn {
          background-color: $bg-theme9;
        }
      }
    }

    &.collection-color15 {
      .collection-banner-contain {
        h3 {
          color: $bg-theme10;
        }

        .btn {
          background-color: $bg-theme10;
        }
      }
    }




    // banner style//
    &.banner-style1 {
      .collection-banner-contain {
        padding: calc(25px + (30 - 25) * ((100vw - 320px) / (1920 - 320)));

        h3 {
          font-size: 16px;
          font-weight: 400;
          font-family: $font-10;
          margin-bottom: 16px;
        }

        h4 {
          font-size: 22px;
          color: $black;
          font-weight: 500;
          font-family: $font-11;
        }

        .shop {
          margin-top: 16px;
        }
      }
    }

    &.banner-style2 {
      .collection-banner-contain {
        padding: 25px;

        h3 {
          font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
        }

        h4 {
          font-size: calc(18px + (24 - 18) * ((100vw - 320px) / (1920 - 320)));
        }
      }
    }

    &.banner-style3 {
      .collection-banner-contain {
        padding: calc(15px + (50 - 15) * ((100vw - 320px) / (1920 - 320)));

        h3 {
          font-size: 14px;
          letter-spacing: 6px;
          color: $black;
          font-weight: 500;
          font-family: $font-12;
          margin-bottom: 15px;
        }

        h4 {
          font-size: calc(20px + (28 - 20) * ((100vw - 320px) / (1920 - 320)));
          color: $black;
          font-weight: 700;
          font-family: $font-13;
          text-transform: capitalize;
          margin-bottom: 16px;
        }
      }
    }

    &.banner-style4 {
      .collection-banner-contain {
        padding: calc(20px + (30 - 20) * ((100vw - 320px) / (1920 - 320)));

        h3 {
          font-size: calc(20px + (30 - 20) * ((100vw - 320px) / (1920 - 320)));
          color: $theme-color1;
          font-weight: 400;
          font-family: $font-15;
          margin-bottom: 9px;
          text-transform: capitalize;
          letter-spacing: 0.05em;
        }

        h4 {
          font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
          color: $black;
          font-weight: 500;
          font-family: $font-8;
          margin-bottom: 12px;
        }
      }
    }

    &.banner-style5 {
      .collection-banner-contain {
        padding: calc(20px + (30 - 20) * ((100vw - 320px) / (1920 - 320)));

        h3 {
          font-size: calc(18px + (25 - 18) * ((100vw - 320px) / (1920 - 320)));
          letter-spacing: 1px;
          font-weight: 600;
          font-family: $font-16;
          text-transform: capitalize;
          margin-bottom: 19px;
        }

        h4 {
          font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
          letter-spacing: 4px;
          color: $black;
          font-weight: 400;
          font-family: $font-12;
          margin-bottom: 18px;
        }
      }
    }

    &.banner-style6 {
      .collection-banner-contain {
        padding: 30px;

        h3 {
          font-size: calc(20px + (28 - 20) * ((100vw - 320px) / (1920 - 320)));
          letter-spacing: 2px;
          font-weight: 400;
          font-family: $font-17;
          margin-bottom: 10px;
          text-transform: uppercase;
        }

        h4 {
          font-size: calc(14px + (17 - 14) * ((100vw - 320px) / (1920 - 320)));
          letter-spacing: 1px;
          color: $black;
          font-weight: 500;
          font-family: $font-8;
          margin-bottom: 15px;
        }
      }
    }

    &.banner-style7 {
      .collection-banner-contain {
        padding: 25px;

        h3 {
          font-size: calc(16px + (23 - 16) * ((100vw - 320px) / (1920 - 320)));
          letter-spacing: 1px;
          font-weight: 400;
          font-family: $font-18;
          text-transform: capitalize;
          margin-bottom: 11px;
        }

        h4 {
          font-size: 14px;
          letter-spacing: 3px;
          color: $black;
          font-weight: 400;
          font-family: $font-12;
          margin-bottom: 14px;
        }
      }
    }

    &.banner-style8 {
      .collection-banner-contain {
        padding: 25px;

        h3 {
          font-size: calc(16px + (20 - 16) * ((100vw - 320px) / (1920 - 320)));
          letter-spacing: 1px;
          color: $white;
          font-weight: 700;
          font-family: $font-19;
          text-transform: capitalize;
          margin-bottom: 12px;
        }

        h4 {
          font-size: calc(18px + (24 - 18) * ((100vw - 320px) / (1920 - 320)));
          color: $black;
          font-weight: 600;
          font-family: $font-4;
          margin-bottom: 12px;
        }

        .btn {
          background-color: $white;
          color: $theme-color1 !important;
        }
      }
    }



    &.collection-13 {
      .collection-img {
        height: 513px;
      }
    }

    &:after {
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 2;
      display: block;
      content: '';
      width: 0;
      height: 0;
      background: rgba(255, 255, 255, 0.2);
      border-radius: 100%;
      transform: translate(-50%, -50%);
      opacity: 0;
    }

    &:hover {
      .collection-img {
        transform: scale(1.1);
      }

      &:after {
        animation: collection 1s;
      }
    }
  }

  .banner-2-padding {
    margin-bottom: 15px;
  }

  @keyframes collection {
    0% {
      opacity: 1;
    }

    40% {
      opacity: 1;
    }

    100% {
      width: 200%;
      height: 200%;
      opacity: 0;
    }
  }

  .collection-p1 {
    >div {

      &:first-child,
      &:last-child {
        >div {
          >div {
            &:nth-child(n+2) {
              margin-top: 15px;
            }
          }
        }
      }

      &:nth-child(2) {
        padding-left: unset;
        padding-right: unset;
      }
    }
  }

  .collection-p2 {
    >div {
      &:first-child {
        >div {
          >div {
            &:first-child {
              padding-right: unset;
            }

            &:nth-child(n+3) {
              margin-top: 15px;
            }
          }
        }
      }

      &:last-child {
        padding-left: unset;
      }
    }
  }

  .collection-p3 {
    >div {
      &:first-child {
        padding-right: unset;

        >div {
          >div {
            &:nth-child(n+2) {
              margin-top: 15px;
            }
          }
        }
      }

      &:last-child {
        >div {
          >div {
            &:first-child {
              padding-right: unset;
            }

            &:nth-child(n+3) {
              margin-top: 15px;
            }
          }
        }
      }
    }
  }

  .collection-p4 {
    >div {
      &:nth-child(2) {
        padding-left: unset;
        padding-right: unset;

        >div {
          >div {
            &:nth-child(n+2) {
              margin-top: 15px;
            }
          }
        }
      }
    }
  }

  .collection-p5 {
    >div {
      &:nth-child(n+2) {
        margin-top: 15px;
      }

      >div {
        >div {
          &:nth-child(n+2) {
            padding-left: unset;
          }
        }
      }
    }
  }

  .collection-p6 {
    >div {
      &:nth-child(n+2) {
        margin-top: 15px;
      }
    }
  }
}

.collection2 {
  >div {
    &:nth-child(2) {
      padding-right: 0;
      padding-left: 0;
    }
  }
}

.collection3 {
  >div {
    &:first-child {
      padding-right: 0;
    }
  }
}

.collection4 {
  >div {
    &:first-child {
      padding-right: 0;
    }
  }
}

.collection5 {
  >div {
    &:first-child {
      margin-bottom: 15px;
    }
  }
}

.layout-4-collection {
  >div {
    &:first-child {
      margin-left: 270px;
    }

    &:nth-child(2) {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.layout-3-collection {
  >div {
    &:first-child {
      padding-right: 0;
    }
  }
}
/*============================
  14. Product CSS start
==============================*/
.product-block {
  >div {

    &:nth-child(n+2) {
      margin-top: 15px;
    }
  }
}
.product-block2 {
  >div {
    padding-left: 0;
    padding-right: 0;

    &:nth-child(n+2) {
      margin-top: 30px;
    }
  }
}
.product-block3 {
  >div {
    padding-right: 0;

    &:nth-child(n+2) {
      margin-top: 15px;
    }
  }
}
.product-block4 {
  >div {
    .product-box {
      padding-right: unset;
    }
    &:nth-child(n+5) {
      margin-top: 30px;
    }
  }
}
.product {
  .product-box {
    padding-right: 15px;

    .product-imgbox {
      position: relative;
      overflow: hidden;
      background-color: $white;
      img {
        margin: 0 auto;
        width: 100%;
      }

      .product-front {
        left: 0;
        top: 0;
        transition: all 0.5s ease;
      }

      .product-back {
        position: absolute;
        top: 0;
        left: 0;
        transition: all 0.5s ease;
        opacity: 0;
      }

      .on-sale {
        background-color: $theme-color2;
        color: $white;
        text-transform: uppercase;
        position: absolute;
        top: 0;
        right: 0;
        padding: 0 6px;
        font-size: calc(10px + (12 - 10) * ((100vw - 320px) / (1920 - 320)));
      }

      .new-label {
        position: absolute;
        top: 0;
        left: 0;
        padding: 5px;
        font-weight: 600;
        letter-spacing: 1px;

        div {
          color: $white;
          text-transform: uppercase;
          transform: rotate(-45deg);
          width: fit-content;
          font-size: calc(10px + (14 - 10) * ((100vw - 320px) / (1920 - 320)));
          margin-top: 3px;
        }

        &::before {
          width: 0;
          height: 0;
          border-top: 60px solid $theme-color1;
          border-right: 60px solid transparent;
          content: '';
          position: absolute;
          top: 0;
          left: 0;
        }
      }

      .product-icon {
        position: absolute;
        bottom: 0;
        right: 0;
        opacity: 0;
        transition: all 0.5s ease;

        button {
          background-color: $white;
          border: 1px solid $light-border;
          color: $icon-color;
          width: 45px;
          height: 45px;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: all 0.5s ease;
          svg {
            width:calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
            transition: all 0.5s ease;
          }
          &:hover{
            background-color: $theme-color1;
            transition: all 0.5s ease;
            svg{
              color:$white;
              transition: all 0.5s ease;
            }
          }
        }
        a {
          background-color: $white;
          border:1px solid $light-border;
          color: $icon-color;
          width: 45px;
          height: 45px;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: all 0.5s ease;
          svg {
            width:calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
          }
          i {
            display: block;
            padding: 15px;
            border: 1px solid $light-border;
            background-color: $white;
            color: $icon-color;
          }

          &:hover{
            background-color: $theme-color1;
            transition: all 0.5s ease;
            svg{
              color:$white;
              transition: all 0.5s ease;
            }
          }
        }

        &.icon-inline {
          bottom: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          opacity: 0;
          transition: all 0.5s ease;
          width: 100%;
          a {
            margin-left: 7px;
          }
        }

        &.icon-center {
          display: flex;
          justify-content: center;
          align-items: center;
          width: fit-content;
          height: fit-content;
          transform: scale(0);         
          position: absolute;
          top: 50%;
          left: 50%;
          margin-right: -50%;
          transform: translate(-50%, -50%)
        }

        &.icon-top {
          bottom: unset;
          top: 0;
        }
      }

      .on-sale1 {
        color: $theme-color2;
        text-transform: uppercase;
        position: absolute;
        top: 40px;
        right: -10px;
        padding: 0 6px;
        font-size: calc(11px + (15 - 11) * ((100vw - 320px) / (1920 - 320)));
        font-weight: 700;
        transform: rotate(-90deg);
      }

      .new-label1 {
        position: absolute;
        top: 15px;
        left: 15px;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background-color: $theme-color1;
        color: $white;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all 0.5s ease;
        text-transform: uppercase;
        font-weight: 700;
        font-size: calc(10px + (14 - 10) * ((100vw - 320px) / (1920 - 320)));
      }

      .on-sale2 {
        color: $theme-color2;
        text-transform: uppercase;
        position: absolute;
        top: 40px;
        right: -10px;
        padding: 0 6px;
        font-size: calc(10px + (15 - 10) * ((100vw - 320px) / (1920 - 320)));
        font-weight: 700;
        transform: rotate(-90deg);
      }

      .new-label2 {
        position: absolute;
        top: 15px;
        left: 15px;
        color: $theme-color2;
        transition: all 0.5s ease;
        text-transform: uppercase;
        font-weight: 700;
        font-size: calc(10px + (14 - 10) * ((100vw - 320px) / (1920 - 320)));
      }

      .on-sale3 {
        position: absolute;
        top: 10px;
        right: 10px;
        color: $theme-color1;
        text-transform: uppercase;
        font-size: calc(10px + (14 - 10) * ((100vw - 320px) / (1920 - 320)));
        font-weight: 700;
      }

      .new-label3 {
        position: absolute;
        top: 10px;
        left: 10px;
        color: $white;
        background-color: $theme-color2;
        padding: 15px 10px;
        text-transform: uppercase;
        font-weight: 700;
        line-height: 1;
        font-size: calc(10px + (14 - 10) * ((100vw - 320px) / (1920 - 320)));
      }

      .on-sale4 {
        background-color: $theme-color2;
        color: $white;
        text-transform: uppercase;
        position: absolute;
        top: 0;
        right: 0;
        padding: 0 6px;
        font-size: calc(10px + (12 - 10) * ((100vw - 320px) / (1920 - 320)));
      }

      .btn-cart {
        position: absolute;
        bottom: 15px;
        left: 0;
        right: 0;
        margin: 0 auto;
        background: $theme-color1;
        color: $white;
        text-transform: capitalize;
        font-weight: 700;
        line-height: 1;
        font-size: 14px;
        padding-top: calc(5px + (10 - 5) * ((100vw - 320px) / (1920 - 320)));
        padding-bottom: calc(5px + (10 - 5) * ((100vw - 320px) / (1920 - 320)));
        padding-left: calc(10px + (20 - 10) * ((100vw - 320px) / (1920 - 320)));
        padding-right: calc(10px + (20 - 10) * ((100vw - 320px) / (1920 - 320)));
        opacity: 0;
        visibility: hidden;

        &:focus {
          box-shadow: none;
          border: none;
          outline: none
        }
      }

      .counter-cart {
        opacity: 0;
        visibility: hidden;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
      }
    }

    &:hover {
      .product-imgbox {
        .btn-cart {
          visibility: visible;
          overflow: hidden;
          opacity: 1;
          animation: fadeInUp 300ms ease-in-out;
        }

        .counter-cart {
          opacity: 1;
          visibility: visible;
          animation: fadeInUp 500ms ease-in-out;
        }
      }

      .product-back {
        opacity: 1;
      }

      .product-icon {
        opacity: 1;
        button {
          animation: fadeInRight 300ms ease-in-out;
        }

        a {
          &:nth-child(2) i {
            animation: fadeInRight 500ms ease-in-out;
          }

          &:nth-child(3) i {
            animation: fadeInRight 700ms ease-in-out;
          }

          &:nth-child(4) i {
            animation: fadeInRight 1000ms ease-in-out;
          }
        }

        &.icon-inline {
          opacity: 1;

          button {
            animation: fadeInUp 300ms ease-in-out;
          }

          a {
            &:nth-child(2) i {
              animation: fadeInUp 500ms ease-in-out;
            }

            &:nth-child(3) i {
              animation: fadeInUp 700ms ease-in-out;
            }

            &:nth-child(4) i {
              animation: fadeInUp 1000ms ease-in-out;
            }
          }
        }

        &.icon-center {
          button {
            animation: none;
          }

          a {
            &:nth-child(2) i {
              animation: none;
            }
          }
        }
      }

      .product-detail {
        &.detail-center {
          .detail-title {
            opacity: 0;
          }
          .icon-detail {
            opacity: 1;
            button {
              animation: fadeInUp 300ms ease-in-out;
            }
            a {
              &:nth-child(2) i {
                animation: fadeInUp 500ms ease-in-out;
              }

              &:nth-child(3) i {
                animation: fadeInUp 700ms ease-in-out;
              }

              &:nth-child(4) i {
                animation: fadeInUp 1000ms ease-in-out;
              }
            }
          }
        }

        &.product-detail2 {
          .size-box {
            display: block;
          }
        }
      }
    }

    .product-detail {
      &.detail-center1 {
        text-align: center;
        padding-bottom: 20px;
        padding-left: 10px;
        padding-right: 10px;
        background-color: $white;

        .rating-star {
          li {
            i {
              color: $rating-star;
              font-size: calc(10px + (14 - 10) * ((100vw - 320px) / (1920 - 320)));
            }

            &:last-child {
              i {
                color: $light-gray;
              }
            }
          }
        }

        h6 {
          font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
          font-weight: 700;
          padding: 3px 0;
          text-transform: capitalize;
          color: $dark;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        .detail-price {
          color: $theme-color2;
          font-weight: 700;
          font-size: calc(12px + (14 - 12) * ((100vw - 320px) / (1920 - 320)));

          span {
            margin-left: 20px;
            color: $dark;
          }
        }
      }

      &.product-detail2 {
        text-align: center;
        padding: 15px;
        background-color: $white;

        ul {
          li {
            margin-right: 0;

            &:nth-child(n+2) {
              margin-left: 2px;
            }

            i {
              color: $rating-star;
            }
          }


        }

        .color-variant {
          line-height: 1;
          width: fit-content;
          margin-left: auto;
          margin-right: auto;

          .check-color {
            width: calc(8px + (12 - 8) * ((100vw - 320px) / (1920 - 320)));
            height: calc(8px + (12 - 8) * ((100vw - 320px) / (1920 - 320)));
            border-radius: 50%;
            display: inline-block;

            &.pro-color1 {
              background-color: $theme-color5;
            }

            &.pro-color2 {
              background-color: $black;
            }

            &.pro-color3 {
              background-color: $theme-color4;
            }

            &.pro-color4 {
              background-color: $bg-theme8;
            }
          }
        }

        .pro-bottom-slide {
          width: 85px;
          margin-left: auto;
          margin-right: auto;
        }

        button {
          border: 1px solid $theme-color1;
          background: $theme-color1;
          outline: none;
          color: $white;
          text-transform: capitalize;
          padding-top: calc(8px + (12 - 8) * ((100vw - 320px) / (1920 - 320)));
          padding-bottom: calc(8px + (12 - 8) * ((100vw - 320px) / (1920 - 320)));
          padding-left: calc(12px + (20 - 12) * ((100vw - 320px) / (1920 - 320)));
          padding-right: calc(12px + (20 - 12) * ((100vw - 320px) / (1920 - 320)));
          line-height: 1;
          font-weight: 600;
          border-radius: 5px;
          margin-top: 10px;
          transition: all 0.5s ease;
          letter-spacing: 0.05em;

          &:hover {
            background-color: transparent;
            color: $theme-color1;
          }
        }

        .size-box {
          margin-top: 10px;
          display: none;
        }


      }

      h3 {
        font-weight: 700;
        font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
        text-transform: capitalize;
        margin-top: 5px;
        margin-bottom: 5px;
        color: $dark;
        max-width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      h5 {
        color: $theme-color1;
        font-weight: 600;
        font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));

        span {
          color: $font-color;
          text-decoration: line-through;
        }
      }

      &.detail-center {
        position: relative;
        overflow: hidden;
        padding: calc(10px + (15 - 10) * ((100vw - 320px) / (1920 - 320)));
        background-color: $white;
        transition: all 0.5s ease;

        .detail-title {
          transition: all 0.5s ease;
          display: block;

          .detail-left {
            width: 100%;
          }

          .detail-right {
            display: flex;
            width: 100%;

            .price {
              font-size: calc(12px + (14 - 12) * ((100vw - 320px) / (1920 - 320)));
              margin-left: 10px;
              color: $theme-color1;
            }
          }
        }
      }

      &.detail-inline {
        background-color: $white;
        padding: calc(10px + (15 - 10) * ((100vw - 320px) / (1920 - 320)));
        overflow: auto;
      }

      &.detail-inverse {
        .detail-title {
          .detail-right {
            .price {
              color: $theme-color2;
            }
          }
        }
      }

      .detail-title {
        padding-top: 10px;

        .detail-left {
          float: left;
          
          .rating-star {
            i {
              color: $rating-star;
              font-size: calc(11px + (14 - 11) * ((100vw - 320px) / (1920 - 320)));

              &:last-child {
                color: $light-gray;
              }
            }
          }

          .price-title {
            text-transform: capitalize;
            color: $dark;
            font-weight: 700;
            font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
          }
        }

        .detail-right {
          float: right;
          font-size: calc(12px + (14 - 12) * ((100vw - 320px) / (1920 - 320)));

          .check-price {
            text-decoration: line-through;
            font-size: calc(12px + (14 - 12) * ((100vw - 320px) / (1920 - 320)));
          }

          .price {
            color: $theme-color2;
            font-size: calc(12px + (14 - 12) * ((100vw - 320px) / (1920 - 320)));
            font-weight: 700;
          }
        }
      }
      .icon-detail {
        position: absolute;
        bottom: 15px;
        width: 100%;
        left: 0;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all 0.5s ease;
        opacity: 0;
        button {
          background-color: $white;
          border: 1px solid $light-border;
          color: $icon-color;
          width: 45px;
          height: 45px;
          display: flex;
          justify-content: center;
          align-items: center;
          transition: all 0.5s easer;
          svg {
            width: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
            color: $icon-color;
            transition: all 0.5s easer;
          }
          &:hover{
            background-color: $theme-color1;
            svg{
              color: $white;
            }
          }
        }
        a {
          width: 45px;
          height: 45px;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: $white;
          border: 1px solid $light-border;
          transition: all 0.5s ease;
          svg {
            width: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
            color: $icon-color;
            transition: all 0.5s ease;
          }
          i {
            background-color: $white;
            padding: 15px;
            border: 1px solid $light-border;
            color: $icon-color;
          }
          &:hover{
            background-color: $theme-color1;
            svg{
              color: $white;
            }
          }
        }
      }
    }

    &.product-box2 {
      .product-icon {
        button {
          border-radius: 50%;
          transition: all 0.5s ease;
          width: 45px;
          height: 45px;
          display: flex;
          align-items: center;
          justify-content: center;

          svg {
            color: $font-color;
            width: 18px;
          }

          &:hover {
            background-color: $theme-color1;
            color: $white-1;
          }
        }

        a {
          width: 45px;
          height: 45px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;

          svg {
            width: 18px;
            color: $font-color;
          }

          i {
            border-radius: 50%;
            transition: all 0.5s ease;

            &:hover {
              background-color: $theme-color1;
              color: $white-1;
            }
          }
        }
      }

      &:hover {
        .product-icon {
          bottom: 45%;
        }
      }
    }

    .tab-content {
      display: block;
    }

    .nav-tabs {
      border: unset;

      .nav-link {
        border: unset;
        padding: 0;

        &.active {
          border: unset;
          background-color: unset;
        }
      }
    }

  }
}
.product-box3 {
  background-color: $white;
  padding: 15px;
  margin-right: 15px;
  .product-detail {
    text-align: left;
    padding-left: 20px;
    padding-top: 0;
    .rating {
      margin-bottom: 12px;
      line-height: 1;
      li {
        a {
          i {
            color: $rating-star;
          }
        }
      }
    }
    h3 {
      margin-bottom: 12px;
      text-transform: capitalize;
      font-weight: 600;
      font-size: calc(16px + (22 - 16) * ((100vw - 320px) / (1920 - 320)));
      color: $black;
    }

    h4 {
      color: $gray-4;
      font-weight: 600;
      margin-bottom: 12px;
      font-size: calc(12px + (14 - 12) * ((100vw - 320px) / (1920 - 320)));

      span {
        text-decoration: line-through;
        color: $theme-color2;
        margin-left: 10px;
      }
    }
  }

  .media {
    align-items: center;
  }
}
.no-slider {
  .product-box {
    width: 100%;
    flex: 0 0 25%;
    max-width: calc(25% - 30px);
    margin: 0 15px 30px;
    padding-right: 0;

    &:nth-last-child(-n+4) {
      margin: 0 15px 0;
    }
  }

  &.five-product {
    .product-box {
      width: 100%;
      flex: 0 0 20%;
      max-width: calc(20% - 30px);
      margin: 0 15px 30px;

      &:nth-last-child(-n+5) {
        margin: 0 15px 0;
      }
    }
  }
}
.product-border {
  border: 1px solid $light-gray;
}
.digipro-box {
  border-radius: 10px;
  border: 1px solid $gray-8;
  background-color: $white;
  margin-right: 15px;
  margin-left: 15px;
  overflow: hidden;
  &:hover{
    .img-wrraper {
      img {
        transform: scale(1.05);
        transition: all 0.3s ease;
      }
    }
   
  }
  .img-wrraper {
    img {
      width: 100%;
      transition: all 0.3s ease;
    }
  }

  .product-detail {
    padding: 20px;

    h4 {
      font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
      color: rgba($black, 0.7);
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      margin-bottom: 5px;
    }

    h5 {
      color: $dark;
      font-size: 14px;
      text-transform: capitalize;
      font-weight: 400;
    }

    .sale-box {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      margin-top: 10px;

      ul {
        li {
          i {
            color: $rating-star
          }
        }
      }

      h4.price {
        color: $dark;
      }

      .pro-sale {
        h4 {
          color: $dark;
        }
      }
    }

    .pro-btn {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 8px;

      .btn {
        padding: 10px 15px;
        text-transform: uppercase;
        border-radius: 5px;
        font-size: 12px;

        &:focus {
          box-shadow: none;
          border: none;
          outline: none;
        }

        &:last-child {
          background-color: $theme-color2;
        }
      }
    }
  }
}

/*============================
   15. Hot Deal CSS start
==============================*/
.hotdeal-right-nav {
  height: 100%;
  display: flex;
  align-items: center;
}

.hot-deal {
  &.space-abjust {
    .hot-deal-contain {
      padding: 25px;
    }
  }

  .hot-deal-heading {
    margin-bottom: 25px;

    h3 {
      font-size: 18px;
      text-transform: uppercase;
      color: $dark;
    }
  }

  .hotdeal-block1 {
    >div {
      &:nth-child(2) {
        padding-left: 25px;
      }
    }
  }

  &.hotdeal-first {
    .hot-deal-contain {
      .hotdeal-right-slick {
        .img-wrraper {
          >div {
            width: 100%;
            height: 455px;
          }
        }
      }
    }
  }

  &.hotdeal-first,
  &.hotdeal-third {
    .hot-deal-contain {
      h5 {
        font-size: calc(18px + (26 - 18) * ((100vw - 320px) / (1920 - 320)));
        margin-bottom: 10px;
      }

      .rating {
        margin-bottom: 12px;
      }

      p {
        margin-bottom: 12px;
      }

      .price {
        margin-bottom: 18px;
      }

      .timer {
        margin-bottom: 30px;
      }
    }
  }

  &.hotdeal-two {
    .hot-deal-contain {
      padding: 30px;

      &.b-g-white {
        background-color: $white
      }

      h5 {
        font-size: calc(16px + (20 - 16) * ((100vw - 320px) / (1920 - 320)));
        margin-bottom: 8px;
      }

      .rating {
        margin-bottom: 8px;
      }

      p {
        margin-bottom: 7px;
      }

      h6 {
        margin-bottom: 5px;
      }

      .timer {
        margin-bottom: 15px;

        span {
          display: inline-grid;
          background-color: transparent;
          text-align: center;
          font-weight: 700;
          color: $black;
          margin: 0 4px;
          min-width: fit-content;
          font-size: calc(18px + (24 - 18) * ((100vw - 320px) / (1920 - 320)));
          padding: 0;
          line-height: 1;

          &:first-child {
            margin-left: 0;
          }

          &:last-child {
            margin-right: 0;
          }

          .timer-cal {
            font-size: 12px;
            color: $icon-color;
          }

          >span {
            font-weight: 400;
            font-size: 12px;
            padding: 0;
            margin-left: 0;
            text-transform: capitalize;
          }
        }

        p {
          margin: 0;
        }
      }

      .img-wrraper {
        border: 1px solid $light-border;
        margin-right: 2px;

        >div {
          width: 100%;
          height: 260px;
        }
      }

      .hotdeal-right-nav-1 {
        img {
          width: 65px;
          border: 1px solid $light-border;
          margin: 0 0 10px auto;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      .btn {
        padding: 12px 25px;
      }
    }
  }

  &.hotdeal-third {
    .hot-deal-contain {
      padding-top: 30px;

      .timer {
        span {
          background-color: $gray-5;
        }
      }

      .hotdeal-right-slick {
        .img-wrraper {
          >div {
            width: 100%;
            height: 411px;
          }
        }
      }
    }
  }

  .hot-deal-contain {
    background-color: $body-back;
    padding: 50px;

    h5 {
      color: $dark;
      letter-spacing: 0.05em;
      text-transform: capitalize;
    }

    .rating {
      line-height: 1;

      i {
        color: $rating-star;
      }
    }

    p {
      line-height: 1.6;
      letter-spacing: 0.05em;
    }

    h6 {
      color: $theme-color1;
      font-weight: 700;
      font-size: 16px;
      line-height: 1;
      margin-bottom: 18px;

      span {
        margin-left: 10px;
        color: $gray-4;
        text-decoration: line-through;
      }
    }

    .timer {
      span {
        display: inline-grid;
        background-color: $white;
        padding: 12px 0 18px;
        text-align: center;
        font-size: calc(18px + (24 - 18) * ((100vw - 320px) / (1920 - 320)));
        font-weight: 700;
        color: $black;
        min-width: 60px;

        &:nth-child(n+2) {
          margin-left: calc(2px + (8 - 2) * ((100vw - 320px) / (1920 - 320)));
        }

        .padding-l {
          display: none;
        }

        .timer-cal {
          font-size: 12px;
          color: $icon-color;
        }

        >span {
          color: $gray-4;
          font-weight: 400;
          font-size: 14px;
          padding: 0;
          margin-left: 0;
          text-transform: capitalize;
        }
      }

      p {
        margin-bottom: 0;
      }
    }

    .btn {
      border-radius: 5px;
      text-transform: capitalize;
      display: block;
      width: fit-content;
    }

    .hot-deal-subcontain {
      display: flex;
      align-items: center;
    }

    &.hot-deal-contain2 {
      padding-top: 30px;

      .hot-deal-center {
        .timer {
          span {
            background-color: $gray-5;
          }
        }
      }
    }

    .hotdeal-right-slick {
      &.border {
        border: 1px solid $light-border;
        transition: all 0.3s ease;
        line-height: 0.8;

        &:hover {
          border: 1px solid $theme-color1;
        }
      }
    }

    .hotdeal-right-nav {
      img {
        height: 130px;
        width: auto;
        margin-left: auto;
        border: 1px solid $light-border;
        transition: all 0.3s ease;

        &:hover {
          border: 1px solid $theme-color1;
        }
      }
    }

    .slick-slider {
      .slick-arrow {
        display: none !important;
      }
    }
  }


  .slick-prev {
    &:before {
      color: $gray-2;
      opacity: 1;
      font: normal normal normal 30px/1 FontAwesome;
      content: '\f104';
    }

    top: 35px;
    right: 50px;
    left: unset;
    z-index: 9;
  }

  .slick-next {
    &:before {
      color: $gray-2;
      opacity: 1;
      content: '\f105';
      font: normal normal normal 30px/1 FontAwesome;
    }

    top: 35px;
    right: 25px;
    z-index: 9;
  }
}

.hot-deal-padding {
  padding-right: 0;
  padding-left: 0;
}

.hot-1 {
  >div {
    &:first-child {
      padding-right: 0;
    }

    &:nth-child(2) {
      padding-left: 0;
      padding-right: 0;
    }

    &:last-child {
      padding-left: 0;
    }
  }
}

.hotdeal-block2 {
  >div {
    padding-right: 0;
  }
}

.hotdeal-second {
  .hotdeal-box {
    background-color: $white;
    margin-right: 15px;
    display: flex;
    align-items: center;

    .img-wrapper {
      width: 50%;

      a {
        width: 100%;
        height: 330px;
      }
    }

    .hotdeal-contain {
      padding: 20px;
      width: 50%;

      h3 {
        text-transform: capitalize;
        color: $black;
        font-weight: 600;
        margin-bottom: 8px;
        line-height: 1;
        font-size: calc(16px + (24 - 16) * ((100vw - 320px) / (1920 - 320)));
      }

      h5 {
        margin-bottom: 5px;
        color: $theme-color1;

        span {
          text-decoration: line-through;
          color: $gray-4;
        }
      }

      p {
        margin-bottom: 5px;
      }

      ul {
        margin-bottom: 15px;

        li {
          i {
            color: $rating-star;
          }
        }
      }

      .timer2 {
        margin-bottom: 20px;

        p {
          display: flex;
        }

        span {
          display: inline-grid;
          background-color: rgba($theme-color1, 0.08);
          padding-top: 10px;
          padding-bottom: 10px;
          text-align: center;
          font-size: 20px;
          font-weight: 700;
          color: $theme-color1;
          min-width: 55px;
          border-radius: 3px;

          &:nth-child(n+2) {
            margin-left: 5px;
          }

          >span {
            background-color: transparent;
            border: none;
            padding: 0;
            text-transform: capitalize;
            font-size: 14px;
            color: $theme-color2;
          }
        }
      }

      .btn {
        text-transform: capitalize;
      }
    }
  }
}

.layout-3-hotdeal {
  >div {
    &:first-child {
      padding-right: 0;
    }

    &:nth-child(2) {
      padding-right: 0;
    }

    &:nth-child(4) {
      padding-left: 0;
    }
  }
}


/*============================
   16. Header CSS start
==============================*/
.top-header {
  background-color: $theme-color1;
  padding: 10px 0;
  z-index: 99;

  .top-header-left {
    display: flex;
    align-items: center;

    .shpping-order {
      margin-right: 35px;

      h6 {
        font-family: $font-2, $font_1;
        color: $white;
        text-transform: capitalize;
        font-size: 13px;
      }
    }

    .app-link {
      display: flex;
      align-items: center;

      h6 {
        font-size: 13px;
        color: $white;
        text-transform: capitalize;
        font-family: $font-2, $font_1;
      }

      ul {
        li {
          margin-left: 10px;

          a {
            i {
              color: $white;
            }
          }
        }
      }
    }
  }

  .top-header-right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 100%;

    .top-menu-block {
      text-align: right;

      ul {
        padding: 0;

        li {
          margin-right: 20px;

          a {
            color: $white;
            text-transform: capitalize;
            font-family: $font-2, $font_1;
            font-size: 13px;
            font-weight: 800;
            letter-spacing: 1px;
            height: 100%;
          }

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }

    .language-block {
      margin-left: 20px;
      display: flex;

      .language-dropdown {
        position: relative;

        .language-dropdown-click {
          color: $white;
          font-family: $font-2, $font_1;
          font-size: 13px;
          font-weight: 800;
          text-transform: capitalize;

          i {
            margin-left: 10px;
          }
        }

        .language-dropdown-open {
          background-color: $white;
          box-shadow: 0 0 1px 0 $light-border;
          padding: 10px 20px;
          margin-top: 10px;
          position: absolute;
          top: 100%;
          right: 0;
          left: unset;
          z-index: 11;

          li {
            display: block;
            margin-bottom: 10px;

            &:last-child {
              margin-bottom: 0;
            }

            a {
              color: $dark;
              font-size: 12px;
              font-family: $font-2, $font_1;
              text-transform: capitalize;
            }
          }
        }
      }

      .curroncy-dropdown {
        position: relative;
        margin-left: 20px;

        .curroncy-dropdown-click {
          color: $white;
          font-family: $font-2, $font_1;
          font-size: 13px;
          font-weight: 800;
          text-transform: capitalize;

          i {
            margin-left: 10px;
          }
        }

        .curroncy-dropdown-open {
          background-color: $white;
          box-shadow: 0 0 1px 0 $light-border;
          margin-top: 10px;
          padding: 10px 20px;
          position: absolute;
          top: 100%;
          right: 0;
          left: unset;
          z-index: 11;

          li {
            display: block;
            margin-bottom: 8px;

            &:last-child {
              margin-bottom: 0;
            }

            a {
              color: $dark;
              font-size: 12px;
              font-family: $font-2, $font_1;
              text-transform: capitalize;
              display: flex;
              align-items: center;

              i {
                margin-right: 10px;
              }
            }
          }
        }
      }
    }
  }
  &.top-header-inverse {
    background-color: $theme-color2;
  }
}

.input-block {
  .input-box {
    .big-deal-form {
      width: 100%;
      background-color: $white;
      border: 1px solid $light-border;
      .input-group {
        height: 58px;
        .form-control {
          border-radius: 0;
          border: none;
          border-left: 1px solid $light-border;
          border-right: 1px solid $light-border;
          color: $gray-7;
          &:focus {
            box-shadow: none;
          }
        }
        span {
          padding: 10px 20px;
          background-color: $white;
          align-self: center;
          i {
            font-size: 20px;
            color: $gray-7;
          }
        }
        select {
          appearance: none;
          border: none;
          outline: none;
          font-weight: 700;
          color: $gray-7;
          background: url("../images/layout-1/drop-1.png") no-repeat scroll 20px 23px;
          text-align: center;
          text-align-last: center;
          width:175px;
          height:100%;
          text-transform: uppercase;
        }
        .input-group-text{
          background-color:transparent;
          border:none;
          padding:0;
        }
      }
    }
  }
}




.layout-header1 {
  background-color: $white;
  padding-top: calc(10px + (20 - 10) * ((100vw - 320px) / (1920 - 320)));
  padding-bottom: calc(10px + (20 - 10) * ((100vw - 320px) / (1920 - 320)));
  position: relative;
  .main-menu-block {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 10;
    .menu-left {
      display: flex;
      align-items: center;
      .sm-nav-block {
        margin-right: 15px;
        cursor: pointer;
        display: none;

        span {
          i {
            font-size: 24px;
          }
        }

        .nav-slide {
          position: fixed;
          top: 0;
          left: -300px;
          width: 300px;
          border-radius: 0;
          background-color: $white;
          border: 1px solid $light-border;
          height: 100vh;
          z-index: 99;
          transition: all 0.3s ease;
          overflow: scroll;

          >li {
            display: block;
            border-bottom: 1px solid $light-border;
            padding: 10px 15px;

            &.mor-slide-open {
              ul {
                li {
                  padding-left: 0;
                }
              }
            }

            a {
              color: $font-color;
              text-transform: capitalize;
              font-size: 14px;
              font-weight: 600;
            }

            &:first-child {
              padding: 0;
            }

            &:last-child {
              border-bottom: none;

              a {
                color: $theme-color1;

                i {
                  font-size: 20px;
                  margin-left: 10px;
                  float: right;
                }
              }
            }

            .nav-sm-back {
              font-size: 16px;
              color: $dark;
              font-weight: 700;
              text-transform: uppercase;
              border-bottom: 1px solid $light-border;
              text-align: right;   
              padding: 15px;           
            }
            &:hover {
              >li {
                a {
                  color: $theme-color1;
                }
              }
            }
          }

          li {
            display: block;
            border-bottom: 1px solid $light-border;
            padding: 7px 10px;
          }
        }
      }
    }

    .menu-right {
      display: flex;
      float: right;
      align-items: center;

      .toggle-nav {
        margin-left: 15px;
      }

      .icon-nav {
        display: flex;
        height: 100%;
        align-items: center;
        ul {
          li {
            position: relative;
            svg {
              fill: $gray-2;
              width: calc(20px + (28 - 20) * ((100vw - 320px) / (1920 - 320)));
            }
            a{
              display:flex;
              align-items:center;
            }

            .cart-item {
              display: inline-block;
              color: $theme-color1;
              font-weight: 700;
              text-transform: uppercase;
              font-family: $font-1;
              margin-left: 10px;

              span {
                display: block;
                color: $black;
                margin-top: 3px;
              }
            }

            &.cart-block {
              svg {
                fill: $white;
              }
            }
            .item-count-contain {
              position: absolute;
              top: -8px;
              left: 13px;
              padding-bottom: 1px;
            }
            &.cart-block {
              background-color: $theme-color1;
              padding: 15px 20px;
              margin-left: 20px;
              position: relative;

              i {
                font-size: 29px;
                color: $white;
              }

              .cart-product {
                background-color: $white;
                width: 16px;
                height: 16px;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 11px;
                position: absolute;
                top: 3px;
                right: 15px;
                line-height: 0;
                color: $theme-color1;
              }

              .item-count-contain {
                position: absolute;
                top: 3px;
                left: 29px;
              }
            }

            &.mobile-search,
            &.mobile-setting {
              display: none;
            }
          }
        }
      }

      .menu-banner {
        margin-top: 20px;
      }
    }
  }

  &.layout-header1-inverse {
    .main-menu-block {
      .menu-right {
        .icon-nav {
          .cart-block {
            background-color: $theme-color2;
          }
        }
      }
    }
  }

  .searchbar-input {
    display: none;
  }
}

.category-header {
  background-color: $theme-color1;

  .navbar-menu {
    display: flex;
    justify-content: space-between;
    position: relative;
    align-items: center;
    z-index: 9;

    .category-left {
      display: flex;

      .nav-block {
        min-width: 270px;
        margin-right: 10px;

        .nav-left {
          position: absolute;
          top: 0;
          width: 270px;
          z-index: 1;
          overflow: hidden;

          .navbar {
            display: flex;
            justify-content: center;
            background-color: $dark;
            padding: 28px 0;

            h5 {
              text-transform: uppercase;
              font-size: 14px;
              letter-spacing: 1px;
              margin-left: 16px;
            }
          }

          .navbar-toggler {
            width: 17px;
            height: 17px;
            padding: 0;
            background-color: $white;
            color: $dark;
            border-radius: 50%;
            font-size: 12px;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          .nav-cat {
            padding: 20px;
            margin-top: 15px;
            margin-right: 10px;
            background-color: $white;

            li {
              display: block;
              padding-bottom: 10px;

              &:last-child {
                padding-bottom: 0;
              }

              a {
                color: $dark;
                font-size: 14px;
                text-transform: capitalize;
                letter-spacing: 0.03em;
              }

              img {
                margin-right: 10px;
                border: 2px solid $img-border;
                border-radius: 50%;
                padding: 3px;
                height: 46.5px;
                transition: all 0.5s ease;
              }

              &:hover {
                >a {
                  color: $theme-color1;
                }

                >img {
                  border: 2px solid $theme-color2;
                }
              }

            }

            .mor-slide-click {
              color: $theme-color1;
              font-size: 16px;
              font-weight: 700;
              text-transform: capitalize;
              line-height: 1;
              display: block;
              width: 100%;
              margin-top: 10px;
              letter-spacing: 0.5px;
              background-color: transparent;
              border: none;
              outline: none;
              text-align: left;

              i {
                float: right;
                font-size: 20px;
              }

            }
          }
        }
      }

      .input-block {
        width: 745px;
      }
    }

    .category-right {
      display: flex;

      .gift-block {
        background-color: $theme-color2;
        padding: 17px 28px;
        display: flex;
        align-items: center;
        color: $white;
        width: 255px;
        margin-left: 15px;
        border: 0;

        .grif-icon {
          i {
            font-size: 35px;
          }
        }

        .gift-offer {
          margin-left: 10px;

          p {
            text-transform: uppercase;
            line-height: 1;
          }

          span {
            font-size: 20px;
            text-transform: capitalize;
            font-weight: 700;
            line-height: 1.2;
          }
        }
      }

      .contact-block {
        padding: 20px 0;
        align-self: center;

        i {
          font-size: 28px;
          color: $white;
        }

        span {
          font-size: 14px;
          color: $white;
          margin-left: 15px;
          text-transform: uppercase;
          letter-spacing: 1px;

          span {
            font-size: calc(14px + (20 - 14) * ((100vw - 320px) / (1920 - 320)));
            font-weight: 600;
          }
        }
      }
    }
  }

  &.category-header-inverse {
    .navbar-menu {
      .category-left {
        .nav-block {
          .nav-left {
            .navbar {
              background-color: $theme-color2;
            }

            .navbar-toggler {
              color: $theme-color2;
            }
          }
        }
      }
    }
  }
}

.layout-header2 {
  background-color: $white;
  padding-top: calc(10px + (25 - 10) * ((100vw - 320px) / (1920 - 320)));
  padding-bottom: calc(10px + (25 - 10) * ((100vw - 320px) / (1920 - 320)));
  position: relative;

  .main-menu-block {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .sm-nav-block {
      margin-right: 15px;
      display: none;

      span {
        i {
          font-size: 24px;
        }
      }

      .nav-slide {
        position: fixed;
        top: 0;
        left: -300px;
        width: 300px;
        border-radius: 0;
        background-color: $white;
        border: 1px solid $light-border;
        height: 100vh;
        z-index: 99;
        transition: all 0.3s ease;
        overflow: scroll;

        li {
          display: block;
          border-bottom: 1px solid $light-border;
          padding: 10px;

          &.mor-slide-open {
            ul {
              li {
                padding-left: 0;
              }
            }
          }

          a {
            color: $dark;
            text-transform: capitalize;
            font-size: 14px;
            font-weight: 500;
          }

          &:last-child {
            border-bottom: none;

            a {
              i {
                font-size: 20px;
                margin-left: 10px;
                float: right;
              }
            }
          }

          .nav-sm-back {
            font-size: 20px;
            color: $dark;
            font-weight: 700;
            text-transform: uppercase;
            border-bottom: 1px solid $light-border;
            text-align: right;            
            padding: 17px;
          }

          .mor-slide-click {
            color: $theme-color1;
          }

          &:hover {
            >li {
              a {
                color: $theme-color2;
              }
            }
          }
        }
      }
    }

    .brand-logo {
      margin-right: 45px;
    }

    .input-block {
      width: 750px;
    }

    .icon-block {
      margin-left: 30px;
      display: flex;
      align-items: center;

      ul {
        li {
          position: relative;
          display: none;

          svg {
            width: 32px;
            fill: $dark;
          }

          .cart-block {
            position: relative;
            width: fit-content;
            display: flex;
            align-items: center;

            .cart-item {
              margin-left: 10px;

              h5 {
                color: $dark;
                text-transform: uppercase;
                letter-spacing: 0.03em;
                font-family: $font-1;
              }
            }
          }

          .item-count-contain {
            position: absolute;
            top: -16px;
            left: 16px;
          }



          &:nth-child(n+5) {
            display: inline-block;
          }


        }
      }
    }

    .menu-nav {
      display: none;
    }
  }

  .searchbar-input {
    display: none;
  }
}

.category-header-2 {
  background-color: $theme-color2;
  position: relative;
  .navbar-menu {
    display: flex;
    justify-content: space-between;
    position: relative;
    z-index: 9;

    .logo-block {
      display: none;
    }

    .nav-block {
      min-width: 255px;

      .nav-left {
        position: absolute;
        width: 255px;
        z-index: 1;
        overflow: hidden;

        .navbar {
          display: flex;
          justify-content: center;
          background-color: $theme-color1;
          padding: 28px 0;
          cursor: pointer;

          h5 {
            text-transform: uppercase;
            font-size: 14px;
            letter-spacing: 1px;
            margin-left: 16px;
          }
        }

        .navbar-toggler {
          width: 17px;
          height: 17px;
          padding: 0;
          background-color: $white;
          color: $theme-color1;
          border-radius: 50%;
          font-size: 12px;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .nav-cat {
          padding: 20px;
          margin-top: 15px;
          background-color: $white;

          li {
            display: block;
            padding-bottom: 10px;
            a {
              color: $dark;
              font-size: 14px;
              text-transform: capitalize;
              letter-spacing: 0.03em;             
            }

            img {
              margin-right: 10px;
              border: 2px solid $img-border;
              border-radius: 50%;
              padding: 3px;
              transition: all 0.5s ease;
            }

            &:hover {
              >a {
                color: $theme-color1;
              }

              >img {
                border: 2px solid $theme-color2;
              }
            }

            .mor-slide-click {
              color: $theme-color1;
              font-size: 16px;
              font-weight: 700;
              text-transform: capitalize;
              display: block;
              width: 100%;
              margin-top: 10px;
              letter-spacing: 0.5px;

              i {
                font-size: 20px;
                float: right;
              }
            }

          }
        }
      }
    }

    .menu-block {
      display: flex;
      align-items: center;

      .pixelstrap {
        li {
          .label-nav {
            background-color: $theme-color1;
            top: 7px;

            &:before {
              border-top: 5px solid $theme-color1;
            }
          }
        }

        .dark-menu-item {
          padding-top: 25px;
          padding-bottom: 25px;
          color: white;
        }
      }
    }
    .icon-block{
      display: flex;
      height: 100%;
      align-items: center;
      align-self: center;
      cursor: pointer;
      ul {
        li {
          position: relative;
          a{
            display: flex;
            align-items:center;
          }
          &:nth-child(n+2) {
            margin-left: 20px;
          }
          .item-count-contain {
            position: absolute;
            top: -9px;
            left: 14px;
          }
          .cart-item {
            display: inline-block;
            font-weight: 700;
            text-transform: uppercase;
            font-family: $font-1;
            letter-spacing: 0.5px;
            color: $white;
            margin-left:10px;
            span {
              display: block;
              margin-top: 3px;
            }
          }
          svg {
            width: 30px;
            height: auto;
            fill: $white;
          }
          &.mobile-search,
          &.mobile-setting,
          &.mobile-cart {
            display: none;
          }
        }
      }
    }

    .category-right {
      display: flex;
      align-items: center;

      .gift-block {
        background-color: $theme-color1;
        padding: 17px 28px;
        display: flex;
        align-items: center;
        color: $white;
        width: 255px;
        border: 0;
        cursor: pointer;

        .grif-icon {
          i {
            font-size: 35px;
          }
        }

        .gift-offer {
          margin-left: 10px;

          p {
            text-transform: uppercase;
            line-height: 1;
          }

          span {
            font-size: 20px;
            text-transform: capitalize;
            font-weight: 700;
            line-height: 1.2;
          }
        }
      }

      .contact-block {
        padding: 20px 28px;
        align-self: center;
        background-color: darken($theme-color2, 8%);

        i {
          font-size: 28px;
          color: $white;
        }

        span {
          font-size: 14px;
          color: $white;
          margin-left: 10px;
          text-transform: uppercase;
          letter-spacing: 1px;

          span {
            font-size: 20px;
            font-weight: 600;
          }
        }
      }
    }
  }

  .searchbar-input {
    display: none;
  }
}

.layout-header3 {
  background-color: $theme-color2;
  position: relative;
  .main-menu {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 15px;
    padding-bottom: 15px;
    .menu-left {
      display: flex;
      align-items: center;
      position: relative;
      .sm-nav-block {
        margin-right: 15px;
        display: none;
        span {
          i {
            font-size: 25px;
            color: $white;
          }
        }
        .nav-slide {
          position: fixed;
          top: 0;
          left: -300px;
          width: 300px;
          border-radius: 0;
          background-color: $white;
          border: 1px solid $light-border;
          height: 100vh;
          z-index: 99;
          transition: all 0.3s ease;
          overflow: scroll;
          li {
            display: block;
            border-bottom: 1px solid $light-border;
            padding: 7px 10px;
            a {
              color: $dark;
              text-transform: capitalize;
              font-size: 14px;
              font-weight: 600;
            }
          }
          >li {
            &:last-child {
              border-bottom: none;
              a {
                color: $theme-color1;
                i {
                  font-size: 20px;
                  margin-left: 10px;
                  float: right;
                }
              }
            }
            .nav-sm-back {
              font-size: 18px;
              color: $dark;
              font-weight: 700;
              text-transform: uppercase;
              border-bottom: 1px solid $light-border;
              text-align: right;   
              padding: 9px;           
            }
            &:hover {
              >li {
                a {
                  color: $theme-color1;
                }
              }
            }
          }
        }
      }
      .nav-block {
        min-width: 255px;
        .nav-left {
          position: absolute;
          z-index: 1;
          overflow: hidden;
          width: 255px;
          top: 0;
          .navbar {
            padding: 28px 0;
            justify-content: center;
            h5 {
              text-transform: uppercase;
              font-size: 14px;
              letter-spacing: 1px;
              margin-left: 16px;
            }
          }
          .navbar-toggler {
            width: 17px;
            height: 17px;
            padding: 0;
            background-color: $white;
            color: $theme-color2;
            border-radius: 50%;
            font-size: 12px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .nav-cat {
            padding: 20px;
            margin-top: 25px;
            background-color: $white;
            li {
              display: block;
              padding-bottom: 10px;
              a {
                color: $dark;
                font-size: 14px;
                text-transform: capitalize;
                letter-spacing: 0.03em;
              }

              img {
                margin-right: 10px;
                border: 2px solid $img-border;
                border-radius: 50%;
                padding: 3px;
                transition: all 0.5s ease;
              }

              &:hover {
                >a {
                  color: $theme-color1;
                }

                >img {
                  border: 2px solid $theme-color2;
                }
              }
            }
            .mor-slide-click {
              a {
                color: $theme-color1;
                font-size: 16px;
                font-weight: 700;
                display: block;
                width: 100%;
                text-transform: capitalize;
                line-height: 1;
                letter-spacing: 0.5px;
                margin-top: 10px;

                i {
                  font-size: 20px;
                  float: right;
                }
              }
            }
          }
        }
      }
    }
    .menu-nav {
      span {
        i {
          color:$white;
          font-size: 25px;
        }
      }
      .desc-horizontal {
        position: fixed;
        background-color: $white;
        width: 300px;
        border-radius: 0;
        border: 1px solid $light-border;
        top: 0;
        height: 100vh;
        right: -300px;
        z-index: 99;
        transition: all 0.3s ease;
        .desc-back {
          padding: 20px;
          font-size: 18px;
          color: $dark;
          font-weight: 700;
          text-transform: uppercase;
          border-bottom: 1px solid $light-border;
          cursor: pointer;
          display: flex;
          justify-content: flex-end;
          align-items: center;
        }
        li {
          .label-nav {
            top: -10px;
            left: 20px;
          }
        }
      }
    }
    .input-block {
      .input-box {
        width:600px;
        .big-deal-form {
          width:100%;
          background-color:$white;
          border:1px solid $light-border;
          .input-group {           
            height:43px;
            .form-control {
              border-radius:0;
              border: none;
              padding: 0 13px;
              &:focus {
                box-shadow: none;
              }
            }
            span {
              padding: 10px 20px;
              background-color: $theme-color1;
              align-self: center;
              i {
                font-size: 20px;
                color: $white;
              }
            }
            select {
              appearance:none;
              border:none;
              outline:none;
              font-weight:700;
              color: $dark;
              background: url("../images/layout-1/drop-1.png") no-repeat scroll 20px;
              text-align: center;
              text-align-last: center;
              width: 175px;
              text-transform: uppercase;
              border-left: 1px solid $light-border;
            }
          }
        }
      }
    }

    .icon-block {
      display: flex;
      height: 100%;
      align-items: center;
      align-self: center;
      ul {
        li {
          position: relative;

          svg {
            fill: $white;
            width: calc(20px + (30 - 20) * ((100vw - 320px) / (1920 - 320)));
          }

          &.mobile-cart {
            .item-count-contain {
              position: absolute;
              top: -10px;
              left: 9px;
            }
          }

          &.mobile-wishlist {
            .cart-item {
              display: inline-block;
              color: $white;
              font-weight: 400;
              text-transform: uppercase;
              font-family: $font-1;
              letter-spacing: 0.5px;
              margin-left: 5px;

              span {
                display: block;
                margin-top: 3px;
                font-weight: 700;
              }
            }

            .item-count-contain {
              top: -5px;
              left: 14px;
            }
          }


          &.mobile-search,
          &.mobile-setting {
            display: none;
          }
        }
      }

      .toggle-nav-desc {
        margin-left: 15px;
        display: none;

        i {
          font-size: 25px;
          color: $white
        }
      }
    }

    .gift-block {
      background-color: darken($theme-color1, 5%);
      padding: 17px 28px;
      display: flex;
      align-items: center;
      color: $white;
      width: auto;
      border: 0;
      cursor: pointer;

      .grif-icon {
        i {
          font-size: 35px;
        }
      }

      .gift-offer {
        margin-left: 10px;

        p {
          text-transform: uppercase;
          line-height: 1;
        }

        span {
          font-size: 20px;
          text-transform: capitalize;
          font-weight: 700;
          line-height: 1.2;
        }
      }
    }
  }
}

.layout-header4 {
  padding: 15px 0;
  background-color: $white;
  position: relative;

  .contact-block {
    display: flex;
    align-items: center;
    height: 100%;

    .sm-nav-block {
      margin-right: 15px;

      .sm-nav-btn {
        display: none;
      }

      span {
        i {
          font-size: 24px;
        }
      }

      .nav-slide {
        position: fixed;
        top: 0;
        left: -300px;
        width: 300px;
        border-radius: 0;
        background-color: $white;
        border: 1px solid $light-border;
        height: 100vh;
        z-index: 99;
        transition: all 0.3s ease;
        overflow: scroll;
        cursor: pointer;

        li {
          display: block;
          border-bottom: 1px solid $light-border;
          padding: 7px 10px;

          ul {
            li {
              padding-left: 0;
            }
          }

          a {
            color: $dark;
            text-transform: capitalize;
            font-size: 14px;
            font-weight: 600;
          }

          &:first-child {
            padding: 0;
          }

          &:last-child {
            border-bottom: none;

            a {
              i {
                font-size: 20px;
                margin-left: 10px;
                float: right;
              }
            }
          }

          .nav-sm-back {
            font-size: 16px;
            color: $dark;
            font-weight: 700;
            text-transform: uppercase;
            border-bottom: 1px solid $light-border;
            text-align: right;
            padding: 7px;
          }
        }
      }
    }

    .desc-nav-block {
      display: flex;

      ul {
        li {
          &:nth-child(n+2) {
            margin-left: 30px;
          }

          svg {
            width: 30px;
            fill: $theme-color1;
            display: inline-block;
          }

          .contact-item {
            color: $icon-color;
            text-transform: uppercase;
            margin-left: 10px;
            font-size: 12px;
            display: inline-block;

            span {
              font-size: calc(15px + (20 - 15) * ((100vw - 320px) / (1920 - 320)));
              color: $dark;
              display: block;
              line-height: 1;
              font-weight: 700;
            }
          }

          &.mobile-user {
            color: $gray-2;
            font-size: calc(20px + (33 - 20) * ((100vw - 320px) / (1920 - 320)));
          }
        }
      }
    }
  }

  .logo-block {
    text-align: center;
  }

  .icon-block {
    display: flex;
    height: 100%;
    align-items: center;
    align-self: center;
    justify-content: flex-end;

    ul {
      li {
        position: relative;

        &:nth-child(n+2) {
          margin-left: 25px;
        }

        .item-count-contain {
          top: -5px;
          left: 15px;
        }

        a {
          svg {
            fill: $gray-2;
            width: 30px;
          }

          .cart-item {
            display: inline-block;
            color: $dark;
            text-transform: uppercase;
            font-weight: 700;
            font-family: $font-1;

            span {
              display: block;
              letter-spacing: 1px;
              margin-top: 2px;
            }
          }
        }

        &.mobile-search,
        &.mobile-setting,
        &.mobile-user {
          display: none;
        }

        &.mobile-cart {
          svg {
            fill: $theme-color1;
          }
        }



      }
    }

    .toggle-nav {
      display: none;
    }
  }
}




.category-header-4 {
  background-color: $theme-color1;
  position: relative;

  .navbar-menu {
    display: flex;
    justify-content: space-between;

    .category-left {
      display: flex;

      .sm-nav-btn {
        margin-right: 15px;
        display: none;

        i {
          font-size: 24px;
        }
      }

      .logo-block {
        display: none;
      }

      .nav-block {
        min-width: 260px;
        position: relative;

        .nav-left {
          position: absolute;
          width: 260px;
          z-index: 1;
          overflow: hidden;

          .navbar {
            display: flex;
            justify-content: center;
            background-color: $theme-color2;
            padding: 28px 0;

            h5 {
              text-transform: uppercase;
              font-size: 14px;
              letter-spacing: 1px;
              margin-left: 16px;
            }
          }

          .navbar-toggler {
            width: 17px;
            height: 17px;
            padding: 0;
            background-color: $white;
            color: $dark;
            border-radius: 50%;
            font-size: 12px;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          .nav-cat {
            padding: 30px 35px;
            margin-top: 15px;
            background-color: $white;

            li {
              display: block;
              padding-bottom: 19px;

              &:last-child {
                padding-bottom: 0;
              }

              a {
                color: $dark;
                font-size: 14px;
                text-transform: capitalize;
                letter-spacing: 0.05em;

                i {
                  margin-right: 15px;
                  float: right;
                }
              }

              &:hover {
                >a {
                  color: $theme-color1;
                }
              }
            }

            .mor-slide-click {
              padding-bottom: 0;

              a {
                color: $theme-color1;
                font-size: 16px;
                font-weight: 700;
                display: block;
                width: 100%;
                line-height: 1;
                text-transform: capitalize;
                letter-spacing: 0.5px;

                i {
                  float: right;
                  font-size: 20px;
                }
              }
            }
          }
        }

        &:before {
          width: 100%;
          height: 100%;
          background-color: $theme-color2;
          position: absolute;
          content: '';
          top: 0;
          left: -100%;
          z-index: 9;
        }
      }

    }

    .menu-block {
      .pixelstrap {
        .light-menu-item {
          padding-top: 25px;
          padding-bottom: 25px;
          color: $white;
        }

        li {
          .label-nav {
            background-color: $theme-color2;
            top: 7px;

            &:before {
              border-top: 5px solid $theme-color2;
            }
          }
        }
      }
    }

    .category-right {
      display: flex;
      align-items: center;

      .gift-block {
        background-color: $dark;
        padding: 12px 28px;
        display: flex;
        align-items: center;
        color: $white;
        width: 255px;
        border: 0;
        cursor: pointer;

        .grif-icon {
          i {
            font-size: 35px;
          }
        }

        .gift-offer {
          margin-left: 10px;

          p {
            text-transform: uppercase;
            line-height: 1;
          }

          span {
            font-size: 20px;
            text-transform: capitalize;
            font-weight: 700;
            line-height: 1.2;
          }
        }
      }

      .icon-block {
        display: none;

        ul {
          li {
            svg {
              width: calc(20px + (30 - 20) * ((100vw - 320px) / (1920 - 320)));
              fill: $white;
            }

            .item-count-contain {
              top: -10px;
              right: -5px;
            }

            &.mobile-search,
            &.mobile-setting {
              display: none;
            }
          }
        }

        .toggle-nav {
          margin-left: 15px;
        }
      }
    }

    .input-block {
      width: 535px;
      margin-top: auto;
      margin-bottom: auto;
    }
  }

  .searchbar-input {
    display: none;
  }

}

.category-header-6 {
  background-color: $theme-color2;

  .navbar-menu {
    .category-left {
      .nav-block {
        .nav-left {
          .navbar {
            background-color: $theme-color1;
          }

          .nav-cat {
            li {
              a {
                color: $font-color;
              }
            }
          }
        }
      }
    }

    .category-right {
      .gift-block {
        background-color: $theme-color1;
      }
    }
  }
}

.onhover-dropdown {
  position: relative;
  z-index: 9;

  .onhover-show-div {
    top: 50px;
    position: absolute;
    z-index: 10;
    background-color: $white;
    transition: all linear 0.5s;
    min-width: 160px;
    text-align: left;
    transform: translateY(30px);
    left: 0;
    opacity: 0;
    visibility: hidden;
    padding: 15px 20px;
    border: 5px solid $body-back;

    li {
      display: block;
      line-height: 2.2;
      margin-left: 0 !important;

      a {
        color: $dark;
        text-transform: capitalize;
        font-size: 14px;
        letter-spacing: 0.03em;
        font-family: $font-1;
      }
    }
  }

  &:hover {
    .onhover-show-div {
      transform: translateY(0px);
      opacity: 1;
      visibility: visible;
    }
  }
}

.cart-hover-div {
  position: relative;

  .cart-show-div {
    top: 50px;
    position: absolute;
    z-index: 10;
    background-color: $white;
    transition: all linear 0.5s;
    min-width: 250px;
    text-align: left;
    box-shadow: 0 0 1px 0 $light-border;
    transform: translateY(30px);
    right: 0;
    padding: 20px;
    opacity: 0;
    visibility: hidden;
    border: 5px solid $body-back;

    &.right {
      right: unset;
    }

    li {
      margin-left: 0 !important;
      width: 100%;

      .media {
        padding-bottom: 10px;
        align-items: flex-start;
        img {
          height: 70px;
          margin-right: 20px;
          border: 1px solid $light-border;
        }

        .media-body {
          align-self: center;

          h6 {
            color: $dark;
            text-transform: capitalize;
            font-family: $font-1;
            padding-bottom: 5px;
          }
        }
      }

      .total {
        padding: 10px 0;
        border-top: 1px solid $light-border;
        border-bottom: 1px solid $light-border;

        h6 {
          font-size: 16px;
          color: $gray-3;
          text-transform: capitalize;
          letter-spacing: 0.03em;

          span {
            float: right;
          }
        }
      }

      .check-btn {
        text-transform: capitalize;
        font-size: 16px;
        padding-top: 10px;

        a {
          color: $black;
          font-family: $font-1;
          letter-spacing: 0.05em;

          &:last-child {
            float: right;
          }
        }
      }
    }
  }

  &:hover {
    .cart-show-div {
      transform: translateY(0px);
      visibility: visible;
      opacity: 1;

      &.right {
        right: unset;
      }
    }
  }
}

.search-overlay {
  width: 100%;
  height: 100%;
  background-color: $white;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;

  >div {
    width: 100%;

    .close-mobile-search {
      position: absolute;
      top: 15px;
      right: 30px;
      font-size: 50px;
      color: $dark;
      cursor: pointer;
    }

    .overlay-content {
      input {
        padding: 20px 0;
        background-color: transparent;
        border: none;
        border-bottom: 1px solid $light-border;

        &:focus {
          box-shadow: none;
        }

        &::placeholder {
          letter-spacing: 0.05em;
          font-size: 18px;
          font-family: $font-1;
          color: $dark;
        }
      }

      button {
        position: absolute;
        top: 10px;
        right: 20px;
        background-color: transparent;
        border: none;
        font-size: 22px;
        i {
          color: $dark !important;
        }
      }
    }
  }
}

.mobile-setting-hover {
  position: relative;

  .mobile-setting-show {
    position: absolute;
    bottom: 40px;
    right: 0;
    background-color: $white;
    box-shadow: 0 0 1px 0 $light-border;
    min-width: 160px;
    padding: 20px;
    transition: all linear 0.5s;
    transform: translateY(30px);
    visibility: hidden;
    opacity: 0;

    h6 {
      font-size: 16px;
      color: $black;
      text-transform: capitalize;
      font-family: $font-1;
      margin-bottom: 10px;
    }

    .curroncy {
      margin-top: 10px;
    }

    ul {
      li {
        display: block;
        margin-bottom: 8px;
        padding-left: 17px;

        &:last-child {
          margin-bottom: 0;
        }

        a {
          color: $dark;
          text-transform: capitalize;
          font-family: $font-1;
          font-size: 14px;
        }
      }
    }
  }

  &:hover {
    .mobile-setting-show {
      transform: translate(0);
      opacity: 1;
      visibility: visible;
    }
  }
}

.product-notification {
  width: 300px;
  padding: 20px;
  background-color: white;
  box-shadow: 0 0 7px 2px rgba(158, 158, 158, 0.2);
  margin: 10px;
  position: fixed;
  bottom: 0;
  animation: movebottom 10s infinite;
  z-index: 99;
  img {
    height: 70px;
  }
  .btn-close {
    position: absolute;
    right: 13px;
    top: 5px;
    font-size:14px;
    z-index: 99;
    opacity:1;
  }
}

.item-count {
  position: relative;

  .item-count-contain {
    position: absolute;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: $theme-color1;
    color: $white;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;

    &.item-whtie {
      background-color: $white;
      color: $theme-color1;
    }

    &.inverce {
      background-color: $theme-color2;
    }

    &.item-md {
      width: 15px;
      height: 15px;
      font-size: 10px;
    }
  }

  &:hover {
    .item-count-contain {
      animation: pulse 0.8s infinite;
    }
  }
}

//new header add//
.mobilecat-toggle {
  display: none;
}

.icon-lable {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: $theme-color1;
  color: $white;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.top-header2 {
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: $theme-color1;
  ul {
    li {
      a {
        color: $white;
        font-weight: 600;
        text-transform: capitalize;
        letter-spacing: 0.03em;

        i {
          margin-right: 10px;
        }
      }

      &:nth-child(n+2) {
        margin-left: 15px;
      }
    }
  }
  .top-header-right {
    text-align: right;
  }
  &.light-header {
    background-color: $gray1;
    ul {
      li {
        a {
          color: $dark;
        }
      }
    }
  }
}
.header7 {
  padding-top: calc(10px + (20 - 10) * ((100vw - 320px) / (1920 - 320)));
  padding-bottom: calc(10px + (20 - 10) * ((100vw - 320px) / (1920 - 320)));
  background-color: $white;
  position: relative;
  border-bottom: 1px solid $light-border;
  .pixelstrap ul a, .pixelstrap ul a:hover, .pixelstrap ul a:focus, .pixelstrap ul a:active, .pixelstrap ul a.highlighted{
    font-size: 16px;
  }
  .header-contain {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .logo-block {
      display: flex;
      align-items: center;
      .mobilecat-toggle {
        font-size: 24px;
        color: $theme-color1;
        margin-right: 15px;
      }
    }
    .menu-block {
      .toggle-nav {
        display: none;
      }
      ul {
        li {
          a.dark-menu-item {
            font-size: 16px;
          }
        }
      }
    }

    .icon-block {
      display: flex;
      align-items: center;

      &.icon-lg-block {
        display: none;
      }

      ul {
        display: flex;
        align-items: center;

        li {
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;

          &:nth-child(n+2) {
            margin-left: 10px;
          }

          svg {
            width: 30px;
            color: $theme-color1;
          }

          .item-count-contain {
            top: -8px;
            right: 0;
          }

          &.icon-desk-none {
            display: none;
          }

          &.icon-lg-block {
            display: none;
          }

          &.icon-md-block {
            display: none;
          }
        }

        &.rounde-icon {
          li {
            width: calc(30px + (50 - 30) * ((100vw - 320px) / (1920 - 320)));
            height: calc(30px + (50 - 30) * ((100vw - 320px) / (1920 - 320)));
            border-radius: 50%;
            border: 2px solid $light-border;
            position: relative;

            &:nth-child(n+2) {
              margin-left: calc(5px + (15 - 5) * ((100vw - 320px) / (1920 - 320)));
            }

            svg {
              width: 45%;
              height: auto;
              fill: $black;
            }

            .icon-lable {
              position: absolute;
              top: -5px;
              right: -4px;
            }
          }
        }

        &.theme-color {
          li {
            width: calc(30px + (45 - 30) * ((100vw - 320px) / (1920 - 320)));
            height: calc(30px + (45 - 30) * ((100vw - 320px) / (1920 - 320)));
            border-radius: 100%;
            background-color: $theme-color1;
            cursor: pointer;
            svg { 
              fill: $white;
              width: 35%;
            }

            &:nth-child(n+2) {
              margin-left: calc(5px + (15 - 5) * ((100vw - 320px) / (1920 - 320)));
            }

          }

          &.color-full {
            li {
              &:nth-child(2) {
                background-color: $theme-color2;
              }

              &:nth-child(3) {
                background-color: $theme-color3;
              }

              &:nth-child(4) {
                background-color: $theme-color4;
              }

              &:nth-child(5) {
                background-color: $theme-color5;
              }
            }
          }
        }

        &.icon-radius {
          li {
            border-radius: 50%;
          }
        }
      }

      .toggle-nav {
        margin-left: 15px;
        ;
      }
    }
  }

  .toggle-nav {
    padding-top: 0;
    padding-bottom: 0;
  }

}

.header-style2 {
  .searchbar-input {
    display: none;
  }
}

.searchbar-input {
  position: absolute;
  top: 0;
  right: 0;
  width: 50%;
  height: 100%;
  transition: all 0.3s ease;
  opacity: 0;
  visibility: hidden;
  box-shadow: 0 0 20px rgba(89, 102, 122, 0.1);
  z-index: 10;
  .input-group {
    height: 100%;
    .twitter-typeahead {
      width: calc(100% - 126px);

      .form-control {
        height: 100%;
      }
    }
    .form-control {
      border: none;
      text-transform: capitalize;
      color: $dark;
      font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
      padding: 0;
      border-radius: 0;
      background-color: $white !important;

      &::placeholder {
        color: $gray-7;
        font-weight: 600;
        letter-spacing: 0.03em;
      }

      &:focus {
        box-shadow: none;
        border: none;
      }
    }
    .input-group-text{
      background-color:$white;
      height:100%;
      border:none;
      cursor: pointer;
      &:first-child{
        padding-left:30px;
      }
      &:last-child{
        padding-right:30px;
      }
      svg{
        width:22px;
        height:auto;
        fill:$gray-7;
      }
    }
  }
  &.open {
    width: 100%;
    opacity: 1;
    visibility: visible;
  }
}
.category-header7 {
  background-color: $theme-color1;
  position: relative;
  .category-contain {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    .category-left {
      .logo-block {
        .mobilecat-toggle {
          display: none;
          margin-right: 15px;
          i {
            font-size: 24px;
            color: $theme-color1
          }
        }
        .brand-logo {
          display: none;
        }
      }
    }
    .category-right {
      .menu-block {
        ul {
          li {
            a {
              color: $white;
            }
          }
        }

      }
    }
    .icon-block {
      display: none;
      ul {
        li {
          svg {
            width: calc(20px + (30 - 20) * ((100vw - 320px) / (1920 - 320)));
          }
          .item-count-contain {
            top: -10px;
            right: -4px;
          }
        }
      }

      .toggle-nav {
        margin-left: 15px;
      }
    }

    .gift-block-main {
      height: 100%;
      cursor: pointer;

      .gift-block {
        height: 100%;
      }

      .gift-dropdown {
        &.dropdown-menu {
          right: 0;
        }
      }
    }
  }

  &.category-inverse {
    background-color: $theme-color2;

    .category-contain {
      .store-locator {
        select {
          background-color: $theme-color1;
        }
      }
    }
  }

  .searchbar-input {
    display: none;
  }

}

.header-category {
  z-index: 1;
  position: relative;

  .category-toggle {
    background: $theme-color2;
    width: 100%;
    display: block;
    padding: 20px 30px;
    color: $white !important;
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
    cursor: pointer;

    i {
      margin-right: 15px;
    }
  }

  ul {
    &.collapse-category {
      width: 100%;
      background-color: $white;
      padding: 20px;
      position: absolute;
      top: 100%;
      left: 0;
      margin-top: 15px;
      border: 1px solid $light-border;

      li {
        display: block;
        text-transform: capitalize;

        &.back-btn {
          padding-bottom: 15px;
          font-size: 18px;
          color: $dark;
          font-weight: 700;
          text-transform: uppercase;
          cursor: pointer;
          align-items: center;
          display: none;
          border-bottom: 1px solid $light-border;

          i {
            margin-right: 10px;
          }
        }

        a {
          color: $dark;
          font-size: 16px;
          padding-bottom: 10px;
          padding-top: 10px;
          display: block;
          width: 100%;
          line-height: 1;

          img {
            border: 2px solid $img-border;
            padding: 3px;
            border-radius: 50%;
            margin-right: 10px;
          }
        }

        &:nth-child(n+3) {
          a {
            border-top: 1px solid $light-border;
          }
        }

        &:first-child {
          a {
            padding-top: 0;
          }
        }

        &:last-child {
          a {
            padding-bottom: 0;
          }
        }
      }

      &.hide {
        display: none;
      }

      &.show {
        display: block;
      }
    }
  }
}

.header-category2 {
  .category-toggle {
    background: $theme-color1;
    width: 100%;
    display: block;
    padding: 20px 30px;
    color: $white !important;
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;

    i {
      margin-right: 15px;
    }
  }

  ul {
    &.collapse-category {
      width: 100%;
      background-color: $white;
      padding: 25px;
      margin-top: 15px;
      border: 1px solid $light-border;

      li {
        display: block;
        text-transform: capitalize;

        &.back-btn {
          padding-bottom: 15px;
          font-size: 18px;
          color: $dark;
          font-weight: 700;
          text-transform: uppercase;
          cursor: pointer;
          align-items: center;
          display: none;
          border-bottom: 1px solid $light-border;

          i {
            margin-right: 10px;
          }
        }

        a {
          .icon {
            margin-right: 15px;
            padding: 3px;
            border: 2px solid $img-border;
            width: 41px;
            height: 41px;
            border-radius: 50%;

            >div {
              border-radius: 50%;
              width: 100%;
              height: 100%;
              background-color: rgba($theme-color1, 0.07);
              display: flex;
              align-items: center;
              justify-content: center;
            }

            svg {
              width: 50%;
            }
          }

          color: $dark;
          font-size: 16px;
          font-weight: 700;
          padding-bottom: 10px;
          padding-top: 10px;
          display: flex;
          align-items: center;
          width: 100%;
          line-height: 1;
        }

        &:nth-child(2) {
          a {
            padding-top: 0;
          }
        }

        &:last-child {
          a {
            padding-bottom: 0;
          }
        }
      }

      &.hide {
        display: none;
      }

      &.show {
        display: block;
      }
    }
  }

}

.header-category3 {
  z-index: 1;
  position: relative;

  .category-toggle {
    background-color: $theme-color2;
    width: 100%;
    display: block;
    padding: 20px 30px;
    color: $white !important;
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
    cursor: pointer;

    i {
      margin-right: 15px;
    }
  }

  ul {
    li {
      display: block;
      text-transform: capitalize;
      position: relative;
      width: 240px;
      background-color: $white;

      a {
        color: $dark;
        font-size: 16px;
        padding-bottom: 8px;
        padding-top: 8px;
        display: block;
        width: 100%;
        line-height: 1;
        padding-left: 20px;
        padding-right: 20px;
        position: relative;

        span {
          &:before {
            content: "\f105";
            position: absolute;
            right: 15px;
            top: 7px;
            color: $dark;
            font-size: 20px;
            font-family: $font-awesome;
          }
        }

        &:hover {
          color: $theme-color1;

          span {
            &:before {
              color: $theme-color1;
            }
          }
        }

        i {
          float: right;
          font-weight: 600;
        }
      }
    }

    &.collapse-category {
      width: 100%;
      background-color: $white;
      position: absolute;
      top: 100%;
      left: 0;
      padding-top: 15px;
      margin-top: 10px;
      border: 1px solid $light-border;
      z-index: 1;
      transition: all 0.5s ease;

      >li {
        &.back-btn {
          padding-bottom: 15px;
          font-size: 18px;
          color: $dark;
          font-weight: 700;
          text-transform: uppercase;
          cursor: pointer;
          align-items: center;
          display: none;
          border-bottom: 1px solid $light-border;

          i {
            margin-right: 10px;
          }
        }

        &:nth-child(2) {
          >a {
            padding-top: 20px;

            span {
              &:before {
                top: 17px;
              }
            }
          }
        }

        &:last-child {
          a {
            padding-bottom: 20px;
          }
        }

        ul {
          background-color: $white;
          border: 1px solid $light-border;
          box-shadow: 0 0 0 $light-border;

          li {
            &:first-child {
              a {
                padding-top: 20px;
              }
            }

            &:last-child {
              a {
                padding-bottom: 20px;
              }
            }
          }
        }
      }

      &.open {
        visibility: hidden;
        opacity: 0;
      }
    }

    // catgory toggle//
    .categoryone {
      .collapse-two {
        position: absolute;
        top: 0;
        left: 100%;
        opacity: 0;
        visibility: hidden;
        transition: all 0.5s ease;
        border-radius: 2px;

        .categorytwo {
          .collapse-third {
            position: absolute;
            top: 0;
            left: 100%;
            opacity: 0;
            visibility: hidden;
            transition: all 0.5s ease;
            border-radius: 2px;
          }

          &:hover {
            .collapse-third {
              opacity: 1;
              visibility: visible;
            }
          }
        }
      }

      &:hover {
        .collapse-two {
          opacity: 1;
          visibility: visible;
        }

        .collapse-mega {
          opacity: 1;
          visibility: visible;
        }
      }

      .collapse-mega {
        background-color: $white;
        padding-top: 20px;
        padding-bottom: 20px;
        position: absolute;
        top: 0;
        left: 100%;
        display: flex;
        border: 1px solid $light-border;
        border-radius: 2px;
        display: flex;
        opacity: 0;
        visibility: hidden;
        transition: all 0.5s ease;

        .mega-box {
          h5 {
            color: $dark;
            text-transform: capitalize;
            margin-bottom: 15px;
            font-size: 18px;
            font-weight: 600;
            padding-left: 20px;
            padding-right: 20px;
            line-height: 1;
          }

          ul {
            border: none;

            li {
              &:first-child {
                border-top: none;

                a {
                  padding-top: 0;
                }
              }

              &:last-child {
                a {
                  padding-bottom: 0;
                }
              }
            }
          }
        }
      }
    }
  }
}

.category-heandle {
  position: relative;
  z-index: 3;

  .heandle-left,
  .heandle-right {
    width: 4px;
    height: 40px;
    border-radius: 3px;
    background-color: $theme-color1;
    position: absolute;
    top: -13px;
    transition: all 0.3s ease 0s;

    .point {
      position: relative;

      &:after,
      &:before {
        position: absolute;
        content: "";
        width: 12px;
        height: 12px;
        border-radius: 50%;
        border: 2px solid $theme-color1;
        z-index: -1;
        left: -4px;
      }

      &:after {
        bottom: -9px;
      }

      &:before {
        top: 31px;
      }
    }
  }

  .heandle-left {
    left: 20px;
  }

  .heandle-right {
    right: 20px;
  }

  &.open {
    visibility: hidden;
    opacity: 0;
    transition: all 0.5s ease;
  }
}

.header-search {
  width: 850px;
  .input-group {
    height: 60px;
    justify-content: center;
    .twitter-typeahead {
      width: calc(100% - 270px) !important;
    }
    .form-control,
    .typeahead {
      border-left: none;
      border-right: none;
      text-transform: capitalize;
      color: $dark;
      padding-left: 25px;
      padding-right: 25px;
      height: 60px;
      width: 100%;
      &:focus {
        box-shadow: none;
        border-color: $light-border;
      }
    }
    .input-group-text {
      padding-left: 25px;
      padding-right: 25px;
      background-color: $theme-color1;
      color: $white;
      border: none;
      &:first-child {       
        padding: 0;
        border-right: none;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        background-color: $theme-color1;       
      }
      &:last-child {       
          border-top-right-radius: 5px;
          border-bottom-right-radius: 5px;       
      }
      select {
        appearance: none;
        background-color: transparent;
        height: 100%;
        text-transform: capitalize;
        border: none;
        border-left: 1px solid $light-border;
        background: url(../images/dropdown1.png) no-repeat 85%;
        background-size: 18px;
        cursor: pointer;
        position: relative;
        padding-left: 30px;
        padding-right: 64px;
        border: none;
        outline: none;
        color: $white;
        font-weight: 700;

        option {
          background-color: $theme-color1;
        }
      }
    }   
  }

  &.search-style2 {
    .input-group {
      padding: 3px;
      border: 1px solid $light-border;
      // height:auto;
      .form-control {
        border: none;
        height:100%;
      }     
      .input-group-text {
        background-color: rgba($theme-color1, 0.7);
        border-left:unset;
        height:100%;
        select {
          background: url(../images/dropdown2.png) no-repeat 85%;
          background-size: 18px;
        }         
      }        
    }
  }
}

.header-search2 {
  width: 850px;
  .input-group {
    height: 60px;
    .form-control {
      border-left: none;
      border-right: none;
      text-transform: capitalize;
      color: $dark;
      &:focus {
        box-shadow: none;
        border-color: $light-border;
      }
    }   
    .input-group-text {
      padding-left: 20px;
      padding-right: 20px;
      background-color: $theme-color1;
      color: $white;
      select {
        background-color: transparent ;
        height: 100% ;
        text-transform: uppercase ;
        border: none ;
        border-left: 1px solid $light-border ;
        background: url(../images/pin.png) no-repeat 88% ;
        background-size: 16px;
        -webkit-appearance: none;
        -moz-appearance: none;
        cursor: pointer;
        position: relative;
        padding-left: 20px;
        padding-right: 45px;
        border: none;
        outline: none;
        color: $white;
        font-weight: 700;
        option {
          background-color: $theme-color1;
          border: none;
          outline: none;
        }
      }
      &:first-child{
        padding: 0;
        border-right: none;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
      }
    }   
  }
}

.header-search3 {
  display: flex;
  align-items: center;

  .input-group {
    height: 68px;

    .form-control {
      border-right: none;
      text-transform: capitalize;
      color: $dark;
      padding: 15px 25px;
      font-weight: 700;
      font-size: 16px;

      &:focus {
        box-shadow: none;
        border-color: $light-border;
      }
    }   
    .input-group-text {
      padding-left: 25px;
      padding-right: 25px;
      background-color: $theme-color1;
      color: $white;
      i {
        font-size: 20px;
      }
    }   
  }
  .icon-block {
    ul {
      display: flex;
      align-items: center;
      li {
        width: 65px;
        height: 65px;
        border-radius: 5px;
        background-color: $theme-color1;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 15px;
        position: relative;
        .item-count-contain {
          position: absolute;
          top: -5px;
          right: 0;
        }
        svg {
          fill: $white;
          width: 43%;
        }
      }
    }
  }
}
.store-locator {
  background-color:$theme-color2;
  height:100%;
  select {
    background-color: transparent;
    height: 100%;
    border: none;
    background: url(../images/pin.png) no-repeat 88%;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-size: 14px;
    cursor: pointer;
    position: relative;
    padding-left: 25px;
    padding-right: 45px;
    color: $white;
    font-weight: 600;
    font-size: 16px;
    text-transform: uppercase;
    option {
      background-color: $theme-color1;
    }
  }
}

.gift-block-main {
  position: relative;
  cursor: pointer;

  .gift-toggle {
    background-color: $theme-color1;
    color: $white;
    font-weight: 700;
    min-width: 230px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: capitalize;
    padding-top: 15px;
    padding-bottom: 15px;
    border-radius: 5px;
    user-select: none;

    .grif-icon {
      margin-right: 20px;

      i {
        font-size: 30px;
      }
    }

    .gift-offer {
      p {
        text-transform: uppercase;
        line-height: 1;
      }

      span {
        font-size: 20px;
        text-transform: capitalize;
        font-weight: 700;
        line-height: 1.2;
      }
    }
  }

  .gift-block {
    background-color: $theme-color2;
    padding: 12px 28px;
    display: flex;
    align-items: center;
    color: $white;
    width: 255px;
    border: 0;

    .grif-icon {
      i {
        font-size: 35px;
      }
    }

    .gift-offer {
      margin-left: 10px;

      p {
        text-transform: uppercase;
        line-height: 1;
      }

      span {
        font-size: 20px;
        text-transform: capitalize;
        font-weight: 700;
        line-height: 1.2;
      }
    }
  }

  .gift-dropdown {
    &.dropdown-menu {
      position: absolute;
      top: 0;
      left: 0;
      width: fit-content;
    }
  }
}

@keyframes movebottom {
  0% {
    display: block;
    bottom: -200px;
  }

  25% {
    bottom: 0px;
  }

  75% {
    bottom: 0px;
  }

  100% {
    display: none;
    bottom: -200px;
  }
}

.gift-dropdown {
  &.dropdown-menu {
    border: 2px solid $gray1;
    width: 100%;
    border-radius: 0;
    padding: 20px 10px 20px 20px;

    .media {
      transition: transform 0.3s ease;
      transform: scale(1.0);

      &:hover {
        transform: scale(1.05);
        transition: transform 0.3s ease;
      }
    }

    .cash {
      height: 10px;
      margin-top: -5px;
    }

    .fire {
      height: 13px;
      margin-top: -5px;
    }

    .offer-banner {
      width: 60px;
      border: 1px dashed #afafaf;
      border-radius: 5px;
      text-align: center;
      margin-right: 13px;
      font-size: 13px;
      padding: 5px 1px;
      text-transform: uppercase;
      letter-spacing: 1px;
      font-weight: 700;
    }
    .media {
      &+.media {
        margin-top: 12px;
        padding-top: 12px;
        border-top: 1px solid $white1;
        h5 {
          padding-bottom: 2px;
        }
      }
    }
    .media {
      .me-3 {
        height: 45px;
        width: 45px;
        background-color: lighten($theme-color1, 45%);
        padding: 7px;
        border-radius: 100%;
        margin-right: 12px !important;
        img {
          height: 30px;
        }
      }
    }
  }
}

header{
  animation: slideInUp 300ms ease-in-out;
  transition: all 0.3s ease;
}

// sticky header//
.sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: $white;  
  z-index: 2;  
  box-shadow: 1px 1px 15px $gray-7;
  animation: slideInDown 300ms ease-in-out;
  transition: all 0.3s ease;    
  .header7{
    padding-top: 0;
    padding-bottom: 0;
    .header-contain{
      .icon-block ul.theme-color li{
        height: calc(20px + (30 - 20) * ((100vw - 320px) / (1920 - 320)));
        width: calc(20px + (30 - 20) * ((100vw - 320px) / (1920 - 320)));
      }
      .brand-logo{
        img{
          height: 45px;
        }
      }
    }
  }  
  &.header-style2 {
    .category-header7{
      padding-top: 5px;
      padding-bottom: 5px;
      .category-contain{
        .icon-block ul li svg{
          width: calc(18px + (20 - 18) * ((100vw - 320px) / (1920 - 320)));
        }
      }
      .brand-logo{
        img{
          height: 40px;
        }
      }
    }
  }
  .category-header-4 {
    background-color: $white;
    padding-top: 5px;
    padding-bottom: 5px;
    .navbar-menu{
      .menu-block .pixelstrap .light-menu-item{
        padding-top: 15px;
        padding-bottom: 15px;
      }
      .category-left{
        .logo-block{
          img{
            height: 40px;
          }          
        }
        .nav-block {
          display: none;

          &:before {
            display: none;
          }
        }

        .logo-block {
          display: flex;
          align-items: center;
        }
      }
      .category-right .icon-block ul li svg {
        width: calc(18px + (20 - 18) * ((100vw - 320px) / (1920 - 320)));     
       }
      .input-block {
        display: none;
      }

      .category-right {
        display: flex;

        .gift-block {
          display: none;
        }

        .icon-block {
          display: flex;

          ul {
            li {
              svg {
                fill: $dark;
              }

              .item-count-contain {
                &.item-whtie {
                  background-color: $theme-color1;
                  color: $white;
                }
              }

              &.mobile-setting,
              &.mobile-search {
                display: inline-block;
              }
            }
          }
        }
      }

      .menu-block {
        .pixelstrap {
          .light-menu-item {
            color: $dark;
          }
        }
      }


    }
    .searchbar-input {
      display: block;
    }
  }
  .layout-header3{  
    .main-menu{
      padding-top: 5px;
      padding-bottom: 5px;
      .item-count .item-count-contain {
        width: 16px;
        height: 16px;
        font-size: 10px;
    }
      .menu-left{
        .nav-block{
          .nav-left{
            .navbar{
              padding: 12px 0;
            }
          }
        }
      }
      .gift-dropdown.dropdown-menu {
        padding: 10px 5px 10px 5px;
        .media{
          .me-3{
            width: 35px;
            img{
              height: 25px;
            }
          }
        }
      }
      .gift-block{
        padding: 7px 20px;
        i{
          font-size: 25px;
        }
      }
      .icon-block{
        ul {
          li {
            &.mobile-wishlist .item-count-contain{
              top: -9px;              
            }
            svg{
              width: calc(16px + (20 - 16) * ((100vw - 320px) / (1920 - 320)));
            }
          }
        }
      }
      .logo-block{
        img{
          height: 40px;
        }
      }
    }
  }
  .layout-header1{
    padding: 0;
    .main-menu-block{
      .menu-right{
        .icon-nav{
          ul{
            li{
              svg{
                width: calc(18px + (20 - 18) * ((100vw - 320px) / (1920 - 320)));
              }
            }
          }
        }
      }
      .brand-logo{
        img{
          height: 40px;
        }
      }
    }
  }
  &+ .theme-slider{
    padding-top: 107px;
  }
  .category-header-2{
    .navbar-menu .icon-block ul li svg{
      width: 20px;
      margin-left: 5px;
    }
    .menu-block{
      .dark-menu-item{
        padding-top: 5px !important;
        padding-bottom: 5px !important;
      }
    }
    .logo-block{
      .brand-logo{
        img{
          height: 40px;
        }
      }
    }
  }
  .top-header {
    display: none;
  }
  .layout-header2 {
    display: none;
  }
  .category-header-2 {
    padding-top: 15px;
    padding-bottom: 15px;
    background-color: $white;
    border-bottom: 1px solid $light-border;
    .navbar-menu {
      .logo-block {
        display: flex;
        align-items: center;
      }
      .nav-block {
        display: none;
      }

      .icon-block {
        ul {
          li {
            &:nth-child(n+2) {
              margin-left: unset;
            }
            &.mobile-search,
            &.mobile-setting,
            &.mobile-cart {
              display: inline-block;
            }

            .cart-item {
              display: none;
            }

            svg {
              fill: $black1;
            }

            .item-count-contain {
              width: 15px;
              height: 15px;
            }
          }
        }
      }

      .menu-block {
        .pixelstrap {
          .dark-menu-item {
            color: $black1;
          }
        }
      }


      .category-right {
        display: none;
      }
    }

    .searchbar-input {
      display: block;
    }
  }

  .category-header {
    display: none;
  }

  .layout-header1 {
    .main-menu-block {
      .menu-right {
        .icon-nav {
          ul {

            .mobile-search,
            .mobile-setting {
              display: inline-block;
            }

            li {
              .item-count-contain {
                width: 15px;
                height: 15px;
              }

              .cart-item {
                display: none;
              }

              &.cart-block {
                padding: 0;
                margin-left: 0;
                background-color: transparent;
                svg {
                  fill: $gray-2;
                  width: calc(18px + (22 - 18) * ((100vw - 320px) / (1920 - 320)));
                }
                &.item-count {
                  .item-count-contain {
                    left: unset;
                    right: 1px;
                    top: -8px;

                    &.item-whtie {
                      background-color: $theme-color1;
                      color: $white;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .searchbar-input {
      display: block;
    }
  }

  .layout-header3 {
    .main-menu {
      .icon-block {
        ul {
          li {
            &.mobile-setting {
              display: inline-block;
            }

            &.mobile-wishlist {
              .cart-item {
                display: none;
              }
            }
          }
        }
      }
    }
  }
  .layout-header4 {
    height: 0;
    padding: 0;
  }


  .top-header2 {
    display: none;
  }

  .header7 {
    .header-contain {
      .icon-block {
        ul {
          li {
            &.icon-desk-none {
              display: flex;
            }
          }
        }
      }
    }
  }


  &.header-style2 {
    .header7 {
      display: none;
    }

    .category-header7 {
      background-color: $white;
      .category-contain {
        height: auto;

        .category-left {
          .logo-block {
            .brand-logo {
              display: block;
            }
          }

          .header-category {
            display: none;
          }

          .header-category3 {
            display: none;
          }
        }

        .category-right {
          .menu-block {
            ul {
              li {
                a {
                  color: $dark;
                }
              }
            }
          }
        }

        .icon-block {
          display: flex;

          ul {
            li {
              svg {
                fill: $dark;
              }
            }
          }
        }

        .store-locator {
          display: none;
        }
      }

      .searchbar-input {
        display: block;
      }
    }






  }
}




/*============================
  17. Media Banner start
==============================*/
.Jewellery-banner {
  height: 100%;

  .Jewellery-banner-contian {
    padding: 30px;
  }

  h5 {
    color: $dark;
    text-transform: capitalize;
    font-weight: 700;
    font-size: 14px;
  }

  h6 {
    color: $theme-color1;
    font-size: 31px;
    font-weight: 700;
    text-transform: capitalize;
    line-height: 1;
  }
}

.layout-5 {
  .media-banner.media-banner-1 {
    background-color: $bg-light;
  }
}

.tab-product-main .tab-prodcut-contain ul li a {
  color: $white;
}

.media-banner {
  padding: 30px;
  background-color: $body-back;
  border-right: 1px solid $light-border;
  .media-banner-box {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }

    .media-heading {
      h5 {
        font-size: 18px;
        color: $dark;
        text-transform: uppercase;
        line-height: 1;
        letter-spacing: 0.5px;
      }
    }

    .media-view {
      h5 {
        text-transform: capitalize;
        color: $theme-color1;
        line-height: 18px;
      }
    }

    .media {
      align-items: center;

      img {
        border: 1px solid $light-border;
      }

      .media-body {
        .media-contant {
          margin-left: 15px;
          display: flex;
          align-items: center;
          height: 100px;
          position: relative;

          .product-detail {
            transition: all 0.5s ease;

            .rating {
              i {
                color: $rating-star;
              }
            }

            p {
              font-size: 14px;
              margin-bottom: 0;
              color: $dark;
              text-transform: capitalize;
            }

            h6 {
              color: $theme-color1;
              font-weight: 700;
              margin-top: 3px;

              span {
                color: $dark;
                text-decoration: line-through;
                font-weight: 500;
              }
            }
          }

          .cart-info {
            position: absolute;
            left: 0;
            bottom: 0;
            top: 0;
            margin-top: auto;
            margin-bottom: auto;
            height: fit-content;
            transition: all 0.5s ease;
            visibility: hidden;
            opacity: 0;
            button {
              padding: 0;
              background-color: transparent;
              line-height: 1;
              border: none;
              svg {
                width:calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
                color:$dark;
              }
              i {
                color: $dark;
                font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
              }
            }

            a {
              color: $dark;

              svg {
                width: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
                color: $dark;
              }

              i {
                font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
              }

              &:nth-child(n+2) {
                margin-left: 5px;
              }
            }
          }
        }
      }

      &:hover {
        .media-body {
          .media-contant {
            .product-detail {
              opacity: 0;
              visibility: hidden;
            }

            .cart-info {
              opacity: 1;
              visibility: visible;
              transform: translateY(0);

              button {
                animation: fadeInUp 300ms ease-in-out;
              }

              a {
                &:nth-child(2) {
                  animation: fadeInUp 600ms ease-in-out;
                }

                &:nth-child(3) {
                  animation: fadeInUp 700ms ease-in-out;
                }

                &:nth-child(4) {
                  animation: fadeInUp 900ms ease-in-out;
                }
              }

            }
          }
        }
      }
    }
  }

  &.media-banner-1 {
    background-color: $hot-deal;
    margin: 0 8px;
  }

  &.media-banner-2 {
    .media-banner-box {
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &.banner-inverse {
    .media-banner-box {
      .media {
        .media-body {
          .media-contant {
            h6 {
              color: $theme-color2;
            }
          }
        }
      }
    }
  }

  &.plrb-0 {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
  }
}

/*============================
   18. Footer start
==============================*/
ul.sosiyal {
  display: flex;
  align-items: center;
  justify-self: center;

  li {
    width: calc(28px + (35 - 28) * ((100vw - 320px) / (1920 - 320)));
    height: calc(28px + (35 - 28) * ((100vw - 320px) / (1920 - 320)));
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $follow-back;
    transition: all 0.3s ease;

    &:nth-child(n+2) {
      margin-left: calc(5px + (10 - 5) * ((100vw - 320px) / (1920 - 320)));
    }

    a {
      i {
        color: $dark;
        font-size: calc(12px + (14 - 12) * ((100vw - 320px) / (1920 - 320)));
      }
    }

    &:hover {
      background-color: $theme-color2;
      transition: all 0.3s ease;

      i {
        color: $white;
      }
    }
  }

  &.sosiyal-inverse {
    li {
      &:hover {
        background-color: $theme-color1;
      }
    }
  }
}



.footer-title {
  margin-bottom: 30px;
  cursor: pointer;

  h5 {
    font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
    text-transform: uppercase;
    color: $black;
    font-family: $font-2;
  }

  .line {
    width: 50px;
    height: 2px;
    background-color: $theme-color1;
    margin-top: 10px;
  }

  &.mobile-title {
    display: none;
  }
}

.footer-contant {
  .footer-logo {
    margin-bottom: 20px;

    img {
      width: auto;
      height: calc(30px + (66 - 30) * ((100vw - 320px) / (1920 - 320)));
    }
  }
  ul {
    li {
      position: relative;
      line-height: 1.2;
      display: block;
      padding-bottom: 17px;
      font-size: calc(12px + (14 - 12) * ((100vw - 320px) / (1920 - 320)));
      text-transform: capitalize;
      font-family: $font-2;
      letter-spacing: 0.03em;

      &:last-child {
        margin-right: 0;
        padding-bottom: 0;
      }

      a {
        color: $gray-3;
      }
    }
  }
  .contact-list {
    li {
      padding-left: 25px;

      &:first-child {
        line-height: 1.8;

        i {
          margin-top: 4px;
        }
      }

      span {
        font-family: $font-1;
      }

      i {
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }
  .news-letter {
    .input-group {
      text-transform: capitalize;
      .form-control {
        text-transform: capitalize;
        border-color: $light-border;
        height: 42px;
        padding-left: 15px;
        padding-right: 15px;
        &:focus {
          box-shadow: none;
          border-color: $light-border;
        }
      }
      .input-group-text {
        background-color: $theme-color2;
        color: $white;
        font-weight: 700;
        border-color: $theme-color2;
      }
    }
  }
  .newsletter-second {
    .form-group {
      margin-bottom:15px;
      .form-control {
        border-color: $light-border;
        text-transform: capitalize;
        height:40px;
        padding-left:12px;
        padding-right:12px;
        font-size:14px;
        border-bottom-left-radius:5px;
        border-top-left-radius:5px;       
        &:focus {
          box-shadow: none;
          border-color: $light-border;
        }
      }
      .input-group-text {
        background-color: $theme-color1;
        border-color: $theme-color1;
        color:$white;
        border-bottom-right-radius:5px;
        border-top-right-radius:5px;     
      }
      .btn {
        text-transform: capitalize;
      }
    }
  }
  ul.sosiyal {
    margin-top: 20px;
    li {
      display: flex;
      padding: 0;
      margin-bottom: 0;

    }
  }

  ul.paymant {
    li {
      display: inline-block;
      margin-bottom: 0;
      padding-bottom: 0;

      img {
        width: calc(20px + (25 - 20) * ((100vw - 320px) / (1920 - 320)));
      }

      &:nth-child(n+2) {
        margin-left: calc(5px + (15 - 5) * ((100vw - 320px) / (1920 - 320)));
      }
    }
  }
  p {
    line-height: 1.6;
    margin-bottom: 20px;
  }
}

.footer1 {
  padding-top: calc(30px + (60 - 30) * ((100vw - 320px) / (1920 - 320)));
  padding-bottom: calc(30px + (60 - 30) * ((100vw - 320px) / (1920 - 320)));
  background-color: $white;

  .footer-main {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    >div {
      max-width: 30%;
    }
  }
}

.subfooter {
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: $white;

  .footer-left,
  .footer-right {
    display: flex;
    align-items: center;
    height: 100%;
  }

  .footer-left {
    p {
      color: $gray-3;
      text-transform: uppercase;
      font-family: $font-2;
      letter-spacing: 0.05em;
      font-size: calc(12px + (14 - 12) * ((100vw - 320px) / (1920 - 320)));
    }
  }

  .footer-right {
    justify-content: flex-end;

    ul.payment {
      li {
        &:nth-child(n+2) {
          margin-left: 10px;
        }
      }
    }
  }

  &.dark-footer {
    background-color: $dark;

    .footer-left {
      p {
        color: $white;
      }
    }
  }

  &.light-footer {
    background-color: rgba($theme-color1, 0.05);
  }

  &.footer-border {
    border-top: 1px solid $light-border;
  }
}

//subscribe//
.subscribe1 {
  padding-top: calc(25px + (40 - 25) * ((100vw - 320px) / (1920 - 320)));
  padding-bottom: calc(25px + (40 - 25) * ((100vw - 320px) / (1920 - 320)));
  background-color: rgba($theme-color1, 0.8);
  .subscribe-contain {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .subscribe-left {
      .media {
        align-items: center;
        svg {
          width: 50px;
          height: auto;
          fill: $white;
        }
        .media-body {
          margin-left: 15px;
          h6 {
            color: $white;
            text-transform: capitalize;
            font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
            font-weight: 500;
          }
          h3 {
            color: $white;
            text-transform: uppercase;
          }
        }
      }
    }

    .subscribe-right {
      .input-group {
        .form-control {
          height: calc(38px + (50 - 38) * ((100vw - 320px) / (1920 - 320)));
          font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
          width: 400px;
          padding-left: calc(10px + (20 - 10) * ((100vw - 320px) / (1920 - 320)));
          padding-right: calc(10px + (20 - 10) * ((100vw - 320px) / (1920 - 320)));
          border-color: $light-border;
          &:focus {
            box-shadow: none;
            border-color: $light-border;
          }
        }       
        .input-group-text {
          background-color: $dark;
          color: $white;
          text-transform: uppercase;
          font-weight: 700;
          padding-left: calc(10px + (30 - 10) * ((100vw - 320px) / (1920 - 320)));
          padding-right: calc(10px + (30 - 10) * ((100vw - 320px) / (1920 - 320)));
          font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
          border: none;
        }       
      }
    }
  }

  &.block {
    .subscribe-contain {
      display: grid;
      justify-content: center;
      text-align: center;

      .subscribe-left {
        .media {
          display: block;

          svg {
            margin-bottom: 10px
          }

          .media-body {
            h3 {
              margin-bottom: 13px;
            }

            h6 {
              margin-bottom: 13px
            }
          }
        }
      }
    }
  }

  &.newsletter-inverse {
    background-color: rgba($theme-color2, 0.8);
  }
}

.subscribe2 {
  padding-top: calc(30px + (60 - 30) * ((100vw - 320px) / (1920 - 320)));
  padding-bottom: calc(30px + (60 - 30) * ((100vw - 320px) / (1920 - 320)));
  background-color: $theme-color1;

  .subscribe-contain {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    h2 {
      color: $white;
      text-transform: uppercase;
      font-weight: 700;
      font-family: $font-6;
      margin-bottom: 12px;
      letter-spacing: 2px;
      margin-top: -3px;
    }

    p {
      color: $white;
      width: 700px;
      line-height: 1.6;
      margin-bottom: 25px;
      font-size: 16px;
    }

    .form-control {
      padding-top: calc(10px + (15 - 10) * ((100vw - 320px) / (1920 - 320)));
      padding-bottom: calc(10px + (15 - 10) * ((100vw - 320px) / (1920 - 320)));
      padding-left: calc(10px + (20 - 10) * ((100vw - 320px) / (1920 - 320)));
      padding-right: calc(10px + (20 - 10) * ((100vw - 320px) / (1920 - 320)));
      text-transform: capitalize;
      border-radius: 10px;
      width: 450px;
      height: calc(40px + (58 - 40) * ((100vw - 320px) / (1920 - 320)));
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 30px;

      &:focus {
        box-shadow: none;
        border-color: $light-border;
      }
    }

    ul {
      margin-bottom: 28px;

      li {
        a {
          width: 35px;
          height: 35px;
          border-radius: 5px;
          color: $white;
          background-color: $theme-color2;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        &:nth-child(n+2) {
          margin-left: calc(5px + (15 - 5) * ((100vw - 320px) / (1920 - 320)));
        }
      }
    }

    .btn {
      text-transform: uppercase;
      font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
      background-color: $theme-color2;
    }
  }
}

.subscribe-block1 {
  >div {
    &:nth-child(n+2) {
      padding-left: 0;
    }
  }
}

.newslatter1 {
  padding-top: 30px;
  padding-bottom: 30px;
  background-color: $theme-color1;

  .newslatter-right {
    .form-group {
      .form-control {
        border-color: $light-border;
      }
    }
  }
}

/*============================
   19.  Slider start
==============================*/
.theme-slider {
  line-height: 0;

  .slider-banner {
    position: relative;
    overflow: hidden;

    &.p-left {
      .slider-banner-contain {
        justify-content: flex-start;
      }
    }

    &.p-right {
      .slider-banner-contain {
        justify-content: flex-end;
      }
    }

    &.p-center {
      .slider-banner-contain {
        justify-content: center;
      }
    }

    &.p-top {
      .slider-banner-contain {
        align-items: flex-start;
      }
    }

    .slider-img {
      height: 455px;
      background-color: $rich-gold;
    }

    .slider-banner-contain {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      padding: 0 100px;

      h4 {
        font-size: 24px;
        color: $black;
        text-transform: capitalize;
        line-height: 1;
        padding-bottom: 8px;
      }

      h1 {
        font-size: 72px;
        color: $theme-color2;
        text-transform: capitalize;
        line-height: 1;
        padding-bottom: 12px;
      }

      h2 {
        font-size: 36px;
        color: $icon-color;
        text-transform: capitalize;
        line-height: 1;
        padding-bottom: 16px;
      }

      .btn-rounded {
        display: inline-block;
        letter-spacing: 1px;
        padding: 15px 35px;
        font-size: 18px;
      }
    }

    .layout2-slide-1 {
      li {
        position: absolute;
        bottom: -8px;
        right: 0;
      }
    }

    .layout2-slide-2 {
      li {
        position: absolute;
        bottom: 55px;
        right: 60px;
      }
    }

    .layout2-slide-3 {
      li {
        position: absolute;
        bottom: 35px;
        right: 55px;
      }
    }

    .slide-center {
      display: flex !important;
      align-items: center !important;
      height: 100% !important;
    }

    &.slide-banner-1 {
      .layout1-slide-1 {
        li {
          position: absolute;
          bottom: -30px;
          left: -50px;

          &:nth-child(2) {
            right: 60px;
            left: unset;
          }
        }
      }

      .layout1-slide-2 {
        li {
          position: absolute;
          left: 50px;

          &:nth-child(2) {
            right: 20px;
            left: unset;
          }
        }
      }

      .layout1-slide-3 {
        li {
          position: absolute;
          bottom: -30px;
          left: -50px;

          &:nth-child(2) {
            right: 100px;
            bottom: -20px;
            left: unset;
          }
        }
      }

      .slider-banner-contain {
        h1 {
          color: $theme-color1;
          font-weight: 400;
          text-transform: uppercase;
          padding-bottom: 2px;

          span {
            color: $theme-color2;
            text-transform: capitalize;
            margin-left: 17px;
          }
        }

        h4 {
          font-size: 30px;
          color: $gray-7;
          font-weight: 400;
          padding-bottom: 20px;
        }

        h2 {
          font-size: 36px;
          color: $black;
          padding-bottom: 15px;
        }
      }

      .btn-normal {
        padding: 17px 35px;
        font-size: 18px;
      }

      .slider-img {
        height: 495px;
        background-color: #d3ecf3;
        overflow: hidden;
      }
    }

    &.slide-banner-2 {
      .layout3-slide-1 {
        li {
          position: absolute;
          top: 0;
          left: 20px;
          display: flex;
          align-items: center;
          height: 100%;

          &:last-child {
            right: 20px;
            left: unset;
          }
        }
      }

      .layout3-slide-2 {
        li {
          position: absolute;
          top: 0;
          left: 65px;
          display: flex;
          align-items: center;
          height: 100%;

          &:last-child {
            right: 65px;
            left: unset;
          }
        }
      }

      .slider-img {
        height: 480px;
        background-color: $gray-5;
      }

      .slider-banner-contain {
        .sub-contain {
          background-color: $white;
          padding: 40px;
          text-align: center;
          min-width: 440px;

          h4 {
            font-size: 18px;
            color: $dark;
            padding-bottom: 5px;
          }

          h1 {
            color: $theme-color2;
            padding-bottom: 21px;
          }

          span {
            display: block;
            background-color: $theme-color1;
            font-weight: 700;
            color: $white;
            font-size: 36px;
            text-transform: capitalize;
            letter-spacing: 1px;
            line-height: 1.2;
            padding-top: 8px;
            padding-bottom: 10px;
            margin-bottom: 22px;
          }

          a {
            text-transform: uppercase;
            font-weight: 700;
            color: $theme-color2;

            i {
              margin-left: 15px;
            }
          }
        }
      }
    }

    &.slide-banner-3 {
      .layout4-slide-1 {
        li {
          position: absolute;
          top: 0;
          right: 170px;
        }
      }

      .layout4-slide-2 {
        li {
          position: absolute;
          top: 0;
          right: 180px;
        }
      }

      .layout4-slide-3 {
        li {
          position: absolute;
          top: 0;
          right: 230px;
        }
      }

      .slider-img {
        height: 455px;
        background-color: $white;
      }

      .slider-banner-contain {
        h5 {
          font-size: 18px;
          color: $icon-color;
          font-weight: 400;
          text-transform: capitalize;
          padding-bottom: 6px;
        }

        h3 {
          font-size: 42px;
          text-transform: capitalize;
          color: $theme-color1;
          font-weight: 400;
          padding-bottom: 10px;
        }

        h1 {
          font-size: 52px;
          color: $theme-color2;
          padding-bottom: 27px;
          text-transform: uppercase;
        }

        h2 {
          font-size: 24px;
          color: $dark;
          padding-bottom: 25px;
        }

        .btn-normal {
          text-transform: capitalize;
          font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
          padding: 18px 45px;
        }
      }
    }

    &.slide-banner-4 {
      .layout5-slide-1 {
        li {
          position: absolute;
          right: 100px;
          bottom: 0;
        }
      }

      .layout5-slide-2 {
        li {
          position: absolute;
          right: 100px;
          bottom: 0;
        }
      }

      .layout5-slide-3 {
        li {
          position: absolute;
          right: 100px;
          bottom: 0;
        }
      }

      .slider-img {
        height: 695px;
        background: linear-gradient($solitud, $gaynsboro);
      }

      .slider-banner-contain {
        text-align: center;

        h3 {
          color: $theme-color1;
          font-size: calc(18px + (36 - 18) * ((100vw - 320px) / (1920 - 320)));
          font-weight: 400;
          text-transform: capitalize;
          position: relative;
          width: fit-content;
          margin: 0 auto;

          &:after {
            position: absolute;
            top: 50%;
            left: 120%;
            height: 5px;
            width: 100px;
            content: '';
            background-color: $theme-color2;
          }

          &:before {
            position: absolute;
            top: 50%;
            right: 120%;
            height: 5px;
            width: 100px;
            content: '';
            background-color: $theme-color2;
          }
        }

        h1 {
          color: $theme-color2;
          text-transform: capitalize;
          margin-top: 10px;
          font-size: calc(24px + (72 - 24) * ((100vw - 320px) / (1920 - 320)));
        }

        h2 {
          color: $theme-color1;
          margin-top: 11px;
          font-size: calc(18px + (36 - 18) * ((100vw - 320px) / (1920 - 320)));
        }

        .btn-normal {
          text-transform: capitalize;
          font-weight: 700;
          margin-top: 15px;
          font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
        }
      }
    }

    &.slide-banner-5 {
      .layout6-slide-1 {
        li {
          position: absolute;
          bottom: 0;
          right: 50px;
          width: 450px;
        }
      }

      .layout6-slide-2 {
        li {
          position: absolute;
          top: 60%;
          right: 17%;
          animation: vegislide-move 2s linear infinite;
          width: calc(25px + (55 - 25) * ((100vw - 320px) / (1920 - 320)));

          &:nth-child(2) {
            right: 50%;
            animation: vegislide-move 2.8s linear infinite;
            width: calc(20px + (55 - 20) * ((100vw - 320px) / (1920 - 320)));
          }

          &:nth-child(3) {
            top: unset;
            bottom: 10%;
            margin-right: 26%;
            animation: vegislide-move 3s linear infinite;
            width: calc(20px + (55 - 20) * ((100vw - 320px) / (1920 - 320)));
          }

          &:nth-child(4) {
            top: unset;
            bottom: 20%;
            right: 40%;
            animation: vegislide-move 3.5s linear infinite;
            width: calc(30px + (45 - 30) * ((100vw - 320px) / (1920 - 320)));
          }
        }
      }


      @keyframes vegislide-move {
        0% {
          transform: rotate(0deg);
        }

        50% {
          transform: rotate(15deg);
        }

        100% {
          transform: rotate(0deg);
        }
      }

      .slider-img {
        background-color: $gaynsboro;
        ;
      }

      .slider-banner-contain {
        h5 {
          font-size: 18px;
          color: $icon-color;
          font-weight: 400;
          text-transform: capitalize;
          padding-bottom: 6px;
        }

        h3 {
          font-size: 42px;
          text-transform: capitalize;
          color: $theme-color1;
          font-weight: 400;
          padding-bottom: 10px;
        }

        h1 {
          font-size: 52px;
          color: $theme-color2;
          padding-bottom: 27px;
          text-transform: uppercase;
        }

        h2 {
          font-size: 24px;
          color: $dark;
          padding-bottom: 25px;
        }

        .btn-rounded {
          text-transform: capitalize;

          &:hover {
            background-color: $theme-color2;
          }
        }
      }
    }
  }
}

.slider-layout-4 {
  .slider-slide {
    max-width: calc(100% - 275px);
    margin-left: 275px;
  }
}

.furniture-slide {
  position: relative;
  overflow: hidden;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: lighten($theme-color2, 25%);
    z-index: -2;
  }

  .slide-main {
    z-index: 1;
    overflow: hidden;

    .slide-contain {
      height: calc(100vh - 225px);
      display: flex;
      align-items: center;
      font-family: $font-5;
      padding-left: 15px;
      padding-right: 15px;

      h2 {
        font-size: calc(30px + (70 - 30) * ((100vw - 320px) / (1920 - 320)));
        color: $theme-color1;
        font-weight: 700;
        font-family: $font-5;
        text-transform: capitalize;
        line-height: 1;
      }

      h3 {
        font-size: calc(16px + (45 - 16) * ((100vw - 320px) / (1920 - 320)));
        color: $black;
        font-weight: 400;
        text-transform: capitalize;
        margin-bottom: 5px;
      }

      h4 {
        font-size: calc(16px + (30 - 16) * ((100vw - 320px) / (1920 - 320)));
        color: $theme-color2;
        font-weight: 500;
        margin-bottom: 15px;

        span {
          color: $black;
          margin-left: 15px;
          text-decoration: line-through;
        }
      }

      p {
        font-size: calc(14px + (19 - 14) * ((100vw - 320px) / (1920 - 320)));
        color: $gray-2;
        font-weight: 400;
        width: 600px;
        line-height: 1.6;
        margin-bottom: 20px;
      }
    }
  }

  .animat-block {
    .animat1,
    .animat2,
    .animat3,
    .animat4 {
      position: absolute;
      z-index: -2;
    }

    .animat1 {
      top: 5%;
      right: 5%;
      animation: rotationxy 90s infinite linear;
    }

    .animat2 {
      bottom: 15px;
      left: -21%;
      animation: rotationxy 30s infinite linear;
    }

    .animat3 {
      top: -66px;
      right: 42%;
      animation: rotation1 8s infinite alternate linear;
    }

    .animat4 {
      top: -60px;
      right: 43%;
      z-index: -2;
      animation: rotation1 5s infinite alternate linear;
    }
  }
}

.cosmetic-slide {
  .btn-solid {
    font-size: 20px; 
  }
  .slide-main {
    position: relative;
    z-index: 1;

    .slide-contain {
      height: calc(100vh - 148px);
      display: flex;
      align-items: center;
      justify-content: flex-end;
      text-align: center;
      padding-left: 15px;
      padding-right: 15px;

      h3 {
        font-size: calc(18px + (51 - 18) * ((100vw - 320px) / (1920 - 320)));
        color: $black1;
        font-weight: 500;
        font-family: $font-8;
        text-transform: capitalize;
        margin-bottom: 17px;
      }

      h2 {
        font-size: calc(24px + (67 - 24) * ((100vw - 320px) / (1920 - 320)));
        color: $theme-color1;
        font-weight: 700;
        font-family: $font-9;
        text-transform: uppercase;
        margin-bottom: 10px;
      }

      h4 {
        font-size: calc(18px + (35 - 18) * ((100vw - 320px) / (1920 - 320)));
        color: $black1;
        font-weight: 300;
        font-family: $font-8;
        text-transform: capitalize;
        margin-bottom: 28px;
      }

    }

    .animat-block {

      .animat-img1,
      .animat-img2,
      .animat-img3 {
        position: absolute;
        z-index: -1;
      }

      .animat-img1 {
        top: 40px;
        right: 100px;
      }

      .animat-img2 {
        top: 30px;
        right: 50px;
      }

      .animat-img3 {
        bottom: -10%;
        right: -3%;
      }
    }
  }
}

.kids-slide {
  position: relative;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: lighten($theme-color1, 43%);
    z-index: -1;
  }

  .slide-main {
    position: relative;
    overflow: hidden;

    .slide-contain {
      height: calc(100vh - 225px);
      display: flex;
      align-items: center;
      padding-left: 15px;
      padding-right: 15px;

      h4 {
        font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
        letter-spacing: 5px;
        color: $theme-color2;
        font-weight: 500;
        font-family: $font-12;
        margin-bottom: 5px;
        text-transform: uppercase;
      }

      h2 {
        font-size: calc(24px + (50 - 24) * ((100vw - 320px) / (1920 - 320)));
        font-weight: 800;
        color: $theme-color1;
        font-family: $font-13;
        margin-bottom: 15px;
      }

      h3 {
        font-size: calc(16px + (30 - 16) * ((100vw - 320px) / (1920 - 320)));
        color: $dark;
        font-weight: 400;
        font-family: $font-7;
        margin-bottom: 10px;
      }

      p {
        font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
        font-weight: 400;
        font-family: $font-12;
        width: 58%;
        line-height: 1.6;
        margin-bottom: 25px;
      }
    }
  }

  .animat-block {

    .animat1,
    .animat2,
    .animat3,
    .animat4,
    .animat5,
    .animat6 {
      position: absolute;
      transition: all 0.5s linear;
      z-index: -1;
    }

    .animat1 {
      bottom: 50px;
      left: 70px;
      animation: bounce1 5s infinite alternate-reverse;
    }

    .animat2 {
      top: 40px;
      left: 40%;
      animation: move2 9s linear infinite;
    }

    .animat3 {
      top: 80px;
      right: 80px;
      animation: move2 12s linear infinite;
    }

    .animat4 {
      bottom: 100px;
      right: 30px;
      animation: move2 15s linear infinite;
    }

    .animat5 {
      bottom: -83px;
      left: 23%;
      animation: move3 35s linear infinite alternate-reverse;
    }

    .animat6 {
      top: 0;
      left: -5%;
      right: 0;
      bottom: 0;
      margin-top: auto;
      margin-bottom: auto;
      margin-left: auto;
      margin-right: auto;
      width: 120%;
      animation: rotationxy 300s linear infinite;
    }
  }
}

.backanimat {
  position: relative;
  animation: backanimat 60s linear infinite;
  will-change: background-position;
  background-size: auto !important;
  overflow: hidden;
}

.tools-slide {
  .slide-main {
    position: relative;

    .slide-contain {
      height: calc(100vh - 214px);
      display: flex;
      align-items: center;
      font-family: $font-5;
      padding-left: 15px;
      padding-right: 15px;

      h2 {
        font-size: calc(30px + (60 - 30) * ((100vw - 320px) / (1920 - 320)));
        color: $theme-color1;
        font-weight: 700;
        text-transform: uppercase;
        margin-bottom: 5px;
      }

      h3 {
        font-size: calc(20px + (40 - 20) * ((100vw - 320px) / (1920 - 320)));
        color: $black1;
        font-weight: 400;
        text-transform: capitalize;
        margin-bottom: 15px;
      }

      h4 {
        font-size: calc(18px + (45 - 18) * ((100vw - 320px) / (1920 - 320)));
        color: $theme-color1;
        font-weight: 700;
        margin-bottom: 15px;

        span {
          font-size: 60%;
          text-decoration: line-through;
          color: $black1;
        }
      }

      p {
        font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
        color: $gray-6;
        font-weight: 500;
        width: 455px;
        line-height: 1.6;
        margin-bottom: 20px;
      }

    }

    .animat-block {
      .animat1 {
        position: absolute;
        top: 50px;
        bottom: 0;
        right: 10%;
        margin-top: auto;
        margin-bottom: auto;
      }
    }
  }
}


.megastore-slide {
  .slide-main {
    .slide-contain {
      height: 459px;
      display: flex;
      align-items: center;
      padding-left: 40px;
      padding-right: 40px;

      h4 {
        font-size: calc(18px + (23 - 18) * ((100vw - 320px) / (1920 - 320)));
        letter-spacing: 1px;
        color: $theme-color1;
        font-weight: 700;
        font-family: $font-8;
        text-transform: capitalize;
        margin-bottom: 11px;
      }

      h2 {
        font-size: calc(28px + (55 - 28) * ((100vw - 320px) / (1920 - 320)));
        letter-spacing: 2px;
        color: $theme-color2;
        font-weight: 700;
        font-family: $font-11;
        text-transform: uppercase;
        line-height: 1;
        margin-bottom: 14px;
      }

      h3 {
        font-size: calc(18px + (23 - 18) * ((100vw - 320px) / (1920 - 320)));
        letter-spacing: 1px;
        color: $black;
        font-weight: 700;
        font-family: $font-8;
        text-transform: capitalize;
        margin-bottom: 24px;
      }
    }
  }

  .mega-slide-block {
    >div {
      &:first-child {
        padding-left: 275px;

        >div {
          >div {
            &:nth-child(n+2) {
              padding-top: 15px;
            }

            &:last-child {
              padding-left: 0;
            }
          }
        }
      }

      &:nth-child(n+2) {
        padding-left: 0;
      }
    }
  }
}

.grocery-slide-block {
  >div {
    &:nth-child(2) {
      padding-left: unset;
    }
  }
}

.grocery-slide {
  margin-top: 15px;

  .header-search3 {
    margin-bottom: 15px;
  }

  .slide-main {
    position: relative;
    margin-left: 260px;

    .slide-contain {
      height: 660px;
      display: flex;
      align-items: center;
      text-align: center;
      padding-left: 40px;
      padding-right: 40px;

      h3 {
        color: $dark;
        text-transform: capitalize;
        margin-bottom: 13px;
        font-size: calc(18px + (24 - 18) * ((100vw - 320px) / (1920 - 320)));
      }

      h2 {
        color: $theme-color2;
        font-size: calc(30px + (65 - 30) * ((100vw - 320px) / (1920 - 320)));
        text-transform: uppercase;
        font-weight: 700;
        margin-bottom: 12px;
      }

      h4 {
        color: $dark;
        text-transform: capitalize;
        font-size: calc(18px + (24 - 18) * ((100vw - 320px) / (1920 - 320)));
        margin-bottom: 25px;
      }
    }

    .animat-block {
      .animat1 {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 2%;
        margin-top: auto;
        margin-bottom: auto;
      }
    }
  }
}

.pets-slide {
  .slide-main {
    .slide-contain {
      height: calc(100vh - 215px);
      display: flex;
      align-items: center;
      text-align: center;
      justify-content: center;
      text-align: center;

      h3 {
        font-size: calc(18px + (24 - 18) * ((100vw - 320px) / (1920 - 320)));
        letter-spacing: 1px;
        color: $dark;
        font-weight: 400;
        font-family: $font-8;
        text-transform: capitalize;
        margin-bottom: 20px;
      }

      h2 {
        font-size: calc(20px + (35 - 20) * ((100vw - 320px) / (1920 - 320)));
        letter-spacing: 2px;
        color: $theme-color1;
        font-family: $font-6;
        font-weight: 700;
        text-transform: capitalize;
        margin-bottom: 20px;
      }

      .form-control {
        margin-bottom: 30px;
        padding-left: calc(15px + (30 - 15) * ((100vw - 320px) / (1920 - 320)));
        padding-right: calc(15px + (30 - 15) * ((100vw - 320px) / (1920 - 320)));
        border-radius: 30px;
        font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
        height: 60px;
        width: 500px;
    text-align: center;
        margin-left: auto;
        margin-right: auto;
        text-transform: capitalize;
        border-color: $light-border;
        box-shadow: -3.009px 3.993px 6.5px rgba($theme-color1, 0.21);

        &:focus {
          box-shadow: -3.009px 3.993px 6.5px rgba($theme-color1, 0.21);
          outline: none;
          border-color: $light-border;
        }
      }

      .btn {
        font-weight: 700;
        text-transform: uppercase;
      }
    }

    .animat-block {

      .animat1,
      .animat2 {
        position: absolute;
        top: 0;
        bottom: 0;
        margin-top: auto;
        margin-bottom: auto;
      }

      .animat1 {
        left: 30px;
      }

      .animat2 {
        right: 30px;
      }
    }
  }
}

.farming-slide {
  position: relative;

  .slide-main {
    position: relative;
    overflow: hidden;

    .slide-contain {
      height: calc(100vh - 148px);
      display: flex;
      align-items: center;
      text-align: center;
      text-transform: uppercase;
      padding-left: 15px;
      padding-right: 15px;

      h3 {
        font-size: calc(18px + (34 - 18) * ((100vw - 320px) / (1920 - 320)));
        color: $theme-color1;
        font-weight: 500;
        font-family: $font-8;
        margin-bottom: 15px;
      }

      h2 {
        font-size: calc(34px + (80 - 34) * ((100vw - 320px) / (1920 - 320)));
        color: $black;
        font-family: $font-6;
        line-height: 1;
        margin-bottom: 15px;
        text-transform: capitalize;
      }

      h4 {
        font-size: calc(18px + (28 - 18) * ((100vw - 320px) / (1920 - 320)));
        color: $theme-color1;
        font-weight: 700;
        margin-bottom: 25px;
      }
    }

  }

  .animat-block {

    .animat1,
    .animat2,
    .animat3 {
      position: absolute;
    }

    .animat1 {
      top: 0;
      right: 8%;
      bottom: 0;
      margin-top: auto;
      margin-bottom: auto;
      z-index: 2;
      width: 740px;
      height: auto;
    }

    .animat2 {
      bottom: -10px;
      left: -27px;
    }

    .animat3 {
      top: 32px;
      right: -55px;
    }
  }
}

.digitalmark-slide {
  .slide-main {
    position: relative;

    .slide-contain {
      height: calc(100vh - 214px);
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-left: 15px;
      padding-right: 15px;

      .sub-contain {
        width: 700px;

        h2 {
          font-size: calc(20px + (56 - 20) * ((100vw - 320px) / (1920 - 320)));
          color: $theme-color1;
          font-weight: 500;
          font-family: $font-11;
          margin-bottom: 13px;
        }

        p {
          font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
          color: $gray-2;
          font-family: $font-12;
          width: 530px;
          margin-bottom: 27px;
        }

        .input-group {
          display: block;
          width: fit-content;
          background-color: $white;
          width: 507px;
          border-radius: 32px;
          overflow: hidden;

          .form-control {
            background-color: $white;
            text-transform: capitalize;
            font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
            padding-left: 30px;
            border: none;
            width: 73%;
            height: 66px;

            &:focus {
              box-shadow: none;
              outline: none;
              border: none;
            }
          }

          .btn {
            border-radius: 30px;
            font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
            font-weight: 500;
            font-family: $font-8;
            display: flex;
            align-items: center;
            text-transform: capitalize;
            height: fit-content;
            position: absolute;
            top: 0;
            bottom: 0;
            right: 10px;
            margin-top: auto;
            margin-bottom: auto;
            padding: 17px 30px;
            z-index: 3;
          }
        }

        .video-btn {
          position: absolute;
          top: 37%;
          left: 10%;
          display: none;

          i {
            width: 150px;
            height: 150px;
            font-size: 80px;
            background-color: $theme-color1;
            color: $white;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            padding-left: 10%;
            box-shadow: 0 15px 15px 5px rgba($theme-color1, 0.2);
          }
        }
      }
    }

    .animat-block {

      .animat1,
      .animat2 {
        position: absolute;
      }

      .animat1 {
        top: 14%;
        left: 35%;
        box-shadow: 0 5px 5px 2px rgba($theme-color1, 0.2);
        border-radius: 50%;
        animation: move2 30s linear infinite;
      }

      .animat2 {
        left: 7%;
        bottom: 20%;
        animation: move1 9s linear infinite alternate;
      }

    }
  }
}

/*============================
   20. Title css
==============================*/
.title1 {
  text-align: center;
  background-color: $white;
  padding: 42px 0;

  h4 {
    font-size: 18px;
    color: $dark;
    text-transform: uppercase;
    letter-spacing: 0.05em;
  }

  &.title-big {
    h4 {
      font-size: calc(18px + (24 - 18) * ((100vw - 320px) / (1920 - 320)));
    }
  }
}

.title2 {
  padding-bottom: 15px;
  margin-bottom: 30px;
  text-align: center;
  background-color: $white;

  h4 {
    font-size: 18px;
    text-transform: uppercase;
    color: $dark;
    font-weight: 600;
    letter-spacing: 0.05em;
    text-align: center;
    width: fit-content;
    position: relative;
    margin: 0 auto;

    &:before {
      content: '';
      position: absolute;
      bottom: -10px;
      left: 50%;
      height: 3px;
      width: 100%;
      max-width: 100%;
      background: $theme-color1;
      transform: translateX(-50%);
      transition: all 0.3s ease-out;
    }

    &:hover {
      &:before {
        width: 48px;
        max-width: 50%;
      }
    }
  }
}

.title3 {
  text-align: center;
  padding-top: 50px;
  padding-bottom: 30px;

  h4 {
    text-transform: uppercase;
    color: $black;
    letter-spacing: 0.05em;
    margin-top: -4px;
  }

  &.title-big {
    h4 {
      font-size: calc(16px + (20 - 16) * ((100vw - 320px) / (1920 - 320)));
    }
  }
}

.title4 {
  text-align: center;
  padding-top: calc(20px + (42 - 20) * ((100vw - 320px) / (1920 - 320)));
  padding-bottom: calc(20px + (42 - 20) * ((100vw - 320px) / (1920 - 320)));

  h4 {
    font-size: calc(18px + (30 - 18) * ((100vw - 320px) / (1920 - 320)));
    color: $dark;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    line-height: 1;

    span {
      color: $theme-color1;
    }
  }
}

.title5 {
  text-align: center;
  background-color: $white;

  h4 {
    font-size: 36px;
    text-transform: uppercase;
    color: $black;
    letter-spacing: 0.05em;
  }

  p {
    color: $dark;
    margin-top: 10px;
    font-size: 18px;
  }
}

.title6 {
  text-align: center;
  position: relative;
  padding: calc(18px + (42 - 18) * ((100vw - 320px) / (1920 - 320)));

  h4 {
    font-size: calc(18px + (24 - 18) * ((100vw - 320px) / (1920 - 320)));
    font-weight: 700;
    display: inline-block;
    margin-bottom: 0;
    padding: 0 25px;
    text-transform: uppercase;
    position: relative;
    color: $dark;

    &:before,
    &:after {
      width: 10%;
      height: 2px;
      content: '';
      background: $dark;
      display: inline-block;
      position: absolute;
      top: 50%;
      left: -30px;
    }

    &:after {
      right: -30px;
      left: unset;
    }
  }
}

.title7 {
  text-align: center;
  padding-bottom: calc(20px + (42 - 20) * ((100vw - 320px) / (1920 - 320)));
  background-color: $white;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;

  h3 {
    text-transform: uppercase;
    margin-bottom: calc(5px + (15 - 5) * ((100vw - 320px) / (1920 - 320)));
    font-size: calc(18px + (24 - 18) * ((100vw - 320px) / (1920 - 320)));
    line-height: 1;
  }

  .line {
    position: relative;
    width: 100%;
    height: 3px;
    background-color: $theme-color2;

    &:before {
      content: "";
      position: absolute;
      top: -4px;
    left: 0;
    width: 10px;
    height: 10px;
      border-radius: 50%;
      background-color: $theme-color1;
      animation: moveboll 15s linear infinite alternate-reverse;
    }
  }
}

.title8 {
  text-align: center;
  position: relative;
  padding-bottom: 15px;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 45px;

  h4 {
    font-size: calc(18px + (30 - 18) * ((100vw - 320px) / (1920 - 320)));
    color: $dark;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    line-height: 1;
  }

  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    height: 3px;
    width: 100%;
    max-width: 100%;
    background: $theme-color1;
    transform: translateX(-50%);
    transition: all 0.3s ease-out;
  }

  &:hover {
    &:before {
      width: 50%;
      max-width: 50%;
    }
  }
}



.title2 {
  padding-bottom: 15px;
  margin-bottom: 30px;
  text-align: center;
  background-color: $white;

  h4 {
    font-size: 18px;
    text-transform: uppercase;
    color: $dark;
    font-weight: 600;
    letter-spacing: 0.05em;
    text-align: center;
    width: fit-content;
    position: relative;
    margin: 0 auto;

    &:before {
      content: '';
      position: absolute;
      bottom: -10px;
      left: 50%;
      height: 3px;
      width: 100%;
      max-width: 100%;
      background: $theme-color1;
      transform: translateX(-50%);
      transition: all 0.3s ease-out;
    }

    &:hover {
      &:before {
        width: 48px;
        max-width: 50%;
      }
    }
  }
}

/*============================
  21. Tab-product css
==============================*/
.tab-product-main {
  background-color: $white;

  .tab-prodcut-contain {
    text-align: center;

    ul {
      li {
        padding: 40px 0;
        position: relative;

        &:nth-child(n+2) {
          margin-left: 40px;
        }

        &.current {
          a {
            color: $theme-color1;
          }

          &:before {
            position: absolute;
            top: 0;
            left: 0;
            height: 5px;
            background-color: $theme-color1;
            width: 100%;
            content: '';
          }
        }

        a {
          color: $theme-color1;
          text-transform: uppercase;
          font-weight: 700;
          font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
        }
      }
    }
  }

  &.tab-second {
    padding-top: calc(20px + (40 - 20) * ((100vw - 320px) / (1920 - 320)));
    padding-bottom: calc(20px + (40 - 20) * ((100vw - 320px) / (1920 - 320)));

    .tab-prodcut-contain {
      ul {
        li {
          border-radius: 5px;
          border: 1px solid $theme-color1;
          line-height: 1;
          padding: 0;
          transition: all 0.3s ease;

          a {
            display: flex;
            align-items: center;
            padding-top: calc(8px + (10 - 8) * ((100vw - 320px) / (1920 - 320)));
            padding-bottom: calc(8px + (10 - 8) * ((100vw - 320px) / (1920 - 320)));
            padding-left: calc(15px + (30 - 15) * ((100vw - 320px) / (1920 - 320)));
            padding-right: calc(15px + (30 - 15) * ((100vw - 320px) / (1920 - 320)));
            transition: all 0.3s ease;

            svg {
              width: calc(20px + (35 - 20) * ((100vw - 320px) / (1920 - 320)));
              margin-right: 10px;
              transition: all 0.3s ease;
            }
          }

          &.current {
            border-color: $theme-color1;
            background-color: $theme-color1;

            a {
              color: $white;

              svg {
                fill: $white;
              }
            }

            &:before {
              display: none;
            }
          }
        }
      }
    }

  }

  &.tab-third {
    .tab-prodcut-contain {
      ul {
        li {
          &.current {
            &:before {
              top: unset;
              bottom: 32%;
            }
          }
        }
      }
    }
  }

  &.tab-four {
    background-color: transparent;

    .tab-prodcut-contain {
      ul {
        li {
          padding: 0;
          margin: 0;

          &.current {
            a {
              background-color: $theme-color1;
              color: $white;
            }

            &::before {
              display: none;
            }
          }

          a {
            padding-top: calc(8px + (15 - 8) * ((100vw - 320px) / (1920 - 320)));
            padding-bottom: calc(8px + (15 - 8) * ((100vw - 320px) / (1920 - 320)));
            padding-left: calc(12px + (30 - 12) * ((100vw - 320px) / (1920 - 320)));
            padding-right: calc(12px + (30 - 12) * ((100vw - 320px) / (1920 - 320)));
            border-radius: 30px;
            border: 1px solid $theme-color1;
            line-height: 1;
            font-size: calc(12px + (16 - 12) * ((100vw - 320px) / (1920 - 320)));
            display: block;
          }

          &:nth-child(n+2) {
            margin-left: calc(0px + (15 - 0) * ((100vw - 320px) / (1920 - 320)));
          }
        }
      }
    }
  }

}

/*============================
   22.  Newsleatter css
==============================*/
.newsletter {
  background-color: $white;
  display: flex;
  justify-content: center;
  padding: 50px 0;

  .news-leble {
    display: flex;
    align-items: center;
    border-right: 1px solid $light-border;
    padding-right: 35px;

    svg {
      fill: $theme-color1;
      width: 50px;
      height: 50px;
    }

    .news-text {
      font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
      text-transform: uppercase;
      color: $dark;
      line-height: 1;
      font-weight: 700;
      margin-left: 25px;

      span {
        display: block;
        margin-top: 6px;
      }
    }
  }

  .subscribe-block {
    display: flex;
    align-items: center;

    .input-group {
      input {
        border: none;
        width: 320px;
        background-color: transparent;

        &::placeholder {
          color: $light-gray;
          font-family: $font-2;
          letter-spacing: 1px;
          font-size: 12px;
        }

        &:focus {
          border: none;
          outline: none;
          box-shadow: none;
        }
      }

      span {
        background-color: transparent;
        border: none;
        padding: 0 45px;

        i {
          font-size: 20px;
          color: $theme-color2;
        }
      }
    }

    .btn-normal {
      text-transform: uppercase;
      padding: 18px 30px;
    }
  }

  &.newsletter-inverse {
    .subscribe-block {
      .btn-normal {
        background-color: $theme-color2;
      }
    }

    .news-leble {
      svg {
        fill: $theme-color2;
      }
    }
  }
}


/*=====================
  23.breadcrumb CSS start
==========================*/
.breadcrumb-main {
  background-color: $white;
  padding: 50px 0;

  .breadcrumb-contain {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    h2 {
      color: $font-color;
      text-transform: uppercase;
      margin-bottom: 10px;
      font-size: calc(20px + (28 - 20) * ((100vw - 320px) / (1920 - 320)));
    }

    ul {
      li {
        margin-right: 15px;

        &:last-child {
          margin-right: 0;
        }

        a {
          color: $font-color;
          font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
          text-transform: uppercase;
          font-weight: 700;
        }

        i {
          color: $font-color;
          font-size: 17px;
          font-weight: 700;
        }
      }
    }
  }
}


/*=====================
  24. payment offer CSS start
==========================*/
.paymant-offer-mian {
  background-color: $white;
  padding: 30px;
  margin-right: 15px;
  text-align: center;

  img {
    margin: 0 auto;
  }

  h2 {
    color: $theme-color2;
    font-weight: 600;
    line-height: 1;
    margin-top: 25px;
    font-family: monospace;
  }

  h3 {
    text-transform: capitalize;
    line-height: 1;
    margin-top: 10px;
    color: $font-color;
    font-size: calc(18px + (20 - 18) * ((100vw - 320px) / (1920 - 320)));
    font-weight: 500;
    margin-bottom: 10px;
  }

  h4 {
    text-transform: capitalize;
    line-height: 1;
    margin-top: 10px;
    margin-bottom: 15px;
    font-weight: 500;
  }

  .payment-cod {
    padding: 8px 20px;
    width: fit-content;
    text-transform: capitalize;
    margin-left: auto;
    margin-right: auto;
    margin-top: 15px;
    line-height: 1;
    color: $theme-color2;
    background-color: rgba($theme-color2, 0.1);
    font-weight: 700;
    border: 1px dashed;
    border-radius: 5px;
  }
}



/*=====================
  25.Inner pages CSS start
==========================*/
// sitemap page //
.sitemap_page {
  .title {
    color: $theme-color1;
    text-transform: capitalize;
    font-weight: 700;
  }

  ul {
    padding-left: 10px;

    li {
      display: block;
      margin-bottom: 10px;

      a {
        color: $dark;
        font-weight: 700;
        transition: all 0.5s ease;

        text-transform: capitalize;

        i {
          font-size: 10px;
        }

        &:hover {
          color: $theme-color1;
          transition: all 0.5s ease;
        }
      }
    }

    ul {
      padding-left: 15px;
      margin-top: 5px;

      li {
        margin-bottom: 0;

        a {
          font-weight: 400;
          color: $font-color;
          font-size: 14px;
        }
      }
    }
  }
}

// profile page //
.select_input {
  select {
    padding: 13px 20px;
    margin-bottom: 25px;
  }
}

.mb-3 {
  &.theme-form {
    .mb-5 {
      margin-bottom: 0 !important;
    }
  }
}

// coming soon //
.template-password {
  background: url("../images/coming-soon.jpg");
  background-size: cover;
  height: 100vh;
  background-blend-mode: overlay;
  background-color: #82c2db;
  #container {
    height: 100vh;
    max-width: 600px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;

    >div {
      width: 100%;
    }

    #login {
      width: 100%;
      margin-bottom: 120px;

      h2 {
        color: $theme-color1;
        font-size: 30px;
        font-weight: 600;
        margin-top: 50px;
        font-size: 300%;
      }

      .theme-form {
        h3 {
          color: $font-color;
          font-size: 20px;
          margin-bottom: 15px;
          font-weight: 500;      
        }

        input {
          border-color: $light-border;
          font-size: 12px;
          padding: 17px 25px;
          margin-bottom: 15px;
          height: inherit;
        }

        input {
          &:focus {
            box-shadow: none;
          }
        }
      }
    }

    #powered {
      position: absolute;
      bottom: 5px;
      left: 0;
      right: 0;
    }

    #owner {
      a {
        color: $theme-color1;
      }
    }
  }

  .btn-normal {
    text-transform: uppercase;
  }
}

.review-page {
  .comment-section {
    border-bottom: none !important;

    li {
      &:first-child {
        border-top: none;
        padding-top: 0;
      }

      &:last-child {
        padding-bottom: 0;
      }
    }
  }

  .comnt-sec {
    margin-top: 10px;

    li {
      margin-right: 15px;
      padding-bottom: 0 !important;
      padding-top: 0 !important;
      border-top: none !important;

      a {
        color: $icon-color;
        font-size: 16px;

        i {
          margin-right: 5px;
        }
      }
    }
  }
}

// error section //
.error-section {
  padding: 130px 0;
  text-align: center;

  h1 {
    font-size: 200px;
    font-weight: 900;
    margin: 0;
    line-height: 0.8;
    color: $dark;
    margin-top: -11px;
  }

  h2 {
    color: $font-color;
    font-weight: 700;
    letter-spacing: 3px;
    margin: 40px 0;
    text-transform: uppercase;
  }

  .btn-normal {
    text-transform: uppercase;
  }
}

// breadcrumb section //
.breadcrumb-section {
  background-color: $light-border;
  padding: 30px 0;

  .page-title {
    display: flex;
    height: 100%;
    align-items: center;

    h2 {
      font-size: 16px;
      margin-bottom: 0;
    }
  }

  .breadcrumb {
    background-color: transparent;
    justify-content: flex-end;
    text-transform: uppercase;
    font-size: 14px;
    color: $dark;
    font-weight: 600;
    margin: 0;
    padding-right: 0;

    a {
      color: $dark;
      font-weight: 600;
    }
  }
}

// about page section //
.about-head {
  margin-bottom: 20px;
  align-items: center;
  display: flex;
  height: 100%;

  h2 {
    color: $theme-color1;
    margin-bottom: 0;
  }
}

.about-page {
  p {
    margin-bottom: -7px;
  }

  .banner-section {
    border-radius: 5px;
    overflow: hidden;
  }

  h4 {
    margin-bottom: 10px;
    margin-top: 30px;
    text-transform: capitalize;
    font-weight: 600;
    line-height: 1.3;
    color: $font-color;
  }

  p {
    line-height: 1.8;
    letter-spacing: 0.03em;
    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
  }
}

.team {
  position: relative;

  img {
    margin: 0 auto;
    padding: 0 10px;
  }

  .slick-prev,
  .slick-next {
    position: absolute;
    top: -45px;

    &:before {
      font: normal normal normal 30px/1 $font-awesome;
      color: $dark;
      opacity: 0.6;
    }
  }

  .slick-prev {
    left: unset;
    right: 20px;

    &:before {
      content: "\f104";
    }
  }

  .slick-next {
    right: 0;

    &:before {
      content: "\f105";
    }
  }

  h2 {
    color: $font-color;
    padding-bottom: 15px;
    border-bottom: 1px solid $light-border;
    margin-bottom: 10px;
  }

  h4,
  h6 {
    text-align: center;
  }

  h4 {
    color: $theme-color1;
    font-weight: 700;
    margin-top: 15px;
    margin-bottom: 5px;
  }

  h6 {
    margin-bottom: 0;
    color: $dark;
  }
}


// product-sidebar //
.collection-filter {
  >div {
    &:nth-child(n +2) {
      margin-top: 30px;
    }
  }
}



// category page //
.category-6 {
  .col-xl-2 {
    padding-left: 10px;
    padding-right: 10px;

    .product {
      .product-box {
        .product-detail {
          .icon-detail {
            button {
              padding: 10px;
            }

            a {
              i {
                padding: 10px;
              }
            }
          }
        }
      }
    }
  }
}

.collection-filter-block {
  .product-service {
    .media {
      padding-bottom: 20px;
      margin-bottom: 20px;
      border-bottom: 1px solid lighten($light-border, 5%);

      svg {
        height: 40px;
        width: 40px;

        path {
          fill: $theme-color1;
        }
      }

      .media-body {
        padding-left: 10px;

        h4 {
          margin-bottom: 5px;
          text-transform: capitalize;
          color: $font-color;
          letter-spacing: 0.05em;
        }

        p {
          margin-bottom: 0;
          text-transform: capitalize;
          font-size: calc(13px + (15 - 13) * ((100vw - 320px) / (1920 - 320)));
        }
      }

      &:last-child {
        padding-bottom: 0;
      }
    }
  }
}

.collection-collapse-block {
  .collapse-block-title {
    position: relative;
    cursor: pointer;
    text-transform: uppercase;
    font-size: 16px;
    color: $font-color;
    font-weight: 600;
    letter-spacing: 0.05em;
    line-height: 1;

    &:after {
      position: absolute;
      display: block;
      top: 0;
      right: -3px;
      content: "\f0d7";
      font-family: $font-awesome;
      color: $font-color;
    }
  }

  .collection-collapse-block-content {
    .color-selector {
      margin-top: 20px;
    }

    .collection-brand-filter {
      .form-check-input{
        height: 20px;
        width: 20px;
      }
      .category-list {
        li {
          display: flex;
          font-size: 16px;
          text-transform: uppercase;
          line-height: 18px;

          a {
            color: $icon-color;
            padding-bottom: 10px;
          }

          &:last-child {
            a {
              padding-bottom: 0;
            }
          }

          &:first-child {
            margin-top: 30px;
          }
        }
      }

      .collection-filter-checkbox {
        margin-bottom: 12px;

        &:first-child {
          margin-top: 20px;
        }

        &:last-child {
          margin-bottom: -5px;
        }

        label {
          text-transform: uppercase;
          cursor: pointer;
          color: lighten($black, 46.5%);
          font-weight: 600;
          letter-spacing: 0.5px;
          padding-left: 5px;
          line-height: 1.7;
          margin-bottom: 0;
          &:checked {
            background-color: $theme-color1;
            border-color: $theme-color1;
          }
          &:before {
            top: 1px;
            height: 15px;
            width: 15px;
            background: $white;
            border: 1px solid $light-border;
          }

          &:after {
            top: 1px;
            height: 15px;
            width: 15px;
            background-size: 65%;
          }
        }

        .custom-control-input {
          &:checked {
            ~.custom-control-label {
              &:after {
                background-image: url("data:image/svg+xml;charset=utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'><path fill='%23000' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/></svg>");
              }
            }
          }
        }
      }
    }
  }
}

.color-selector {
  ul {
    li {
      display: flex;
      align-items: center;
      font-size: 14px;
      color: $icon-color;
      font-weight: 700;
      text-transform: uppercase;
      letter-spacing: 0.05em;
      &:nth-child(n+2) {
        margin-top: 5px;

        >div {
          &.active {
            &:after {
              filter: invert(1);
            }
          }
        }
      }

      >div {
        height: 22px;
        width: 22px;
        border-radius: 5px;
        margin-right: 10px;
        cursor: pointer;
        border: 1px solid $light-border;       
        &.active {
          position: relative;

          &:after {
            content: "";
            background-image: url("data:image/svg+xml;charset=utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'><path fill='%23000' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/></svg>");
            top: 5px;
            right: 0;
            height: 15px;
            width: 15px;
            background-size: 70%;
            background-repeat: no-repeat;
            position: absolute;
          }
        }
      }

      .color-1 {
        background-color: $white;
      }

      .color-2 {
        background-color: brown;
      }

      .color-3 {
        background-color: red;
      }

      .color-4 {
        background-color: purple;
      }

      .color-5 {
        background-color: teal;
      }

      .color-6 {
        background-color: pink;
      }

      .color-7 {
        background-color: coral;
      }
    }




  }

  &.inline {
    ul {
      li {
        display: inline-block;

        >div {
          margin-right: unset;
        }

        &:nth-child(n+2) {
          margin-top: 0;
          margin-left: 5px;
        }
      }
    }
  }
}

.collection-sidebar-banner {
  margin-top: 30px;

  img {
    width: 100%;
  }
}

.top-banner-wrapper {
  .top-banner-content {
    h4 {
      font-weight: 600;
      color: $font-color;
      margin-bottom: 15px;
      text-transform: capitalize;
    }

    h5 {
      font-size: 16px;
      font-weight: 600;
      color: $font-color;
      letter-spacing: 1px;
      line-height: 1.2;
      margin-bottom: 10px;
    }

    p {
      line-height: 1.5;
      color: lighten($black, 46.5%);
      margin-bottom: 0;
      font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
    }
  }
}

.product-wrapper-grid.list-view {
  .product-wrap {
    .product-info {
      text-align: left;
      align-self: center;
      padding-left: 15px;
    }
  }

  .product-box {
    display: flex;
    padding-bottom: 0;
    background-color: $white;
    .product-imgbox,
    .img-block {
      width: 25%;
    }

    .product-detail {
      padding-left: 15px;
      align-self: center;
      text-align: left !important;
      &.detail-center {
        padding: 0;
        padding-left: 25px;
        background-color: transparent;
        overflow: unset;
      }

      .rating-star {
        margin-top: 0;
        margin-bottom: 10px;
      }

      p {
        display: block !important;
        margin-bottom: 10px;
        line-height: 18px;
      }

      .color-variant {
        padding-top: 10px;
      }

      h6 {
        font-weight: 700;
        margin-bottom: 10px;
      }

      .detail-title {
        opacity: 1 !important;
        padding-top: 0 !important;
        background-color: transparent;
      }

      .icon-detail {
        position: relative;
        padding-top: 10px;
        left: unset;
        right: unset;
        width: fit-content;
        top:0;
        bottom:0;
        opacity: 1;
        button {
          animation: none;         
          i {
            font-size: 14px;
          }
        }

        a {
          i {
            font-size: 12px;           
          }

          &:nth-child(2) i {
            animation: none;
          }

          &:nth-child(3) i {
            animation: none;
          }

          &:nth-child(4) i {
            animation: none;
          }
        }

       
      }
    }
  }
}
.collection-product-wrapper {
  .product-top-filter {
    border-bottom: 1px solid $light-border;
    border-top: 1px solid $light-border;
    .product-filter-content,
    .popup-filter {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .search-count, .sidebar-popup {
        border-right: 1px solid $light-border;
        padding:20px;
        text-transform: capitalize;
        width:35%;
        font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
        h5 {
          margin-bottom: 0;
          color: $font-color;
        }
      }
      .collection-view {
        padding: 20px;
        width: 10%;
        ul {
          li {
            height: 100%;
            vertical-align: middle;
            cursor: pointer;
            color: $font-color;

            &:first-child {
              margin-right: 14px;
            }
          }
        }
      }

      .collection-grid-view {
        padding: 20px;
        width: 20%;
        border-right: 1px solid $light-border;

        ul {
          display: flex;
          justify-content: flex-end;

          li {
            margin-left: 10px;

            img {
              height: 18px;
              cursor: pointer;
            }
          }
        }
      }

      .product-page-per-view,
      .product-page-filter {
        position: relative;
        width: 20%;

        select {
          border: 0;
          padding: 20px;
          border-right: 1px solid $light-border;
          width: 100%;
          appearance: none;
          cursor: pointer;
          background: url(../images/dropdown.png) no-repeat 95%;
          color: $font-color;
          font-weight: 700;
          letter-spacing: 0.05em;
          font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));

          &:focus {
            outline: unset;
          }

          option {
            padding: 20px 0;
          }
        }
      }

      .product-page-filter {
        width: 15%;

        &:before {
          right: 10px;
        }

        select {
          border-right: none;
        }
      }

      &.horizontal-filter-mian {

        .horizontal-filter-toggle {
          background: url(../images/dropdown.png) no-repeat 95%;
          border-right: 1px solid $light-border;
          padding: 20px;
          text-transform: capitalize;
          width: 35%;
          font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
          cursor: pointer;

          h5,
          h4 {
            margin-bottom: 0;
            color: $font-color;
          }

          h4 {
            display: flex;
            align-items: center;

            svg {
              margin-right: 10px;
            }
          }
        }

        .horizontal-filter {
          position: absolute;
          top: 100%;
          left: 15px;
          width: 860px;
          padding: 30px;
          z-index: 1;
          background-color: $white;
          box-shadow: 0 0 10px rgba($black, .15);
          display: none;

          .horizontal-filter-contain {
            display: flex;
            width: 800px;

            .filter-group {
              padding-right: 20px;
              margin-right: 20px;
              width: 25%;
              border-right: 1px solid $light-gray;

              &:last-child {
                padding-right: 0;
                margin-right: 0;
                border-right: none;
              }
            }

          }

          .btn {
            float: right;
          }
        }
      }

    }

    .popup-filter {
      justify-content: unset;

      ::-webkit-scrollbar {
        width: 3px;
      }

      ::-webkit-scrollbar-track {
        background: $light-border;
      }

      ::-webkit-scrollbar-thumb {
        background: $dark;
      }

      ::-webkit-scrollbar-thumb:hover {
        background: $dark;
      }

      .sidebar-popup {
        position: relative;
        cursor: pointer;
        width: 20%;
        background: url(../images/dropdown.png) no-repeat 95%;

        a {
          font-size: 16px;
          color: $font-color;
        }

        .collection-filter {
          background-color: $gray-6;
          width: 270px;
          height: 600px;
          overflow-y: scroll;

          .collection-filter-block {
            border: none;
          }

          .theme-card {
            padding: 0 30px;
          }

          .collection-sidebar-banner {
            padding: 0 30px;

            img {
              width: 100%;
            }
          }
        }
      }

      .open-popup {
        display: none;
        position: absolute;
        z-index: 9;
        top: 75px;
        left: 0;
        min-width: 330px;
        border: 5px solid $body-back;

        &.open {
          display: block;
        }

        .collection-filter-block {
          margin-bottom: 0;
        }
      }

      .product-page-per-view,
      .product-page-filter {
        width: 20%;

        select {
          padding-right: 45px;
          font-size: 16px;
        }

        &:before {
          right: 35px;
        }
      }

      .collection-grid-view {
        padding: 20px;
      }

      .collection-view {
        width: 20%;
      }



      .product-page-filter {
        &:before {
          right: 25px !important;
        }
      }
    }


  }

  .product-wrapper-grid {
    .product-box {
      margin-top: 30px;
      padding-right: 0;
      .product-detail {
        padding-top: 0;
        p {
          display: none;
        }
        .detail-title {
          .detail-left {
            .price-title {
              font-size: calc(12px + (16 - 12) * ((100vw - 320px) / (1920 - 320)));
              padding-top: 5px;
            }
          }
        }
      }
    }

    .product-five {
      flex: 0 0 20%;
      max-width: 20%;
      position: relative;
      width: 100%;
      min-height: 1px;
      padding-right: 15px;
      padding-left: 15px;
    }

    .col-lg-2 {
      padding-left: 10px;
      padding-right: 10px;
      .product-box {
        margin-top: 15px;
        .product-detail {
          .icon-detail {
            a,button{
              width:35px;
              height:35px;
              svg{
                width:16px;
              }
            }           
          }
          h6 {
            font-size: 13px;
          }

          h4 {
            font-size: 18px;
          }
          .rating {
            i {
              padding-right: 0;
            }
          }         
        }
        .img-wrapper {
          .label-block {
            .label3 {
              padding: 8px 4px;
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}
.filter-slide {
  padding-top: 30px;

  .irs {
    position: relative;
    display: block;
    user-select: none;
    font-size: 12px;
    font-family: Arial, sans-serif;
  }

  .irs-line {
    position: relative;
    display: block;
    overflow: hidden;
    outline: none !important;
  }

  .irs-bar {
    position: absolute;
    display: block;
    left: 0;
    width: 0;
  }

  .irs-shadow {
    position: absolute;
    display: none;
    left: 0;
    width: 0;
  }

  .irs-handle {
    position: absolute;
    display: block;
    box-sizing: border-box;
    cursor: default;
    z-index: 1;
  }

  .irs-handle.type_last {
    z-index: 2;
  }

  .irs-min {
    position: absolute;
    display: block;
    cursor: default;
    left: 0;
    display: none;
  }

  .irs-max {
    position: absolute;
    display: block;
    cursor: default;
    right: 0;
  }


  .irs-from {
    position: absolute;
    display: block;
    left: 0;
    cursor: default;
    white-space: nowrap;
  }

  // heandel //
  .irs-from,
  .irs-to {
    top: -10px;
    color: $white;
    font-size: 14px;
    text-shadow: none;
    padding: 1px 5px;
    background-color: $theme-color1;
    border-radius: 4px;
    font-weight: 700;
  }

  .irs-to {
    position: absolute;
    display: block;
    left: 0;
    cursor: default;
    white-space: nowrap;
  }

  .irs--flat {

    .irs-to,
    .irs-from {
      &:before {
        position: absolute;
        display: block;
        content: "";
        bottom: -6px;
        left: 50%;
        width: 0;
        height: 0;
        margin-left: -3px;
        overflow: hidden;
        border: 3px solid transparent;
        border-top-color: $theme-color1;
      }
    }
  }



  .irs-single {
    position: absolute;
    display: block;
    top: -10px;
    left: 0;
    cursor: default;
    white-space: nowrap;
  }

  .irs-grid {
    position: absolute;
    display: none;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 20px;
  }



  .irs-grid-pol {
    position: absolute;
    top: 0;
    left: 0;
    width: 1px;
    height: 8px;
    background: #000;
  }

  .irs-grid-pol.small {
    height: 4px;
  }

  .irs-grid-text {
    position: absolute;
    bottom: 0;
    left: 0;
    white-space: nowrap;
    text-align: center;
    font-size: 9px;
    line-height: 9px;
    padding: 0 3px;
    color: #000;
  }

  .irs-disable-mask {
    position: absolute;
    display: block;
    top: 0;
    left: -1%;
    width: 102%;
    height: 100%;
    cursor: default;
    background: rgba(0, 0, 0, 0);
    z-index: 2;
  }

  .lt-ie9 .irs-disable-mask {
    background: #000;
    filter: alpha(opacity=0);
    cursor: not-allowed;
  }

  .irs-disabled {
    opacity: 0.4;
  }

  .irs-hidden-input {
    position: absolute !important;
    display: block !important;
    top: 0 !important;
    left: 0 !important;
    width: 0 !important;
    height: 0 !important;
    font-size: 0 !important;
    line-height: 0 !important;
    padding: 0 !important;
    margin: 0 !important;
    overflow: hidden;
    outline: none !important;
    z-index: -9999 !important;
    background: none !important;
    border-style: solid !important;
    border-color: transparent !important;
  }

  .irs--flat {
    height: 40px;
  }

  .irs--flat .irs-line {
    top: 25px;
    height: 5px;
    background-color: #f7f7f7;
    border-radius: 4px;
  }

  .irs--flat .irs-bar {
    top: 25px;
    height: 5px;
    background-color: $theme-color1;
  }

  .irs--flat .irs-bar--single {
    border-radius: 4px 0 0 4px;
  }

  .irs--flat .irs-shadow {
    height: 1px;
    bottom: 16px;
    background-color: #e1e4e9;
  }

  .irs--flat .irs-handle {
    top: 22px;
    width: 16px;
    height: 18px;
    background-color: transparent;
  }

  .irs--flat .irs-handle>i:first-child {
    position: absolute;
    display: block;
    top: -2px;
    left: 50%;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    margin-left: -1px;
    background-color: $theme-color1;
    border: 4px solid $theme-color1;
    -webkit-transition: all 1s ease;
    transition: all 1s ease;
  }

  .irs--flat .irs-handle.state_hover>i:first-child {
    background-color: $theme-color1;
  }

  .irs--flat .irs-min {
    top: 0;
    padding: 1px 3px;
    color: #999;
    font-size: 10px;
    line-height: 1.333;
    text-shadow: none;
    background-color: #e1e4e9;
    border-radius: 4px;
  }

  .irs--flat .irs-max {
    top: 0;
    padding: 1px 3px;
    color: #999;
    font-size: 10px;
    line-height: 1.333;
    text-shadow: none;
    background-color: #e1e4e9;
    border-radius: 4px;
    display: none;
  }






  .irs--flat .irs-single {
    color: $white;
    font-size: 14px;
    line-height: 1.333;
    text-shadow: none;
    padding: 1px 5px;
    background-color: $theme-color1;
    border-radius: 4px;
  }

  .irs--flat .irs-single:before {
    position: absolute;
    display: block;
    content: "";
    bottom: -6px;
    left: 50%;
    width: 0;
    height: 0;
    margin-left: -3px;
    overflow: hidden;
    border: 3px solid transparent;
    border-top-color: $theme-color1;
  }

  .irs--flat .irs-grid-pol {
    background-color: #e1e4e9;
  }

  .irs--flat .irs-grid-text {
    color: #999;
  }


  .irs--big {
    height: 55px;
  }

  .irs--big .irs-line {
    top: 33px;
    height: 12px;
    background-color: $white;
    background: -webkit-gradient(linear, left top, left bottom, color-stop(-50%, #ddd), color-stop(150%, white));
    background: linear-gradient(to bottom, #ddd -50%, white 150%);
    border: 1px solid #ccc;
    border-radius: 12px;
  }

  .irs--big .irs-bar {
    top: 33px;
    height: 12px;
    background-color: #92bce0;
    border: 1px solid #428bca;
    background: -webkit-gradient(linear, left top, left bottom, from(#ffffff), color-stop(30%, #428bca), to(#b9d4ec));
    background: linear-gradient(to bottom, #ffffff 0%, #428bca 30%, #b9d4ec 100%);
    -webkit-box-shadow: inset 0 0 1px 1px rgba(255, 255, 255, 0.5);
    box-shadow: inset 0 0 1px 1px rgba(255, 255, 255, 0.5);
  }

  .irs--big .irs-bar--single {
    border-radius: 12px 0 0 12px;
  }

  .irs--big .irs-shadow {
    height: 1px;
    bottom: 16px;
    background-color: rgba(66, 139, 202, 0.5);
  }

  .irs--big .irs-handle {
    top: 25px;
    width: 30px;
    height: 30px;
    border: 1px solid rgba(0, 0, 0, 0.3);
    background-color: #cbcfd5;
    background: -webkit-gradient(linear, left top, left bottom, from(white), color-stop(30%, #B4B9BE), to(white));
    background: linear-gradient(to bottom, white 0%, #B4B9BE 30%, white 100%);
    -webkit-box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2), inset 0 0 3px 1px white;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2), inset 0 0 3px 1px white;
    border-radius: 30px;
  }

  .irs--big .irs-handle:hover {
    border-color: rgba(0, 0, 0, 0.45);
    background-color: #939ba7;
    background: -webkit-gradient(linear, left top, left bottom, from(white), color-stop(30%, #919BA5), to(white));
    background: linear-gradient(to bottom, white 0%, #919BA5 30%, white 100%);
  }

  .irs--big .irs-handle.state_hover {
    border-color: rgba(0, 0, 0, 0.45);
    background-color: #939ba7;
    background: -webkit-gradient(linear, left top, left bottom, from(white), color-stop(30%, #919BA5), to(white));
    background: linear-gradient(to bottom, white 0%, #919BA5 30%, white 100%);
  }

  .irs--big .irs-min {
    top: 0;
    padding: 1px 5px;
    color: white;
    text-shadow: none;
    background-color: #9f9f9f;
    border-radius: 3px;
  }

  .irs--big .irs-max {
    top: 0;
    padding: 1px 5px;
    color: white;
    text-shadow: none;
    background-color: #9f9f9f;
    border-radius: 3px;
  }

  .irs--big .irs-from {
    color: white;
    text-shadow: none;
    padding: 1px 5px;
    background-color: #428bca;
    background: -webkit-gradient(linear, left top, left bottom, from(#428bca), to(#3071a9));
    background: linear-gradient(to bottom, #428bca 0%, #3071a9 100%);
    border-radius: 3px;
  }

  .irs--big .irs-to {
    color: white;
    text-shadow: none;
    padding: 1px 5px;
    background-color: #428bca;
    background: -webkit-gradient(linear, left top, left bottom, from(#428bca), to(#3071a9));
    background: linear-gradient(to bottom, #428bca 0%, #3071a9 100%);
    border-radius: 3px;
  }

  .irs--big .irs-single {
    color: white;
    text-shadow: none;
    padding: 1px 5px;
    background-color: #428bca;
    background: -webkit-gradient(linear, left top, left bottom, from(#428bca), to(#3071a9));
    background: linear-gradient(to bottom, #428bca 0%, #3071a9 100%);
    border-radius: 3px;
  }

  .irs--big .irs-grid-pol {
    background-color: #428bca;
  }

  .irs--big .irs-grid-text {
    color: #428bca;
  }

  .irs--big.irs-with-grid {
    height: 70px;
  }

  .irs--modern {
    height: 55px;
  }

  .irs--modern .irs-line {
    top: 25px;
    height: 5px;
    background-color: #d1d6e0;
    background: -webkit-gradient(linear, left top, left bottom, from(#e0e4ea), to(#d1d6e0));
    background: linear-gradient(to bottom, #e0e4ea 0%, #d1d6e0 100%);
    border: 1px solid #a3adc1;
    border-bottom-width: 0;
    border-radius: 5px;
  }

  .irs--modern .irs-bar {
    top: 25px;
    height: 5px;
    background: #20b426;
    background: -webkit-gradient(linear, left top, left bottom, from(#20b426), to(#18891d));
    background: linear-gradient(to bottom, #20b426 0%, #18891d 100%);
  }

  .irs--modern .irs-bar--single {
    border-radius: 5px 0 0 5px;
  }

  .irs--modern .irs-shadow {
    height: 1px;
    bottom: 21px;
    background-color: rgba(209, 214, 224, 0.5);
  }

  .irs--modern .irs-handle {
    top: 37px;
    width: 12px;
    height: 13px;
    border: 1px solid #a3adc1;
    border-top-width: 0;
    -webkit-box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
    border-radius: 0 0 3px 3px;
  }

  .irs--modern .irs-handle>i:nth-child(1) {
    position: absolute;
    display: block;
    top: -4px;
    left: 1px;
    width: 6px;
    height: 6px;
    border: 1px solid #a3adc1;
    background: white;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  .irs--modern .irs-handle>i:nth-child(2) {
    position: absolute;
    display: block;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    top: 0;
    left: 0;
    width: 10px;
    height: 12px;
    background: #e9e6e6;
    background: -webkit-gradient(linear, left top, left bottom, from(white), to(#e9e6e6));
    background: linear-gradient(to bottom, white 0%, #e9e6e6 100%);
    border-radius: 0 0 3px 3px;
  }

  .irs--modern .irs-handle>i:nth-child(3) {
    position: absolute;
    display: block;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    top: 3px;
    left: 3px;
    width: 4px;
    height: 5px;
    border-left: 1px solid #a3adc1;
    border-right: 1px solid #a3adc1;
  }

  .irs--modern .irs-handle:hover {
    border-color: #7685a2;
    background: #c3c7cd;
    background: -webkit-gradient(linear, left top, left bottom, from(#ffffff), color-stop(30%, #919ba5), to(#ffffff));
    background: linear-gradient(to bottom, #ffffff 0%, #919ba5 30%, #ffffff 100%);
  }

  .irs--modern .irs-handle:hover>i:nth-child(1) {
    border-color: #7685a2;
  }

  .irs--modern .irs-handle:hover>i:nth-child(3) {
    border-color: #48536a;
  }

  .irs--modern .irs-handle.state_hover {
    border-color: #7685a2;
    background: #c3c7cd;
    background: -webkit-gradient(linear, left top, left bottom, from(#ffffff), color-stop(30%, #919ba5), to(#ffffff));
    background: linear-gradient(to bottom, #ffffff 0%, #919ba5 30%, #ffffff 100%);
  }

  .irs--modern .irs-handle.state_hover>i:nth-child(1) {
    border-color: #7685a2;
  }

  .irs--modern .irs-handle.state_hover>i:nth-child(3) {
    border-color: #48536a;
  }

  .irs--modern .irs-min {
    top: 0;
    font-size: 10px;
    line-height: 1.333;
    text-shadow: none;
    padding: 1px 5px;
    color: white;
    background-color: #d1d6e0;
    border-radius: 5px;
  }

  .irs--modern .irs-max {
    top: 0;
    font-size: 10px;
    line-height: 1.333;
    text-shadow: none;
    padding: 1px 5px;
    color: white;
    background-color: #d1d6e0;
    border-radius: 5px;
  }

  .irs--modern .irs-from {
    font-size: 10px;
    line-height: 1.333;
    text-shadow: none;
    padding: 1px 5px;
    background-color: #20b426;
    color: white;
    border-radius: 5px;
  }

  .irs--modern .irs-from:before {
    position: absolute;
    display: block;
    content: "";
    bottom: -6px;
    left: 50%;
    width: 0;
    height: 0;
    margin-left: -3px;
    overflow: hidden;
    border: 3px solid transparent;
    border-top-color: #20b426;
  }

  .irs--modern .irs-to {
    font-size: 10px;
    line-height: 1.333;
    text-shadow: none;
    padding: 1px 5px;
    background-color: #20b426;
    color: white;
    border-radius: 5px;
  }

  .irs--modern .irs-to:before {
    position: absolute;
    display: block;
    content: "";
    bottom: -6px;
    left: 50%;
    width: 0;
    height: 0;
    margin-left: -3px;
    overflow: hidden;
    border: 3px solid transparent;
    border-top-color: #20b426;
  }

  .irs--modern .irs-single {
    font-size: 14px;
    text-shadow: none;
    padding: 1px 5px;
    background-color: #20b426;
    color: white;
    border-radius: 5px;
  }

  .irs--modern .irs-single:before {
    position: absolute;
    display: block;
    content: "";
    bottom: -6px;
    left: 50%;
    width: 0;
    height: 0;
    margin-left: -3px;
    overflow: hidden;
    border: 3px solid transparent;
    border-top-color: #20b426;
  }

  .irs--modern .irs-grid {
    height: 25px;
  }

  .irs--modern .irs-grid-pol {
    background-color: #dedede;
  }

  .irs--modern .irs-grid-text {
    color: silver;
    font-size: 13px;
  }

  .irs--modern.irs-with-grid {
    height: 55px;
  }

  .irs--sharp {
    height: 50px;
    font-size: 12px;
    line-height: 1;
  }

  .irs--sharp .irs-line {
    top: 30px;
    height: 2px;
    background-color: black;
    border-radius: 2px;
  }

  .irs--sharp .irs-bar {
    top: 30px;
    height: 2px;
    background-color: #ee22fa;
  }

  .irs--sharp .irs-bar--single {
    border-radius: 2px 0 0 2px;
  }

  .irs--sharp .irs-shadow {
    height: 1px;
    bottom: 21px;
    background-color: rgba(0, 0, 0, 0.5);
  }

  .irs--sharp .irs-handle {
    top: 25px;
    width: 10px;
    height: 10px;
    background-color: #a804b2;
  }

  .irs--sharp .irs-handle>i:first-child {
    position: absolute;
    display: block;
    top: 100%;
    left: 0;
    width: 0;
    height: 0;
    border: 5px solid transparent;
    border-top-color: #a804b2;
  }

  .irs--sharp .irs-handle:hover {
    background-color: black;
  }

  .irs--sharp .irs-handle:hover>i:first-child {
    border-top-color: black;
  }

  .irs--sharp .irs-handle.state_hover {
    background-color: black;
  }

  .irs--sharp .irs-handle.state_hover>i:first-child {
    border-top-color: black;
  }

  .irs--sharp .irs-min {
    color: white;
    font-size: 14px;
    line-height: 1;
    top: 0;
    padding: 3px 4px;
    opacity: 0.4;
    background-color: #a804b2;
    border-radius: 2px;
  }

  .irs--sharp .irs-max {
    color: white;
    font-size: 14px;
    line-height: 1;
    top: 0;
    padding: 3px 4px;
    opacity: 0.4;
    background-color: #a804b2;
    border-radius: 2px;
  }

  .irs--sharp .irs-from {
    font-size: 14px;
    line-height: 1;
    text-shadow: none;
    padding: 3px 4px;
    background-color: #a804b2;
    color: white;
    border-radius: 2px;
  }

  .irs--sharp .irs-from:before {
    position: absolute;
    display: block;
    content: "";
    bottom: -6px;
    left: 50%;
    width: 0;
    height: 0;
    margin-left: -3px;
    overflow: hidden;
    border: 3px solid transparent;
    border-top-color: #a804b2;
  }

  .irs--sharp .irs-to {
    font-size: 14px;
    line-height: 1;
    text-shadow: none;
    padding: 3px 4px;
    background-color: #a804b2;
    color: white;
    border-radius: 2px;
  }

  .irs--sharp .irs-to:before {
    position: absolute;
    display: block;
    content: "";
    bottom: -6px;
    left: 50%;
    width: 0;
    height: 0;
    margin-left: -3px;
    overflow: hidden;
    border: 3px solid transparent;
    border-top-color: #a804b2;
  }

  .irs--sharp .irs-single {
    font-size: 14px;
    line-height: 1;
    text-shadow: none;
    padding: 3px 4px;
    background-color: #a804b2;
    color: white;
    border-radius: 2px;
  }

  .irs--sharp .irs-single:before {
    position: absolute;
    display: block;
    content: "";
    bottom: -6px;

    left: 50%;
    width: 0;
    height: 0;
    margin-left: -3px;
    overflow: hidden;
    border: 3px solid transparent;
    border-top-color: #a804b2;
  }

  .irs--sharp .irs-grid {
    height: 25px;
  }

  .irs--sharp .irs-grid-pol {
    background-color: #dedede;
  }

  .irs--sharp .irs-grid-text {
    color: silver;
    font-size: 13px;
  }

  .irs--sharp.irs-with-grid {
    height: 57px;
  }

  .irs--round {
    height: 50px;
  }

  .irs--round .irs-line {
    top: 36px;
    height: 4px;
    background-color: #dee4ec;
    border-radius: 4px;
  }

  .irs--round .irs-bar {
    top: 36px;
    height: 4px;
    background-color: #006cfa;
  }

  .irs--round .irs-bar--single {
    border-radius: 4px 0 0 4px;
  }

  .irs--round .irs-shadow {
    height: 4px;
    bottom: 21px;
    background-color: rgba(222, 228, 236, 0.5);
  }

  .irs--round .irs-handle {
    top: 26px;
    width: 24px;
    height: 24px;
    border: 4px solid #006cfa;
    background-color: white;
    border-radius: 24px;
    -webkit-box-shadow: 0 1px 3px rgba(0, 0, 255, 0.3);
    box-shadow: 0 1px 3px rgba(0, 0, 255, 0.3);
  }

  .irs--round .irs-handle:hover {
    background-color: #f0f6ff;
  }

  .irs--round .irs-handle.state_hover {
    background-color: #f0f6ff;
  }

  .irs--round .irs-min {
    color: #333;
    font-size: 14px;
    line-height: 1;
    top: 0;
    padding: 3px 5px;
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 4px;
  }

  .irs--round .irs-max {
    color: #333;
    font-size: 14px;
    line-height: 1;
    top: 0;
    padding: 3px 5px;
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 4px;
  }

  .irs--round .irs-from {
    font-size: 14px;
    line-height: 1;
    text-shadow: none;
    padding: 3px 5px;
    background-color: #006cfa;
    color: white;
    border-radius: 4px;
  }

  .irs--round .irs-from:before {
    position: absolute;
    display: block;
    content: "";
    bottom: -6px;
    left: 50%;
    width: 0;
    height: 0;
    margin-left: -3px;
    overflow: hidden;
    border: 3px solid transparent;
    border-top-color: #006cfa;
  }

  .irs--round .irs-to {
    font-size: 14px;
    line-height: 1;
    text-shadow: none;
    padding: 3px 5px;
    background-color: #006cfa;
    color: white;
    border-radius: 4px;
  }

  .irs--round .irs-to:before {
    position: absolute;
    display: block;
    content: "";
    bottom: -6px;
    left: 50%;
    width: 0;
    height: 0;
    margin-left: -3px;
    overflow: hidden;
    border: 3px solid transparent;
    border-top-color: #006cfa;
  }

  .irs--round .irs-single {
    font-size: 14px;
    line-height: 1;
    text-shadow: none;
    padding: 3px 5px;
    background-color: #006cfa;
    color: white;
    border-radius: 4px;
  }

  .irs--round .irs-single:before {
    position: absolute;
    display: block;
    content: "";
    bottom: -6px;
    left: 50%;
    width: 0;
    height: 0;
    margin-left: -3px;
    overflow: hidden;
    border: 3px solid transparent;
    border-top-color: #006cfa;
  }

  .irs--round .irs-grid {
    height: 25px;
  }

  .irs--round .irs-grid-pol {
    background-color: #dedede;
  }

  .irs--round .irs-grid-text {
    color: silver;
    font-size: 13px;
  }

  .irs--round.irs-with-grid {
    height: 65px;
  }

  .irs--square {
    height: 50px;
  }

  .irs--square .irs-line {
    top: 31px;
    height: 4px;
    background-color: #dedede;
  }

  .irs--square .irs-bar {
    top: 31px;
    height: 4px;
    background-color: black;
  }

  .irs--square .irs-shadow {
    height: 2px;
    bottom: 21px;
    background-color: #dedede;
  }

  .irs--square .irs-handle {
    top: 25px;
    width: 16px;
    height: 16px;
    border: 3px solid black;
    background-color: white;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  .irs--square .irs-handle:hover {
    background-color: #f0f6ff;
  }

  .irs--square .irs-handle.state_hover {
    background-color: #f0f6ff;
  }

  .irs--square .irs-min {
    color: #333;
    font-size: 14px;
    line-height: 1;
    top: 0;
    padding: 3px 5px;
    background-color: rgba(0, 0, 0, 0.1);

  }

  .irs--square .irs-max {
    color: #333;
    font-size: 14px;
    line-height: 1;
    top: 0;
    padding: 3px 5px;
    background-color: rgba(0, 0, 0, 0.1);
  }

  .irs--square .irs-from {
    font-size: 14px;
    line-height: 1;
    text-shadow: none;
    padding: 3px 5px;
    background-color: black;
    color: white;
  }

  .irs--square .irs-to {
    font-size: 14px;
    line-height: 1;
    text-shadow: none;
    padding: 3px 5px;
    background-color: black;
    color: white;
  }

  .irs--square .irs-single {
    font-size: 14px;
    line-height: 1;
    text-shadow: none;
    padding: 3px 5px;
    background-color: black;
    color: white;
  }

  .irs--square .irs-grid {
    height: 25px;
  }

  .irs--square .irs-grid-pol {
    background-color: #dedede;
  }

  .irs--square .irs-grid-text {
    color: silver;
    font-size: 11px;
  }

  .irs--square.irs-with-grid {
    height: 60px;
  }

  /*# sourceMappingURL=ion.rangeSlider.css.map */

}

.filter-main-btn {
  display: none;
  margin-bottom: 20px;
  cursor: pointer;

  span {
    font-size: calc(16px + (20 - 16) * ((100vw - 320px) / (1920 - 320)));
    background-color: $theme-color1;
    padding: 5px 20px;
    color: $white;
    border-radius: 3px
  }
}

.collection-mobile-back {
  padding: 15px 0;
  border-bottom: 1px solid $light-border;
  display: none;

  span {
    text-transform: uppercase;
    font-size: 18px;
    color: $font-color;
    cursor: pointer;
    font-weight: 700;
    letter-spacing: 0.05em;

    i {
      font-size: 20px;
      font-weight: 700;
      margin-right: 5px;
    }
  }
}

.product-pagination {
  border-bottom: 1px solid $light-border;
  border-top: 1px solid $light-border;
  margin-top: calc(25px + (50 - 25) * ((100vw - 320px) / (1920 - 320)));
  margin-bottom: calc(25px + (50 - 25) * ((100vw - 320px) / (1920 - 320)));
  .pagination {
    border-radius: 0;
    .page-item {
      border-radius: 0;     
      a {
        padding: 18px;
        border-bottom: 0;
        border-top: 0;
        color: lighten($black, 46.5%);
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        box-shadow: none;
        &:hover{
          color: $theme-color1;
        background-color: $white;
        border-color: $light-border;
        background-color: rgba($theme-color1,0.1);  
        }
        
      }
      &:last-child {
        .page-link {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
      }
      
    }
    .page-item.active {
      a {
        color: $theme-color1;
        background-color: $white;
        border-color: $light-border;
        background-color: rgba($theme-color1,0.1);
      }
    }
  }
  .product-search-count-bottom {
    justify-content: flex-end;
    display: flex;
    align-items: center;
    height: 100%;
    border-left: 1px solid $light-border;
    border-right: 1px solid $light-border;
    padding-right: 15px;

    h5 {
      color: $font-color;
      margin-bottom: 0;
      padding: 5px 0;
    }
  }
}

.bundle {
  margin-top: 10px;

  .bundle_img {
    display: flex;

    .img-box {
      img {
        max-width: 115px;
        border: 1px solid $light-border;
        padding: 2px;
      }
    }

    .plus {
      display: flex;
      align-items: center;
      padding: 0 7px;
      font-size: 22px;
    }
  }

  .bundle_detail {
    margin-top: 15px;

    .price_product {
      color: $black;
      padding-left: 3px;
      font-weight: 700;
    }

    .btn-normal {
      padding: 13px 25px;
      text-transform: uppercase;
    }
  }
}

.theme_checkbox {
  label {
    display: block;
    position: relative;
    padding-left: 30px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 14px;
    user-select: none;
    text-transform: capitalize;
    color: $font-color;

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;

      &:checked {
        ~.checkmark {
          &:after {
            display: block;
          }
        }
      }
    }

    .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 20px;
      width: 20px;
      background-color: $white;
      border: 1px solid $light-border;

      &:after {
        content: "";
        position: absolute;
        display: none;
        left: 7px;
        top: 3px;
        width: 5px;
        height: 10px;
        border: solid black;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
      }
    }
  }
}

.theme-card {
  position: relative;
  background-color: $white;

  input,textarea,select{
    &:focus{
      box-shadow:none;
      border-color:$light-border;
    }
  }
  &.center-align {
    display: flex;
    height: 100%;
    align-items: center;

    .offer-slider {
      .product-box2 {
        .media {
          .media-body {
            margin-top: 0;
          }
        }
      }
    }
  }

  &.card-border {
    border: 1px solid $light-border;

    h5 {
      padding-top: 20px;
      padding-bottom: 20px;
      padding-left: 30px;
      margin-top: 0;
    }

    .slick-prev,
    .slick-next {
      top: -40px;
    }

    .slick-next {
      right: 30px;
    }

    .slick-prev {
      right: 50px;
    }

    .offer-slider {
      padding-top: 0;

      img {
        padding: 15px;
      }

      .media {
        .media-body {
          a {
            h6 {
              margin-right: 0;
            }
          }
        }
      }
    }
  }

  h5 {
    font-weight: 700;
    text-transform: uppercase;
    padding-bottom: 10px;
    color: $font-color;
    letter-spacing: 0.05em;

    &.title-border {
      border-bottom: 1px solid $light-border;
      font-size: calc(16px + (20 - 16) * ((100vw - 320px) / (1920 - 320)));
    }
  }

  .slick-prev,
  .slick-next {
    top: -28px;

    &:before {
      font: normal normal normal 30px/1 $font-awesome;
      color: $font-color;
      opacity: 0.6;
    }
  }

  .slick-next {
    right: 0;

    &:before {
      content: "\f105";
    }
  }

  .slick-prev {
    left: unset;
    right: 25px;

    &:before {
      content: "\f104";
    }
  }

  .offer-slider {
    img {
      height: 140px;
      padding: 15px 15px 0 0;
    }

    >div {
      .media {
        &:last-child {
          .media-body {
            margin-top: 15px;
          }

          img {
            margin-bottom: -15px;
          }
        }
      }
    }

    .media {
      .media-body {
        .rating {
          margin-top: 0;

          i {
            padding-right: 3px;

            &:nth-child(-n + 4) {
              color: $rating-star;
            }

            &:last-child {
              color: $light-border;
            }
          }
        }

        a {
          h6 {
            margin-right: 61px;
            margin-top: 5px;
            font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
            margin-bottom: 0;
            color: $icon-color;
          }
        }

        h4 {
          margin-top: 10px;
          font-weight: 700;
          color: $font-color;
          margin-bottom: 0;
        }
      }
    }
  }
}

.related-pro1 {
  .product-box {
    padding-right: 0;
  }
}

.addtocart_count {
  position: relative;

  button {
    &:focus {
      box-shadow: none;
    }
  }

  .product-box {
    .product-detail {
      text-align: center;

      .detail-title {
        .detail-right {
          text-align: center;
          justify-content: center;
        }
      }
    }

    .cart-info {
      bottom: 40px;
      right: 10px;

      a {
        i {
          background-color: #e2e2e2;
          border-radius: 100%;
          margin: 10px 0;
          padding: 8px;
          font-size: 16px;
          color: #313131;
        }
      }
    }

    .add-button {
      background-color: $theme-color2;
      color: $white;
      text-align: center;
      font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
      text-transform: capitalize;
      width: 100%;
      padding: 5px 0;
      transition: all 0.5s ease;
      border: none;
      cursor: pointer;
      font-weight: 700;
    }

    &:hover {
      .cart-info {
        a {
          &:nth-child(1) {
            i {
              animation: fadeInRight 300ms ease-in-out;
            }
          }
        }
      }

      .add-button {
        bottom: 0;
        transition: all 0.5s ease;
      }
    }
  }

  .addtocart_btn {
    position: relative;

    .cart_qty {
      width: 100%;

      &.qty-box {
        position: absolute;
        bottom: 0;
        display: none;

        .input-group {
          .form-control {
            width: 100%;

            &:focus {
              border-color: #efefef;
              box-shadow: none;
            }
          }

          button {
            background: $theme-color2;
            color: $white;
            position: absolute;
            height: 100%;
            z-index: 9;

            &.quantity-left-minus {
              left: 0;

              &:before {
                display: none;
              }
            }

            &.quantity-right-plus {
              right: 0;

              &:before {
                display: none;
              }
            }

            i {
              color: $white !important;
            }
          }

          button {
            i {
              color: $black;
            }
          }
        }
      }

      &.open {
        display: block;
      }
    }
  }
}

.category-side {
  .collection-collapse-block {
    .collapse-block-title {
      margin-top: 20px;
    }
  }

  .color-selector {
    ul {
      li {
        margin-bottom: 12px;
      }
    }
  }
}

.metro-block {
  .product-box {
    .product-imgbox {
      .product-detail {
        background-color: $white;
        padding: 15px;
        text-align: center;
        width: fit-content;
        margin: 0 auto;
        position: absolute;
        bottom: -100%;
        left: 0;
        right: 0;
        transition: all 0.5s ease;

        h6 {
          color: $dark;
          text-transform: capitalize;
          font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
        }

        h4 {
          color: $font-color;
        }
      }
    }

    &:hover {
      .product-imgbox {
        .product-detail {
          bottom: 15px;
        }
      }
    }
  }
}

// center-product //
.center-product {
  .theme-card {
    border: 1px solid $light-border;
    padding: 15px;
    margin-right: 15px;

    .offer-slider {
      .media {
        .media-body {
          a h6 {
            margin-right: 0;
          }
        }
      }
    }
  }
}

// collection page //
.collection {
  .collection-block {
    text-align: center;

    img,
    .bg-size {
      margin-bottom: 20px;
    }

    .collection-content {
      text-align: center;
      padding: 15px;
      border: 1px solid #e8e8e8;
      h3 {
        margin-bottom: 10px;
        color: $font-color;
        text-transform: uppercase;
        font-weight: 900;
        letter-spacing: 0.05em;
        font-size: calc(18px + (24 - 18) * ((100vw - 320px) / (1920 - 320)));
      }

      h4 {
        color: $dark;
        margin-bottom: 10px;
        font-size: 16px;
        font-weight: 400;
      }

      p {
        line-height: 1.5;
        margin-bottom: 15px;
      }

      .btn-normal {
        padding: 10px 25px;
        text-transform: uppercase;
      }
    }
  }
}

//faq //

.faq-section{
  .accordion-item{
    p{
      font-size: 16px;
    line-height: 1.6;
    }
    &:nth-child(n+2){
      margin-top:15px;
    }
    .accordion-header {
      .accordion-button{
        font-size:calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));       
        color:$theme-color1;
        font-weight:600;
        background-color: $gray-8;
        border-bottom:1px solid rgba($theme-color1, 0.2);
        padding:calc(15px + (20 - 15) * ((100vw - 320px) / (1920 - 320)));       
        &:after{
          content:"\f0d7";
          font-family:$font-awesome;
          background-image:none;
        }
        &:focus{
          box-shadow:none;        
          border-color:$light-border;  
        }
      }
    }   
    .accordion-collapse{
      border: 1px solid rgba(0, 0, 0, 0.125);
    }
  }
}

//forget_pwd//
.pwd-page {
  text-align: center;

  h2 {
    margin-bottom: 25px;
    color: $font-color;
    margin-top: -5px;
  }

  .theme-form {
    input {
      border-color: $light-border;
      font-size: 15px;
      padding: 15px 25px;
      margin-bottom: 15px;
      height: inherit;
      text-align: center;
    }

    .btn-normal {
      margin: 0 auto;
    }
  }

  .btn-normal {
    text-transform: uppercase;
  }
}

//login//
.login-page {
  h3 {
    margin-bottom: 20px;
    text-transform: uppercase;
    color: $font-color;
    font-weight: 700;
    margin-top: -5px;
    font-size: calc(18px + (24 - 18) * ((100vw - 320px) / (1920 - 320)));
  }
  .theme-card {
    padding: 30px;
    background-color: $white;
    .theme-form {
      padding: 30px;
      background-color: #f1f4f7;
      label {
        text-transform: capitalize;
        color: $font-color;
        font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
        font-weight: 600;
        line-height: 1;
      }
      input {
        padding: 12px 25px;
        border-radius: 0;
      }
    }

    .btn {
      text-transform: uppercase;
      padding: 14px 35px;
    }
  }

  .authentication-right {
    height: calc(100% - 48px);

    h6 {
      text-transform: uppercase;
      color: $font-color;
      font-weight: 600;
      margin-bottom: 20px;
    }

    p {
      font-size: 14px;
      color: $icon-color;
      line-height: 2;
      margin-bottom: 1rem;
    }
  }
}


// search //
.search-block {
  padding: 0;

  .form-header {
    .input-group {
      input {
        border-radius: 0 5px 5px 0;
        padding: 15px 20px;
      }

      i {
        padding-right: 10px;
      }

      .btn {
        z-index: 0;
        text-transform: uppercase;
      }
    }
  }
}

// contacts //
.contact-page {
  h3 {
    color: #333;
  }

  .map {
    .theme-card {
      padding: 30px;
      background-color: #f1f4f7;
      border: 30px solid $white;
    }

    iframe {
      width: 100%;
      height: 100%;
      border: none;
    }

  }

  .contact-right {
    padding-top: 30px;
    padding-bottom: 30px;

    ul {
      li {
        position: relative;
        padding-left: 150px;
        margin-top: 45px;
        display: block;

        &:first-child {
          margin-top: 0;
        }

        .contact-icon {
          position: absolute;
          left: 0;
          text-align: center;
          width: 130px;
          border-right: 1px solid $light-border;

          i {
            font-size: 24px;
            color: $theme-color1;
          }

          h6 {
            text-transform: uppercase;
            padding-top: 5px;
            font-weight: 600;
            color: $theme-color1;
            font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
          }
        }

        p {
          margin-bottom: 0;
          line-height: 30px;
          color: $font-color;
          font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
        }
      }
    }

  }

  .theme-form {
    padding: 30px;
    background-color: #f1f4f7;
    border: 30px solid $white;

    label {
      text-transform: capitalize;
      color: $font-color;
      font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
      font-weight: 600;
    }

    input {
      padding: 12px 25px;
      border-radius: 0;
      margin-bottom: 0;
    }

    textarea {
      padding: 17px 25px;
      margin-bottom: 30px;
      height: inherit;
    }

  }

  .form-control {
    border-radius: 0;
  }

  .btn {
    text-transform: uppercase;
  }
}

// shopping cart //
.qty-box {
  .input-group {
    justify-content: center;
    border: 1px solid $light-border;
    .form-control {
      text-align: center;
      width:80px;
      flex:unset;
      border-color:$light-border;
      border-top:unset;
      border-bottom: unset;
    }  
    button {
      background-color: transparent;
      cursor: pointer;
      line-height:1;
      background-color:$white;     
      font-family:$font-themify;
      padding-left:10px;
      padding-right:10px;     
      border:none;
      i {
        font-weight: 900;
        color: $dark;
        font-size: 16px;
      }
      &:first-child {
        &:before {
          content: "\e622";
        }
      }
      &:last-child {
        &:before {
          content: "\e61a";
        }
      }
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
}

.qty-box {
  display: flex;
  align-items: center;
  .input-group {
    justify-content: unset;
    width: unset;
    .input-group-prepend {
      button {
        &:focus {
          border-color: $light-border;
          box-shadow: none;
        }
      }
    }
    .form-control {
      border-color: $light-border;     
      box-shadow: none;
    }
  }
}





.order-history {
  .responsive-data {
    display: none;

    .price {
      margin-bottom: 2px;
    }
  }

  .dark-data {
    color: $font-color;
    font-weight: 600;
  }

  .btn-normal {
    text-transform: uppercase;
    padding: 13px 25px;
  }
}

.cart-section,
.wishlist-section { 
  .table {
    tbody {
      tr{
        td{
          .qty-box{
            width:fit-content;
            margin-left:auto;
            margin-right:auto;
          }
        }
      }
      &+tbody {
        border-top: none;
      }
    }
  }

  .cart-buttons {
    padding-top: 35px;
    text-transform: uppercase;
    >div {
      &:last-child {
        text-align: right;
        padding-right: 59px;
      }
    }
  }

  .wishlist-buttons {
    padding-top: 35px;
    text-align: right;
    text-transform: uppercase;

    &:last-child {
      a {
        margin-left: 15px;
      }
    }
  }

  .cart-table {
    overflow: hidden;
    margin-bottom: 0;

    thead {
      th {
        border-bottom-width: 1px;
        font-weight: 900;
        color: $font-color;
        text-transform: uppercase;
        font-size: 14px;
        border-top: 0;
        text-align: center;
        padding: 0 0.75rem 0.75rem 0.75rem;
        letter-spacing: 0.05em;
        line-height: 1;
        border-bottom: 1px solid $light-border !important;     
      }
    }
  }
  tbody {
    h2 {
      margin-bottom: 0;
    }
    tr {        
      td {
        min-width: 210px;
        vertical-align: middle;
        color: $dark;
        border-top: 0;
        border-bottom: 1px solid $light-border !important;
        text-align: center;
        a,
        p {
          color: $icon-color;
          font-weight: 400;
          font-size: calc(14px + (20 - 14) * ((100vw - 320px) / (1920 - 320)));
          text-transform: capitalize;
          margin-bottom: 0;
          img {
            height: 90px;
          }
        }
        .td-color {
          color: $theme-color1;
        }
        h2 {
          font-size: calc(16px + (24 - 16) * ((100vw - 320px) / (1920 - 320)));
          color: $dark;
          font-weight: 400;
        }
        .mobile-cart-content {
          display: none;
          justify-content: center;
          margin-top: 10px;
          .qty-box {
            border-radius: 5px;
            .input-group {
              .form-control {
                width: 48px;
                padding: 6px;
              }
            }
          }
          .col-xs-3 {
            align-self: center;
            margin-left: 10px;
            margin-right: 10px;
          }         
        }
      }
    }
  }

  tfoot {
    tr {
      th {
        padding-top: 35px;
        text-align: right;
      }

      td {
        padding-top: 35px;
        padding-bottom: 0;
        text-align: right;
        border: none;
        padding-right: 63px;

        h2 {
          font-size: 24px;
          margin-bottom: 0;
        }

        &:first-child {
          width: 85%;
          font-size: 15px;
          padding-right: 10px;
          text-transform: capitalize;
          font-weight: 700;
        }
      }
    }
  }
}

// blog-details //
.blog-block {
  >div {
    padding-left: 0;
    padding-right: 0;
  }

  .blog-contain {
    margin-right: 15px;
    margin-left: 15px;
  }
}

.blog-detail-page {
  .blog-detail {
    p {
      &:last-child {
        margin-bottom: 0;
      }
    }

    img {
      margin-bottom: 30px;
    }

    h3 {
      color: $font-color;
      font-weight: 700;
      margin-bottom: 20px;
      text-transform: uppercase;
      letter-spacing: 0.03em;
      font-size: calc(18px + (22 - 18) * ((100vw - 320px) / (1920 - 320)));
    }

    p {
      color: $icon-color;
      line-height: 1.8;
      font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)))
    }

    .post-social {
      color: $dark;
      text-align: left;
      padding-bottom: 15px;
      border-bottom: 1px solid $light-border;
      margin-bottom: 15px;

      li {
        letter-spacing: 0.05em;       
        &+li {
          padding-left: 15px;
          margin-left: 15px;
          border-left: 1px solid $light-border;
          font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
        }       
      }
    }
  }

  .blog-advance {
    >div {
      &:last-child {
        p {
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }

    img {
      margin-bottom: 15px;
    }

    ul {
      list-style-type: decimal;
      list-style-position: inside;

      li {
        display: list-item;
        font-weight: 700;
        color: $font-color;
        letter-spacing: 0.05em;
        font-size: 15px;
        margin-bottom: 11px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    p {
      font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
      line-height: 1.5;
    }
  }

  .blog-contact {
    h2 {
      margin-bottom: 30px;
      margin-top: -5px;
      color: $font-color;
    }

    .theme-form {
      label {
        text-transform: capitalize;
        color: $font-color;
        font-size: 16px;
        font-weight: 700;
      }

      input,
      textarea {
        border-color: $light-border;
        font-size: 14px;
        padding: 17px 25px;
        margin-bottom: 30px;
        letter-spacing: 0.05em;
      }

      .btn {
        text-transform: uppercase;
      }
    }
  }

  .comment-section {
    li {
      padding-bottom: 35px;
      &+li{
        padding-top: 35px;
        border-top: 1px solid $light-border;        
      }
      &:last-child{
        padding-bottom: 0;
      }
      img {
        height: 65px;
        width: 65px;
        margin-right: 20px;
        border-radius: 100%;
      }

      h6 {
        color: $font-color;
        text-transform: uppercase;
        font-weight: 700;
        margin-bottom: 5px;

        span {
          font-size: 13px;
          font-weight: 500;
          text-transform: capitalize;
          color: $dark;
          margin-left: 20px;
        }
      }

      p {
        line-height: 1.8;
        margin-bottom: 0;
      }
    }
  }
}

// creative card //
.creative-card {
  padding: 30px;
  background-color: $white;

  &.creative-inner {
    border-width: 15px;
  }
}

// review //
.review-box {
  box-shadow: 0 11px 35px 2px rgba(0, 0, 0, 0.1);
  padding: 30px;
  text-align: center;
  border-radius: 5px;
  background-color: $white;

  .rating-star {
    li {
      i {
        color: $rating-star;
      }
    }
  }

  img {
    height: 60px;
    border-radius: 50%;
  }

  h5 {
    color: $theme-color1;
    font-weight: 600;
    font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
    margin-top: 15px;
  }

  p {
    line-height: 1.4;    
    color: $dark;
    margin-top: 10px;
    margin-bottom: 10px;
    letter-spacing: 0.05rem;
    opacity: 0.4;
  }

  h6 {
    text-transform: capitalize;
    margin-top: 5px;
  }
}

.review-block {
  >div {
    &:nth-child(n+4) {
      margin-top: 20px;
    }
  }
}

// search page //
.search-product {
  >div {
    &:nth-child(n + 5) {
      margin-top: 30px;
    }
  }
}

// blog-page //
.blog-page {
  .order-sec {
    padding-left: 30px;
  }

  .blog-sidebar {
    h6 {
      margin-bottom: 0;
      line-height: 20px;
      color: $font-color;
      font-weight: 700;
      letter-spacing: 0.05em;
    }

    p {
      line-height: 20px;
      margin-bottom: 0;
      color: $icon-color;
    }

    h4 {
      color: $font-color;
      text-transform: uppercase;
      font-weight: 600;
      margin-bottom: 20px;
      line-height: 20px;
      letter-spacing: 1px;
    }

    .theme-card {
      padding: 30px;
      background-color: $white;

      .recent-blog {
        li {
          .media {
            img {
              width: 100px;
              margin-right: 15px;
            }
          }          
          &+li {
            margin-top: 30px;
          }
        }
      }

      .popular-blog {
        li {
          .blog-date {
            background-color: $theme-color1;
            height: 70px;
            width: 70px;
            margin: 0 15px 0 0;
            padding: 15px;

            span {
              display: block;
              text-align: center;
              color: $white;
              text-transform: uppercase;
              font-weight: 700;
            }
          }

          &+li {
            margin-top: 30px;
          }

          p {
            margin-top: 10px;
          }
        }
      }

      &+.theme-card {
        margin-top: 30px;
      }
    }
  }

  .blog-media {
    margin-bottom: 30px;
    background-color: $white;
    padding: 30px 15px;

    &:last-child {
      margin-bottom: 0;
    }

    .blog-left {
      overflow: hidden;
      position: relative;
      img {
        transition: all 0.5s ease;
      }
      .date-label {
        background-color: $theme-color1;
        color: $white;
        font-weight: 700;
        font-size: calc(12px + (16 - 12) * ((100vw - 320px) / (1920 - 320)));
        text-transform: uppercase;
        position: absolute;
        top: 15px;
        left: 15px;
        padding: 10px 15px;
        line-height: 1;
      }
    }
    .blog-right {
      height: 100%;
      align-items: center;
      justify-content: center;
      display: flex;
      h6 {
        color: $theme-color1;
        text-transform: uppercase;
        margin-bottom: 10px;
        font-weight: 700;
        letter-spacing: 0.05em;
      }
      h4 {
        color: $font-color;
        font-weight: 700;
        line-height: 1.5;
        margin-bottom: 15px;
        text-transform: capitalize;
        letter-spacing: 0.05em;
        font-size: calc(14px + (22 - 14) * ((100vw - 320px) / (1920 - 320)))
      }
      p {
        line-height: 1.8;
        margin-bottom: 0;
        letter-spacing: 0.05em;
        color: $icon-color;
      }
      ul {
        color: $dark;
        margin-bottom: 15px;
        li {
          font-size: 14px;
          line-height: 1.5;
          letter-spacing: 0.05em;
          i {
            padding-right: 3px;
          }
          &+li {
            padding-left: 10px;
            margin-left: 10px;
            border-left: 1px solid $light-border;
          }
        }
      }
    }
    &:hover {
      .blog-left {
        img {
          transform: translateX(20px) scale(1.1);
        }
      }
    }
    &.media-change {
      text-align: right;
      >div {
        &:first-child {
          order: 2;
        }
      }
    }
  }
}

// product page //
.product-slick,
.rtl-product-slick,
.product-right-slick,
.rtl-product-right-slick {
  img {
    width: 100%;
  }

  .slick-prev,
  .slick-next {
    opacity: 0;
    transform: scale(1);
    transition: all 0.5s ease;
    margin-top: -10px;

    &:before {
      font: normal normal normal 14px/1 $font-awesome;
      opacity: 1;
      color: black;
      background-color: $white;
      border-radius: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .slick-next {
    right: 1px;
    &:before {
      content: "\f105";
    }
  }

  .slick-prev {
    left: 1px;
    z-index: 1;

    &:before {
      content: "\f104";
    }
  }

  .slick-slide {
    >div {
      display: flex;
      margin-bottom: 15px;
    }
  }

  &:hover {

    .slick-prev,
    .slick-next {
      opacity: 1;
      transform: scale(1.05);
      transition: all 0.5s ease;

      &:before {
        opacity: 1;
      }
    }

    .slick-next {
      right: 20px;
    }

    .slick-prev {
      left: 20px;
    }
  }
}

.slider-nav,
.rtl-slider-nav,
.slider-right-nav,
.rtl-slider-right-nav {
  .slick-slide {
    >div {
      display: flex;
      margin: 15px 15px 0 15px;
      border: 1px solid $white1;
      transition: all 0.5s ease;
      opacity: 0.5;
      overflow: hidden;

      &:hover {
        border-color: $theme-color1;
        opacity: 1;
      }
    }

    &.slick-current {
      >div {
        border-color: $theme-color1;
        transition: all 0.5s ease;
        opacity: 1;
      }
    }

    &.slick-active {
      transition: all 0.5s ease;
    }
  }
}

.slider-right-nav,
.rtl-slider-right-nav {
  .slick-slide {
    &:first-child {
      >div {
        margin-top: 0;
      }
    }
  }
}
.pro_sticky_info {
  padding: 20px;
  border: 1px solid $light-border;
  // top: -100px;
}
.is_stuck {
  margin-top:120px;
}

.image-scroll {
  div {
    >div {
      padding-bottom: 20px;

      &:last-child {
        padding: 0;
      }
    }
  }
}

.size-box {
  ul {
    li {
      width: calc(40px + (45 - 40) * ((100vw - 320px) / (1920 - 320)));
      height: calc(30px + (35 - 30) * ((100vw - 320px) / (1920 - 320)));
      border-radius: 5px;
      cursor: pointer;
      border: 1px solid $light-border;
      text-align: center;

      &:nth-child(n+2) {
        margin-left: 5px;
      }

      a {
        color: $dark;
        font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
        text-transform: uppercase;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
      }

      &.active {
        background-color: $light-border;
      }
    }
  }
}




.addeffect-section {
  .error-message {
    display: none;
    color: red;
  }

  &.cartMove {
    .error-message {
      display: block;
    }

    .size-box {
      animation: shakeX .82s cubic-bezier(.36, .07, .19, .97) both;
    }
  }
}

.added-notification {
  position: fixed;
  top: -60px;
  right: 30px;
  display: flex;
  align-items: center;
  background-color: $dark;
  border-radius: 3px;
  padding: 6px;
  overflow: hidden;
  box-shadow: 0 0 2px 1px $light-border;
  transition: all 0.5s ease;

  img {
    width: 25px;
  }

  h3 {
    color: $white;
    text-transform: capitalize;
    font-size: 16px;
    margin-bottom: 0;
    margin-left: 10px;
    margin-right: 10px;
    font-weight: 600;
  }

  &.show {
    z-index: 2;
    top: 30px;
    transition: all 0.5s ease;
  }
}

.color-selector {
  ul {
    line-height: 1;

    li {
      display: flex;

      >div {
        margin-right: 5px;
      }

      &:nth-child(n+2) {
        margin-top: 5px;
      }
    }
  }
}

.product-offer {
  .offer-contain {
    ul {
      li {
        color: $dark;
        font-size: 14px;
        text-transform: capitalize;
        display: flex;
        align-items: center;
        margin-top: 15px;

        .code-lable {
          color: $theme-color1;
          background-color: rgba($theme-color1, 0.08);
          border: 1px dashed $theme-color1;
          width: fit-content;
          padding: 3px 10px;
          text-transform: uppercase;
          border-radius: 5px;
          margin-right: 10px;
          font-size: calc(12px + (14 - 12) * ((100vw - 320px) / (1920 - 320)));
          animation: headShake 2s ease-in-out infinite;
        }

        h5 {
          font-weight: 600;
          font-size: 14px;
        }

        p {
          font-size: calc(12px + (14 - 12) * ((100vw - 320px) / (1920 - 320)));
          padding-top: 5px;
        }
      }
    }

    .offer-sider {
      display: none;
    }

    .show-offer {
      color: $theme-color1;
      text-transform: capitalize;
      margin-top: 10px;
      font-size: 14px;
      cursor: pointer;

      .less-offer {
        display: none;
      }
    }
  }
}

.product-title {
  color: $font-color;
  text-transform: capitalize;
  font-weight: 700;
  margin-bottom: 10px;
  line-height: 1;
  font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));

  i {
    color: $theme-color1;
    margin-right: 5px;
  }

  span {
    width: 100%;
    text-align: center;
    margin-bottom: 5px;

    a {
      color: $theme-color1;
      letter-spacing: 0.05em;
    }
  }
}

.product-right {
  .pro-group {
    padding-bottom: 20px;

    &:nth-child(n+2) {
      padding-top: 20px;
      border-top: 1px solid $light-border;
    }

    h2 {
      font-size: calc(16px + (22 - 16) * ((100vw - 320px) / (1920 - 320)));
      font-weight: 700;
      line-height: 1.2em;
      color: $font-color;
      letter-spacing: 1px;
      margin-bottom: 5px;
      text-transform: capitalize;
    }

    ul {
      &.pro-price {
        font-size: calc(14px + (22 - 14) * ((100vw - 320px) / (1920 - 320)));
        color: $dark;
        margin-bottom: 5px;
        text-transform: uppercase;
        font-weight: 700;
        line-height: 1;

        span {
          text-decoration: line-through;
          color: $icon-color;
          font-size: 80%;
          font-weight: 500;
        }

        li {
          text-transform: uppercase;

          span {
            color: $gray-6;
            font-size: 80%;
          }

          &:nth-child(n+2) {
            margin-left: 5px;
          }

          &:last-child {
            color: $theme-color1;
            font-size: 80%;
          }
        }
      }

      &.best-seller {
        display: flex;
        align-items: center;

        li {
          font-size: 14px;
          background-color: $gray1;
          display: flex;
          align-items: center;
          padding: 5px 10px;
          border-radius: 5px;
          width: fit-content;
          line-height: 1;
          text-transform: capitalize;

          &:nth-child(n+2) {
            margin-left: 10px;
          }

          svg {
            width: 24px;
            margin-right: 5px;
          }
        }
      }

      &.delivery-services {
        display: flex;
        align-items: center;

        li {
          padding: 15px 25px;
          display: block;
          font-size: 14px;
          color: $dark;
          font-weight: 700;
          text-transform: capitalize;
          border: 1px solid $light-border;
          text-align: center;
          line-height: 1;

          &:nth-child(n+2) {
            margin-left: 10px;
          }

          svg {
            width: calc(30px + (35 - 30) * ((100vw - 320px) / (1920 - 320)));
            fill: $theme-color1;
            margin-right: 10px;
            display: block;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 5px;
          }
        }
      }

      &.image-swatch {
        li {
          width: 33px;
          height: 33px;
          overflow: hidden;

          img {
            width: 33px;
            height: 33px;
            padding: 2px;
            border: 1px solid $light-border;
            transition: all 0.5s ease;
          }

          &:nth-child(n+2) {
            margin-left: 5px;
          }

          &.active {
            img {
              border: 1px solid $theme-color1;
            }
          }
        }
      }
    }

    .revieu-box {
      display: flex;
      align-items: center;
      margin-bottom: 5px;

      ul {
        li {
          i {
            color: $rating-star;
          }
        }
      }

      a {
        color: $theme-color1;
      }

      span {
        display: inline-block;
        margin-left: 10px;
        text-transform: capitalize;
        font-size: calc(12px + (14 - 12) * ((100vw - 320px) / (1920 - 320)));
      }
    }

    .size-box {
      margin-bottom: 15px;
    }

    .qty-box {
      margin-bottom: 15px;
    }

    .color-selector {
      margin-bottom: 15px;
    }

    .product-buttons {
      text-transform: uppercase;

      a {
        padding: 13px 18px;

        &.cart-btn {
          i {
            animation: ring 4s .7s ease-in-out infinite;
          }
        }

        &:last-child {
          margin-left: 10px;
        }
      }
    }

    .delivery-detail {
      .delivery-detail-contian {
        display: flex;
        align-items: center;
        margin-bottom: 15px;

        .input-group {
          border-radius: 5px;
          width: 270px;
          height: 48px;         
          .input-group-text {
              background-color: transparent;
          }         
          .form-control {
            border-left: none;
            padding-left: 0;
            &:focus {
              border-color: $light-border;
              outline: none;
              box-shadow: none;
            }
          }
        }

        .btn {
          text-transform: capitalize;
          margin-left: 10px;
        }
      }

      .delivery-lable {
        display: flex;
        align-items: center;
        background-color: $gray1;
        padding: 10px 12px;
        width: 380px;
        font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));

        svg {
          width: calc(20px + (24 - 20) * ((100vw - 320px) / (1920 - 320)));
          margin-right: 10px;
        }
      }
    }

    .product-social {
      li {
        &:nth-child(n+2) {
          margin-left: 5px;
        }

        a {
          width: calc(30px + (40 - 30) * ((100vw - 320px) / (1920 - 320)));
          height: calc(30px + (40 - 30) * ((100vw - 320px) / (1920 - 320)));
          border-radius: 5px;
          display: flex;
          justify-content: center;
          align-items: center;
          border: 1px solid $light-border;
          color: $gray-2;
          border-radius: 100%;
          transition: all 0.5s ease;

          i {
            font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
          }
        }

        &:hover {
          a {
            background-color: $theme-color1;
            border-color: $theme-color1;
            color: $white;
          }
        }
      }
    }

    .timer {
      p {
        color: $dark;
        >span {
          border-radius: 50%;
          width: 70px;
          height: 70px;
          border: 1px solid $theme-color1;
        }

        span {
          padding-top: 15px;
          padding-bottom: 15px;
          overflow: hidden;
          line-height: 1;
          font-size: 20px;
          margin-right:unset;
          &:nth-child(n+2){
            margin-left:15px;
          }
          .timer-cal {
            font-size: 14px;
            padding-top: 0;
            padding-bottom: 0;
          }
        }
      }

      margin-top: 10px;

    }
  }

  .payment-card-bottom {
    margin-top: 10px;

    ul {
      li {
        padding-right: 10px;
      }
    }
  }

  &.product-form-box {
    text-align: center;
    padding: 20px;
    background-color: $white;

    .pro-group {
      .product-title {
        &.size-text {
          display: flex;
          text-align: left;
          justify-content: space-between;
        }
      }

      .qty-box {
        justify-content: center;
      }
    }
  }
}

.bottom-cart-sticky {
  position: fixed;
  bottom: -100px;
  left: 0;
  width: 100%;
  z-index: 9;
  background-color: $white;
  padding: 12px 0;
  box-shadow: 0 0 3px 1px rgba($black, 0.16);
  transition: all 0.5s ease;

  &.open-cart {
    bottom: 0;
  }

  .cart-content {
    display: flex;
    align-items: center;
  }

  .product-image {
    display: flex;
    align-items: center;
    margin-right: 15px;

    img {
      width: 60px;
      height: 70px;
      object-fit: cover;
      object-position: top;
    }

    .content {
      margin-left: 12px;

      h5 {
        font-size: 18px;
        font-weight: 600;
        color: $dark;
        text-transform: capitalize;
      }

      h6 {
        font-size: 15px;
        color: $dark;
        font-weight: 700;

        del {
          margin-left: 7px;
          color: $gray-2;
          font-weight: 400;
        }

        span {
          color: $theme-color1;
          margin-left: 5px;
        }
      }
    }
  }

  .selection-section {
    display: flex;
    align-items: center;
    margin: 0 auto;

    .form-control {
      background-color: $gray1;
      border: 1px solid $gray1;
      text-transform: uppercase;

      &:focus {
        box-shadow: none;
      }
    }

    .form-group {
      &:nth-child(2) {
        margin-left: 40px;
      }
    }
  }

  .btn {
    margin-left: 15px;
  }
}

.product-side-tab {
  margin-top: 50px !important;
}

.single-product-tables {
  display: flex;
  margin-top: 20px;

  table {
    width: 20%;

    tr {
      height: 35px;

      td {
        color: $font-color;
        letter-spacing: 0.05em;

        &:first-child {
          font-weight: 600;
        }
      }
    }
  }

  &.detail-section {
    margin-top: 0;

    table {
      width: 55%;
    }
  }
}

.product-accordion {
  .btn-link {
    color: $font-color;
    text-transform: capitalize;
    font-weight: 700;
    letter-spacing: 0.05em;
    text-decoration: none;
    &:hover {
      text-decoration: none;
    }
  }

  .card {
    border: none;
  }

  .card-body {
    width: 100%;
    height: auto;
  }

  .card-header {
    padding: 5px 8px;
    border-bottom: 1px solide $light-border;
  }
}

.product-related {
  h2 {
    color: $dark;
    padding-bottom: 20px;
    border-bottom: 1px solid $light-border;
    margin-bottom: 20px;
    text-transform: uppercase;
    font-weight: 600;
    line-height: 1;
    margin-top: -4px;
    font-size: calc(18px + (28 - 18) * ((100vw - 320px) / (1920 - 320)));
  }
}

.rating {
  margin-top: 0;

  i {
    &:nth-child(-n + 4) {
      color: $rating-star;
    }

    &:last-child {
      color: $light-border;
    }
  }

  .three-star {
    padding-bottom: 5px;

    i {
      color: #acacac;

      &:nth-child(-n + 3) {
        color: #ffd200;
      }
    }
  }
}

.tab-border {
  border: 1px solid $light-border;
  margin: 0;
  padding: 8px 0;

  .nav-border {
    border-bottom: 0;
    border-right: 1px solid $light-border;
  }
}

.vertical-tab {

  &.tab-product,
  .product-full-tab {
    .nav-material {
      &.nav-tabs {
        .nav-item {
          .nav-link {
            padding: 15px 20px;
          }
        }
      }
    }
  }
}

.tab-product,
.product-full-tab {
  margin-top: 30px;

  &.tab-exes {
    margin-top: 50px;
  }

  .nav-material {
    &.nav-tabs {
      display: flex;
      align-items: center;
      flex-wrap: nowrap;

      .nav-item {
        .nav-link {
          color: $font-color;
          text-align: center;
          padding: 0 15px 20px 15px;
          text-transform: uppercase;
          border: 0;
          background-color: transparent;
          font-weight: 700;
          letter-spacing: 0.05em;
        }

        .material-border {
          border-bottom: 2px solid $theme-color1;
          opacity: 0;
        }
      }

      .nav-link.active {
        color: $theme-color1;

        &~.material-border {
          transition: all 0.3s ease;
          opacity: 1;
        }
      }
    }
  }

  .theme-form {
    input {
      border-color: $light-border;
      font-size: 15px;
      padding: 15px 25px;
      margin-bottom: 15px;
      height:inherit;
      text-align:left;
      &:focus{
        box-shadow:none;
      }
    }
    .btn-solid,
    .btn-outline {
      margin: 0 auto;
    }

    textarea {
      border-color: $light-border;
      font-size: 15px;
      padding: 17px 25px;
      margin-bottom: 15px;
      height: inherit;
      &:focus{
        box-shadow:none;
      }
    }
  }

  .tab-content.nav-material {
    p {
      padding: 20px 20px 0 20px;
      margin-bottom: -8px;
      line-height: 2;
      letter-spacing: 0.05em;
    }

    .media {
      margin-top: 20px;
    }
  }

  .title {
    padding-right: 45px;
    color: $theme-color1;
    padding-bottom: 20px;
  }

  .theme-slider {
    .slick-arrow {
      top: -45px;
      height: auto;

      :before {
        color: $black;
        font-size: 18px;
      }
    }
  }

  .product-box {
    position: relative;
    margin: 5px;

    &:hover {
      box-shadow: 0 0 12px 0 $light-border;

      .lbl-1 {
        opacity: 1;
        transition: all 0.3s ease;
      }

      .lbl-2 {
        opacity: 1;
        animation: flipInY 1000ms ease-in-out;
      }

      .color-variant li {
        opacity: 1 !important;
        animation: fadeInUp 500ms ease-in-out;
      }
    }

    .img-block {
      min-height: unset;
    }

    .cart-info {
      position: absolute;
      padding: 10px 0;
      top: 25%;
      right: 15px;
      width: 40px;
      margin-right: 0;

      i {
        padding-right: 0;
      }

      a,
      button {
        color: $font-color;
        transition: all 0.3s ease;
        background-color: $white;
        height: 35px;
        width: 35px;
        margin: 7px 0;
        border-radius: 100%;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border: 0;
        box-shadow: 0 0 12px 0 $light-border;

        :hover {
          transition: all 0.3s ease;
          color: $theme-color1;
        }
      }
    }

    .lbl-1 {
      background-color: $theme-color1;
      padding: 2px 20px 2px 10px;
      display: inline-block;
      text-align: center;
      color: $white;
      position: absolute;
      left: 0;
      top: 15px;
      font-size: 14px;
      line-height: 1.5;
      opacity: 0;

      &:before {
        content: "";
        position: absolute;
        right: 0;
        top: 0;
        width: 0;
        height: 0;
        border-top: 12px solid $theme-color1;
        border-bottom: 13px solid $theme-color1;
        border-right: 7px solid $white;
      }
    }

    .lbl-2 {
      font-size: 14px;
      top: 15px;
      position: absolute;
      right: 10px;
      color: $font-color;
      font-weight: 600;
      text-transform: capitalize;
      opacity: 0;
    }

    a {
      color: #0072bb;
      font-size: 15px;
      font-weight: 700;
      letter-spacing: 1px;
    }

    .color-variant {
      position: absolute;
      top: -35px;
      width: 100%;
    }

    .slick-slide img {
      display: block;
    }

    .product-details {
      position: relative;

      .color-variant {
        position: absolute;
        top: -35px;
        width: 100%;

        li {
          opacity: 0;
          display: inline-block;
          height: 15px;
          width: 15px;
          border-radius: 100%;
          margin: 0 3px;
          transition: all 0.3s ease;
          cursor: pointer;
        }
      }

      h6 {
        color: $font-color;
        font-weight: 600;
        letter-spacing: 1px;
        text-transform: capitalize;
      }

      .price {
        padding-bottom: 10px;
        font-size: 16px;
        color: $theme-color1;
      }
    }
  }
}

.product-full-tab {
  padding-top: 70px;
}

.product-load-more {
  .col-grid-box {
    display: none;
  }
}

.load-more-sec {
  margin-top: 40px;
  text-align: center;

  a {
    font-size: 18px;
    text-transform: uppercase;
    display: block;
    padding: 10px 0;
    border-top: 1px solid $light-border;
    border-bottom: 1px solid $light-border;
    width: 100%;
    color: $theme-color1;
  }
}

.product_image_4 {
  >div {
    &:nth-last-child(-n + 2) {
      margin-top: 25px;
    }
  }
}

.image-360 {
  cursor: pointer;
  position: absolute;
  top: 15px;
  right: 35px;
  z-index: 8;
  width: calc(22px + (30 - 22) * ((100vw - 320px) / (1920 - 320)));
}

.product-image-360 {
  position: relative;
  overflow: hidden;
  margin: 0 auto;
  cursor: pointer;

  .nav_bar {
    position: absolute;
    bottom: 40px;
    left: 50%;
    margin-left: -67.5px;
    z-index: 11;
    background-color: $white;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);

    a {
      display: inline-block;
      width: 45px;
      height: 45px;
      line-height: 48px;
      text-align: center;
      text-decoration: none;
      color: $dark;
    }
  }

  .product-images-item {
    display: none;
    list-style: none;
    margin: 0;
    padding: 0;

    img.previous-image {
      visibility: hidden;
    }

    img.current-image {
      visibility: visible;
    }

    img {
      position: absolute;
      top: 50%;
      vertical-align: middle;
      height: auto;
      left: 50%;
      transform: translate(-50%, -50%);
      max-width: 100%;
    }
  }

  .spinner {
    background: $white;
    position: relative;
    z-index: 999;
    width: 800px;
    height: 90px;
    margin-top: 0 !important;
    display: inline-block;

    span {
      width: 60px;
      height: 60px;
      line-height: 60px;
      text-align: center;
      display: block;
      margin: 0 auto;
      color: $black;
      font-weight: bolder;
      background: rgba(255, 255, 255, 0.7);
      box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
      border-radius: 50%;
      margin-top: 20px;
    }
  }
}

.custom_stop {
  display: none !important;
}

.play-video .custom_play {
  display: none !important;
}

.play-video .custom_stop {
  display: inline-block !important;
}

.video-product {
  video {
    width:100%;
    height:75vh;   
  }
}

.video-icon {
  position: relative;

  img {
    opacity: 0.8;
  }

  i {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 24px;
  }
}

//pro right//






// Quick-view //
.quick-view {
  width: 100%;
  max-width: 1080px;
  max-height: 600px;
  position: relative;
}

// Check-out page //
.checkout-page {
  .checkout-title {
    margin-bottom: 25px;

    h3 {
      color: $font-color;
      font-weight: 700;
      font-size: 24px;
    }
  }

  .checkout-form {
    .check-out {
      .form-group {
        &:last-child {
          margin-bottom: -5px;

          label {
            margin-bottom: -5px;
          }
        }
      }
    }

    .form-group {
      position: relative;
      margin-bottom: 25px;

      h3 {
        color: $dark;
        font-weight: 700;
        margin-bottom: 30px;
        margin-top: 30px;
      }

      .field-label {
        line-height: 24px;
        text-transform: capitalize;
        color: $font-color;
        margin-bottom: 10px;
        font-weight: 700;

        span {
          font-size: 16px;
          color: $dark;
          font-weight: 600;
        }
      }

      label {
        color: $font-color;
      }
    }

    select {
      cursor: pointer;
      appearance: none;
      background: url(../images/dropdown.png) no-repeat 95%;
    }

    input {

      &[type="text"],
      &[type="email"],
      &[type="password"],
      &[type="tel"],
      &[type="number"],
      &[type="url"] {
        width: 100%;
        padding: 0 22px;
        height: 45px;
        border: 1px solid $light-border;
      }
    }

    select,
    textarea {
      width: 100%;
      padding: 0 22px;
      height: 45px;
      background: $white;
      border: 1px solid $light-border;
    }
  }

  .check-box {
    line-height: 24px;
    font-size: 14px;
    font-weight: normal;
    padding-top: 5px;

    label {
      position: relative;
      top: -1px;
      font-weight: normal;
      padding: 0;
      font-size: 16px;
      cursor: pointer;
      color: $font-color;
    }
  }

  .lower-content {
    margin-top: 30px;

    .order-column {
      margin-bottom: 40px;
    }
  }
}

.order-box {
  position: relative;
  margin-bottom: 50px;
  .title-box {
    position: relative;
    padding-bottom: 25px;
    color: $dark;
    font-weight: 600;
    font-size: 22px;
    border-bottom: 1px solid $light-border;
    margin-bottom: 20px;

    span {
      position: relative;
      width: 35%;
      float: right;
      line-height: 1.2em;
    }
  }

  .qty {
    position: relative;
    border-bottom: 1px solid $light-border;
    margin-bottom: 30px;

    li {
      position: relative;
      display: block;
      font-size: 15px;
      color: $dark;
      line-height: 20px;
      margin-bottom: 20px;

      span {
        float: right;
        font-size: 18px;
        line-height: 20px;
        color: $font-color;
        font-weight: 400;
        width: 35%;
      }
    }
  }

  .sub-total {
    position: relative;
    border-bottom: 1px solid $light-border;
    margin-bottom: 30px;

    li {
      position: relative;
      display: inline-block;
      font-size: 16px;
      font-weight: 600;
      color: $font-color;
      line-height: 20px;
      margin-bottom: 20px;
      width: 100%;

      .count {
        position: relative;
        font-size: 18px;
        line-height: 20px;
        color: $theme-color1;
        font-weight: 400;
        width: 35%;
        float: right;
      }
    }

    .shopping-option {
      label {
        position: relative;
        font-size: 16px;
        line-height: 32px;
        padding-left: 10px;
        color: $dark;
      }
    }

    .shipping {
      width: 35%;
      float: right;
    }
  }

  .total {
    position: relative;
    margin-bottom: 40px;

    li {
      position: relative;
      display: block;
      font-weight: 400;
      color: $font-color;
      line-height: 20px;
      margin-bottom: 10px;
      font-size: 18px;

      .count {
        position: relative;
        font-size: 18px;
        line-height: 20px;
        color: $theme-color1;
        font-weight: 400;
      }

      span {
        float: right;
        font-size: 15px;
        line-height: 20px;
        color: $dark;
        font-weight: 400;
        width: 35%;
        display: block;
      }
    }
  }
}

.payment-box {
  position: relative;

  .upper-box {
    position: relative;
  }

  .btn-normal {
    text-transform: uppercase;
  }

  .payment-options {
    position: relative;
    margin-top: 20px;
    margin-bottom: 30px;

    li {
      display: flex;
      margin-bottom: 15px;

      .radio-option {
        position: relative;

        label {
          position: relative;
          padding-left: 30px;
          text-transform: capitalize;
          color: $dark;
          cursor: pointer;
          font-weight: 600;
          font-size: 16px;
          line-height: 20px;
          margin-bottom: 0;
        }

        input[type="radio"] {
          position: absolute;
          left: 0;
          top: 5px;
        }

        label {
          .small-text {
            position: relative;
            display: none;
            font-size: 15px;
            line-height: 25px;
            font-weight: 300;
            color: #666666;
            margin-top: 10px;
          }

          img {
            position: relative;
            display: block;
            max-width: 100%;
            margin-left: -30px;
            margin-top: 5px;
          }
        }
      }
    }
  }
}

.checkout-second {
  .checkout-box {
    width: 900px;
    margin-left: auto;
    margin-right: auto;
    padding: calc(20px + (30 - 20) * ((100vw - 320px) / (1920 - 320)));
    background-color: $white;

    .checkout-header {
      text-align: center;
      padding-bottom: calc(15px + (20 - 15) * ((100vw - 320px) / (1920 - 320)));

      h2 {
        text-transform: uppercase;
        color: $dark;
        font-weight: 700;
        margin-bottom: 5px;
        font-size: calc(16px + (28 - 16) * ((100vw - 320px) / (1920 - 320)));
      }

      h4 {
        text-transform: capitalize;
        font-size: calc(12px + (18 - 12) * ((100vw - 320px) / (1920 - 320)));
        font-weight: 300;
    padding-top: 5px;
      }
    }

    .checkout-body {
      .btn {
        text-transform: capitalize;
        padding: 13px 30px;
        text-transform: uppercase;
        font-weight: 700;
        border-radius: 3px;
        margin-top: calc(8px + (10 - 8) * ((100vw - 320px) / (1920 - 320)));
      }

      .previous {
        margin-right: calc(5px + (10 - 5) * ((100vw - 320px) / (1920 - 320)));
      }

      .checkout-form {
        position: relative;

        .menu-bar {
          margin-bottom: calc(15px + (20 - 15) * ((100vw - 320px) / (1920 - 320)));
          overflow: hidden;
          padding-top: calc(18px + (30 - 18) * ((100vw - 320px) / (1920 - 320)));
          padding-bottom: calc(18px + (30 - 18) * ((100vw - 320px) / (1920 - 320)));
          background-color: $bg-light;
          text-align: center;

          .active {
            color: $theme-color1;
          }

          li {
            font-size: calc(12px + (16 - 12) * ((100vw - 320px) / (1920 - 320)));
            position: relative;
            color: $theme-color2;

            &:nth-child(n+2) {
              margin-left: calc(10px + (70 - 10) * ((100vw - 320px) / (1920 - 320)));
            }

            .icon {
              width: calc(30px + (50 - 30) * ((100vw - 320px) / (1920 - 320)));
              height: calc(30px + (50 - 30) * ((100vw - 320px) / (1920 - 320)));
              border-radius: 5px;
              display: flex;
              justify-content: center;
              align-items: center;
              background-color: $theme-color2;
              margin-left: auto;
              margin-right: auto;
              border-radius: 100%;
              margin-bottom: 5px;
              svg {
                width: 42%;
                height: auto;
                fill: $white;
              }
            }
          }

          li.active {
            .icon {
              background-color: $theme-color1;
            }
          }
        }

        .checkout-fr-box {
          width: 100%;
          position: relative;

          &:not(:first-of-type) {
            display: none;
          }

          .form-card {
            h3.form-title {
              text-transform: capitalize;
              margin-bottom: calc(10px + (15 - 10) * ((100vw - 320px) / (1920 - 320)));
              font-size: calc(18px + (26 - 18) * ((100vw - 320px) / (1920 - 320)));              
            }

            .form-group {
              margin-bottom: calc(12px + (16 - 12) * ((100vw - 320px) / (1920 - 320)));

              label {
                font-weight: 700;
                color: $dark;
              }

              .form-control {
                border-color: $light-border;
                height: calc(35px + (48 - 35) * ((100vw - 320px) / (1920 - 320)));
                padding-top: 0;
                padding-bottom: 0;
                padding-left: calc(10px + (15 - 10) * ((100vw - 320px) / (1920 - 320)));
                font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));

                &:focus {
                  box-shadow: none;
                  border-color: $light-border;
                }
              }

              .small-group {
                display: flex;
                align-items: center;

                select {
                  -webkit-appearance: none;
                  background-image: url(../images/checkout/dropdown.png);
                  background-size: 12px;
                  background-repeat: no-repeat;
                  background-position: 95%;

                  &:nth-child(n+2) {
                    margin-left: 15px;
                  }
                }

                >div {
                  width: 100%;
                }

                .small-sec {
                  width: 300px;
                  margin-left: 15px;
                }
              }
            }

            ul.payment-info {
              margin-bottom: calc(10px + (15 - 10) * ((100vw - 320px) / (1920 - 320)));

              li {
                line-height: 1;
                border: 1px solid $light-border;
                border-radius: 5px;

                &:nth-child(n+2) {
                  margin-left: calc(2px + (15 - 2) * ((100vw - 320px) / (1920 - 320)));
                }

                img {
                  width: calc(52px + (60 - 52) * ((100vw - 320px) / (1920 - 320))) !important;
                  height: auto;                 
                }
              }
            }

            .payment-success {
              text-align: center;
              padding-top: calc(30px + (50 - 30) * ((100vw - 320px) / (1920 - 320)));
              padding-bottom: calc(30px + (50 - 30) * ((100vw - 320px) / (1920 - 320)));
              background-color: $bg-light;

              svg {
                width: calc(50px + (100 - 50) * ((100vw - 320px) / (1920 - 320)));
                fill: $light-green;
                margin-bottom: calc(5px + (10 - 5) * ((100vw - 320px) / (1920 - 320)));
              }

              h2 {
                font-weight: 700;
                text-transform: capitalize;
                color: $light-green;
                line-height: 1;
              }
            }
          }
        }
      }
    }
  }
}




// Dashboard page
.dashboard-left {
  background-color: $white;

  .block-title h2 {
    font-size: 24px;
    padding-bottom: 20px;
  }

  .block-content {
    padding: 15px;

    ul li {
      display: flex;
      transition: all 0.5s ease;

      &.active {
        color: $theme-color1;

        a {
          color: $theme-color1;
        }
      }

      &:before {
        content: '\f105';
        display: inline-block;
        font-family: FontAwesome;
        font-style: normal;
        line-height: 1.5;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        width: 30px;
        height: 35px;
        text-align: center;
        font-size: 18px;
        font-weight: 700;
        margin-top: 3px;
      }

      a {
        color: $font-color;
        font-size: calc(14px + (20 - 14) * ((100vw - 320px) / (1920 - 320)));
      }

      &:hover {
        a {
          color: $theme-color1;
          transition: all 0.5s ease;
        }

        padding-left: 10px;
        color: $theme-color1;
        transition: all 0.5s ease;
      }
    }
  }
}

.padding-cls {
  padding-left: 50px;
  padding-right: 50px;
}

.dashboard-right {
  background-color: $white;

  p {
    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
    line-height: 20px;
  }

  .dashboard {
    padding: 30px;

    .page-title h2 {
      font-size: calc(18px + (22 - 18) * ((100vw - 320px) / (1920 - 320)));
      margin-bottom: 15px;
      color: $font-color;
      line-height: 1;
      font-weight: 600;
    }

    .welcome-msg {
      p {
        margin-bottom: 0;
        font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
        line-height: 1.6;
      }
    }
  }
}

.dashboard {
  .box-head {
    h2 {
      font-size: calc(18px + (22 - 18) * ((100vw - 320px) / (1920 - 320)));
      margin: 20px 0 0;
      text-transform: capitalize;
      color: $font-color;
    }
  }

  .box {
    a {
      color: $theme-color1;
      font-weight: 700;
    }

    h6 {
      margin-bottom: 0;
      font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
    }

    .box-title {
      border-bottom: 1px solid $light-border;
      display: inline-block;
      margin-bottom: 5px;
      padding: 12px 0;
      position: relative;
      width: 100%;

      h3 {
        font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
        margin: 0;
        text-transform: capitalize;
        color: $font-color;
        letter-spacing: 0.05em;
      }

      >a {
        position: absolute;
        right: 0;
        top: 12px;
        color: $theme-color1;
        font-weight: 700;
        font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
      }
    }

    address {
      margin-bottom: 0;
      font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
    }
  }
}

.box-content {
  h6 {
    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
    line-height: 1.8 !important;
    margin-bottom: 0;
  }

  a {
    color: $theme-color1;
    font-weight: 700;
    letter-spacing: 0.05em;
  }
}

.account-sidebar {
  display: none;
  cursor: pointer;
  background-color: $theme-color1;
  color: $white;
  width: 30%;
  font-size: 16px;
  text-transform: uppercase;
  text-align: center;
  font-weight: 700;
  margin-bottom: 20px;
  padding: 5px;
}

// Order-success //
.success-text {
  text-align: center;

  i {
    font-size: 50px;
    color: $light-green;
  }

  h2 {
    text-transform: uppercase;
    font-size: calc(20px + (36 - 20) * ((100vw - 320px) / (1920 - 320)));
    color: $font-color;
    font-weight: 700;
  }

  p {
    text-transform: capitalize;
    font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
  }

  &.order-fail {
    i {
      color: $theme-color1;
    }
  }
}

.order-success-sec {
  h4 {
    font-weight: 700;
    text-transform: capitalize;
    color: $font-color;
    font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
    line-height: 1;
    margin-bottom: 10px;
  }

  .order-detail {
    li {
      display: flex;
      text-transform: capitalize;
      font-size: 16px;
    }
  }

  .payment-mode {
    p {
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 0;
    }
  }
}

.delivery-sec {
  padding: 30px;
  text-align: center;
  margin-top: 20px;
  background-color: $gray-5;

  h2,
  h3 {
    color: $black;
    text-transform: capitalize;
  }

  h2 {
    font-weight: 600;
    font-size: calc(20px + (36 - 20) * ((100vw - 320px) / (1920 - 320)));
  }

  h3 {
    font-size: calc(16px + (24 - 16) * ((100vw - 320px) / (1920 - 320)));
    font-weight: 500;
  }
}

.product-order {
  h3 {
    font-weight: 700;
    text-transform: uppercase;
    color: $font-color;
    font-size: calc(18px + (26 - 18) * ((100vw - 320px) / (1920 - 320)));
    line-height: 1;
  }

  .product-order-detail {
    margin-top: 15px;

    img {
      height: 130px;
    }

    .order_detail {
      display: flex;
      align-items: center;

      h4 {
        margin-bottom: 5px;
        text-transform: capitalize;
        font-weight: 700;
        color: $font-color;
      }

      h5 {
        text-transform: capitalize;
        margin-bottom: 0;
        font-weight: 500;
      }
    }
  }

  .total-sec {
    border-top: 1px solid $light-border;
    border-bottom: 1px solid $light-border;
    margin: 15px 0;
    padding: 15px 0;

    ul {
      li {
        font-size: 18px;
        display: block;
        text-transform: capitalize;
        color: $font-color;

        span {
          float: right;
        }
      }
    }
  }

  .final-total {
    h3 {
      display: block;
      margin-bottom: 0;
      font-size: 24px;

      span {
        float: right;
      }
    }
  }
}

// compare //
.compare-section {
  .compare-part {
    position: relative;

    .close-btn {
      background-color: transparent;
      border: none;
      font-size: 25px;
      position: absolute;
      top: 0;
      right: 0;
    }

    .img-secton {
      padding: 25px 15px 15px;
      text-align: center;
      img,
      .bg-size {
        width: 50%;
        margin: 0 auto;
      }

      a {
        h5 {
          margin-bottom: 0;
          text-transform: capitalize;
          margin-top: 10px;
          color: $font-color;
          font-weight: 600;
        }
      }

      h5 {
        margin-bottom: 0;
        line-height: 1.2;
        font-weight: 400;

      }
    }

    .detail-part {
      .title-detail {
        background-color: #f7f8fa;
        text-align: center;
        padding: 8px 0;
        border-top: 1px solid $light-border;
        border-bottom: 1px solid $light-border;

        h5 {
          margin-bottom: 0;
          text-transform: uppercase;
          color: $font-color;
          letter-spacing: 0.05em;
        }
      }

      .inner-detail {
        padding: 15px;

        p {
          margin-bottom: 0;
          line-height: 1.2;
          letter-spacing: 0.05em;
          text-align: center;
        }
      }
    }

    .btn-part {
      text-align: center;
      padding: 15px;
      border-top: 1px solid $light-border;

      .btn-normal {
        padding: 13px 25px;
        text-transform: uppercase;
      }
    }
  }

  .slick-slide {
    >div {
      border: 1px solid $light-border;
      border-left: none;
    }

    &:first-child {
      border-left: 1px solid $light-border;
    }
  }
}

.compare-padding {
  padding-bottom: 50px;

  h2 {
    margin-bottom: 20px;
  }
}

.compare-label {
  position: absolute;
  font-size: 13px;
  font-weight: 700;
  text-transform: uppercase;
  writing-mode: vertical-rl;
  transform: rotate(-180deg);
  top: 20px;
  left: 16px;
  letter-spacing: 0.1em;
  z-index: 1;
}

.compare-page {
  .table-wrapper {
    padding-bottom: 0;
    .table {
      border: 1px solid $light-border;
      text-transform: capitalize;
      color: $font-color;
      margin-bottom: 0;
      .product-name {
        width: 15%;
      }
      .featured-image {
        width: 185px;
      }
      td {
        border-top: none;
      }
      thead, tbody, tfoot, tr, td, th{
        border: 1px solid $light-border !important;
      }
      thead {
        .th-compare {
          td {
            font-size: 14px;
            font-weight: 700;
            background: $gray1;
            border-right: 1px solid $light-border;
            letter-spacing: 0.05em;
            padding-left: 20px;
          }

          th {
            text-align: left;
            border-bottom: 1px solid $light-border;
            border-right: 1px solid $light-border;
            padding-left: 20px;
            .remove-compare {
              float: left;
              border: none;
              background: transparent;
              padding: 0;
              font-size: 14px;
              font-weight: 500;
            }
          }
        }
      }

      tbody {
        tr {
          th {
            background: $gray1;
          }

          td {
            border-right: 1px solid $light-border;
            position: relative;
          }

          th,
          td {
            padding: 15px;
            vertical-align: top;
            border-bottom: 1px solid $light-border;
            border-top: 1px solid $light-border;
            border-right: 1px solid $light-border;
          }

          .grid-link__title {
            font-size: 16px;
            margin-top: 10px;
            font-weight: 600;
          }

          .product_price {
            margin: 15px 0;

            span.money {
              padding-left: 5px;
            }
          }

          p {
            color: $icon-color;
            font-size: 13px;
            line-height: 20px;
            margin: 0;
          }

          .add-to-cart,
          .select-option-cls {
            padding: 6px 10px;
          }
        }
      }
    }
  }
}

// typography page //
.typography_section {
  .typography-box {
    margin-bottom: 30px;
    p {
      line-height: 22px;
    }
    .headings {
      margin-bottom: 20px;
      background-color: $bredcumb-main;
      padding: 8px 12px;
      h3 {
        text-transform: capitalize;
        color: #2d2d2d;
        margin-bottom: 0;
        font-weight: 400;
        font-size: 20px;
      }

      span {
        color: $dark;

        code {
          color: $dark;
        }
      }
    }

    .typo-content {
      &.heading_content {

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          font-weight: 400;
          color: black;
          text-transform: uppercase;
          line-height: 1;
          margin-bottom: 10px;
        }
      }

      .sub-title {
        border-bottom: 1px solid $light-border;
        padding-bottom: 5px;
        margin-bottom: 8px;
        font-size: 18px;
        color: $dark;
        text-transform: capitalize;
      }

      &.product-pagination {
        border: none;
        margin: 0;

        .pagination {
          .page-item {
            border-top: 1px solid $light-border;
            border-bottom: 1px solid $light-border;

            a {
              padding: 10px 14px;
            }
          }
        }
      }

      &.input_button {
        input {
          margin-bottom: 10px;
        }

        label {
          font-size: 16px;
          text-transform: capitalize;
          padding-left: 5px;
        }
      }

      &.loader-typo {
        width: 100%;
        justify-content: center;
        display: flex;

        .pre-loader {
          position: relative;
          width: 50px;
          height: 50px;
          background-color: $theme-color1;
          border-radius: 50%;

          &:after {
            content: '';
            position: absolute;
            border-radius: 50%;
            top: 50%;
            left: 50%;
            border: 0 solid $light-border;
            transform: translate(-50%, -50%);
            animation: loading 1000ms ease-out forwards infinite;
            transition: all 0.3s ease;
          }
        }
      }

      .footer-social {
        margin-top: 0;
        justify-content: center;
      }
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  code {
    color: rgba($theme-color1, 0.6);
    background-color: #f7f7f7;
    padding: 3px;
    margin: 0 3px;
    border-radius: 2px;
  }
}
.blockquote-footer{
  display:block;
  font-size:80%; 
  margin-top:unset;
}

// account page //
.profile-section {
  background-color: #f5f5f5;
  border: 1px solid $light-border;
  padding: 15px;

  .profile-detail {
    .profile-name {
      font-weight: 700;
    }

    p {
      margin-bottom: 8px;
    }
  }
}

// look-book //
.lookbook-section {
  padding-left: 25px;
  padding-right: 25px;
  padding-bottom: 25px;

  .lookbook-img {
    >div {
      +div {
        img {
          margin-top: 25px;
        }
      }
    }
  }
}

.lookbook {
  .lookbook-block {
    position: relative;

    .lookbook-dot {
      cursor: pointer;
      position: absolute;
      z-index: 2;
      width: 29px;
      height: 29px;
      line-height: 29px;
      border-radius: 50%;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      transition: all 0.3s ease;
      top: 50%;
      left: 12%;
      background-color: $white;

      &.dot2 {
        top: 70%;
        left: 27%;
      }

      &.dot3 {
        top: 28%;
        left: 30%;
      }

      &.dot4 {
        top: 67%;
        left: 8%;
      }

      &.dot5 {
        top: 40%;
        left: 35%;
      }

      &.dot6 {
        top: 80%;
        left: 58%;
      }

      &.dot7 {
        top: 67%;
        left: 45%;
      }

      &.dot8 {
        top: 43%;
        left: 39%;
      }

      &.dot9 {
        top: 32%;
        left: 50%;
      }

      &.dot10 {
        top: 60%;
        left: 50%;
      }

      &.dot11 {
        top: 50%;
        left: 45%;
      }

      &.dot12 {
        top: 75%;
        left: 45%;
      }

      &.dot13 {
        top: 40%;
        left: 45%;
      }

      &.dot14 {
        top: 76%;
        left: 45%;
      }

      &.dot15 {
        top: 50%;
        left: 15%;
      }

      &:before {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        content: "";
        background-color: rgba(255, 255, 255, 0.5);
        zoom: 1;
        z-index: -1;
        border-radius: 50%;
        transition: all 0.3s ease;
      }

      span {
        text-align: center;
        justify-content: center;
        display: flex;
        color: $font-color;
      }

      .dot-showbox {
        visibility: hidden;
        top: -98px;
        left: 150%;
        position: absolute;
        width: 130px;
        background-color: $white;
        box-shadow: -3px -3px 13px rgba(48, 54, 61, 0.1);

        img {
          margin-top: 0;
        }

        .dot-info {
          padding: 5px;
          text-align: center;

          h5 {
            margin-bottom: 0;
            text-transform: capitalize;
            line-height: 20px;
            color: $font-color;

            &.title {
              font-weight: 700;
            }
          }

          h6 {
            text-transform: uppercase;
            color: $theme-color1;
            margin-bottom: 0;
          }
        }

        &:before {
          position: absolute;
          content: "";
          width: 0;
          height: 0;
          border-top: 8px solid transparent;
          border-right: 8px solid $light-border;
          border-bottom: 8px solid transparent;
          top: 41%;
          left: -8px;
        }
      }

      &:hover {
        background-color: $theme-color1;
        box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.5);
        z-index: 10;

        .dot-showbox {
          animation: fadeInUp 400ms ease-in-out;
          visibility: visible;
          transition: all 0.5s ease;
        }

        span {
          color: $white;
        }

        &:before {
          transform: scale(1.3);
        }
      }
    }
  }
}

//portfolio css //
.portfolio-padding {
  padding-bottom: 40px;
}

.portfolio-section {
  .isotopeSelector {
    margin-bottom: 30px;
  }

  .portfolio-2 {
    margin-bottom: -20px;
  }

  .portfolio-3 {
    margin-bottom: -5px;
  }

  .article-title {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
    color: white;
    transition: all 0.5s ease;
    text-align: center;
    float: left;
    font-size: 18px;
    line-height: 24px;

    a {
      color: white;
    }
  }

  .isotopeSelector {
    float: left;
    // width: 100%;
    position: relative;
    .overlay {
      position: relative;
      overflow: hidden;
    }
    img {
      width: 100%;
      height: auto;
    }
  }

  .isotopeSelector {
    .product-box {
      padding-right: 0;
    }

    img {
      transition: all .4s ease;
    }

    .overlay-background {
      transform: scale(0);
      transition: all .4s ease;

      i {
        position: absolute;
        top: 50%;
        left: 50%;
        background: rgba($white, 0.4);
        padding: 10px;
        border-radius: 100%;
        transform: translate(-50%, -50%);
        color: $white;
        font-size: 22px;
        display: none;
        transition: all 0.4s ease;
        width: 40px;
        height: 40px;
        align-items: center;
        justify-content: center;
      }
    }

    &:hover {
      img {
        transform: scale(1.2) rotate(2deg);
        transition: all .4s ease;
      }

      .overlay-background {
        transform: scale(1);
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba($black, 0.4);
        opacity: 1;
        z-index: 1;
        transition: opacity .3s linear;

        i {
          display: flex;
          transition: all 0.4s ease;
        }
      }
    }
  }

  .border-portfolio {
    margin: 3px;
    overflow: hidden;

    &:after {
      position: absolute;
      top: 10%;
      left: 10%;
      z-index: 2;
      display: block;
      content: '';
      width: 0;
      height: 0;
      background: rgba($white, 0.2);
      border-radius: 50%;
      transform: translate(-20%, -20%);
      opacity: 0;
    }

    &:before {
      position: absolute;
      bottom: 10%;
      right: 10%;
      z-index: 2;
      display: block;
      content: '';
      width: 0;
      height: 0;
      background: rgba($white, 0.2);
      border-radius: 50%;
      transform: translate(10%, 10%);
      opacity: 0;
    }

    &:hover {
      &:after {
        animation: portfolio-circle 0.5s ease;
      }

      &:before {
        animation: portfolio-circle 0.8s ease;
      }
    }
  }

  @keyframes portfolio-circle {
    0% {
      opacity: 1;
    }

    40% {
      opacity: 1;
    }

    100% {
      width: 300px;
      height: 300px;
      opacity: 0;
    }
  }

  #form1 {
    padding-bottom: 20px;
  }

  .filter-button {
    padding: 15px 24px;
    font-size: calc(16px + (20 - 16) * ((100vw - 320px) / (1920 - 320)));
    border: none;
    background-color: $white;
    margin: 0 5px;
    line-height: 1;

    &.active {
      background-color: $theme-color1;
      color: $white;
    }
  }

  &.fullwidth-portfolio {
    .isotopeSelector {
      padding-left: 7px;
      padding-right: 7px;
      margin-bottom: 14px;
    }
  }
}

.filter-section {
  .filter-container {
    text-align: center;
    padding: 0 0 20px;

    ul {
      &.filter {
        >li {
          padding: 0 25px;

          >a {
            color: $theme-color1;
            font-size: 20px;
            line-height: 40px;

            &:hover,
            &:focus {
              text-decoration: none;
              color: $theme-color1;
              outline: none;
            }
          }

          &.active {
            background-color: $theme-color1;

            a {
              color: $white;
            }
          }

          span {
            color: #ddd;
            font-size: 20px;
            display: inline-block;
            margin: 0 5px
          }
        }
      }
    }

    .filter {
      >li {
        padding: 0;
        margin: 0;
      }
    }
  }

  .title1 {
    .title-inner1 {
      margin-top: -5px;
    }
  }

  h1 {
    text-align: center;
  }
}

.demo-colored-wrap {
  background: #ecf0f1;
}

.mfp-with-zoom .mfp-container,
.mfp-with-zoom.mfp-bg {
  opacity: 0;
  transition: all 0.3s ease-out;
}

.mfp-with-zoom.mfp-ready .mfp-container {
  opacity: 1;
}

.mfp-with-zoom.mfp-ready.mfp-bg {
  opacity: 0.8;
}

.mfp-with-zoom.mfp-removing .mfp-container,
.mfp-with-zoom.mfp-removing.mfp-bg {
  opacity: 0;
}

.mfp-title {
  display: none;
}

.offer-box {
  top: 50%;
  position: fixed;
  transform: translateY(-50%);
  display: flex;
  left: -415px;
  transition: all 0.5s ease;
  z-index: 9;

  img {
    margin-right: 18px;
  }

  &.toggle-cls {
    left: 0;
    transition: all 0.5s ease;
  }

  .heading-right {
    h3 {
      position: absolute;
      top: 50%;
      right: 0;
      width: 230px;
      height: 36px;
      line-height: 36px;
      letter-spacing: 5px;
      font-size: 18px;
      color: $white;
      font-weight: 700;
      text-transform: uppercase;
      transform: translate(50%, -50%) rotate(-90deg);
      text-align: center;
      background-color: $theme-color1;
      margin-bottom: 0;
      cursor: pointer;
    }
  }
}

// Theme tab CSS start //
.theme-tab {
  position: relative;

  &.product {
    &.tab-abjust {
      margin-bottom: -15px;
    }
  }

  .tab-content {
    display: none;

    &[style="display: block;"] {

      .product-box,
      .tab-box,
      .media-banner,
      .digipro-box,
      .product-box3,
      .gallery-box {
        animation: zoomIn 300ms ease-in-out;
      }
    }

    .product-tab {
      .tab-box {
        background-color: lighten($theme-color1, 70%);
        width: 100%;
        flex: 0 0 25%;
        max-width: calc(25% - 10px);
        margin: 0 5px 10px;

        &:nth-last-child(-n + 4) {
          margin: 0 5px 0;
        }

        .product-box2 {
          img {
            height: 250px;
            padding: 15px;
            transition: all 0.5s ease;
          }

          .media {
            .media-body {
              .rating {
                margin-top: 0;

                i {
                  padding-right: 0;

                  &:nth-child(-n + 4) {
                    color: $rating-star;
                  }

                  &:last-child {
                    color: $rating-star;
                  }
                }
              }

              a {
                h6 {
                  margin-right: 35px;
                  line-height: 17px;
                  margin-top: 5px;
                  margin-bottom: 0;
                }
              }

              h4 {
                font-size: 20px;
                margin-top: 5px;
                font-weight: 700;
                color: $font-color;
                margin-bottom: 0;
              }

              .color-variant {
                li {
                  display: inline-block;
                  height: 20px;
                  width: 20px;
                  border-radius: 100%;
                  margin-right: 2px;
                  margin-top: 20px;
                  transition: all 0.1s ease;
                  margin-bottom: 0;
                  cursor: pointer;
                }
              }
            }
          }
        }

        &:hover {
          .product-box2 {
            img {
              transform: scale(1.03);
              transition: all 0.5s ease;
            }
          }
        }
      }
    }
  }

  .tab-title,
  .tab-title2 {
    text-align: center;
    font-size: 18px;
    margin-bottom: 30px;
    margin-top: -6px;

    &.media-tab {
      li {
        padding-top: 40px;
        position: relative;

        &.current {
          &::before {
            width: 100%;
            position: absolute;
            top: 6px;
            left: 0;
            background-color: $theme-color1;
            height: 5px;
            content: '';
          }
        }
      }

      .media-banner {
        margin-right: 15px;
      }
    }

    .current {
      a {
        color: $theme-color1;
        font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
      }
    }

    li {
      padding-left: 25px;
      padding-right: 25px;
    }

    a {
      color: $font-color;
      text-transform: uppercase;
      font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
    }
  }

  .tab-title2 {
    font-size: 24px;
    font-weight: 700;
    text-transform: uppercase;
    margin-top: -8px;

    &:after {
      content: "";
      position: absolute;
      border: 1px solid $light-border;
      width: 100%;
      left: 0;
      top: 17px;
      z-index: -1;
    }

    .current {
      background-color: $body-back;

      a {
        background-color: $body-back;
      }
    }
  }

}

.theme-secondtab .tab-bg {
  background-color: lighten($theme-color1, 45%);
  padding: 70px 50px 60px 50px;

  .theme-tab {
    .tab-title {
      margin-bottom: 30px;
    }
  }

  .product-box {
    padding-right: 0;
  }
}


// parallax banner //
.parallax {
  background-attachment: fixed;
}

.full-banner {
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 190px;
  padding-bottom: 190px;
  background-position: center;

  &.advertise-banner {
    background-image: url("../images/fashion/banner.jpg");
  }

  &.parallax-layout {
    padding-top: 350px;
    padding-bottom: 350px;

    .banner-contain {
      h4 {
        color: $gray1;
      }
    }
  }

  &.banner-layout-3 {
    .banner-contain {
      .btn-normal {
        text-transform: uppercase;
      }

      h4 {
        color: $white;
      }

      h3 {
        color: $white;
        margin-top: 10px;
      }

      .color {
        color: $theme-color1;
        line-height: 1;
      }
    }
  }

  &.parallax {
    background-attachment: fixed;

  }

  &.p-left {
    .banner-contain {
      float: left;
    }
  }

  &.p-right {
    .banner-contain {
      float: right;
    }
  }

  &.p-center {
    .banner-contain {
      left: 0;
      right: 0;
      margin: 0 auto;
    }
  }

  .banner-contain {

    .btn-solid,
    .btn-outline {
      margin-top: 20px;
    }

    h2 {
      font-size: calc(55px + (100 - 55) * ((100vw - 320px) / (1920 - 320)));
      font-weight: 700;
      color: $theme-color1;
      text-transform: uppercase;
      margin-top: -15px;
    }

    h3 {
      font-size: calc(36px + (60 - 36) * ((100vw - 320px) / (1920 - 320)));
      color: $font-color;
      text-transform: uppercase;
      font-weight: 700;
    }

    h4 {
      font-size: calc(18px + (24 - 18) * ((100vw - 320px) / (1920 - 320)));
      color: $font-color;
      font-weight: 600;
      text-transform: uppercase;
      letter-spacing: 0.5em;
      padding-top: 10px;
      margin-bottom: -5px;
      line-height: 1.3;
    }
  }
}

.parallax-cls {
  section {
    &:nth-child(odd) {
      .full-banner {
        background-position: right;
      }
    }

    &:nth-child(even) {
      .full-banner {
        background-position: left;
      }
    }

    .banner-contain {
      margin-top: -12px;
    }
  }
}

.pet-parallax {
  position: relative;

  .pet-decor {
    position: absolute;
    left: 110px;
    bottom: -164px;
  }

  .banner-contain {

    h4,
    h3,
    p {
      color: #212121;
    }

    p {
      max-width: 75%;
      margin: 0 auto;
      line-height: 22px;
      font-size: 16px;
      letter-spacing: 0.04em;
    }

    h4 {
      letter-spacing: 0.05em;
      padding-top: 0 !important;
      margin-bottom: 0;
      line-height: 1.3;
      margin-top: -7px;
    }

    h3 {
      margin: 15px 0;
      font-size: 48px;
    }
  }

  .full-banner {
    padding-top: 130px;
    padding-bottom: 130px;
    background-blend-mode: overlay;
  }
}

.advertise-section {
  .full-banner {
    padding-bottom: 105px;
    padding-top: 105px;
    background-position: top;

    .banner-contain {
      background-color: rgba($white, 0.9);
      padding: 40px 0;
      width: 90%;
      border: 3px solid $theme-color1;

      h2 {
        font-size: 75px;
      }
    }
  }
}

.center-slider {
  border-left: 1px solid $light-border;
  border-right: 1px solid $light-border;
}

.about-text p {
  letter-spacing: 0.06em;
  text-align: center;
  margin-bottom: 50px;
}

.full-box {
  .theme-card {
    .offer-slider {
      .product-box2 {
        overflow: hidden;
      }
    }
  }
}


//  Theme pannel start  //
.theme-pannel-main {
  background-color: $white;
  position: fixed;
  top: 30%;
  right: 0;
  z-index: 9;
  padding: 0 5px;
  box-shadow: 0 0 7px 1px rgba(158, 158, 158, 0.2);
  ul {
    li {
      span {
        padding: 0;
        text-transform: uppercase;
        color: #949494;
        font-weight: 700;
      }

      a {
        padding: 0;
      }

      display: flex;
      padding: 10px 12px;
      border-bottom: 1px solid #dadada;

      &:last-child {
        border-bottom: 0;
      }
    }
  }
}

.color-picker {
  position: fixed;
  top: calc(30% + 110px);
  z-index: 9;
  padding: 0 5px;
  right: 0;
  -webkit-box-shadow: 0 0 7px 1px rgba(158, 158, 158, 0.2);
  box-shadow: 0 0 7px 1px rgba(158, 158, 158, 0.2);
  background-color: $white;
  max-width: 150px;

  h4 {
    padding: 10px 8px;
    border-bottom: 1px solid #ddd;
    margin-bottom: 10px;
    font-size: 16px;
    text-transform: uppercase;
  }

  .title {
    display: inline-block;
    position: absolute;
    left: -68px;
    width: 68px;
    box-shadow: -3px 0 7px 1px rgba(158, 158, 158, 0.2);
    background-color: $white;
    padding: 10px 14px;
  }

  .colors {
    text-align: center;

    li {
      height: 35px;
      margin: 3px;
      width: 35px;
      display: inline-block;
      position: relative;

      &.active {
        &:before {
          content: "\e600";
          position: absolute;
          font-family: themify;
          color: #fff;
          font-size: 19px;
          top: 2px;
          left: 10px;
          transform: rotate(-90deg);
        }
      }
    }
  }
}

.color1 {
  background: linear-gradient(135deg, #ff6000 49%, #ff6000 50%, #ffa800 51%, #ffa800 100%);
}

.color2 {
  background: linear-gradient(135deg, #00baf2 49%, #00baf2 50%, #1c3481 51%, #1c3481 100%);
}

.color3 {
  background: linear-gradient(135deg, #f1bb3a 49%, #f1bb3a 50%, #0083c1 51%, #0083c1 100%);
}

.color4 {
  background: linear-gradient(135deg, #f15838 49%, #f15838 50%, #6465a5 51%, #6465a5 100%);
}

.color5 {
  background: linear-gradient(135deg, #2690e4 49%, #2690e4 50%, #252525 51%, #252525 100%);
}

.color6 {
  background: linear-gradient(135deg, #84b213 49%, #84b213 50%, #f97c2d 51%, #f97c2d 100%);
}

.color7 {
  background: linear-gradient(135deg, #c76d62 49%, #c76d62 50%, #ef9f77 51%, #ef9f77 100%);
}

.color8 {
  background: linear-gradient(135deg, #f78195 49%, #f78195 50%, #f97107 51%, #f97107 100%);
}

.color9 {
  background: linear-gradient(135deg, #35a7b1 49%, #35a7b1 50%, #f98925 51%, #f98925 100%);
}

.color10 {
  background: linear-gradient(135deg, #fb6436 49%, #fb6436 50%, #f98925 51%, #f98925 100%);
}

.color11 {
  background: linear-gradient(135deg, #4150b5 49%, #4150b5 50%, #e62a16 51%, #e62a16 100%);
}

.color12 {
  background: linear-gradient(135deg, #fa741b 49%, #fa741b 50%, #2fbb2f 51%, #2fbb2f 100%);
}

.color13 {
  background: linear-gradient(135deg, #a03b41 49%, #a03b41 50%, #f56449 51%, #f56449 100%);
}

.color14 {
  background: linear-gradient(135deg, #1e9a05 49%, #1e9a05 50%, #f3725c 51%, #f3725c 100%);
}

.color15 {
  background: linear-gradient(135deg, #3635aa 49%, #3635aa 50%, #46d8d5 51%, #46d8d5 100%);
}

/*=====================
  26.add to cart CSS start
==========================*/
.add_to_cart {
  position: fixed;
  background-color: $white;
  z-index: 9999;
  transition: all 0.5s ease;

  ::-webkit-scrollbar {
    width: 3px;
    height: 7px;
  }

  ::-webkit-scrollbar-track {
    background: $gray1;
  }

  ::-webkit-scrollbar-thumb {
    background: $light-border;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: $black;
  }

  .cart-inner {
    background-color: $white;
    z-index: 9;
    position: relative;
    top: 0;

    .cart_media {
      padding: 0 20px;

      ul {
        li {
          .btn {
            text-transform: capitalize;
            font-size: 18px;
          }
        }

        &.cart_product {
          .addit-box{
            padding-top: 5px;
          }
          .qty-minus,.qty-plus{
            width: 25px;
            padding: 0;
            font-size: 10px;
          }
          .qty-adj{
            height: 25px;
            width: 35px;
          }
          li {
            position: relative;
            width: 100%;
            border: 1px solid $light-border;
            padding: 10px;

            .media {
              align-items: center;
              height: 100%;

              img {
                width: 83px;
                height: auto;
              }

              .media-body {
                h4 {
                  color: $dark;
                  text-transform: capitalize;
                  margin-bottom: 5px;

                  span {
                    color: $font-color;
                    font-size: 16px;
                  }
                }

                h6 {
                  color: $theme-color1;
                  font-weight: 700;
                  margin-bottom: 5px;

                  span {
                    color: $gray-2;
                    text-decoration: line-through;
                  }
                }

                .qty-box {
                  margin-bottom: 5px;
                }

                .addit-box {
                  .pro-add {
                    svg {
                      color: $dark;
                      width: 14px;
                    }
                  }
                }
              }
            }

            .close-circle {
              position: absolute;
              top: 10px;
              right: 10px;

              svg {
                width: 14px;
                color: $dark;
              }

              i {
                color: $dark;
                font-size: 16px;
                transition: all 0.5s ease;

                &:hover {
                  color: $black;
                  transition: all 0.5s ease;
                }
              }
            }
          }
        }

        &.cart_total {

          background-color: rgba($theme-color1, 0.05);
          padding: 15px;

          li {
            display: block;
            color: $dark;
            font-size: 16px;
            text-transform: capitalize;
            line-height: 1;

            &:nth-child(n+2) {
              margin-top: 10px;
            }

            span {
              float: right;
              text-transform: uppercase;
            }

            .total {
              border-top: 1px solid $light-border;
              margin-top: 20px;
              padding-top: 15px;
              padding-bottom: 10px;
              font-size: 18px;
              font-weight: 600;
            }

            .buttons {
              display: flex;
              justify-content: space-between;
            }
          }
        }
      }
    }

    .cart_top {
      display: flex;
      padding: 20px;
      margin-bottom: 20px;
      align-items: center;
      background-color: rgba($theme-color1, 0.05);

      h3 {
        font-size: 18px;
        color: $theme-color1;
        font-weight: 700;
        text-transform: uppercase;
        margin-bottom: 0;
      }

      .close-cart {
        margin-left: auto;

        i {
          color: $theme-color1;
          font-size: 18px;
        }
      }
    }
  }

  .overlay {
    visibility: hidden;
    opacity: 0;
    background-color: $black;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: all 0.5s ease;
  }

  &.open-side {
    transition: all 0.5s ease;

    .overlay {
      opacity: 0.8;
      visibility: visible;
      transition: all 0.5s ease;
    }
  }

  &.left {
    left: -320px;
    top: 0;
    height: 100vh;
    width: 320px;
    .cart-inner {
      height: 100vh;
      overflow: auto;
    }

    &.open-side {
      left: 0;
    }
  }

  &.right {
    right: -320px;
    height: 100vh;
    width: 320px;
    top: 0;

    .cart-inner {
      height: 100vh;
      overflow: auto;
    }

    &.open-side {
      right: 0;
    }
  }

  &.top,
  &.bottom {
    width: 100%;

    .cart_media {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 20px;

      ul {
        &.cart_product {
          display: flex;
          width: 75%;
          overflow-y: auto;
          li {
            min-width: 245px;
            max-width: 280px;
            &:nth-child(n+2) {
              margin-left: 15px;
            }
          }
        }
      }
      .cart_total {
        width:24%;
      }
    }
  }

  &.top {
    top: -320px;

    &.open-side {
      top: 0;
    }
  }

  &.bottom {
    bottom: -320px;

    &.open-side {
      bottom: 0;
    }
  }

  &.left,
  &.right {
    .cart-inner {
      .cart_media {
        ul {
          &.cart_product {
            height: calc(100vh - 335px);
            overflow-y: auto;

            li {
              &:nth-child(n+2) {
                margin-top: 15px;
              }
            }
          }
        }

        .cart_total {
          width: calc(320px - 40px);
          position: fixed;
          bottom: 0px;
          margin-bottom: 20px;
        }
      }
    }
  }


  .setting-block {
    padding-left: 20px;
    padding-right: 20px;

    .form-group {
      select {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        width: 100%;
        height: 44px;
        text-transform: capitalize;
        border: 1px solid $light-border;
        background: url(../images/dropdown.png) no-repeat 95%;
        background-size: 12px;
        cursor: pointer;
        padding-left: 15px;
        padding-right: 15px;
        font-size: 16px;
      }
    }
  }
}




/*=====================
  27.add to account bar start
==========================*/
.account-bar {
  .theme-form {
    padding-left: 20px;
    padding-right: 20px;

    .form-group {
      margin-bottom: calc(15px + (25 - 15) * ((100vw - 320px) / (1920 - 320)));

      label {
        font-size: 18px;
        text-transform: capitalize;
        color: $dark;
        line-height: 1;
        font-weight: 700;
      }

      .form-control {
        border-radius: 5px;
        border-color: $light-border;
        height: 40px;
        padding-left: 15px;
        padding-right: 15px;

        &:focus {
          box-shadow: none;
        }
      }
    }

    h5 {
      color: $theme-color1;
      text-transform: capitalize;
    }

    h6 {
      color: $dark;
      text-transform: capitalize;
      font-weight: 700;
      margin-top: 5px;

      span {
        color: $theme-color1;
        margin-left: 10px;
      }
    }
  }
}



/*=====================
  28. Tab to top CSS start
==========================*/
.tap-top {
  position: fixed;
  bottom: 110px;
  right: 30px;
  z-index: 8;
  cursor: pointer;
  opacity: 0;
  visibility: hidden;
  transition: all 1s ease;

  svg {
    width: calc(35px + (50 - 35) * ((100vw - 320px) / (1920 - 320)));
    fill: $theme-color1;
  }

  &.top-cls {
    opacity: 1;
    visibility: visible;
  }

  &:hover {
    svg {
      animation: fadeInUp 500ms ease-in-out;
    }
  }
}

/*=====================
   29. Modal CSS start
==========================*/
.theme-modal {
  padding-right: 0 !important;
  .quick-view-img {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .modal-dialog {
    .modal-content {
      .modal-body {
        background-color: $white;
        border-radius: 5px;
        .modal-bg {
          background-color: $white;
          padding: 25px;
          .btn-close {
            position:absolute;
            right:17px;
            top:15px;
            padding:0;
            font-size:16px;
            span {
              color: $black;
              font-size: 30px;
            }
          }

          .age-content {
            h2 {
              border-bottom: 1px dashed $gray;
              text-align: center;
              margin-bottom: 15px;
              padding-bottom: 15px;
              font-weight: 700;
              font-size: 24px;
            }

            h4 {
              margin-bottom: 30px;
              text-align: center;
              color: #272727;
              font-weight: 400;
              text-transform: capitalize;
              line-height: 1.6;
              font-size: 14px;
            }

            form {
              margin: 0 auto;
              text-align: center;

              >div {
                display: flex;
              }

              .form-control {
                padding-top: 15px;
                padding-bottom: 15px;
                text-align: center;
                font-size: 12px;
                height: 45px;
                border-radius: 0;
                width: 100%;
                letter-spacing: 0.05em;
                margin: 0 10px;

                &:focus {
                  border-color: $light-border;
                  box-shadow: none;
                }

              }
            }
          }

          .offer-content {
            img {
              margin-bottom: 40px;
            }

            h2 {
              margin-bottom: 15px;
              text-align: center;
              color: $font-color;
              text-transform: uppercase;
              line-height: 1;
              font-weight: 700;
              letter-spacing: 0.05em;
              font-size: calc(20px + (32 - 20) * ((100vw - 320px) / (1920 - 320)));
            }

            form {
              .form-group {
                margin: 0 auto;
                text-align: center;

                .form-control {
                  padding-top: 15px;
                  padding-bottom: 15px;
                  text-align: center;
                  font-size: 14px;
                  max-width: 640px;
                  left: 0;
                  right: 0;
                  margin: 0 auto;
                  letter-spacing: 0.05em;
                  margin-bottom: 15px;

                  &:focus {
                    box-shadow: none;
                    border-color: $light-border;
                  }
                }

                .btn-normal {
                  text-transform: uppercase;
                }
              }
            }
          }
        }
      }
    }

    .quick-view-modal,
    .demo_modal {
      button {
        &.btn-close {
          position: absolute;
          z-index: 1;
          right: 10px;
          top: 5px;
        }
      }

      .view-detail {
        font-size: 14px;
        font-weight: 700;
        text-transform: uppercase;
      }
    }
  }

  &.demo-modal {
    .modal-dialog {
      .modal-content {
        .modal-body {
          height: 75vh;
          overflow-y: scroll;
          padding: 25px;
          background-image: none !important;

          &::-webkit-scrollbar {
            width: 5px;
          }

          &::-webkit-scrollbar-track {
            background: #f1f1f1;
          }

          &::-webkit-scrollbar-thumb {
            background: $theme-color1;
          }

          &::-webkit-scrollbar-thumb:hover {
            background: #555;
          }
        }
      }
    }
  }

  &.cart-modal {
    .modal-dialog {
      .modal-content {
        .modal-body {
          // background: url("../images/cart-back.png"), $light-border;
          background-position: center;
          background-size: cover;
          background-blend-mode: overlay;

          .modal-bg {
            &.addtocart {
              .media {
                a {
                  img {
                    max-width: 150px;
                  }
                }

                .media-body {
                  padding: 0 15px;

                  a {
                    h6 {
                      font-size: 16px;
                      color: $theme-color1;
                      text-transform: capitalize;
                      margin-bottom: 20px;

                      i {
                        margin-right: 8px;
                        color: $white;
                        background: #19a340;
                        font-size: 14px;
                        width: 18px;
                        height: 18px;
                        line-height: 18px;
                        border-radius: 50%;
                      }
                    }
                  }

                  .buttons {
                    margin-bottom: 20px;
                  }
                }
              }

              #upsell_product {
                .product-box {
                  padding-left: 10px;
                  padding-right: 10px;

                  .img-wrapper {
                    box-shadow: 0 0 5px rgba($light-border, 0.6);
                  }

                  .product-detail {
                    text-align: center;
                    padding: 10px;

                    h6 {
                      font-weight: 700;
                      color: $dark;
                      text-transform: capitalize;
                      margin-bottom: 5px;
                    }

                    h4 {
                      color: $theme-color2;
                      font-size: 14px;

                      span {
                        color: $body-text;
                        text-decoration: line-through;
                        margin-left: 10px;
                      }
                    }
                  }
                }
              }
            }
          }

          .product-upsell {
            margin: 20px 0 10px;

            h4 {
              font-weight: 600;
              margin-bottom: 20px;
              text-transform: capitalize;
              color: $dark;
              font-size: calc(18px + (20 - 18) * ((100vw - 320px) / (1920 - 320)));

              span {
                color: $body-text;
                text-decoration: line-through;
                margin-left: 10%;
              }
            }
          }
        }
      }
    }
  }

  &#exampleModal {
    .modal-body {
      padding: 0;
    }

    .btn-close {
      span {
        color: $theme-color1;
        font-size: 35px;
        text-shadow: none;
      }
    }

    .modal-bg {
      background-color: #ffffff96 !important;
      padding: 0;
    }

    .close {
      position: absolute;
      right: 10px;
    }

    .newslatter-main {
      display: flex;
      align-items: center;
      background-color: rgba($theme-color2, 0.05);

      >div {
        width: 100%;
        height: 400px;

        &:last-child {
          background-repeat: no-repeat;
        }
      }
    }

    .offer-content {
      display: flex;
      align-items: center;

      >div {
        width: 100%;
        padding: 50px;
      }

      p {
        text-align: center;
        color: $icon-color;
        line-height: 1.6;
        margin-bottom: 20px;
      }
    }
  }

  &.exit-modal {
    .modal-body {
      background-image: url(../images/bg-popup.png) !important;
      background-size: cover !important;
      background-position: center !important;
    }

    .modal-bg {
      background-color: #ffffffb3 !important;
    }

    .modal-content {
      border-radius: 10px;
    }

    .media {
      .media-body {
        h4 {
          font-size: 16px;
          line-height: 1.2;
          color: $font-color;
          font-weight: 500;

          b {
            color: $theme-color1;
          }
        }

        h5 {
          font-size: 18px;
          color: $gray;
          margin-bottom: 0;
          padding-bottom: 0;
        }
      }
    }
  }

  &.pro-edit-modal {
    z-index: 11111;

    .modal-dialog {
      width: 350px;

      .modal-content {
        .modal-body {
          padding: 20px;

          .btn-close {
            position: absolute;
            top: 5px;
            right: 15px;
          }
        }
      }
    }

    .pro-group {
      text-align: center;
      margin-bottom: 15px;

      .media {
        align-items: center;

        .img-wraper {
          img {
            width: auto;
            height: 150px;
          }
        }

        .media-body {
          text-align: left;

          h3 {
            color: $dark;
            text-transform: capitalize;
            font-size: 18px;
          }

          h6 {
            color: $theme-color1;
            font-size: 16px;
            font-weight: 700;

            span {
              color: $gray-2;
              text-decoration: line-through;
              margin-left: 5px;
            }
          }
        }
      }

      h6 {
        &.product-title {
          font-size: 16px;
          font-weight: 700;
          text-transform: capitalize;
          color: $dark;
          margin-bottom: 10px;
          text-align: center;
        }
      }

      .qty-box {
        justify-content: center;
      }

      .modal-btn {
        .btn {
          text-transform: capitalize;

          &:nth-child(n+2) {
            margin-left: 5px;
          }
        }
      }
    }
  }

  &.blackfriday-modal {
    font-family: $font-5;

    &#exampleModal {
      .btn-close {
        opacity: 1;
        z-index: 3;
        right: 15px;
        top: 10px;
        filter: invert(1);
        position:absolute;
        span {
          color: $white;
          font-size: 30px;
        }
      }
      .modal-dialog {
        max-width: 800px;
      }
      .modal-body {
        height: 460px;
        overflow: hidden;
        position: relative;
        background-color: $black;
      }
      .rainbow {
        width: 100%;
        height: 100%;
        animation: o-rotate-360 linear 12s infinite;
        transition: all 0.5s ease;
        span {
          display: block;
          width: 100%;
          height: 100%;
          transform: translate(-50%, -50%);
          &:after {
            display: block;
            content: "";
            width: 100%;
            height: 100%;
            position: absolute;
            left: 100%;
          }
          &:first-child {
            background-color: $theme-color2;
          }
          &:last-child {
            &:after {
              background-color: $theme-color2;
            }
          }
        }
      }

      .offer-content {
        background-color: $black;
        text-align: center;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin-top: auto;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: auto;
        width: calc(100% - 5px);
        height: calc(100% - 5px);
        >div {
          padding: calc(10px + (50 - 10) * ((100vw - 320px) / (1920 - 320)));
          display: flex;
          height: 100%;
          align-items: center;
          justify-content: center;
        }
        canvas {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: -1;
        }

        h2 {
          font-size: calc(50px + (100 - 50) * ((100vw - 320px) / (1920 - 320)));
          color: $white;
          text-transform: uppercase;
          font-family: $font-5;
          font-weight: 700;
          line-height: 1;
          margin-bottom: 10px;

          span {
            font-size: 30%;
          }
        }

        .lable {
          background-color: $theme-color2;
          color: $white;
          text-transform: uppercase;
          height: 40px;
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: calc(14px + (20 - 14) * ((100vw - 320px) / (1920 - 320)));
          font-weight: 600;
          margin-left: auto;
          margin-right: auto;
          margin-bottom: 10px;
          padding-left: 20px;

          &:after {
            content: "";
            position: absolute;
            left: 0;
            bottom: 0;
            width: 0;
            height: 0;
            border-left: 20px solid $black;
            border-top: 20px solid transparent;
            border-bottom: 20px solid transparent;
          }

          &:before {
            content: "";
            position: absolute;
            right: -20px;
            bottom: 0;
            width: 0;
            height: 0;
            border-left: 20px solid $theme-color2;
            border-top: 20px solid transparent;
            border-bottom: 20px solid transparent;
          }
        }

        h3,
        h4 {
          font-weight: 700;
          color: $white;
          text-transform: uppercase;
        }

        h3 {
          font-size: calc(20px + (60 - 20) * ((100vw - 320px) / (1920 - 320)));
          line-height: 1;
          margin-bottom: 10px;
        }

        .timer {
          p {
            margin-bottom: 15px;
          }

          span {
            background-color: rgba($theme-color2, 0.3);
            color: $white;
            border-radius: 5px;
          }

          .timer-cal {
            background-color: transparent;
            color: $white;
          }
        }

        h4 {
          font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
        }
      }
    }
  }

  &.cyber-monday {
    &#exampleModal {
      .btn-close {
        opacity: 1;
        font-weight: 400;
        position: absolute;
        top:15px;
        right:15px;
        span {
          color: $theme-color1;
        }
      }
      .offer-content {
        text-align: center;
        font-family: $font-4;
        >div {
          padding: calc(15px + (50 - 15) * ((100vw - 320px) / (1920 - 320)));
          display: flex;
          align-items: center;
          justify-content: center;
        }

        h6 {
          text-transform: capitalize;
          color: $theme-color1;
          font-size: calc(18px + (24 - 18) * ((100vw - 320px) / (1920 - 320)));
          font-weight: 700;
          margin-bottom: 10px;
        }

        h2 {
          text-transform: uppercase;
          font-size: calc(50px + (95 - 50) * ((100vw - 320px) / (1920 - 320)));
          font-weight: 900;
          line-height: 1;
          margin-bottom: 10px;
          color: $dark;
          font-style: italic;

          span {
            display: block;
          }
        }

        h3 {
          text-transform: capitalize;
          font-size: calc(18px + (28 - 18) * ((100vw - 320px) / (1920 - 320)));
          font-weight: 500;
          margin-bottom: 20px;
          color: $dark;
        }

        .btn {
          font-size: 14px;
          padding: 10px 15px;
        }
      }
    }
  }
}

.modal-v-sec {
  &.fade {
    .modal-content {
      .close {
        position: absolute;
        right: 15px;
        top: 15px;
        font-weight: 700;
        z-index: 9;
        color: $white;
        opacity: 1;
      }

      iframe {
        width: 100%;
        height: calc(250px + (400 - 250) * ((100vw - 320px) / (1920 - 320)));
        border: transparent;
      }
    }

    .modal-dialog {
      max-width: 700px;
    }
  }
}


/*=====================
  30. Loader css start
==========================*/
.loader-wrapper {
  background-color: $white;
  margin: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;

  img {
    max-height: 130px;
  }
}

/*=====================
 31. masonory-banner css start
==========================*/
.masonory-banner {
  .masonary-banner-main {
    position: relative;

    .masonary-banner-contant {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: flex-end;
      text-align: center;
      padding: 50px;

      h2 {
        color: $theme-color2;
        text-transform: uppercase;
        margin-bottom: 15px;
        font-weight: 700;
      }

      h5 {
        color: $theme-color2;
        text-transform: uppercase;
        letter-spacing: 7px;
        background-color: $white;
        padding: 10px 20px;
        font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
      }

      .btn-rounded {
        text-transform: uppercase;
        margin-top: 25px;

        &:hover {
          background-color: $theme-color2;
        }
      }

      &.p-top {
        align-items: flex-start;
      }

      &.py-center {
        align-items: center;
      }

      &.p-bottom {
        align-items: flex-end;
      }


      &.p-left {
        justify-content: flex-start;
      }

      &.p-center {
        justify-content: center;
      }

      &.p-right {
        justify-content: flex-end;
      }
    }

    .masonory-banner-img {
      height: 450px;

      &.masonory-img1 {
        height: 915px;
      }
    }

    &.banner-style1 {
      .masonary-banner-contant {
        h2 {
          color: $white;
        }
      }
    }
  }
}

.masonary-banner-block {
  >div {
    &:first-child {
      margin-bottom: 15px;
    }
  }
}

.masonory-p1 {
  >div {
    &:nth-child(n+2) {
      padding-left: unset;

      >div {
        >div {
          &:nth-child(n+2) {
            margin-top: 15px;
          }
        }
      }
    }

    &:last-child {
      >div {
        >div {
          >div {
            >div {
              &:nth-child(n+2) {
                padding-left: unset;
              }
            }
          }
        }
      }
    }
  }
}


/*========================
 32. sale-banenr css start
==========================*/
.sale-banenr {
  .sale-banenr-contain {
    display: flex;
    padding-top: calc(30px + (150 - 30) * ((100vw - 320px) / (1920 - 320)));
    padding-bottom: calc(30px + (150 - 30) * ((100vw - 320px) / (1920 - 320)));

    &.p-right {
      justify-content: flex-end;
    }

    &.p-center {
      justify-content: center;
    }

    h4 {
      font-size: calc(18px + (36 - 18) * ((100vw - 320px) / (1920 - 320)));
      color: $theme-color2;
      font-weight: 400;
      font-family: $font-15;
      text-transform: capitalize;
      margin-bottom: 18px;
    }

    h2 {
      font-size: calc(30px + (81 - 30) * ((100vw - 320px) / (1920 - 320)));
      color: $theme-color1;
      font-weight: 700;
      font-family: $font-8;
      text-transform: uppercase;
      line-height: 1;
      margin-bottom: 11px;
    }

    h3 {
      font-size: calc(18px + (28 - 18) * ((100vw - 320px) / (1920 - 320)));
      letter-spacing: 0.05em;
      font-weight: 700;
      font-family: $font-8;
      text-transform: capitalize;
      margin-bottom: 20px;
    }
  }

  &.banner-style1 {
    h4 {
      font-size: calc(16px + (25 - 16) * ((100vw - 320px) / (1920 - 320)));
      font-weight: 600;
      font-family: $font-12;
      margin-bottom: 10px;
    }

    h2 {
      font-size: calc(40px + (90 - 40) * ((100vw - 320px) / (1920 - 320)));
      font-weight: 700;
      font-family: $font-7;
      text-transform: capitalize;
      line-height: 1;
      margin-bottom: 25px;
    }

    h3 {
      font-size: calc(16px + (25 - 16) * ((100vw - 320px) / (1920 - 320)));
      letter-spacing: 1px;
      font-weight: 600;
    }
  }

  &.banner-style2 {
    h4 {
      font-size: calc(18px + (30 - 18) * ((100vw - 320px) / (1920 - 320)));
      color: $theme-color2;
      font-weight: 500;
      font-family: $font-12;
      margin-bottom: 14px;
    }

    h2 {
      font-size: calc(28px + (65 - 28) * ((100vw - 320px) / (1920 - 320)));
      color: $theme-color1;
      font-family: $font-18;
      font-weight: 500;
      text-transform: capitalize;
      margin-bottom: 15px;
    }

    h3 {
      font-size: calc(16px + (30 - 16) * ((100vw - 320px) / (1920 - 320)));
      color: $theme-color2;
      font-weight: 500;
      font-family: $font-12;
    }
  }
}

/*========================
 33. video-banenr css start
==========================*/
.video-banner {
  position: relative;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($black, 0.5);
  }

  .video-banner-contain {
    height: 550px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    a {
      display: flex;
      justify-content: center;
      align-items: center;
      width: calc(35px + (50 - 35) * ((100vw - 320px) / (1920 - 320)));
      height: calc(35px + (50 - 35) * ((100vw - 320px) / (1920 - 320)));
      background-color: $theme-color2;
      border-radius: 50%;
      margin-left: auto;
      margin-right: auto;

      i {
        font-size: calc(14px + (22 - 14) * ((100vw - 320px) / (1920 - 320)));
        color: $white;
      }
    }

    h3 {
      text-transform: capitalize;
      color: $white;
      font-size: calc(18px + (26 - 18) * ((100vw - 320px) / (1920 - 320)));
      margin-top: 30px;
    }

    h2 {
      text-transform: uppercase;
      color: $white;
      font-weight: 700;
      font-family: $font-6;
      font-size: calc(22px + (60 - 22) * ((100vw - 320px) / (1920 - 320)));
      margin-top: 25px;
    }

  }
}

/*========================
 34. our-gallery css start
==========================*/
.gallery-block {
  >div {
    padding-right: 0;

    &:nth-child(n+2) {
      margin-top: 15px;
    }
  }
}

.our-gallery {
  .gallery-box {
    position: relative;
    margin-right: 15px;

    .gallery-contain {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba($theme-color1, 0.5);
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.5s ease;
      opacity: 0;
      visibility: hidden;

      i {
        font-size: calc(30px + (60 - 30) * ((100vw - 320px) / (1920 - 320)));
        color: $white;
        opacity: 0.8;
      }
    }

    &:hover {
      .gallery-contain {
        visibility: visible;
        opacity: 1;
      }
    }
  }
}

/*========================
 35. our team css start
==========================*/
.team1 {
  .team-box {
    background-color: $white;
    margin-right: 15px;

    .img-wrraper {
      img {
        width: 100%;
      }
    }

    .team-detail {
      padding: 20px;

      h3 {
        font-weight: 600;
        text-transform: uppercase;
        font-size: calc(18px + (22 - 18) * ((100vw - 320px) / (1920 - 320)));
        color: $theme-color1;
        margin-bottom: 8px;
      }

      h5 {
        text-transform: capitalize;
        color: $black;        
        font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
      }

      p {
        line-height: 1.6;
        color: $dark;
        margin-bottom: 15px;
      }

      ul {
        display: flex;
        align-items: center;

        li {
          width: calc(28px + (30 - 28) * ((100vw - 320px) / (1920 - 320)));
          height: calc(28px + (30 - 28) * ((100vw - 320px) / (1920 - 320)));
          border-radius: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: all 0.5s ease;

          &:hover {
            background-color: $theme-color2;
          }

          a {
            i {
              color: $white;
              font-size: 90%;
            }
          }

          &:nth-child(n+2) {
            margin-left: calc(5px + (10 - 5) * ((100vw - 320px) / (1920 - 320)));
          }
        }
      }
    }
  }
}

.team2 {
  .team-box {
    background-color: $white;
    margin-right: 15px;

    .img-wrraper {
      img {
        width: 100%;
      }
    }

    .team-detail {
      padding: calc(15px + (20 - 15) * ((100vw - 320px) / (1920 - 320)));
      text-align: center;
      border-bottom: 2px solid $theme-color1;

      h3 {
        font-weight: 600;
        text-transform: uppercase;
        font-size: calc(18px + (22 - 18) * ((100vw - 320px) / (1920 - 320)));
        color: $theme-color1;
        margin-bottom: 5px;
      }

      h5 {
        text-transform: capitalize;
        color: $black;
        margin-bottom: 10px;
        font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
      }

      ul {
        display: flex;
        align-items: center;
        justify-content: center;

        li {
          width: calc(28px + (30 - 28) * ((100vw - 320px) / (1920 - 320)));
          height: calc(28px + (30 - 28) * ((100vw - 320px) / (1920 - 320)));
          border-radius: 5px;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: all 0.5s ease;

          &:hover {
            background-color: $theme-color2;
          }


          a {
            i {
              color: $white;
              font-size: 90%;
              line-height: 1.8;
            }
          }

          &:nth-child(n+2) {
            margin-left: 5px;
          }
        }
      }
    }
  }
}


/*========================
 36. counterbanner css start
==========================*/

.counter-banner {
  background-color: $theme-color1;
  padding-bottom: calc(30px + (100 - 30) * ((100vw - 320px) / (1920 - 320)));
  padding-top: calc(30px + (100 - 30) * ((100vw - 320px) / (1920 - 320)));

  .counter-box {
    text-align: center;
    position: relative;
    z-index: 1;

    svg {
      width: calc(30px + (70 - 30) * ((100vw - 320px) / (1920 - 320)));
      height: calc(30px + (70 - 30) * ((100vw - 320px) / (1920 - 320)));
      fill: $white;
      margin-bottom: calc(5px + (15 - 5) * ((100vw - 320px) / (1920 - 320)));
    }

    h1 {
      color: $white;
      font-size: calc(18px + (45 - 18) * ((100vw - 320px) / (1920 - 320)));
      line-height: 1;
      font-family: $font-6;

      span {
        margin-right: calc(5px + (10 - 5) * ((100vw - 320px) / (1920 - 320)));
      }
    }

    h3 {
      color: $white;
      text-transform: capitalize;
      font-weight: 600;
      font-size: calc(14px + (24 - 14) * ((100vw - 320px) / (1920 - 320)));
    }
  }

  &.counter-layer {
    position: relative;

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba($black, 0.5);
    }
  }

  &.banner-style1 {
    .counter-box {
      h1 {
        color: $theme-color1;
      }

      h3 {
        color: $theme-color2;
      }
    }
  }
}


/*============================
 37. pricing table css start
=============================*/
.price-block {
  >div {
    padding-left: 0;
    padding-right: 0;
  }
}

.pricing-table1 {
  .pricing-box {
    border: 1px solid $light-border;
    border-radius: 5px;
    background-color: $white;
    margin-right: 15px;

    .pricing-header {
      background-color: $theme-color1;
      padding: calc(15px + (20 - 15) * ((100vw - 320px) / (1920 - 320)));
      text-align: center;

      h2 {
        text-transform: uppercase;
        font-size: calc(16px + (22 - 16) * ((100vw - 320px) / (1920 - 320)));
        color: $white;
        font-weight: 700;
        line-height: 1;
      }
    }

    .pricing-body {
      padding: calc(20px + (30 - 20) * ((100vw - 320px) / (1920 - 320)));

      h1 {
        text-transform: uppercase;
        color: $theme-color1;
        text-align: center;
        font-family: $font-6;
        font-size: calc(30px + (55 - 30) * ((100vw - 320px) / (1920 - 320)));
        padding-top: 30px;
        padding-bottom: 30px;
        background-color: rgba($theme-color1, 0.1);

        sub {
          font-size: 28%;
        }
      }

      ul {
        margin-top: calc(10px + (20 - 10) * ((100vw - 320px) / (1920 - 320)));

        li {
          display: block;
          padding-bottom: calc(5px + (10 - 5) * ((100vw - 320px) / (1920 - 320)));
          margin-bottom: calc(5px + (10 - 5) * ((100vw - 320px) / (1920 - 320)));
          padding-left: 25px;
          position: relative;
          border-bottom: 1px solid $light-border;

          &::before {
            content: "\f00c";
            position: absolute;
            top: 0;
            left: 0;
            font-family: $font-awesome;
            color: $theme-color1;
          }

          a {
            color: $dark;
            font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));            
            line-height: 1;
          }

          &:last-child {
            margin-bottom: unset;
          }
        }
      }

      .btn {
        margin-top: calc(18px + (25 - 18) * ((100vw - 320px) / (1920 - 320)));
        text-transform: uppercase;
      }
    }
  }
}

.pricing-table2 {
  .pricing-box {
    border: 1px solid $light-border;
    border-radius: 5px;
    background-color: $white;
    margin-right: 15px;

    .pricing-header {
      background-color: $theme-color1;
      text-align: center;
      padding-top: calc(15px + (35 - 15) * ((100vw - 320px) / (1920 - 320)));
      padding-bottom: calc(15px + (35 - 15) * ((100vw - 320px) / (1920 - 320)));
      position: relative;
      z-index: 1;

      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba($black, 0.5);
        z-index: -1;
      }

      h3 {
        color: $white;
        font-size: calc(16px + (24 - 16) * ((100vw - 320px) / (1920 - 320)));
        margin-bottom: calc(5px + (10 - 5) * ((100vw - 320px) / (1920 - 320)));
        text-transform: uppercase;
      }

      h2 {
        text-transform: uppercase;
        font-size: calc(20px + (40 - 20) * ((100vw - 320px) / (1920 - 320)));
        color: $white;
        font-weight: 700;
        line-height: 1;
        font-weight: 700;
        font-family: $font-6;
      }
    }

    .pricing-body {
      padding: calc(20px + (30 - 20) * ((100vw - 320px) / (1920 - 320)));

      ul {
        li {
          display: block;
          padding-bottom: calc(5px + (10 - 5) * ((100vw - 320px) / (1920 - 320)));
          margin-bottom: calc(5px + (10 - 5) * ((100vw - 320px) / (1920 - 320)));
          padding-left: 25px;
          position: relative;
          border-bottom: 1px solid $light-border;

          &::before {
            content: "\f00c";
            position: absolute;
            top: 0;
            left: 0;
            font-family: $font-awesome;
            color: $theme-color1;
          }

          a {
            color: $dark;
            font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
            font-weight: 800;
            line-height: 1;
          }

          &:last-child {
            margin-bottom: unset;
          }
        }
      }

      .btn {
        margin-top: calc(18px + (25 - 18) * ((100vw - 320px) / (1920 - 320)));
        text-transform: uppercase;
      }
    }
  }
}

.pricing-table3 {
  .pricing-box {
    border: 1px solid $light-border;
    border-radius: 5px;
    background-color: $white;
    margin-right: 15px;
    margin-left: 15px;

    .pricing-header {
      text-align: center;
      padding: calc(15px + (30 - 15) * ((100vw - 320px) / (1920 - 320)));
      background-color: $theme-color2;

      svg {
        width: calc(40px + (60 - 40) * ((100vw - 320px) / (1920 - 320)));
        fill: rgba($white, 0.7);
        margin-bottom: 5px;
      }

      h2 {
        text-transform: uppercase;
        font-size: calc(18px + (24 - 18) * ((100vw - 320px) / (1920 - 320)));
        color: rgba($white, 0.7);
        font-weight: 700;
        line-height: 1;
        text-transform: uppercase;
        letter-spacing: 0.05em;
      }

      h4 {
        color: $black;
        font-size: calc(12px + (14 - 12) * ((100vw - 320px) / (1920 - 320)));
        text-transform: uppercase;
      }
    }

    .pricing-body {
      padding-top: calc(20px + (30 - 20) * ((100vw - 320px) / (1920 - 320)));
      padding-bottom: calc(20px + (30 - 20) * ((100vw - 320px) / (1920 - 320)));

      h3 {
        text-align: center;
        color: $theme-color2;
        line-height: 1;
        font-size: calc(30px + (40 - 30) * ((100vw - 320px) / (1920 - 320)));

        span {
          font-size: 42%;
        }
      }

      h4 {
        text-align: center;
        color: $black;
        text-transform: capitalize;
      }

      ul {
        padding-top: calc(20px + (30 - 20) * ((100vw - 320px) / (1920 - 320)));

        li {
          display: block;
          padding-top: calc(8px + (10 - 8) * ((100vw - 320px) / (1920 - 320)));
          padding-bottom: calc(8px + (10 - 8) * ((100vw - 320px) / (1920 - 320)));
          position: relative;
          text-align: center;
          line-height: 1;

          &:nth-child(odd) {
            background-color: rgba($gray1, 0.5);
          }

          a {
            color: $dark;
            font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
            font-weight: 500;
            line-height: 1;
          }

          &:last-child {
            margin-bottom: unset;
          }
        }
      }

      .btn {
        margin-top: calc(18px + (25 - 18) * ((100vw - 320px) / (1920 - 320)));
        text-transform: uppercase;
        transition: all 0.5s ease;
        width: fit-content;
        margin-left: auto;
        margin-right: auto;
        display: block;
        border-color: $theme-color2;

        &:hover {
          background-color: $theme-color2;
        }
      }
    }
  }
}

/*============================
 38. app donload  css start
=============================*/
.app-download {
  .app-download-main {
    .app-download-contian {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      height: calc(200px + (500 - 200) * ((100vw - 320px) / (1920 - 320)));

      h2 {
        font-size: calc(18px + (42 - 18) * ((100vw - 320px) / (1920 - 320)));
        color: $theme-color1;
        font-weight: 700;
        font-family: $font-3;
        text-transform: uppercase;
        margin-bottom: 14px;
      }

      h3 {
        font-size: calc(14px + (27 - 14) * ((100vw - 320px) / (1920 - 320)));
        color: $dark;
        font-weight: 600;
        font-family: $font-3;
        text-transform: capitalize;
        margin-bottom: 23px;
      }

      .btn {
        text-transform: uppercase;
      }
    }
  }
}

/*============================
 39. key-feature  css start
=============================*/
.key-block {
  >div {
    padding-left: 8px;
    padding-right: 8px;

    &:nth-child(n+7) {
      margin-top: 15px;
    }
  }
}

.key-feature {
  .key-feature-contain {
    border-radius: 5px;
    overflow: hidden;
    margin-right: 15px;
  }
}



/*===========
 40. invoice
=============*/
.invoice-popup {
  position: relative;
  background-color: $white;
  min-height: 680px;
  padding: 30px;
}

table {
  &.invoice-table {
    width: 100%;
    border-collapse: collapse;

    td {
      padding: 15px;
      font-weight: 700;

      h3 {
        font-weight: 700;
        font-size: 16px;
      }
    }

    th {
      padding: 15px;
      white-space: nowrap;
      font-weight: 600;
      font-size: 16px;
    }
  }
}

.print-bar {
  margin-top: 30px;

  button {
    line-height: 1;
    border: none;
    background-color: rgba($theme-color1, 0.8);
    transition: all 0.5s ease;

    &:focus {
      box-shadow: none;
      border: none;
      outline: none;
    }

    &:focus {
      box-shadow: none;
      border: none;
      outline: none;
    }

    &:hover {
      background-color: rgba($theme-color1, 1);
    }
  }

  .printbar-left {
    button {
      background-color: rgba($theme-color1, 0.5);

      &:hover {
        background-color: rgba($theme-color1, 1);
      }
    }
  }

  .printbar-right {
    text-align: right;
  }
}



.theme-invoice {
  padding-top: 30px;
  padding-bottom: 30px;

  .invoice-popup {
    .invoice-header {
      padding-bottom: 30px;

      .header-right {
        float: right;
        margin-bottom: 5px;

        ul {
          li {
            display: block;

            h2 {
              color: $theme-color1;
              font-weight: 700;
              font-size: 24px;
            }
          }
        }
      }
    }

    .invoice-breadcrumb {
      margin-bottom: 30px;
      padding: 20px;
      background-color: rgba($theme-color1, 0.05);

      ul {
        li {
          display: block;
          color: $theme-color1;
          font-weight: 600;

          span {
            color: $dark;
            margin-left: 5px;
            font-size: 14px;
            font-weight: 400;
          }
        }
      }

      .breadcrumb-right {
        float: right;
      }
    }

    table {
      &.invoice-table {
        td {
          h3 {
            color: $black;
            text-transform: capitalize;
          }
        }

        th {
          background-color: rgba($gray-5, 0.3);
          color: $dark;
          text-transform: uppercase;
        }

        tbody {
          tr {
            td {
              border: 2px solid $white;
              background-color: rgba($gray-5, 0.3);
            }
          }
        }

        tfoot {
          tr {
            &:first-child {
              td {
                padding-bottom: 0;
                padding-top: 30px;
              }
            }

            &:last-child {
              td {
                &:nth-child(n+2) {
                  background-color: rgba($theme-color1, 0.1);
                  color: $theme-color1;
                }
              }
            }
          }
        }
      }
    }

    .print-bar {
      margin-top: 30px;
    }
  }
}

.invoice-second {
  padding-top: 30px;
  padding-bottom: 30px;

  .invoice-popup {
    .invoice-header {
      border-bottom: 2px solid rgba($theme-color1, 0.5);
      padding-bottom: 20px;

      .header-right {
        display: flex;
        justify-content: flex-end;

        ul {
          width: fit-content;

          li {
            display: flex;
            align-items: center;
            border: 1px solid rgba($theme-color1, 0.5);
            padding-right: 10px;

            &:nth-child(n+2) {
              margin-top: 5px;
            }

            span {
              width: 40px;
              height: 40px;
              display: flex;
              align-items: center;
              justify-content: center;
              background-color: rgba($theme-color1, 0.5);
              margin-right: 10px;

              i {
                color: $white;
                font-size: 20px;
              }
            }
          }
        }
      }
    }

    .invoice-breadcrumb {
      padding-top: 20px;
      padding-bottom: 20px;

      .breadcrumb-left {
        ul {
          li {
            display: block;

            h5 {
              color: $dark;
              text-transform: uppercase;
            }

            h3 {
              color: $theme-color1;
              text-transform: uppercase;
              font-size: 22px;
            }

            h4 {
              font-size: 14px;
              color: $dark;
              font-weight: 400;
              margin-top: 7px;
              span {
                font-weight: 600;
              }
            }
          }
        }
      }

      .breadcrumb-right {
        display: flex;
        justify-content: flex-end;

        ul {
          li {
            display: block;

            h4 {
              font-weight: 500; 
              font-size: 14px;
              margin-bottom: 7px;
              span {
                color: $theme-color1;
                font-weight: 700;
               font-size: 16px;
               padding-right: 3px;
              }
            }
          }
        }
      }
    }

    table {
      &.invoice-table {
        td {
          font-weight: 700;

          h3 {
            color: $black;
          }
        }

        th {
          background-color: rgba($theme-color1, 0.8);
          color: $white;
          text-transform: uppercase;
        }

        tbody {
          tr {
            td {
              border: 2px solid $white;
              background-color: rgba($theme-color1, 0.05);
              text-transform: capitalize;
            }
          }
        }

        tfoot {
          tr {
            &:first-child {
              td {
                padding-bottom: 0;
                padding-top: 30px;
              }
            }

            &:last-child {
              td {
                &:nth-child(n+2) {
                  background-color: rgba($theme-color1, 0.8);
                  color: $white;
                }
              }
            }
          }
        }
      }
    }
  }
}

.invoice-third {
  padding-top: 30px;
  padding-bottom: 30px;

  .invoice-popup {
    padding: 0;

    .invoice-header {
      padding: 30px;
      background-color: rgba($theme-color1, 0.05);

      .header-right {
        display: flex;
        justify-content: flex-end;

        ul {
          display: flex;

          li {
            display: flex;
            align-items: center;
            padding-bottom: 15px;
            border-bottom: 2px solid $theme-color1;

            &:nth-child(n+2) {
              margin-left: 15px;
            }

            span {
              width: 45px;
              height: 45px;
              background-color: $theme-color1;
              display: inline-grid;
              align-items: center;
              justify-content: center;
              margin-right: 10px;
              border-bottom-left-radius: 15px;
              border-top-right-radius: 15px;

              i {
                font-size: 18px;
                color: $white;
              }
            }

            h6 {
              font-size: 14px;
            }
          }
        }
      }
    }

    .invoice-breadcrumb {
      padding-left: 30px;
      padding-right: 30px;
      padding-bottom: 30px;
      padding-top: 30px;

      ul {
        li {
          display: block;
        }
      }

      .breadcrumb-left {
        ul {
          li {
            position: relative;
            font-size: 16px;
            font-weight: 500;

            h2 {
              font-weight: 600;
              text-transform: uppercase;
              font-size: 20px;
              color: $theme-color1;
            }

            &:nth-child(n+2) {
              margin-top: 5px;
              padding-left: 25px;
            }

            svg {
              width: 16px;
              fill: $theme-color1;
              position: absolute;
              top: 4px;
              left: 0;
            }
          }
        }
      }

      .breadcrumb-right {
        display: flex;
        justify-content: flex-end;

        ul {
          li {            
            font-size: 14px;
            span {
              color: $theme-color1;
              font-weight: 700;
              font-size: 16px;
            }
          }
        }
      }
    }

    .table-responsive-md {
      padding-left: 30px;
      padding-right: 30px;
      padding-bottom: 30px;

      table {
        &.invoice-table {
          td {
            font-weight: 600;

            h3 {
              color: $black;
            }
          }

          th {
            background-color: $gray-5;
            text-transform: uppercase;
          }

          tbody {
            td {
              border: 1px solid $gray-5;
              text-transform: capitalize;
            }
          }

          tfoot {
            tr {
              &:last-child {
                td {
                  &:nth-child(n+2) {
                    background-color: rgba($theme-color1, 0.8);
                    color: $white;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.invoice-four {
  padding-top: 30px;
  padding-bottom: 30px;

  .invoice-popup {
    .invoice-header {
      .header-left {
        .brand-logo {
          margin-bottom: 10px;
        }

        .invoice-to {
          ul {
            li {
              display: block;

              h2 {
                text-transform: capitalize;
                color: $dark;

              }

              h3 {
                font-size: 20px;
                text-transform: uppercase;
                color: $theme-color1;
              }
            }
          }
        }
      }

      .header-right {
        display: flex;
        justify-content: flex-end;

        ul {
          width: fit-content;

          li {
            display: flex;
            align-items: center;
            border: 1px solid rgba($theme-color1, 0.5);
            padding-right: 10px;

            &:nth-child(n+2) {
              margin-top: 5px;
            }

            span {
              width: 40px;
              height: 40px;
              display: flex;
              align-items: center;
              justify-content: center;
              background-color: rgba($theme-color1, 0.5);
              margin-right: 10px;

              i {
                color: $white;
                font-size: 20px;
              }
            }
          }
        }
      }

      .header-contain {
        background-color: rgba($theme-color1, 0.08);

        .invoice-text {
          font-size: 45px;
          font-weight: 600;
          line-height: 1;
          text-transform: uppercase;
          width: fit-content;
          margin-left: auto;
          background-color: $white;
          color: rgba($theme-color1, 0.5);
          margin-right: 120px;
          margin-top: 30px;
          padding-left: 15px;
          padding-right: 15px;
        }
      }

    }

    .invoice-breadcrumb {
      margin-top: 30px;

      .invoice-contain {
        ul {
          display: flex;
          align-items: center;
          justify-content: space-between;

          li {
            width: 25%;
            text-transform: capitalize;
            font-weight: 600;
            border: 1px solid rgba($gray-5, 0.8);

            h6 {
              background-color: rgba($gray-5, 0.8);
              font-size: 16px;
              font-weight: 700;
            }

            h6,
            h5 {
              padding: 12px;
            }
          }
        }
      }
    }

    .table-responsive-md {
      .invoice-table {
        margin-top: 30px;

        th {
          background-color: rgba($theme-color1, 0.8);
          color: $white;
          text-transform: uppercase;
        }

        td {
          border: 1px solid rgba($theme-color1, 0.8);

          h3 {
            text-transform: capitalize;
          }
        }
        p{
          font-weight: 200;
        }
      }

      .invoice-total {
        margin-top: 30px;

        ul {
          li {
            display: block;
          }
        }

        .total-left {
          ul {
            li {
              h4 {
                text-transform: uppercase;
                color: $theme-color1;
              }

              h3 {
                font-size: 16px;
                color: $dark;
                text-transform: capitalize;
              }
            }
          }
        }

        .total-right {
          ul {
            li {
              color: $dark;
              font-weight: 700;
              text-transform: capitalize;
              padding-top: 7px;
              padding-bottom: 7px;
              padding-left: 15px;
              padding-right: 15px;

              span {
                float: right;
                font-weight: 400;
              }

              &:nth-child(2) {
                border-top: 1px solid $gray-5;
              }

              &:last-child {
                background-color: rgba($theme-color1, 0.8);
                color: $white;
                font-weight: 700;
                border-radius: 30px;
                padding-left: 20px;
                padding-right: 20px;
                padding-top: 10px;
                padding-bottom: 10px;

                span {
                  font-weight: 700;
                  text-transform: uppercase;
                }
              }
            }
          }
        }
      }
    }
  }
}

.invoice-five {
  padding-top: 30px;
  padding-bottom: 30px;

  .invoice-popup {
    padding: 0;
    border-top: 30px solid rgba($theme-color1, 0.8);
    border-bottom: 30px solid rgba($theme-color1, 0.8);

    .invoice-header {
      margin-left: 30px;
      margin-right: 30px;
      padding-top: 25px;
      padding-bottom: 25px;
      margin-bottom: 30px;
      border-bottom: 5px solid rgba($theme-color1, 0.8);

      .header-left {
        .brand-logo {
          margin-bottom: 10px;
        }

        .invoice-to {
          ul {
            li {
              display: block;

              h3 {
                font-size: 20px;
                text-transform: uppercase;
                color: $theme-color1;
              }
            }
          }
        }
      }

      .header-right {
        display: flex;
        justify-content: flex-end;

        ul {
          li {
            display: flex;
            align-items: center;

            span {
              width: 40px;
              height: 40px;
              border-top-left-radius: 10px;
              border-bottom-right-radius: 10px;
              background-color: rgba($theme-color1, 0.8);
              display: flex;
              justify-content: center;
              align-items: center;
              margin-right: 10px;

              i {
                font-size: 18px;
                color: $white;
              }
            }

            &:nth-child(n+2) {
              margin-top: 5px;
            }
          }
        }
      }
    }

    .invoice-breadcrumb {
      padding-left: 30px;
      padding-right: 30px;

      ul {
        display: flex;

        li {
          display: flex;
          justify-content: center;
          background-color: rgba($theme-color1, 0.08);
          padding: 30px;
          width: 25%;
          border-top-left-radius: 30px;
          border-bottom-right-radius: 30px;

          h6 {
            text-transform: capitalize;
          }
          h5{
            color: $theme-color1;
            margin-top: 5px;
          }
          &:nth-child(n+2) {
            margin-left: 15px;
          }
        }
      }
    }

    .table-responsive-md {
      padding-left: 30px;
      padding-right: 30px;
      margin-top: 30px;
      margin-bottom: 30px;

      table {
        &.invoice-table {
          th {
            background-color: rgba($theme-color1, 0.8);
            color: $white;
            text-transform: uppercase;
          }
        }

        tbody {
          tr {
            &:nth-child(even) {
              background-color: rgba($theme-color1, 0.05);
            }
          }

          td {
            h3 {
              text-transform: capitalize;
            }
          }
        }

        tfoot {
          tr {
            td {
              &:nth-child(n+2) {
                background-color: rgba($theme-color1, 0.8);
                color: $white;
              }
            }
          }
        }
      }
    }
  }


}


/**=================
  Ajax Search start
====================**/


.ajax-search {
  &.form_search {
    overflow: unset;
  }

  .tt-menu {
    display: block;
    width: 100%;
    background-color: #fff;
    border: unset !important;
    box-shadow: 0 5px 10px rgba(0, 0, 0, .2);
    max-height: 350px;
    overflow: auto;
    top: 55px !important;
    border-radius: 5px;
    padding: 8px 0;
  }

  .tt-suggestion {
    padding: 0px 14px;
    font-size: 18px;
    line-height: 24px;
    cursor: pointer;
  }

  .man-section {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
  }

  .image-section {
    padding: 5px 0;

    img {
      width: 50px;

      filter: invert(0) !important;

    }
  }

  .description-section {
    width: 80%;

    h4 {
      margin: 0px;
      padding: 0px 7px;
      font-size: 17px;
      color: #222222;
      margin-top: 0;
      margin-bottom: 2px;
      text-transform: capitalize;
    }

    span {
      padding: 7px;
      font-size: 16px;
      color: #a09999;
    }
  }
}

/**=====================
    cookiebar css start
==========================**/

.cookie-bar {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: -100px;
  left: 0;
  width: 100%;
  background-color: #2d2a25;
  padding: 10px;
  transition: all 0.5s ease;
  z-index: 9;

  p {
    margin-bottom: 0;
    color: white;
  }

  .btn {
    margin-left: 20px;
  }

  &.show {
    bottom: 0;
    transition: all 0.5s ease;
  }

  &.left-bottom {
    width: 270px;
    display: block;
    text-align: center;
    left: 30px;
    padding: 35px 25px;
    box-shadow: 0 19px 38px rgba(0, 0, 0, 0.12), 0 15px 12px rgba(0, 0, 0, 0.13);
    background-color: white;
    bottom: 20px;
    display: none;
    animation: fadeInUp 1s;

    svg {
      width: 70px;
      margin-bottom: 10px;
    }

    p {
      margin-bottom: 14px;
      color: #777;
      line-height: 20px;
    }

    .btn {
      margin-left: 5px;
      margin-right: 5px;
    }

    .btn-close {
      position: absolute;
      right: 13px;
      top: 7px;

      i {
        color: #777;
      }
    }

    &.show {
      display: block;
    }
  }

  &.right {
    left: unset;
    right: 30px;
  }
}


/**===========================
    order tracking css start
==============================**/
.order-tracking {
  h4 {
    &.tracking-title {
      text-transform:capitalize;
      color:$dark;     
      margin-bottom: 10px;
      font-size:calc(16px + (20 - 16) * ((100vw - 320px) / (1920 - 320)));
    }
  }

  .order-tracking-contain {      
    .tracking-group {
      padding-bottom: 15px;
      &:nth-child(n+2) {
        border-top: 1px solid $light-border;
        padding-top: 15px;
      }
      .delevery-code {
        display: flex;
        align-items: center;
        justify-content: space-between;
        h4 {
          text-transform: capitalize;
          color: $dark;
          font-weight: 700;
        }
        .btn {
          padding: 10px 12px;
          font-size: 14px;
          font-weight: 500;
        }
      }
      ul {
        &.may-product {
          >li {
            padding: 10px;
            border: 1px solid $light-border;
            width: 100%;
            &:nth-child(n+2) {
              margin-top: 15px;
            }
            .media {
              align-items: center;
              width: 100%;
              position: relative;
              img {
                height: 200px;
              }
              .media-body {
                margin-left:10px;
                h3 {
                  text-transform: capitalize;
                  color: $dark;                 
                  font-size: calc(16px + (20 - 16) * ((100vw - 320px) / (1920 - 320)));
                  margin-bottom: 5px;
                }
                h4 {
                  color: $theme-color1;
                  margin-bottom: 5px;
                  span {
                    color: $gray-4;
                    text-decoration: line-through;
                    margin-left: 10px;
                  }
                }
                h6 {
                  margin-bottom: 5px;
                  text-transform: capitalize;
                  color: $dark;
                }
                .qty-box {
                  margin-bottom: 5px;
                }

              }
              .pro-add {
                position: absolute;
                top:0px;
                right:0px;
                a {
                  color: $dark;
                  svg {
                    width: 18px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .order-tracking-sidebar {   
    .coupan-block {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 15px;
      margin-bottom: 15px;
      border-bottom: 1px solid $light-border;
      h5 {
        display: flex;
        align-items: center;
        color: $dark;
        svg {
          width: 18px;
          margin-right: 10px;
        }
      }
      .btn {
        text-transform: capitalize;
        padding: 7px 18px;
        font-size: 14px;
        font-weight: 400;
      }
    }
    ul {
      li {
        display: block;
        text-transform: capitalize;
        font-size: 16px;
        line-height: 1;
        &:nth-child(n+2) {
          padding-top: 15px;
        }
        span {
          float: right;
        }
        .total {
          line-height: 1;
          padding-top: 15px;
          padding-bottom: 15px;
          border-top: 1px solid $light-border;
          font-weight: 700;
        }
      }
    }
  }
  .order-tracking-box {
    padding:calc(20px + (30 - 20) * ((100vw - 320px) / (1920 - 320)));
    background-color: $white;
    .btn{
      text-transform:capitalize;     
    }
    .form-control{
      border-color: $light-border;
      font-size:14px;
      &:focus {
        box-shadow: none;
      }
    }
    .form-group {
      display: flex;
      .form-control {
        width: 100%;
        height: 50px;
        padding-left: 15px;
        padding-right: 15px;
        text-transform: capitalize;
        border-color: $light-border;
        &:focus {
          box-shadow: none;
        }

        &:nth-child(n+2) {
          margin-left: 15px;
        }
      }
      ul{
        .btn{
          padding:8px 10px;
          font-weight:400;
        }
        &.addresh-locat{
          margin-bottom:10px;   
          li{
            text-transform: capitalize;
            font-weight:700;
            &:nth-child(n+2){
              margin-left:10px;
            }
          }     
        }
      }
      
    }
    .card{
      border-color:rgba($theme-color1,0.1);    
      &:nth-child(n+2){
        margin-top:15px;
      }
      .card-header{
        padding: 0;
        border-color:rgba($theme-color1,0.1);       
        .payment-toggle{
          text-transform:uppercase;
          color:$theme-color1;
          font-weight:700;
          text-decoration:none;
          padding:15px ;      
          font-size:14px ;   
          background-color:rgba($theme-color1,0.1);
          border-radius:5px;
          width: 100%;
          text-align: left;
          line-height:1;
        }
      }
    }
    .paymant-collapce{
      padding:30px;   
      &.show{
        border-bottom: 1px solid rgba($theme-color1,0.1);    
      }  
      ul{
        &.upi-pay{          
          li{
            display: flex;
            align-items: center;  
            >div{
              &:nth-child(n+2){
                display: flex;
                align-items: center;  
                margin-left: auto;
              }
            }
            &:nth-child(n+2){
              margin-top:10px;
            }  
            img{
              width:45px;
            }                  
            span{
              margin-left:15px;
              text-transform: capitalize;
              font-weight:700;
              font-size:calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
            }
            .form-control{
              width:250px;
              border:1px solid $light-border;
              border-radius: 5px;
              height:40px;
              padding-left: 15px;
              padding-right: 15px;
              text-transform: capitalize;              
              &:focus{
                border-color: $light-border;
                box-shadow: none;
              }
            }
            .btn{
              margin-left:15px;
            }
          }
        }
        &.bank-pay{
          margin-bottom:15px;
          display: flex;
          li{
            display:block;
            text-transform:capitalize;
            font-size:calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
            font-weight:700;
            text-align: center;           
            width: 25%;
            padding-top:40px;
            padding-bottom:40px;
            border-radius:5px;
            background-color:rgba($theme-color1,0.05);
            img{
              width:50px;
            }
            span{             
              display: block;
              line-height:1;
              margin-top:10px;
              color: $dark;
            }
            &:nth-child(n+2){
             margin-left:30px;
            }
          }
        }
      }
      select{
        text-transform:uppercase;
      }
      .cash-pay{
        .form-control{
          width:320px;
          height:50px;
          padding-left:20px;
          padding-right:20px;
        }
        .btn{
          margin-top: 15px;
        }           
        .errorcaptcha {
          color:$red;
          font-size: 12px;
          display: none;
        }
        .successmessage {
          color:$light-green;
          font-size: 18px;
          margin-bottom: 15px;
          display: none;
        }
        .captchabox {
          display: flex;         
          align-items: center;
          margin-top: 15px;
          .captchaimagecode {                                                   
            width: 150px;
            canvas{
              width:100%;
            }
          }
          .reloadbtncapcha{
            margin-left:10px;
            svg{
              width:18px;
              fill:$black;
            }
            &:hover{
              animation:rotationxy 2s 0.5s alternate ease  1;
            }           
          }
        }             
      }
    }
  } 
  fieldset{
    >.btn{
      margin-top:20px;   
    }
  }
}
.order-tracking {
  #msform fieldset:not(:first-of-type) {
    display: none
  }
  #progressbar {
    margin-bottom: 30px;
    overflow:hidden;   
    display:flex;
    justify-content:center;  
    width:fit-content;
    position:relative;
    background-color: #fff;
    width: 100%;
    padding: 30px;    
    li {     
      font-size:12px;               
      position:relative;           
      text-align:center;
      &:nth-child(n+2){
        margin-left:130px;
      }
      &:last-child{       
        z-index: 1;
        width: auto;
      }   
      .icon {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background-color: rgba($theme-color1,0.5);
        display: flex;
        justify-content: center;
        align-items: center;   
        position:relative;    
        z-index:2;
        margin-left:auto;
        margin-right:auto;
        margin-bottom: 10px;
        svg {
          width: 50%;
          fill: $white;
        }
      }
      span{
        color:$dark;
        font-weight:700;
        text-transform:capitalize;
        font-size:16px;
      }
      &.active{
        &:after,&:before{
          background-color:$theme-color1;
          width:100%;         
          border-radius:50px;
        }
        .icon{
          background-color:$theme-color1;
        }
        span{
          color:$theme-color1;
        }
      }     
    }
  }
}