@media (min-width: 768px) {
  .p-r-md-0{
    padding-right: 0;
  }
  .p-l-md-0 {
    padding-left: 0;
  }

}
@media(min-width:1470px) {
  .custom-container {
    max-width: 1440px;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }
}

@media(min-width:1680px){
  .custom-container {
    max-width: 1650px;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }
  .container {
    max-width: 1110px;
  }

}

@media(max-width: 1679px){

  // collection bannner 
  .category-header .navbar-menu .category-right .contact-block span{
    font-size: 0;
  }
  .gift-dropdown.dropdown-menu {
    width: 300px;
    right: -80px;
    left: unset !important;
  }
  .layout-header1 ~ .category-header .gift-dropdown.dropdown-menu {
    right: 20px;
  }
  .category-header .navbar-menu .category-left .nav-block .nav-left .nav-cat li img {
    height: 43.5px;
  }

  



  .theme-slider{
    .offset-xl-2{
      margin-left: 270px;
      max-width: 64.5%;
    }
    .slider-banner {
      .layout2-slide-1  {
        li {
          width: 600px;
        }
      }
      .layout2-slide-2  {
        li {
          width: 400px;
        }
      }
      .layout2-slide-3  {
        li {
          width: 400px;
        }
      }
      &.slide-banner-3  {
        .layout4-slide-1  {
          li {
            right: 50px;
          }
        }
        .layout4-slide-2  {
          li {
            right: 70px;
          }
        }
        .layout4-slide-3  {
          li {
            right: 70px;
          }
        }
      }
      &.slide-banner-2  {
        .layout3-slide-1  {
          li {
            width: 350px;
            &:last-child {
             width: 400px;
            }
          }
        }
        .layout3-slide-2  {
         li {
           left: 40px;
           width: 300px;
           &:last-child {
             right: 40px;
             width: 350px;
           }
         }
        }
      }
      &.slide-banner-4  {
        .layout5-slide-1 {
          li {
            width: 50%;
          }
        }
        .layout5-slide-2 {
          li {
            width: 45%;
          }
        }
        .layout5-slide-3 {
          li {
            width: 50%;
          }
        }
      }
    }
  }
  .collection-banner{
    .offset-xl-2{
      margin-left: 270px;
      max-width: 31.2%;
    }
  }
  .offer-banner-img{
    height: 100%;
    background-color: $theme-color1;
    display: flex;
    align-items: center;
  }
  .collection-banner {
    .collection-banner-main{
      &.banner-7 {
        .collection-banner-contain {
          padding: 50px;
          h3 {
            font-size: 22px;
          }
          h4 {
            font-size: 30px;
          }
        }
      }
      &.banner-8 {
        .collection-banner-contain  {
          h3 {
            font-size: 18px;
          }
          h1 {
            font-size: 40px;
          }
          h6 {
            display: none;
          }
        }
      }
      &.banner-2 {
        .collection-img {
          height: 190px;
        }
      }
      &.banner-3 {
        .collection-img {
          height: 395px;
        }
      }
      &.banner-4 {
        .collection-img {
          height: 190px;
        }
      }
      .collection-img {
        height: 235px;
      }
      &.banner-style5 {
        .collection-banner-contain {
          h3{
            margin-bottom: 11px;
          }
          h4{
            letter-spacing: 0.05em;
            margin-bottom: 11px;
          }
        }
      }
      &.banner-style7 {
        .collection-banner-contain{
          background-color: rgba($black,0.2);
          h3{
            color: $white;
          }
          h4{
            color: $white;
          }
        }
      }

      &.banner-style8{
        .collection-banner-contain{
          background-color: rgba($black,0.5);
          h3{
            color: $theme-color1;
          }
          h4{
            color: $theme-color2;
          }
        }
      }
    }
  }
  


  

  // home slider  //
  .farming-slide {
    .animat-block {
      .animat1{
        width: 640px;
        height: auto;
      }
    }
  }

  .tools-slide {
    .slide-main {
      .slide-contain{
        p{
          width: 365px;
        }
      }
      .animat-block {
        .animat1{
          width: 640px;
        }
      }
    }
  }
  .grocery-slide {
    .slide-main {
      .slide-contain {
        h3{
          margin-bottom: 16px;
        }
        h2{
          margin-bottom: 13px;
        }
      }
      .animat-block {
        .animat1{
          width: 550px;
        }
      }
    }
  }
  
  //hot deal //
  .hotdeal-second {
    .hotdeal-box {
      .img-wrapper {
        a{
          height: 276px;
        }
      }
      .hotdeal-contain {
        ul{
          margin-bottom: 13px;
        }
        .timer2{
          margin-bottom: 20px;
        }
      }
    }
  }

  
  


  // instagram //
  .instagram {
    .insta-contant1 {
      .insta-sub-contant1 {
        .insta-title {
          padding: 18px 65px;
        }
      }
    }
  }

  // header //
  .icon-lable{
    width: 15px;
    height: 15px;
    font-size: 10px;
  }
  .top-header {
    .top-header-right {
      .top-menu-block {
        display: none;
      }
    }
  }
  .category-header {
    .navbar-menu {
      .category-left {
        width: 60%;
        .input-block {
          width: 100%;
        }
      }
      .category-right {
        .gift-block {
          margin-left: 20px;
        }
      }
    }
  }
  .category-header-2 {
    .navbar-menu {
      .category-right {
        .contact-block {
          display: none;
        }
      }
    }
  }
  .layout-header3 {
    .main-menu {     
      .btn-group{
        display: none;
      }        
    }
  }
  .category-header-4 {
    .navbar-menu {
      .category-right {
        display: none;
        .btn-group{         
          display: none;                  
        } 
      }
      .category-left  {
        .nav-block  {
          .nav-left  {
            .nav-cat  {
              li {
                padding-bottom: 17px;
              }
            }
          }
        }
      }
    }
  }
  .layout-4-collection {
    >div   {
      flex: 0 0  40%;
      max-width: 40%;
      &:last-child {
        display: none;
      }
    }
  }


  //slider //
  .theme-slider {
    .slider-banner {
      &.slide-banner-4  {
        .slider-img {
          height: 580px;
        }
      }
    }
  }

  // masonory-banner //
  .masonory-banner {
    .masonary-banner-main {
      .masonary-banner-contant {
        padding: 60px 20px;
        .masonary-banner-subcontant {
          margin-right: 20px;
        }
      }
    }
  }

  // hot deal //
  .hot-4 {
    >div{
      &:first-child {
        flex: 0 0 19%;
        max-width: 19%;
      }
      &:nth-child(2) {
        flex:0 0 62% ;
        max-width: 62%;
      }
      &:last-child {
        flex: 0 0 19%;
        max-width: 19%;
      }
    }
    .hot-deal  {
      .title1{
        padding-top: 35px;
        padding-left: 35px !important;
      }
      .hot-deal-contain  {
        padding: 35px;
      }
    }
    .media-banner {
      padding: 20px;
    }
  }


  

  // category page //
  .product-wrapper-grid.list-view  {   
    .product-box {
      .product-imgbox {
        width: 50%;
      }
    }   
  }


}

@media(max-width:1470px) {
  .custom-container {
    max-width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }

  // home slider //
  .theme-slider {
    .offset-xl-2{
      max-width: 64%;
    }
  }
  .cosmetic-slide {
    .slide-main {
       .slide-contain{
         height: 78vh;
       }
      .animat-block {
        .animat-img1{
          width: 40px;
        }
        .animat-img2{
          width: 30px;
        }
        .animat-img3{
          width: 200px;
        }
      }
    }
  }
  .furniture-slide {
    .slide-main {
      .slide-contain {
        h2{
          margin-bottom: 10px;
        }
        h3{
          margin-bottom: 5px;
        }
        p{
          width: 465px;    
          margin-bottom: 20px;
        }
      }
    }
    .animat-block{
      .animat1{
        width: 530px;
      }
      .animat2{
        width: 215px;
      }
      .animat3{
        width: 56px;
        top:-20px;
      }
      .animat4{
        width: 52px;
        top: -40px;
      }
    }
  }
  .farming-slide {
    .slide-main {
      .slide-contain {
        h3{
          margin-bottom: 10px;
        }     
      }
    }
    
    .animat-block {
      .animat1{
        width: 550px;
        right: 6%;
      }
      .animat2{
        height: 140px;
      }
      .animat3{
        height: 160px;
      }
    }
  } 
  .digitalmark-slide {
    .slide-main {
      background-position: 42% !important;
      .slide-contain {
        .sub-contain {
          width: 43%;
          p{
            width: 100%;
          }
          h2{
            margin-bottom: 15px;
          }
          .input-group{ 
            width: 400px;
            .form-control{
              height: 60px;
              padding-left: 23px;
              width: 75%;
            }
            .btn{
              padding: 13px 20px;
            }
          }
        }
      }
      .animat-block {
        .animat1{
          width: 40px;
          left: 28%;
          top:12% ;
        }
        .animat2{
          width: 70px;
          left: 3%;
        }
      }
    }
  }
  .kids-slide {
    .slide-main {
      .slide-contain {
        p{
          width: 45%;
        } 
      }
    }
    .animat-block {
      .animat1{
        width: 350px;
      }
      .animat2{
        width: 60px;
      }
      .animat3{
        width: 80px;
      }
      .animat4{
        width: 80px;
      }
      .animat5{
        bottom: -65px;
        width: 120px;
      }
    }
  }
  .tools-slide {
    .slide-main {     
      .animat-block {
        .animat1{
          right: 30px;
        }
      }
    }
  }
  .pets-slide {
    .slide-main{
      .slide-contain {
        .form-control{
          width: 420px;
        }
      }
      .animat-block {
        .animat1{
          width: 400px;
          left: 15px;
        }
        .animat2{
          width: 380px;
          right: 15px;
        }
      }
    }
  }
  .collection-banner {
    .offset-xl-2 {
      max-width: 30.66%;
    }
  }
  .grocery-slide{
    .slide-main {
      .animat-block {
        .animat1{
          width: 450px;
        }
      }
    }
  }
  .megastore-slide {
    .slide-main {
      .slide-contain {
        h4{
          margin-bottom: 14px;
        }
        h3{
          margin-bottom: 25px;
        }
      }
    }
  }


  // hotdeal //
  .hot-deal {
    .hot-deal-contain{
      .hot-deal-center{
        p{
          margin-top: 10px;
          margin-bottom: 10px;
        }
        .timer{
          margin-top: 20px;
          p{
            margin-top: 0;
          }
        }
        .btn{
          margin-top: 22px;
        }
      }
    }
  }


  //instagram //
  .instagram {
    .insta-contant1 {
      .insta-sub-contant1  {
        .insta-title {
          padding: 15px 50px;
        }
      }
    }
  }

  // collection banner //
  .collection-banner {
    .collection-banner-main {
      .collection-banner-contain {
        padding: 30px;
        h3 {
          font-size: 25px;
        }
        h4 {
          font-size: 25px;
        }
      }
      &.banner-1 {
        .collection-banner-contain {
          h3 {
            font-size: 22px;
            margin-bottom: 5px;
          }
          h4 {
            font-size: 32px;
          }
          .shop {
            margin-top:25px;
          }
        }
      }
      &.banner-2 {
        .collection-banner-contain {
          padding: 15px;
          h3 {
            font-size: 16px;
            margin-bottom: 10px;
          }
          h4 {
            font-size: 16px;
          }
          .shop {
            margin-top: 10px;
            a {
              font-size: 12px;
            }
          }
        }
      }
      &.banner-3 {
        .collection-banner-contain {
          h3 {
            font-size:30px;
          }
          h4 {
            margin-top: 10px;
            margin-bottom: 50px;
            font-size: 16px;
          }
        }
      }
      &.banner-4 {
        .collection-banner-contain {
          h3 {
            font-size: 18px;
          }
          h4 {
            font-size: 22px;
          }
        }
      }
      &.banner-5 {
        .collection-img {
          height: 240px;
        }
        .collection-banner-contain {
          h3 {
            font-size: 30px;
          }
          h4 {
            font-size: 60px;
          }
        }
      }
    }
  }
  .layout-4-collection {
    >div {
      flex: 0  0 39.5%;
      max-width: 39.5%;
    }
  }
  .collection-layout1 {
    >div {
      &:first-child {
        max-width: 31.5%;
      }
      &:nth-child(2) {
        max-width: 31%;
      }
    }
  }



  // sale banenr //
  .sale-banenr {
    .sale-banenr-contain {
      h2{
        margin-bottom: 17px;
      }
    }
    &.banner-style1 {
      h2{
        margin-bottom: 15px;
      }
      h3{
        margin-bottom: 15px;
      }
    }
  }


  // app download //
  .app-download {
    .app-download-main {
      .app-download-contian {
        h2{
          margin-bottom: 17px;
        }
        h3{
          margin-bottom: 25px;
        }
      }
    }
  }



  // slider //
  .theme-slider {
    .slider-banner {
      &.slide-banner-2 {
        .slider-banner-contain {
          .sub-contain {
            min-width: 320px;
            span {
              font-size: 22px;
            }
          }
        }
      }
      &.slide-banner-3  {
        .slider-img {
          height: 350px;
        }
      }
    }
  }
  .offer-banner {
    display: none;
  }
  .theme-slider  {
    .offset-xl-2 {
      max-width: calc(100% - 270px);
    }
  }

  // header //
  .category-header {
    .navbar-menu {
      .category-right  {
        .contact-block {
          span {
            font-size: 0;
            margin-left: 0;
          }
        }
        .gift-block {
          margin-left: 45px;
        }
      }
    }
  }
  .layout-header1 {
    .main-menu-block{
      .menu-left{
        .brand-logo{
          img{
            height: 50px;
          }
        }
      }
    }
  }
  .category-header-2 {
    .navbar-menu {
      .logo-block{
        .brand-logo{
          img{
            height: 50px;
          }
        }
      }
    }
  }
  .layout-header2 {
    .main-menu-block {     
      .brand-logo{
        img{
          height: 50px;
          width: auto;
        }
      }     
    }
  }
  .layout-header3 {
    .main-menu {     
      .input-block {
        .input-box {
          width: 450px;
          .big-deal-form {
            .input-group {
              select {
                background:  url("../images/layout-1/drop-1.png") no-repeat scroll 20px 18px;
              }
              height: 50px;
              span {
                display: none;
              }
            }
          }
        }
      }     
    }
  }
  .category-header-4 {
    .navbar-menu {     
      .input-block {
        width: 500px;
      }    
      .category-left{
        .logo-block{
          img{
            height:45px;
          }
        }
      }
    }
  }
  
  .header-search{
    width: 640px;
  .input-group{
    height: 50px;
    .form-control,.typeahead{
      height:50px;
    }   
    .input-group-text {
      select{
        padding-right: 30px;
        padding-left: 15px;
      }
    }   
  }

  &.search-style2 {
    .input-group{           
      .input-group-text {
        select{
          background: url(../images/dropdown2.png) no-repeat 90%;
          background-size: 18px;
        }
      }     
      .twitter-typeahead{
        width: calc(100% - 220px) !important;
      }
    }
  }

  }
  .header-search2{
    width: 640px;
    .input-group{
      height: 50px;
    }
  }
  .layout-header4 {
    .logo-block{
      img{
        height: 50px;
      }
    }
  }
  .header7 {
    .header-contain {
      .logo-block{
        .brand-logo{
          img{
            height: 50px;
            width: auto;
          }
        }
      }
    }
  }
  

  .category-header7 {
    .category-contain {
      .category-left {
        .brand-logo{
          img{
            width: auto;
            height: 50px;
          }
        }
      }             
    }
  }
  

  // services //
  .services1{
    &.border-services {
      .services-box{
        margin-right: 8px;
        margin-left: 8px;
      }
    }
    .service-block{
      >div{
        padding-right: 8px;
        padding-left: 8px;
      }
    }
    &.style2 {
      .services-box {
        .media {
          .icon-wrraper{
            margin-bottom: 17px;
          }
        }
      }
    }
    
  }


  // video banner //
  .video-banner {
    .video-banner-contain{
      height: 480px;
    }
  }

  // product box //
  .product-block2{
    >div{
      padding-left: 8px;
      padding-right: 8px;
      &:nth-child(n+2){
        margin-top: 15px;
      }
    }
  }
  .digipro-box{
    margin-left: 8px;
    margin-right: 8px;
  }

  


  //testimonial //
  .testimonial-block{
    >div{
      padding-right: 8px;
      padding-left: 8px;
    }
  }

  .testimonial6 {
    .testimonial-box{
      margin-right: 8px;
      margin-left: 8px;
    }
  }



  // pricing table //
  .price-block {
    > div{
      padding-right: 8px;
      padding-left: 8px;
    }
  }
  .pricing-table3 {
    .pricing-box{
      margin-left: 8px;
      margin-right: 8px;
    }
  }


// blog //

  .blog-block {
    >div{
      padding-right: 8px;
      padding-left: 8px;
    }
    .blog-contain{
      margin-left: 8px;
      margin-right: 8px;
    }
  }


  // theme modal start //
  .theme-modal{
    &.blackfriday-modal{
      &#exampleModal {
        .modal-body{
          height:400px;
        }
        .modal-dialog{
          max-width:700px;
        }
      }     
    }   
    &.cyber-monday{   
      &#exampleModal{    
        .modal-dialog{
          max-width:620px;
        }
      }
    }
  }



  /*=====================
     Inner pages CSS
==========================*/
  // inner pages //
  
  .collection-product-wrapper {
    .product-top-filter {
      .product-filter-content {
        .search-count {
          width:100%;
          border-left:1px solid $light-border;
          border-bottom: 1px solid $light-border;
          text-align: center;
        }
        .collection-view {
          width: 20%;
          padding-right: 0 !important;
          border-left:1px solid $light-border;
        }
        .collection-grid-view {
          padding: 20px !important;
          padding-left: 0 !important;
          width: 20%;
          ul {
            li {
              &:last-child {
                display: none;
              }
            }
          }
        }
        .product-page-filter {
          border-right: 1px solid $light-border;
          width: 30%;
        }
        .product-page-per-view {
          width: 30%;
        }
        
        &.horizontal-filter-mian{
          .horizontal-filter-toggle{
            width: 15%;
          }
          .collection-view{
            width:15%;
            border-left:none;
          }
          .collection-grid-view{
            width: 10%;
          }
        }
      }
      .popup-filter {
        .collection-view {
          width: 10%;
        }
        .product-page-per-view {
          width: 25%;
        }
      }
    }
    .product-wrapper-grid {
      .col-lg-3{
        .product-box {
          .product-detail {
            .icon-detail {
              a,button{
                width: 35px;
                height: 35px;
                svg{
                  width:16px;
                }
              }             
            }
          }
        }
      }
    }
  }
  .cart-section {
    .cart-buttons {
      > div {
        &:last-child {
          padding-right: 38px;
        }
      }
    }
    tbody {
      tr {
        td {
          min-width: 175px;
          .qty-box {
            .input-group {
              .form-control {
                width: 75px;
              }
            }
          }
        }
      }
    }
    tfoot {
      tr {
        td {
          padding-right: 41px;
        }
      }
    }
  }
  .product-right {
    .product-icon {
      .product-social {
        li {
          padding-right: 10px;
        }
      }
    }
  }
}
@media(max-width:1366px) {
  .theme-slider .slider-banner.slide-banner-1 .layout1-slide-3 li:nth-child(2) {
    right: 100px;
    bottom: -90px;
    left: unset;
  }
  .theme-slider {
    .slider-banner{
      &.slide-banner-1 {
        .layout1-slide-1,.layout1-slide-2,.layout1-slide-3 {
          li{
            &:nth-child(2){
              img{
                margin: 0 0 0 auto;
              }
            }

          }
        }
        div{
          li{
            img{
              width: 60%;
            }
          }
        }
      }
      &.slide-banner-5 {
        .layout6-slide-1  {
          li {
            width: 400px;
          }
        }
      }
      &.slide-banner-3 {
        .layout4-slide-1 {
          li {
            width:415px ;
            right: 20px;
          }
        }
        .layout4-slide-2 {
          li {
            width: 300px;
            right: 20px;
          }
        }
        .layout4-slide-3 {
          li {
            width: 350px;
            right: 20px;
          }
        }
      }
    }
  }

  

  // hot deal //
  .hot-deal  {
    &.hotdeal-third {
      .hot-deal-contain {
        .hotdeal-right-slick {
          .img-wrraper {
            > div{
              height: 360px;
            }
          }
        }
      }
    }
    .hot-deal-contain  {
      .hot-deal-center {
        .timer {
          span {
            font-size: 22px;
            min-width: 55px;
          }
        }
      }
    }
  }
  .hot-4 {
    
    
    .media-banner {
      .media-banner-box {
        .media {
          .media-body .media-contant {
            height: 87px;
          }
          img {
            border: 1px solid #ddd;
            width: 60px;
          }
        }
      }
    }

    .hot-deal {
      .title1{
        padding-top: 20px;
        padding-left: 20px !important;
      }
      .hot-deal-contain{
        padding: 20px;
      }
    }

  }
  .layout-4{
    &.hot-deal {
      .hot-deal-contain {
        .hotdeal-right-nav img{
          height: 100px;
        }
        .hot-deal-center{
          margin-left: 0;
          height: auto;
        }
      }
    }


  }
  .Jewellery-banner {
    .Jewellery-banner-contian{
      padding: 15px;
    }   
    h6 {
      font-size: 25px;
    }
  }
  .layout-3-hotdeal{
    .media-banner .media-banner-box .media {
      .media-body .media-contant .rating i {
        font-size: 11px;
      }
      img{
        width: 52px;
      }
    }
    .hot-deal .hot-deal-contain1 {
      padding: 21px;
      .hot-deal-subcontain .hot-deal-center .rating{
        margin: 0 auto;
      }
    }
    .media-banner {
      padding: 25px;
    }
  }
  .cart-hover-div {
    &:hover{
      .cart-show-div.right{
        right: 0;
      }
    }
    .cart-show-div.right{
      right: 0;
    }
  }
  .collection-banner .offset-xl-2 {
    max-width: 29.66%;
  }
  .layout-4-collection {
    >div {
      flex: 0 0 38%;
      max-width: 38%;
    }
  }
  .collection-layout1 {
    >div   {
      &:first-child {
        max-width: 29.5%;
      }
      &:nth-child(2){
        max-width: 29.5%;
      }
    }
  }
  .offer-banner{
    display: none;
  }
  .category-header  {
    .navbar-menu{
      .category-left {
        .nav-block {
          .nav-left {
            .nav-cat {
              li {
                padding-bottom: 7px;
              }
            }
          }
        }
      }
    }
    
  }
  .category-header-2 {
    .navbar-menu {
      .nav-block{
        min-width: 220px;       
        .nav-left{
          width: 220px;
        }       
      }
      .icon-block {
        ul {
          li{
            &:nth-child(n+2){ 
              margin-left: 5px;
            }
          }
        }
      }
    }   
  .category-right {
    .gift-block{
      width: auto;    
      .gift-offer{
        display: none;
      }
    }
  }
  }  

  .category-header-2 {
    .navbar-menu {
      .category-right {
        .gift-block{
          width:auto;
        }
      }
    }
  }

  

  // collection banner //
  .collection-banner {
    .collection-banner-main {
      &.banner-7 {
        .collection-banner-contain {
          padding: 30px;
        }
      }
      .collection-img {
        height: 200px;
      }
      &.banner-2 {
        .collection-img {
          height: 175px;
        }
      }
      &.banner-3 {
        .collection-img {
          height: 365px;
        }
      }
      &.banner-4 {
        .collection-img {
          height: 175px;
        }
      }
    }
  }

  // paralax banner //
  .full-banner {
    padding: 150px 0;
  }

  // top header //
  .top-header {
    .top-header-right {
      .language-block {
        display: flex;
      }
      .top-menu-block {
        display: none;
      }
    }
    .top-header-left {
      .shpping-order {
        margin-right: 18px;
      }
    }
  }
  .category-header {
    .navbar-menu {
      .category-right {
        .contact-block {
          i {
            display: none;
          }
        }
        .gift-block {
          margin-left: 40px;
          width: auto;
        }
      }
    }
  }
  .category-header-4 {
    .navbar-menu {
      .category-left {
        .nav-block {
          margin-right: 20px;
          .nav-left  {
            .nav-cat  {
              li {
                padding-bottom: 9px;
              }
            }
          }
        }
      }
      .input-block {
        width:370px;
        .input-box {
          .big-deal-form {
            .input-group {
              select{
                display: none;
              }
            }
          }
        }
      }
      .category-right {
        
        .gift-block {
          display: none;
        }
      }
    }
  }

  // sliiider //
  .theme-slider {
    .slider-banner {
      &.slide-banner-1 {
        .slider-img {
          height: 400px;
        }
      }
      &.slide-banner-4 {
        .slider-img {
          height: 500px;
        }
        .slider-banner-contain  {
          h3 {
            &:before {
              width: 70px;
            }
            &:after {
              width: 70px;
            }
          }
        }
      }
    }
  }



  /*=====================
     Inner pages CSS
==========================*/
  // product sidebar page //
  .product-right  {
    .timer  {
      p  {
        span {
          min-width: 65px;
        }
      }
    }
  }

  // creative card //
  .creative-card {
    border-width: 25px;
    padding: 25px;
  }

  // blog-pages //
  .blog-page {
    .blog-media {
      padding: 25px 15px;
      border-width:25px ;
      .blog-right {
        h4 {
          line-height: 1.2;
          margin-bottom: 10px;
        }
        h6 {
          margin-bottom: 5px;
        }
        ul {
          margin-bottom: 10px;
        }
        p {
          line-height: 1.4;
        }
      }
    }
    .blog-sidebar  {
      .theme-card {
        border-width: 25px;
        padding: 25px;
      }
    }
  }
  // product //
  .theme-card {
    border-width: 25px;
    padding: 25px;
    .offer-slider {
      .media {
        .media-body {
          h4 {
            margin-top: 10px;
          }
          a {
            h6 {
              margin-right: 0px;
            }
          }
        }
      }
    }
  }

}
@media(max-width:1200px) {
  .collection-banner {
    .offset-xl-2 {
      max-width: 26.66%;
    }
    .pl-md-0{
      padding-right: 0;
    }
  }
  .layout-4-collection>div {
    flex: 0 0 38%;
    max-width: 38%;
  }
  // collection banner //
  .collection-banner {
    .collection-banner-main {
      .collection-banner-contain {
        padding: 25px;
        h3 {
          font-size: 20px;
        }
        h4 {
          font-size: 20px;
        }
      }
      &.banner-1 {
        .collection-banner-contain {
          .shop {
            margin-top:10px;
          }
        }
      }
      &.banner-5 {
        .collection-img {
          height: 200px;
        }
        .collection-banner-contain {
          h3 {
            font-size: 20px;
          }
          h4 {
            font-size: 40px;
          }
          .btn-normal {
            padding: 10px 30px;
          }
        }
      }
      &.banner-7 {
        .collection-banner-contain {
          h3 {
            font-size: 18px;
          }
          h4 {
            font-size: 22px;
          }
          .shop {
            margin-top: 15px;
          }
        }
      }
    }
  }
  // slider //
  .theme-slider {
    .slider-banner {
      &.slide-banner-1 {
        .slider-img {
          height: 275px;
        }
      }
      .slider-banner-contain {
        padding: 0 70px;
        h4 {
          font-size: 18px;
        }
        h1 {
          font-size: 45px;
        }
        h2 {
          font-size: 24px;
        }

      }
      &.slide-banner-1 {
        .slider-banner-contain {
          h1 {
            padding-bottom: 5px;
          }
          h4{
            font-size: 20px;
          }
          h2 {
            font-size: 25px;
          }
        }
      }
      &.slide-banner-3 {
        .slider-banner-contain {
          h5 {
            font-size: 16px;
            padding-bottom: 0;
          }
          h3 {
            font-size: 32px;
            padding-bottom: 5px;
          }
          h1 {
            font-size: 42px;
            padding-bottom: 15px;
          }
          h2 {
            padding-bottom: 18px;
            font-size: 18px;
          }
        }
      }
      &.slide-banner-5 {
        .slider-banner-contain {
          h5 {
            font-size: 16px;
            padding-bottom: 0;
          }
          h3 {
            font-size: 32px;
            padding-bottom: 5px;
          }
          h1 {
            font-size: 42px;
            padding-bottom: 15px;
          }
          h2 {
            padding-bottom: 18px;
            font-size: 18px;
          }
        }
      }
    }
  }
}
@media (max-width:1199px){
  .sticky {
    .header7{
      padding-top: 10px;
      padding-bottom: 10px;
      .sm-horizontal .mobile-back{
        padding: 20px;
      }
    }
  }
  
  .layout-header2{
      background-color: rgba($primary-color, 0.05);
      .main-menu-block{
        .sm-nav-block{
          .nav-slide{
            li{
              .nav-sm-back{
                border-bottom: 0;
                padding: 1px;
              }
            }
          }
        }
      }
  }
  .sticky{
    .layout-header1{
      padding: 10px 0;
    }

  }
  .product-right  {
    .product-icon {
      .wishlist-btn  {
        i {
          padding-left: 15px;
          margin-left: 15px;
        }
      }
    }
    &.product-form-box{
      .pro-group {
        .timer {
          p {
            > span{
              font-size:16px;
              width:60px;
              height:60px;
              .timer-cal{
                font-size:12px;
              }
              &:nth-child(n+2){
                margin-left:5px;
              }
            }
          }
        }
        .size-box {
          ul {
            li{
              &:nth-child(n+2){
                margin-left:unset;
              }
            }
          }
        }
        .color-selector{
          &.inline {
            ul {
              li{
                &:nth-child(n+2){
                  margin-left: 0;
                }
              }
            }
          }
        }
      }
    }
  }
  .product-right  {
    .timer  {
      p  {
        span {
          min-width: 47px;
        }
      }
    }
  }
  .tab-product  {
    &.tab-exes {
      margin-top: 40px !important;
    }
  }
  .tab-product-main {
    .tab-prodcut-contain {
      ul {
        li{
          &:nth-child(n+2){
            margin-left: 30px;
          }
        }
      }
    }
  }
  .mor-slide-open{
    img{
      display: none;
    }
  }
  .new-tag{
    position: absolute;
    top: -5px;
  }
  .menu-banner{
    display: none;
  }

  // header start //
  .searchbar-input{
    .input-group {
      .twitter-typeahead{
        width: calc(100%  - 88px);
      }
      .input-group-text{
        &:first-child{
          padding-left:15px;
        }
        &:last-child{
          padding-right:15px;
        }
        svg{
          width: 18px;
        }
      }    
    }
  }
  .category-header-2 {   
    height: 0;   
    .navbar-menu {
      .menu-block {
        .pixelstrap {
          .dark-menu-item{
            color: $dark;
            padding: 10px 15px;
          }
        }
      }
      .category-right,.nav-block,.icon-block {       
          display: none;       
      }
      .menu-block{
        .toggle-nav{
          display: none;
        }
      }     
    }      
    .searchbar-input{
      display: none;
    }
  }
  .layout-header2 {
    .main-menu-block {
      .header-left,.header-right{
        display: flex;
        align-items: center;
      }
      .sm-nav-block{
        display: block;
        margin-right: 15px;
      }
      .menu-nav{
        display: block;
        margin-left: 15px;
      }
      .brand-logo {
        margin-right: unset;
        img{
          height: 45px;
        }
      }     
      .input-block{
        display: none;
      }

      .icon-block {
        margin-left: unset;
        ul {
          li{
            display: inline-block;
            svg{
              width: 24px;
            }
            .item-count-contain{
              top: -14px;
              left: 12px;
              width: 15px;
              height: 15px;
            }
            .cart-block {
              .cart-item {
                h5{
                  font-size: 12px;
                }
              }
            }
          }
        }
      }

      
    }   
   .searchbar-input{
     display: block;
   }
    
  }
  .category-header{
    display:none;
  }
  .layout-header1 {
    .main-menu-block {
      .menu-block{
        .toggle-nav{
          display: none;
        }
      }
      .menu-right {
        .icon-nav {
          ul {
            li{
              &.mobile-search,&.mobile-setting{
                display: inline-block;
              }
            }
          }
        }
      }
      .menu-left {
        .brand-logo {
          img{
            height: 45px;
          }
        }
      }
    }
    .searchbar-input{
      display: block;
    }

    
  }
  .layout-header3 {
    .main-menu{
      .menu-nav{
        .toggle-nav-desc{
          display: none;
        }
      }
      .icon-block{
        .toggle-nav-desc{
          display: block;
        }
      }
    }
  }
  .category-header-4 {
    background-color: $white;
    padding-top: 10px;
    padding-bottom: 10px;
    .navbar-menu {
      .category-left {
        align-items: center; 
         .sm-nav-btn      {
           display: block;
         }
        .logo-block{
          display: block;
          img{
            height: 40px;
          }
        }
        .nav-block{
          display: none;
        }
      }
      .category-right {
        display: flex;
        .icon-block{
          display:flex;
          ul {
            li {
              svg{
                fill: $dark;
              }
              .item-count-contain{
                top: -5px;
                 right: -3px;
                &.item-whtie{
                  background-color:$theme-color1;
                  color: $white;
                }
              }
              
            }
          }
        }

        
      }
      .menu-block {
        .pixelstrap {
          .light-menu-item{
            padding: 10px 15px;
            color: $dark;
          }
        }
      
      }
    }
  } 
  .layout-header4{
    height: 0;
    padding: 0;  
    .logo-block,.icon-block{
      display: none;
    }

    .contact-block {
      .desc-nav-block{
        display: none;
      }
    }
    
  }

  .category-header7 {
    .category-contain {
      .category-left {
        .logo-block{
          .brand-logo {
            img{
              height: 45px;
            }
          }
        }
      }
    }

  }
  .sticky {
    .layout-header2{
      display: block;
      .searchbar-input{
        display: block;
      }
    }
    .category-header-2{
      padding: 0;
      .searchbar-input{
        display: none;
      }
      .navbar-menu {
        .logo-block{
          display: none;
        }
      }
    }


    &.header-style2 {
      .category-header7 {
        .category-contain {
          .category-left {
            .header-category{
              display: block;
            }
          }
        }
      }
    }
  }
  .layout-header3 {
    .main-menu {
      .menu-left {
        .sm-nav-block {
          .nav-slide {
            > li{
              &:nth-last-child(2){
                border: 0;
                padding: 0;
              }
            }
          }
        }
      }
    }
  } 
  .hot-4 > {
    div{
      &:nth-child(2){
        flex: 0 0 100%;
        max-width: 100%;
      }
      &:last-child,&:first-child{
        flex: 0 0 50%;
        max-width: calc(50% - 7.5px);
        margin-bottom: 15px;
        order:-1;
      }
      &:last-child{
        margin-left: 15px;
      }
    }
  }


  // home slider //
  .theme-slider {
    .offset-xl-2 {
      max-width: 75%;
      margin: 0;
    }
    .slider-banner {
      .layout2-slide-1  {
        li {
          width:550px ;
        }
      }
      .layout2-slide-2  {
        li {
          width:340px ;
        }
      }
      .layout2-slide-3  {
        li {
          width:380px ;
        }
      }
      &.slide-banner-5  {
        .layout6-slide-1  {
          li {
            width: 350px;
          }
        }
      }
      &.slide-banner-2  {
        .layout3-slide-1  {
          li {
            width: 250px;
            left: 0;
            &:last-child {
              width: 250px;
              right: 0;
            }
          }
        }
        .layout3-slide-2  {
          li {
            width:240px;
            left: 20px;
            &:last-child {
              width: 250px;
              right: 20px;
            }
          }
        }
      }
      &.slide-banner-4  {
        .layout5-slide-1 {
          li {
            width:55% ;
            right: 50px;
          }
        }
        .layout5-slide-2 {
          li {
            right: 50px;
            width: 50%;
          }
        }
        .layout5-slide-3 {
          li {
            right: 50px;
            width: 55%;
          }
        }
      }
    }
  }
  .farming-slide {
    .slide-main {
      .slide-contain{
        height: 64vh;
       
        h4{
          margin-bottom: 20px;
        }
      }
      .animat-block {
        .animat1{
          width: 560px;
        }
        .animat2{
          height:100px;
        }
        .animat3{
          height: 130px;
        }
      }
    }
    
  }
  .tools-slide {
    .slide-main {
      .slide-contain{
        height: 68vh;
      }
      .animat-block {
        .animat1{
          width: 580px;
        }
      }
    }
  }
  .pets-slide {
    .slide-main{
      .slide-contain {
        height: 60vh;
        h3{
          margin-bottom:15px;
        }
        h2{
          margin-bottom:15px;
        }
        .form-control{
          width: 350px;
          height: 52px;
          margin-bottom: 20px;
        }
      }

      .animat-block {
        .animat1{
          width: 310px;         
        }
        .animat2{
          width: 310px;
        }
      }
    }
  }
  .megastore-slide {
    .slide-main{
      .slide-contain{
        height: 68vh;
       
      }
    }
    .mega-slide-block {
      > div{
        &:first-child{
          padding-left: 15px;
        }
        &:nth-child(n+2){
          margin-top: 15px;
          padding-left: 15px;
        }
      }
    }   
  }
  .grocery-slide{
    .slide-main{
      margin-left: unset;
      .slide-contain{
        height:68vh;
        h3{
          margin-bottom: 12px;
        }
        h2{
          margin-bottom: 9px;
        }
        h4{
          margin-bottom: 20px;
        }
      }
    }
  }
  .header-category {
    ul{
      &.collapse-category{
        position: fixed;
        width: 300px;
        border: 1px solid $light-border;
        top: 0;
        height: 100vh;
        left: -300px;
        z-index: 99;
        transition: all 0.3s ease;
        overflow: scroll;
        margin-top: unset;
        &.hide{
          display: block;
        }
        &.open{
          left: 0;
        }
        li{
          a{
            img{
              width: 30px;
              height: 30px;
            }
          }
          &.back-btn{
            display: block;
          }
        }
      }
    }
  }
  .header-category2 {
    .category-toggle{
      display: none;
    }
    ul{
      &.collapse-category{
        position: fixed;
        width: 300px;
        border: 1px solid $light-border;
        top: 0;
        height: 100vh;
        left: -300px;
        z-index: 99;
        transition: all 0.3s ease;
        overflow: scroll;
        margin-top: unset;
        &.hide{
          display: block;
        }
        &.open{
          left: 0;
        }
        li{
          &.back-btn{

            display: block;
            margin-bottom: 20px;
          }
        }
      }
    }
  }
  .header-category3 {
    .category-toggle{
      display: none;
    }

    ul{
      li{
        a{
          font-size: 18px;
          font-weight: 600;
        
          span{
            &:before{
              content: "+";
            }
            &.active{
              &:before{
                content: "-";
              }
            }
          }
        }
      }
      &.collapse-category{
        position: fixed;
        width: 300px;
        border: 1px solid $light-border;
        top: 0;
        height: 100vh;       
        z-index: 99;
        transition: all 0.3s ease;
        overflow: scroll;
        margin-top: unset;
        overflow: scroll;       
        &.hide{
          display: block;
        }
        &.open{
          left: 0;
        }
        li{
          width: 100%;
          &.back-btn{
            display: block;
            padding-top: 15px;
            padding-left: 15px;
            padding-right: 15px;
          }
          ul{
            li{
              &:first-child {
                a{
                  padding-top: 8px;
                }
              }
            }
          }
        }
        > li {
          ul {
            li{
              &:last-child {
                a{
                  padding-bottom: 8px;
                }
              }
            }
          }
        }
      }
      .categoryone {    
        .collapse-two{
          position: unset;
          opacity: 1;
          visibility: visible;
          border: none;
          padding-left: 15px;     
          ul{
            li{
              a{
                font-weight: 400;
              }
            }
          }
          .categorytwo {                       
            .collapse-third{
              position: unset;
              opacity: 1;
              visibility: visible;
              border: none;
            }
          }
        }
        .collapse-mega{
          position: unset;
          display: block;
          opacity: 1;
          visibility: visible;
          padding-top: 0;
          padding-bottom: 0;
          padding-left: 15px;
          border: none;         
          .mega-box {
            h5{
              position: relative;
              padding-top: 8px;
              padding-bottom: 8px;
              margin-bottom: 0;
              span{
                &.sub-arrow{
                  &:before{
                    content: "+";
                    position: absolute;
                    top: 8px;
                    right: 15px;
                    color: $dark;
                    font-size: 20px;
                    font-family: $font-awesome; 
                  }
                  &.active{
                    &:before{
                      content: "-";
                    }
                  }
                }
              }             
            }      
            ul {
              display: none;
              &.open{
                display: block;
              }
              li{
                &:first-child{
                  a{
                    padding-top: 8px;
                  }
                }
                &:last-child{
                  a{
                    padding-bottom: 8px;
                  }
                }
              }
            }     
          }         
        }
      }
    }
  }
  .mobilecat-toggle{
    display: block;
  }
  .header7 {
    .header-contain {
      .logo-block {
        .brand-logo {
          img{
            height: 45px;
          }
        }
      }
      .icon-block {
        ul {
          li{
            &.icon-lg-block{
              display: flex;
            }
          }
        }
        &.icon-lg-block{
          display: flex;
        }
      }
      .menu-block {
        ul {
          li {
            a.dark-menu-item{
              font-size: 16px;
            }
          }
        }
      }
      .gift-block{
        display: none;
      }
    }
  }
  .category-header7 {
    background-color: transparent;
    .category-contain{
      height: 0;
        .category-right{
          .toggle-nav{
            display: none;
          }
           .menu-block {
            ul {
              li {
                a{
                  color: $dark;
                  padding: 10px 15px;
                }
              }
            }
           }


        }
        .gift-block-main{
          display: none;
        }
    }
  }
  .header-category {
    .category-toggle{
      display: none;
    }
  }
  .header-search{
    width: 480px;
  }
  .header-search2{
    width: 480px;
  }
  .masonary-banner-block1{
    .col-sm-6.pl-3{
      padding-right: 0;
    }
  }
  .gutter-15 .masonary-banner-block2 .col-sm-6{
    padding-left: 0;
  }
  .masonary-banner-block  {
    &.masonary-inner1 {
      >div {
        &:last-child {
          padding-left: 0;
        }
      }
    }
  }
  .layout-3-hotdeal{
    .hot-deal .hot-deal-contain1 {
      margin-top: 15px;
      .hot-deal-subcontain .hot-deal-center .timer span{
        font-size: 14px;
      }
    }
    >div:nth-child(2) {
      padding-right: 15px;
    }
    >div:nth-child(4){
      order: -1;
      padding: 0;
      padding-left: 15px;
    }
  }
 
  .collection-banner-main.p-top.banner-6{
    .collection-img{
      background-size: contain !important;
      background-position: right top !important;
      background-repeat: no-repeat !important;
      background-color: $white !important;
    }
  }
  .layout-4-collection {
    >div {
      &:first-child {
        padding-right: 0;
      }
    }
  }

  // video banner //
  .video-banner {
    .video-banner-contain{
      height: 390px;
      h3{
        margin-top: 20px;
      }
      h2{
        margin-top: 21px;
      }
    }
  }


  // hot deal //
  .hotdeal-second {
    .hotdeal-box{
      display: block;
      .img-wrapper{
        width: 100%;
      }
      .hotdeal-contain{
          width: 100%;
      }
    }
  }


  // testimonial css //
  .testimonial6 {
    .testimonial-box {
      .img-wrapper{
        margin-bottom: 16px;
      }
      .testimonial-detail {
        h6{
          margin-bottom: 11px;
        }
        p{
          margin-bottom: 15px;
        }
      }
    }
  }

  



  // contact banner //
  .subscribe-block1 {
    > div{
      &:nth-child(n+3){
        padding-left: 15px;
      }
      &:last-child{
        margin-top: 15px;
      }
    }
  }

  .contact-banner2{
     .subscribe-box {
      h2{
        margin-bottom: 5px;
      }
      p{
        margin-bottom: 12px;
      }
      .form-control{
        margin-bottom: 19px;
      }     
     }
  }

  // brand //
  .brand-third {
    display: block;
    .brand-box{
      padding: unset;
      display: flex;
      justify-content: center;
      height: auto;
    }
  }
  


  // sale banner //
  .sale-banenr {
    .sale-banenr-contain {
      h4{
        margin-bottom: 15px;
      }
      h2{
        margin-bottom: 10px;
      }
      h3{
        margin-bottom: 20px;
      }
    }   
    &.banner-style1 {
      .sale-banenr-contain{
        h4{
          margin-bottom: 15px;
        }
        h2{
          margin-bottom: 20px;
        }       
      }
    }
  }

  // app download //
  .app-download {
    .app-download-main {
      .app-download-contian {
        h2{
          margin-bottom: 18px;
        }
      }
    }
  }

  



  // header //
  .layout-header1 .main-menu-block .menu-left .sm-nav-block .nav-slide li.mor-slide-open{
    ul{
      li{
        a{
          display: inline-block;
        }
      }
    }
  }
  .collection-banner{
    .pl-md-0{
      flex: 0 0 33.33333%;
      max-width: 33.33333%;
    }
  }
  .collection-banner .offset-xl-2{
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .offer-banner{
    display: inline-block;
  }
  .collection-banner {
    .offset-xl-2{
      margin-left: 0;
    }
  }
  .offer-banner-img{
    img{
      height: 400px;
      width: 100%;
    }
  }
  .collection-layout1 {
    >div {
      max-width: 33.333% !important;
    }
  }
  .collection-banner {
    .collection-banner-main{
      &.banner-13 {
        .collection-img{
          height: 280px;
        }
      }
      &.banner-14 {
        .collection-img{
          height: 575px;
        }
      }
      &.banner-16 {
        .collection-img{
          height: 575px;
        }
      }
      &.banner-17 {
        .collection-img{
          height: 260px;
        }
      }
    }
  }



  


  // ganaral //
  .section-big-pt-space {
    padding-top: 40px;
  }
  .section-big-pb-space {
    padding-bottom: 40px;
  }
  .section-big-py-space {   
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .section-big-mb-space {
    margin-bottom: 40px;
  }
  .section-big-mt-space {
    margin-top:40px;
  }

  .section-big-my-space{
    margin-top: 40px;
    margin-bottom: 40px;
  }

  // collection banner //
  .collection-banner {
    .collection-banner-main {
      .collection-img {
        height: 170px;
      }
      &.banner-2 {
        .collection-img {
          height: 140px;
        }
      }
      &.banner-3 {
        .collection-img {
          height: 295px;
        }
      }
      &.banner-4 {
        .collection-img {
          height: 140px;
        }
      }
      &.banner-12 {
        .collection-img{
          height: 250px;
        }
      }
      &.banner-style3 {
        .collection-banner-contain {
          h3{
            letter-spacing: 1px;
            margin-bottom: 11px;
          }
          h4{
            margin-bottom: 11px;
          }
        }
      }
    }
    .collection-p6 {
      > div{
        &:nth-child(n+2){
          margin-top: 0;
          padding-left: 0;
        }
      }
    }
  }
  .layout-4-collection {
    >div   {
      flex:  0 0 50%;
      max-width: 50%;
      &:first-child {
        margin-left: 0;
      }
      &:nth-child(2) {
        padding-right: 15px;
        padding-left: 15px;
      }
    }
  } 

  //discount banner //
  .discount-banner {   
    padding-top:40px;
    padding-bottom: 40px;
  }

  //title //
  .title1 {
    padding: 30px 0;
  }
  .title3 {
    padding-top: 30px;
  }

  .title8{
    margin-bottom: 40px;
  }

  // product box //
  
  .product {
    .product-box {
      .product-imgbox {
        .product-icon {
          &.icon-inline {
            button {
              margin: 0 3px;

            }
            a {
              margin: 0 3px;
            }
          }
          button{
            width: 40px;
            height: 40px;
          }
          a{
            width: 40px;
            height: 40px;
          }
        }
      }
      
      .product-detail {
        .detail-title {
          .detail-left {
            float: unset;
          }
          .detail-right {
            display: flex;
            float: unset;
            .price {
              margin-left:5px ;
            }
          }
        }
        &.product-detail2 {
          .color-variant{
            padding-top: 0;
          }
        }
      }
    }
  }
  .no-slider {
    .product-box {
      flex: 0 0 33.33%;
      max-width: calc(33.33% - 30px);
      margin: 0 15px 30px;
    }
    &.five-product {
      .product-box {
        width: 100%;
        flex: 0 0 33.33%;
        max-width: calc(33.33% - 30px);
        margin: 0 15px 30px;
        &:nth-last-child(-n+5) {
          margin: 0 15px 30px;
        }
        &:nth-last-child(-n+3) {
          margin: 0 15px 0;
        }
      }
    }
  }

  // hot deal //
  .hot-deal {
    .hotdeal-block1 {
      > div{
        &:nth-child(2){
          padding-left: 15px;
        }
      }
    }
    &.hotdeal-first,&.hotdeal-third {
      .hot-deal-contain {
        h5{
          margin-bottom: 5px;
        }
        .rating{
          margin-bottom: 8px;
        }
        p{
          margin-bottom: 8px;
        }
        .price{
          margin-bottom:  10px;
        }
        .timer{
          margin-bottom: 17px;
        }
      }
    }

   
 
    .hot-deal-contain {
      .hot-deal-center{
        margin-left: 0px;
        .timer {
          margin-top:20px;
          span {
            padding: 15px;
            font-size: 20px;
            margin: 0 3px;
            min-width: 35px;
            >span {
              margin-left: 0;
            }
          }
        }
        h6 {
          margin: 15px 0;
        }
      }
      .hotdeal-right-nav {
        img {
          height: 105px;
        }
      }
    }
    .hot-deal-contain1 {
      .hot-deal-subcontain {
        .hot-deal-center {
          .rating {
            margin: 8px 0;
          }
          h5 {
            font-size: 14px;
            line-height: 1.4;
          }
          p {
            margin: 15px 0;
          }
        }
      }
    }
  }


  
  .media-banner {
    padding: 30px 20px;
  }

  // theme slider //
  .slider-layout-4  {
    .slider-slide {
      max-width: 100%;
      margin-left: 0;
    }
  }
  .grocery-slide{
    padding-top: 0;
    .header-search3{
      display: none;
    }
  }
  .grocery-slide-block{
    > div{
      &:nth-child(2){
        >div{
          >div{
            padding: 0;
          }
        }
      }
    }
  }
  .cosmetic-slide {
    .slide-main {
      .slide-contain{
        height: 68vh;
        h3{
          margin-bottom: 12px;
        }
        h2{
          margin-bottom: 6px;
        }
        h4{
          margin-bottom: 20px;
        }
      }
      .animat-block{
        .animat-img3{
          width: 180px;
        }
      }
    }
  }
  .furniture-slide {
    .slide-main {
      .slide-contain{
        height: 65vh;
        h2{
          margin-bottom:5px;
        }       
        p{
          margin-bottom:15px;
          width: 465px;
        }
      }
    }
    .animat-block {
      .animat1{
        width: 465px;
      }
      .animat2{
        width: 190px;
      }
     }
  }
  .digitalmark-slide {
    .slide-main {
      background-position:30% !important;
      .slide-contain{
        height: 66vh;
      }      
    }
  }
  .kids-slide {
    .slide-main {
      .slide-contain{
        height: 65vh;
        h2{
          margin-bottom: 10px;
        }
        p{
          margin-bottom: 20px;
        }
      } 
    }

    .animat-block {
      .animat1{
        width: 305px;
      }
      .animat2{
        width: 40px;
      }
      .animat3{
        width: 60px;
      }
      .animat4{
        width: 60px;
      }
      .animat5{
        width:90px;
        bottom: -50px;
      }
    }
  }
  
  


  
  // key feature //


  


  // footer //
  

  //slider //
  .theme-slider {
    .slider-banner {
      .slider-img {
        height: 400px;
      }
      &.slide-banner-2 {
        .slider-img {
          height: 400px;
        }
        .slider-banner-contain {
          .sub-contain {
            padding: 30px ;
          }
        }
      }
      &.slide-banner-4 {
        .slider-img {
          height: 450px;
        }
      }
    }
  }

  // header //
  .layout-header1 {
    .main-menu-block {
      .menu-left {
        .sm-nav-block {
          display: block;
        }
      }
      .menu-right {
        .icon-nav {
          margin-left: 0;
        }
        .toggle-block {
          order: 3;
          margin-left: 15px;
        }
        .menu-banner {
          display:none;
        }
      }
    }
  }
  .category-header {
    .navbar-menu {
      .category-left {
        width: 53%;
        .nav-block {
          display: none;
        }
        .input-block {
          .input-box {
            .big-deal-form {
              .input-group {
                height: 50px;
                select {
                  background: url("../images/layout-1/drop-1.png") no-repeat scroll 20px 19px;
                }
              }
            }
          }
        }
      }
      .category-right {
        .contact-block {
          padding: 15px 0;
        }
      }
    }
  }
 
 
  .layout-header3 {
    .main-menu {     
        .menu-nav {
          .toggle-nav {
            display: none;
          }
        }     
      .menu-left {
        .nav-block {
          display: none;
        }
        .sm-nav-block {
          display: block;
        }
      }
    }
  }
 
 

  // newslatter //
  .newsletter {
    .subscribe-block {
      .input-group {
        span{
          padding:  0 25px;
        }
        input {
          width: 220px;

        }
      }
    }
  }

  .subscribe2 {
    .subscribe-contain {
      h2{
        margin-bottom: 5px;
      }
      p{
        margin-bottom: 17px;
      }
      .form-control {
        margin-bottom: 23px;
      }
      ul{
        margin-bottom: 21px;
      }
    }
  }





  // breadcrumb//
  .breadcrumb-main {
    padding: 50px 0;
  }


  // theme invoice //
  .theme-invoice {
    .invoice-popup {
      .invoice-header {
        .header-left {
          .brand-logo{
            img{
              height: 50px;
            }
          }
        }
      }
    }
  }

  // theme modal //
  .theme-modal{
    &.blackfriday-modal{
      &#exampleModal {
        .modal-dialog{
          max-width:550px;
        }
      }
    }
  }

  /*=====================
   Inner pages CSS
==========================*/
// checkout page //
.checkout-second {
  .checkout-box{
    width: 990px;
  }
}

  // product-sidebar //
  .theme-card {
    .offer-slider {
      img  {
        height: 130px;
        padding-right: 10px;
      }
      .media {
        .media-body {
          .rating {
            i {
              padding-right: 1px;
            }
          }
        }
      }
    }

  }

  .tab-product,.product-full-tab {
    .theme-form {
      input,textarea{
        padding:10px 15px;
      }
    }
  }

  .cart-section, .wishlist-section {
    tbody {
      tr {
        td {
          min-width: 186px;
        }
      }
    }
  }
  .blog-detail-page {
    .comment-section {
      li {
        padding-top: 45px;
        padding-bottom: 45px;
      }
    }
  }
  .product-wrapper-grid {
    &.list-view {
      .product-box {
        .img-wrapper, .img-block {
          width:50%;
        }
      }
    }
  }
  .video-product {
    video {  
      height:60vh;   
    }
  }
  .collection-product-wrapper {
    .product-top-filter {
      .product-filter-content {
        .search-count {
          width:100%;
          border-left:1px solid $light-border;
          border-bottom: 1px solid $light-border;
        }
        .collection-view {
          width: 20%;
          padding-right: 0 !important;
          border-left:1px solid $light-border;
        }
        .collection-grid-view {
          padding: 20px !important;
          padding-left: 0 !important;
          width: 20%;
          ul {
            li {
              &:last-child {
                display: none;
              }
            }
          }
        }
        .product-page-filter {
          width: 30%;
        }
        .product-page-per-view {
          width: 30%;
        }
        &.horizontal-filter-mian {
          .horizontal-filter-toggle{
            width:20%;
          }
          .product-page-filter {
            width:25%;
          }
        }
      }
    }
  }
  .cart-section {
    tbody {
      tr {
        td {
          min-width: 140px;
          .qty-box {
            .input-group {
              .form-control {
                width: 52px;
              }
            }
          }
        }
      }
    }
    tfoot {
      tr {
        td {
          padding-right: 25px;
        }
      }
    }
  }
  .collection-collapse-block {
    .collection-collapse-block-content {
      .collection-brand-filter {
        .category-list {
          li {
            &:first-child {
              margin-top: 15px;
            }
          }
        }
      }
    }
  }
  .collection-filter-block {
    .product-service {
      .media {
        .media-body {
          h4 {
            font-size: 16px;
          }
        }
      }
    }
  }
  .product-right {
    h2 {
      font-size: 20px;
    }
  }
  .search-product {
    > div {
      &:nth-child(n + 4) {
        margin-top: 30px;
      }
    }
  }
  .blog-page {
    .blog-media {
      margin-bottom: 20px;
      .blog-right {
        display: block;
        margin-top: 15px;
        p {
          line-height: 1.3;
        }
      }
      &.media-change{
        text-align:left;
        >div{
          &:first-child{
            order:unset;
          }
        }
      }
    }

  }
  .tab-product {
    .tab-content {
      &.nav-material {
        .single-product-tables {
          table {
            width: 50%;
          }
        }
      }
    }
  }
  .product-description-box {
    .border-product {
      padding-top: 10px;
      padding-bottom: 15px;
    }
    .product-icon {
      .product-social {
        li {
          padding-right: 4px;
        }
      }
      .wishlist-btn {
        span {
          padding-left: 4px;
        }
      }
    }
  }
  .product-form-box {
    .border-product {
      padding-top: 10px;
      padding-bottom: 15px;
    }
    .timer {
      span {
        min-width: 48px;
      }
    }
  }
  .product-accordion {
    .single-product-tables {
      table {
        width: 70%;
      }
    }
  }
  .tab-product {
    .flex-column {
      flex-direction: unset !important;
      justify-content: center;
    }
    .nav-material {
      &.nav-tabs {
        border-bottom: 1px solid $light-border;
        border-right: 0;
        .nav-item {
          .nav-link {
            &.active {
              background-color: unset;
            }
          }
        }
      }
    }
  }
  .tab-border {
    padding-top: 0;
  }
  .about-text {
    p {
      margin-bottom: 25px;
    }
  }

  // tools //
  .tools-parallax-product {
    &.full-banner {
      padding-top: 90px;
      padding-bottom: 90px;
    }
    .tools-description {
      h3 {
        font-size: 17px;
      }
    }
  }

  // vagitable //
  .full-box {
    .theme-card {
      .offer-slider {
        img {
          padding: 15px 0 15px 0;
        }
        .product-box2 {
          .media {
            img {
              height: 230px;
            }
            .media-body {
              .color-variant {
                margin-top: 5px;
                li {
                  margin-top: 5px;
                }
              }
            }
          }
        }
      }
    }
  }

  // order  tracking page //
  .order-tracking {
    #progressbar {
      li {
        .icon{
          width:40px;
          height:40px;
        }
      }
    }
  }

  // top cart //
  .add_to_cart {
    &.top, &.bottom {
      left: -320px;
      top: 0;
      height: 100vh;
      width: 320px;
      .cart-inner {
        height: 100vh;
        overflow: auto;
        .cart_top {
          padding: 20px !important;
          margin-bottom: 20px;
        }
        .cart_media {
          padding: 0 20px !important;
          display: block;         

          ul{
            &.cart_product {
              padding: 0;
              width: 100%;
              display: block;
              // overflow-y: hidden;
              height: calc(100vh - 335px);
              overflow-y: auto;
              bottom: 20px;
              li {
                min-width: 100%;
                &:nth-child(n+2){
                  margin-left: unset;
                  margin-top: 15px;
                }
              }
            }
            &.cart_total {
              width:calc(320px - 40px);
              position:fixed;
              bottom:20px;
              li {
                .total{
                  margin-top: 20px;
                }
              }
            }
          }
          .cart_total {           
            width: 100%;
          }
        }
      }
      &.open-side {
        left: 0;
      }
    }
  }

  // msonory-banner //
  .masonory-banner {
    .masonary-banner-main {
      .masonory-banner-img {
        height: 350px;
        &.masonory-img1 {
          height: 715px;
        }
      }
      .masonary-banner-contant {
        padding: 40px 20px;
      }
    }
  }
  .masonary-banner-block1 {
    >div {
      &:first-child {
        margin-bottom: 12px;
      }
    }
  }
  .masonory-p1 {
    > div{
      &:first-child{
        padding-right: unset;
      }
      &:nth-child(n+2){
        padding-left: 15px;
      }
      &:nth-child(n+3){
        margin-top: 15px;
      }
    }
  }

  // portfolio //
  .portfolio-section  {
    .portfolio-2 {
      margin-bottom: -30px;
    }
    .portfolio-3 {
      margin-bottom: -15px;
    }
  }

}
@media (max-width: 1024px){

  // home sldier //
  .farming-slide {
    .slide-main {
      .animat-block {
        .animat1{
          width: 520px;
        }
      }
    }
  }

  .digitalmark-slide {
    .slide-main {
      .slide-contain {
        .sub-contain{
          width: 47%;        
          .input-group{
            width: 340px;
            .form-control{
              height: 52px;
              width: 69%;
            }
          }
          .btn{
            padding: 10px 17px;
          }
        }
      }
    }
  }




  


  // category page //
  .creative-card {
    &.creative-inner {
      padding: 10px;
    }
  }
}

@media (max-width:992px){
  .sticky .header7 .sm-horizontal .mobile-back {
    padding: 18px;
}
  // product box //
  .product {
    .product-box {
      .product-imgbox {
        .new-label {
          padding: 2px;
        }
      }
    }
  }


}
@media (max-width: 991px){
  .gift-dropdown.dropdown-menu {
    width: 300px;
    right: -80px;
    left: 0 !important;
  }
  .theme-slider .slider-banner.slide-banner-1 div li img {
    width: 50%;
  }
  .theme-slider .slider-banner.slide-banner-1 .layout1-slide-1 li:nth-child(2) {
    right: 20px;
    left: unset;
    top: 0;
  }
  .theme-slider  {
    .slider-banner {
      .layout2-slide-1  {
        li {
          width: 450px;
        }
      }
      .layout2-slide-2  {
        li {
          width: 300px;
          bottom: 20px;
          right: 10px;
        }
      }
      .layout2-slide-3  {
        li {
          width: 300px;
          bottom: 20px;
          right: 10px;
        }
      }
      &.slide-banner-2  {
        .layout3-slide-2  {
          li {
            width: 200px;
            &:last-child {
              width: 200px;
            }
          }
        }
        .layout3-slide-1  {
          li {
            width: 220px;
            &:last-child {
              width: 220px;
            }
          }
        }
      }
      &.slide-banner-4  {
        .slider-banner-contain {
          background-color: rgba($white ,0.5);
        }
        .layout5-slide-1 {
          li {
            width: 60%;
          }
        }
        .layout5-slide-2 {
          li {
            width: 60%;
          }
        }
        .layout5-slide-3 {
          li {
            width: 65%;
          }
        }
      }

    }
  }
  .theme-slider .slider-banner.slide-banner-1 .layout1-slide-3 li:nth-child(2) {
    right: 30px;
  }
  .cosmetic-slide {
    .slide-main {
      .slide-contain{
       height: 55vh;
        h2{
          margin-bottom: 9px;
        }
        h4{
          margin-bottom: 19px;
        }
      }
      .animat-block{
        .animat-img1{
          width: 32px;
        }
        .animat-img2{
          width: 22px;
        }
        .animat-img3{
          width: 140px;
        }
      }
    }
  }
  .furniture-slide {
    .slide-main {
      .slide-contain{
        height:54vh;       
        p{
          width: 345px;
        }
      }
    }
    .animat-block {
      .animat1{
        width: 410px;
      }
      .animat2{
        width: 150px;
      }
      .animat3{
        width: 43px;
      }
      .animat4{
        width: 40px;
      }
    }
  }
  .farming-slide {
    .slide-main {
      .slide-contain{
        height: 52vh;
        h2{
          margin-bottom: 12px;
        }
      }
      .animat-block {
        .animat1{
          width: 335px;
        }
      }
    }
  }
  .digitalmark-slide {
    .slide-main {
      .slide-contain {
        .sub-contain{
          width: 52%;
          h2{
            margin-bottom: 17px;
          }
        }
      }
    }
  }
  .kids-slide {
    .slide-main {
      .slide-contain {
        height:59vh;
      }
    }
    .animat-block{
      .animat6{
        left: -12%;
      }
    }
  }
  .tools-slide {
    .slide-main {
      .slide-contain{
        height: 57vh;
        h3{
          margin-bottom: 10px;
        }       
        p{
          width: 300px;         
        }
      }
      .animat-block{
        .animat1{
          width: 410px;
        }
      }
    }
  }
  .pets-slide {
    .slide-main {
      .slide-contain {
        .form-control{
          width: 320px;         
        }
      }
      .animat-block {
        .animat1{
          width: 280px;
        }
        .animat2{
          width: 280px;
        }
      }
    }
  }
  .grocery-slide {
    .slide-main {
      .slide-contain{
        height: 58vh;
        
      }
      .animat-block {
        .animat1{
          right: 0;
        }
      }
    }
  }
  .megastore-slide{
    .slide-main{
      .slide-contain{
        height: 52vh;
      }
    }
  }

  // sale banenr //
  .sale-banenr {
    .sale-banenr-contain {
      h4{
        margin-bottom: 10px;
      }
      h2{
        margin-bottom: 10px;
      }
      h3{
        margin-bottom: 15px;
      }
    }
    &.banner-style1 {
      .sale-banenr-contain {
        h4{
          margin-bottom: 10px;
        }
        h2{
          margin-bottom: 15px;
        }
      }
    }
  }

  // app download //
  .app-download {
    .app-download-main {
      .app-download-contian {
        h2{
          margin-bottom: 11px;
        }
        h3{
          margin-bottom: 16px;
        }
        .btn{
          padding: 12px 30px;
        }
      }
    }
  }


  // header//
  .item-count {
    .item-count-contain{
      width: 15px;
      height: 15px;
      font-size: 10px;
    }
  }
  .layout-header2 {
    .main-menu-block {
      .cart-block {
        ul {
          li {
            .item-count-contain{
              top: -10px;
              left: 9px;
            }
          }
        }
      }
      .icon-block{
        ul{
          li {
            .cart-block {
              .cart-item{
                display: none;
              }
            }
          }
        }
      }
    }
  }
  .layout-header1 ~ .category-header .gift-dropdown.dropdown-menu {
    left: unset !important;
  }
  .header7 {
    .header-contain {
      .logo-block{
        .brand-logo {
          img{
            height: 40px;
          }
        }
      }
      .icon-block {

        
        ul{
          li{
            &.icon-md-block{
              display: flex;
            }
          }
        }
      }
    }
  }
  .header-search{
    display: none;
  }
  .header-search2{
    display: none;
  }

  .layout-header1 {
    .main-menu-block{
      .menu-left{
        .brand-logo {
          img{
            height: 40px;
          }
        }
      }
    }
  }

  .layout-header2 {
    .main-menu-block {     
      .brand-logo {
        img{
          height: 40px;
        }
      }     
    }
  }

  .layout-header3{
    .main-menu {
      .input-block {
        display: none;
       
      }
      .icon-block {
        ul {
          li{
            &.mobile-search{
              display: inline-block;
            }
            &.mobile-wishlist {
              .item-count-contain{
                top: 0;
              }
            }
          }
        }
      }
    }

    
  }

  .category-header-4 {
    .navbar-menu {
      .input-block{
        display: none;
      }
      .category-right{
        .icon-block{
          ul {
            li{
              &.mobile-search{
                display: inline-block;
              }
            }
          }
        }
      }

      .category-left {
        .logo-block {
          img{
            height: 35px;
          }
        }
      }
    }
    .searchbar-input{
      display: block;
    }

    
  }

  .category-header7 {
    .category-contain {
      .category-left {
        .logo-block{
          .brand-logo {
            img{
              height: 40px;
            }
          }
        }
      }
    }
  }

  .header-style2 {
    .searchbar-input {
      display: block;
    }
    .category-header7{
      .searchbar-input{
        display: none;
      }
    }
  }


  

  
  

  
  .hot-deal .hot-deal-contain1 .hot-deal-subcontain .hot-deal-center{
    display: block;
    text-align: left;
  }
  .hot-4{
    >div{
      &:last-child,&:first-child{
        max-width: calc(50%);
        margin-left: 0;
      }
    }
  }
  .layout-3-hotdeal .hot-deal .hot-deal-contain1 .hot-deal-subcontain .hot-deal-center .rating{
    margin: 20px auto;
  }
  .hotdeal-right-nav{
    display: block;
  }
  .hot-deal .hot-deal-contain {
    .hot-deal-subcontain>div:nth-child(3){
      position: absolute;
      bottom: 50px;
      right: 5px;
    }
    .hot-deal-center{
      align-items: flex-start;
    }
  }
  .hot-1 {
    >div {
      &:first-child {
        padding-left: 0;
      }
      &:last-child {
        padding-left: 0;
        padding-right: 0;
        margin-top: 15px;
      }     
    }
  }
  .hot-2 {
    >div {
      &:nth-child(2) {
        margin-bottom: 15px;
      }
    }
  }
  .hot-deal {  
   
    .hot-deal-heading{
      margin-bottom: 10px;
    }
    &.hotdeal-first{
      .hot-deal-contain {
        .hotdeal-right-slick {
          .img-wrraper{
            >div{
              height: 330px;
            }
          }
        }
      }
    }

    &.hotdeal-two {
      .hot-deal-contain{
        padding: 25px;
        .hot-deal-subcontain {
          > div{
            &:nth-child(3){
              position: unset;
              margin-top: 0;
            }
          }
        }
        .btn{
          padding: 10px 20px;
        }
      }
    }

    

     .hot-deal-contain {
       .hot-deal-center {
        h5{
          margin-bottom: 5px;

        }
        p{
          margin-top: 8px;
          margin-bottom: 8px;
        }
        .timer{
          margin-top: 12px;
        }
        .btn{
          margin-top: 17px;
        }
       }
     
     }
  }

  
  .spc-responsive{
    margin-top: 20px;
  }
  .offer-banner-img img {
    height: 350px;
  }
  .offer-banner {
    .banner-contain h5 {
      font-size: 13px;
      margin-bottom: 10px;
    }
  }
  .breadcrumb-main {
    padding: 40px 0;
  }

  // hot deal //



 
  // footer //
  .footer1 {
    .footer-main{
      > div{
        max-width: 50%;
        width: 50%;       
        &:nth-child(n+3){
          margin-top: 30px;
        }
      }
    }
  }

// cookie bar //
.cookie-bar{
  &.left-bottom{
    display: none;
    width: 100%;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 20px;
    svg{
      display: none;
    }
  }
}



  // button //
  .btn-rounded,.btn-white,.btn-normal,.btn-solid{
    padding: 12px 40px;
  }
  .btn-rounded{
    &.btn-md{
      padding: 10px 25px;
    }
  }


  // element subscribe //
  .subscribe1 {
    .subscribe-contain{
      display: block;
      text-align: center;
      .subscribe-left {
        .media{
          display: block;
          svg{
            margin-bottom: 5px;
          }
          .media-body{
            margin-left: unset;
            h6{
              margin-bottom: 5px;
            }
            h3{
              margin-bottom: 10px;
            }
          }
        }
      }
      .subscribe-right{
        .input-group{
          width: fit-content;
          margin-left: auto;
          margin-right: auto;
          .form-control{
            width: 300px;
          }
        }
      }
    }
  }
  .subscribe2 {
    .subscribe-contain {
      p{
        width: 100%;
      }
    }
  }



  // instagram //
  .instagram  {
    .insta-contant {
      .insta-sub-contant {
        .insta-title {
          padding: 15px 25px;
        }
      }
    }
    .insta-contant1 {
      .insta-sub-contant1 {
        .insta-title {
          padding: 15px 30px;
        }
      }
    }
  }

  // services //
  .services {
    .service-block {
      >div {
        &:last-child {
          .media {
            margin-left: 24px;
          }
        }
        &:first-child {
          padding-bottom: 0;
        }
        &:nth-child(2){
          padding-bottom: 0;
        }
      }
    }
  }

  // brand panel //
  .brand-panel {
    .brand-panel-box {
      .brand-panel-contain {
        
        ul {
          li{
            &:first-child {
              display: block;
              text-align: center;
              border: 0;
              border-bottom: 2px solid $theme-color1;
              width: fit-content;
              margin: 0 auto;
              margin-bottom: 10px;
              font-size: 16px;
            }
            &:nth-child(2) {
              display: none;
            }
          }
        }
      }
    }
  }

  // testimonial //
  .testimonial {
    .testimonial-contain {
      padding: 30px 0;
      .media {
        display: block;
        .testimonial-img {
          margin: 0 auto;
        }
        .media-body {
          margin-left: 0;
          text-align: center;
        }
      }
    }
  }
  .testimonial5{
    .testimonial-detail{
      width: 100%;  
    }
  }
  .testimonial6 {
    .testimonial-box {
      .img-wrapper{
        margin-bottom: 10px;
      }
      .testimonial-detail {
        h6{
          margin-bottom: 6px;
        }
        p{
          margin-bottom: 10px;
        }
      }
    }
  }

  




  // deal banner //
  .deal-banner {
    padding: 30px 0;
    .deal-banner-containe {
      text-align: center;
      height: auto;
      h2 {
        margin-bottom: 15px;
      }
      h1 {
        margin-bottom: 17px;
      }
      .deal-btn {
        justify-content: center;
      }
    }
  }

  // collection banner //
  .collection5 {
    > div {
      margin-top: 15px;
      &:last-child {
        padding-left: 0;
        padding-right: 15px;
      }
      &:first-child {
        margin-bottom: 0;
      }
    }
  }
  .collection-banner {
    .collection-banner-main {
      .collection-img {
        height: 140px;
      }
      .collection-banner-contain {
        padding: 20px;
        h3 {
          font-size: 16px;
        }
        h4 {
          font-size: 16px;
        }
        .shop {
          margin-top: 10px;
        }
      }
      &.banner-1 {
        .collection-banner-contain {
          h3 {
            font-size: 18px;
          }
          h4 {
            font-size: 22px;
          }
        }
      }
      &.banner-2 {
        .collection-banner-contain {
          padding:0 5px;
          h3 {
            font-size: 14px;
          }
          h4 {
            font-size: 14px;
          }
        }
        .collection-img {
          height: 130px;
        }
      }
      &.banner-3 {
        .collection-banner-contain {
          h3 {
            font-size: 23px;
          }
          h4 {
            font-size: 14px;
            margin-bottom: 40px;
          }
        }
        .collection-img {
          height: 275px;
        }
      }
      &.banner-4 {
        .collection-banner-contain {
          h3 {
            font-size: 15px;
          }
          h4 {
            font-size: 17px;
          }
        }
        .collection-img {
          height: 130px;
        }
      }
      &.banner-5 {
        .collection-img {
          height: 150px;
        }
        .collection-banner-contain {
          h3 {
            font-size: 18px;
            margin-bottom: 10px;
          }
          h4 {
            font-size: 32px;
          }
          .btn-normal {
            padding: 8px 25px;
          }
        }
      }
      &.banner-10 {
        .collection-img{
          height: 240px;
        }
      }
      &.banner-11 {
        .collection-img{
          height: 495px;
        }
      }
      &.banner-12 {
        .collection-img{
          height: 250px;
        }
      }
      &.banner-13 {
        .collection-img{
          height: 220px;
        }
      }
      &.banner-14 {
        .collection-img{
          height: 455px;
        }
      }
      &.banner-15 {
        .collection-img{
          height: 300px;
        }
      }
      &.banner-16 {
        .collection-img{
          height: 455px;
        }
      }
      &.banner-style1 {
        .collection-banner-contain{
          background-color:rgba($black,0.1);
          h3{
            margin-bottom: 12px;
          }
          h4{
            color: $white;
          }
          .shop{
            margin-top: 12px;
          }
        }
      }
      &.banner-style4 {
        .collection-banner-contain {
          background-color:rgba($black,0.5);
          h4{
            color: $theme-color2 ;
          }
        }
      }
      &.banner-style6 {
        .collection-banner-contain {
          background-color:rgba($black,0.5);         
          h4{
            color: $white;
          }
        }
      }
    }
    .collection-p6 {
      > div{
        &:nth-child(n+3){
          padding-left: 15px;         
          padding-top: 15px;
        }
      }
    }
  }

  

   
  .collection-layout3 {
    >div  {
      &:nth-child(2){
        padding-right: 15px;
      }
    }
  }

  // paralax banner //
  .full-banner {
    padding: 100px 0;
  }

  // rounded category //
  .rounded-category {
    padding: 30px 0 15px;
  }

  // box category //
  .box-category {
    .box-category-contain {
      padding: 20px 0;
    }
  }

  // hot deal //
  .hot-deal {
    .slick-prev,.slick-next {
      top:48px;
    }
    .hot-deal-contain {
      .hotdeal-right-nav {
        img{
          height: auto;
         width: 65px;
        }
      }
     
      .hot-deal-center {
        .timer {
          span {
            min-width: 22px;
            padding: 12px;
          }
        }
    
      }
      .hot-deal-subcontain {
        >div {
          &:nth-child(3) {
            margin-top: 20px;
          }
        }
      }
      .hotdeal-right-nav {
        img {
          height: auto;
          width: 105px;
        }
      }
    }   

&.hotdeal-first,&.hotdeal-third{
  .hot-deal-contain {
    .hotdeal-right-nav {
      img{
        width: 65px;
        height: auto;
      }
    }
  }
}




  }
  .Jewellery-banner {
    text-align: center;
    background-color: rgba($white, 0.70);
    background-blend-mode: overlay;
    a {
      font-size: 18px;
    }
    h6 {
      font-size: 40px;
    }
  }



  // slide //
  .theme-slider {
    .slider-banner {
      .slider-img {
        height: 350px;
      }
      &.slide-banner-1 {
        .slider-img {
          height: 250px;
        }
        .slider-banner-contain {
          h1 {
            font-size: 30px;
            span {
              margin-left: 5px;
            }
          }
          h4 {
            font-size: 16px;
            padding-bottom: 15px;
          }
          h2 {
            font-size: 20px;
          }
          .btn-normal {
            padding:10px 20px ;
          }
        }
      }
      &.slide-banner-2 {
        .slider-img {
          height: 330px;
        }
        .slider-banner-contain {
          .sub-contain {
            min-width: 250px;
            span {
              font-size: 16px;
              margin-bottom: 10px;
            }
            h1 {
              font-size: 32px;
              padding-bottom: 14px;
            }
            h4 {
              font-size: 14px;
            }
          }
        }
      }
      &.slide-banner-3 {
        .layout4-slide-1 {
          li {
            width: 380px;
          }
        }
        .layout4-slide-2 {
          li {
            width: 270px;
          }
        }
        .layout4-slide-3 {
          li {
            width: 300px;
          }
        }
        .slider-img {
          height: 320px;
        }
        .slider-banner-contain {
          padding: 40px;
          h3 {
            font-size: 25px;
          }
          h1 {
            font-size: 35px;
            padding-bottom: 10px;
          }
          h2 {
            font-size: 16px;
          }
        }
      }
      &.slide-banner-4 {
        .slider-img {
          height: 400px;
        }
      }
      &.slide-banner-5 {
        .slider-banner-contain {
          padding: 40px;
          h3 {
            font-size: 25px;
          }
          h1 {
            font-size: 35px;
            padding-bottom: 10px;
          }
          h2 {
            font-size: 16px;
          }
        }
      }
    }
  }
  .multiple-slider {
    >div {
      &:nth-child(n+3){
        margin-top: 15px;
      }
    }
  }

  // header //
  .top-header {
    padding: 5px 0;
  }
  .layout-header1 {   
    .main-menu-block {
      .menu-left {
        .brand-logo {
          img {
            height: 40px;
          }
        }
      }   
              
    }
  }


  


  .category-header {
    .navbar-menu {
      .category-left {
        width: auto;
      }
      .category-right {
        .gift-block {
          margin-left: 20px;
          padding: 12px 20px;
          .grif-icon {
            display: none;
          }
          .gift-offer {
            span {
              font-size: 16px;
            }
          }
        }
        .contact-block {
          display: none;
        }
      }
    }
  }
  .category-header-2 {
    .navbar-menu {
      .category-right {
        .gift-block {
          .gift-offer {
            display: none;
          }
        }
        .contact-block {
          i {
            display: block;
          }
          span {
            display: none;
          }
        }
      }
    }
  } 
  .layout-header3 {
    .main-menu {                    
      .menu-left {
        .logo-block {
          img {
            height: 40px;
          }
        }
      }
    }
  }
 



  // product //
  .no-slider {
    .product-box {
      flex: 0 0 50%;
      max-width: calc(50% - 30px);
      margin: 0 15px 30px !important;
      &:nth-last-child(-n+2) {
        margin: 0 15px 0 !important;
      }
    }
    &.five-product {
      .product-box {
        flex: 0 0 50%;
        max-width: calc(50% - 30px);
        margin: 0 15px 30px !important;
        &:nth-last-child(-n+2) {
          margin: 0 15px 0 !important;
        }
      }
    }
  }

  .product-block4 {
    > div{
      &:nth-child(n+4){
        margin-top:30px;
      }
    }
  }



  // inner pages //

  // order tracking  //
  .order-tracking {
    .order-tracking-box {
      .form-group {
        .form-control{
          height:45px;
        } 
      }
      .paymant-collapce{
        padding:20px;
        ul{
          &.upi-pay {
            li {             
              .form-control{
                height:35px;
              }
            }
          }
          &.bank-pay {
            li{
              padding-top:30px;
              padding-bottom:30px;
              img{
                width:40px;
              }
              &:nth-child(n+2){
                margin-left:15px;
              }
            }
          }         
        }
        .cash-pay {
          .form-control{
            height:40px;
          }
          .captchabox {
            .captchaimagecode{
              width: 110px;
              .reloadbtncapcha {
                svg{
                  width:14px;
                }
              }
            }
          }
        }      
      }
    }
  }
  .shpping-block{
    >div{
      &:nth-child(n+2){
        margin-top: 30px;
      }
    }
  }
  

  
  //category page //
  .category-page-side  {
    .collection-collapse-block  {
      .collapse-block-title {
        margin-top: 0;
      }
    }
    .collection-sidebar-banner {
      margin-bottom: 20px !important;
      margin-top: 10px !important;
    }
  }

  .collection-product-wrapper {
    .product-wrapper-grid {     
        .product-box {
          .product-detail {
            .icon-detail {
              a,button{
                width: 35px;
                height: 35px;
                svg{
                  width: 16px;
                }
              }
            }
          }
        }     
    }
  }


  .collection-wrapper {
    .product-slick  {
      background-color: $white1;
      img {
        width: 50%;
        margin: 0 auto;
      }
    }
    .tab-product {
      margin-top:30px !important;
    }



    
    
  }
  .collection-filter {
    >div  {
      &:nth-child(n+2) {
        margin-top: 0;
      }
    }
  }


  .login-page {
    .authentication-right {
      height: auto;
    }
  }
  .blog-detail-page {
    .blog-advance {
      ul {
        margin-bottom: 20px;
      }
      p {
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
  .top-banner-content {
    h4 {
      font-size: 20px;
    }
  }
  .collection-product-wrapper {
    .product-top-filter {
      border-top: none;
      .product-filter-content {
        .search-count {
          border-top: 1px solid $light-border;
        }
        .collection-view {
          display: none;
        }
        .collection-grid-view {
          display: none;
        }
        .product-page-per-view, .product-page-filter {
          width: 50%;
          &:before {
            right:15px !important;
          }
          select {
            border-left: 1px solid $light-border;
            padding: 21px 34px 21px 34px;
            border-top: none;
            border-bottom: none;
          }
        }
        .product-page-per-view {
          select {
            border-right: none !important;
          }
        }
        &.horizontal-filter-mian {
          .horizontal-filter-toggle{
            display: none;
          }
          .product-page-filter{
            width:50%;
            border-top: 1px solid $light-border;
          }
          .product-page-per-view{
            border-top: 1px solid $light-border;
          }
          .horizontal-filter{
            position: fixed;
            height: 100vh;
            top: 0;           
            left:-350px;
            background-color:$white;
            z-index: 99;
            overflow-y: scroll;
            max-width: 350px;           
            transition: all 0.5s ease;           
            box-shadow: 1px 0 5px $light-border;
            display: block;
            padding-top: unset;
            padding-bottom: unset;
            padding-left:40px;
            padding-right:20px;
            .horizontal-filter-contain {
              display: block;
              width:100%;
              .filter-group{
                width:auto;
                border-right:none ;
                margin-right: unset;
                padding-right: unset;
              }
            }
            .btn{
              display: none;
            }
          }
        }
      }
      .popup-filter {
        .product-page-per-view, .product-page-filter {
          border-top:1px solid $light-border;
        }
      }
    }
  }
  .video-product {
    video {  
      height:400px;     
    }
  }
  .category-list {
    .product-wrapper-grid {
      &.list-view {       
        .product-box {
          .product-imgbox {
            width: 85%;
          }
        }       
      }
    }
  }
  .filter-main-btn {
    display: block;
  }
  .collection-filter {
    position: fixed;
    height: 100vh;
    top: 0;
    left: -350px;
    background-color: $white;
    z-index: 99;
    overflow-y: scroll;
    //padding: 15px 0 15px 15px;
    width: 350px;
    max-width: 350px;
    transition: all 0.5s ease;
    box-shadow: 1px 0 5px $light-border;
    >div {
      &:first-child {
        padding-top: 30px !important;
      }
      &:last-child {
        padding-bottom: 30px !important;
      }
    }
    .creative-card {
      background-color: transparent;
      &.creative-inner {
        padding-left: 30px;
        padding-right: 30px;
        padding-top: 15px;
        padding-bottom: 15px;       
      }
    }
    .collection-sidebar-banner {
      text-align: center;
    }
    .theme-card {
      padding-left: 30px;
      padding-right: 30px;
    }
    .collection-sidebar-banner {
      padding: 0 30px;
    }
  }
  .collection-filter-block {
    border:none;
  }
  .collection-mobile-back {
    display: block;
    padding-top: 0;
    line-height: 1;
  }
  .collection {
    .section-t-space {
      padding-top: 30px;
    }
    .partition-collection {
      > div {
        &:nth-last-child(1) {
          margin-top: 30px;
        }
        &:nth-last-child(2) {
          margin-top: 30px;
        }
      }
    }
  }
  .right-login {
    margin-top: 30px;
  }
  .contact-page {
    .map {
      iframe {
        height: 350px;
      }
    }
    .contact-right {
      padding-bottom: 0;
      ul {
        li {
          padding-left: 0;
          border-bottom: 1px solid $light-border;
          text-align: center;
          padding-bottom: 10px;
          margin-top: 15px;
          .contact-icon {
            position: relative;
            margin: 0 auto;
            border-right: 0;
          }
          p {
            margin-bottom: 10px;
          }
        }
      }
    }
  }
  .cart-section {
    .cart-table {
      thead {
        th {
          &:last-child {
            display: none;
          }
        }
      }
    }
    tbody {
      tr {
        td {
          min-width: 135px;
          &:last-child {
            display: none;
          }
        }
      }
    }
    .cart-buttons {
      > div {
        &:last-child {
          padding-right:15px;
        }
      }
    }
    tfoot {
      tr {
        td {
          padding-right: 0;
        }
      }
    }
    &.order-history {
      tbody {
        tr {
          td {
            &:last-child {
              display: table-cell;
            }
          }
        }
      }
      .cart-table {
        thead {
          th {
            text-transform: capitalize;
            &:last-child {
              display: table-cell;
            }
          }
        }
      }
    }
  }
  .wishlist-section {
    tbody {
      tr {
        td {
          min-width: 138px
        }
      }
    }
  }
  .is_stuck{
    margin-top: 0;
  }
  .product-right {
    h2 {
      margin-top: 15px;
    }
  }
  
  .product-right {     
    text-align:center;        
    .pro-group {
      .product-title {
        &.size-text{
          text-align: left;
        }
      }
      .revieu-box{
        justify-content:center;
      }
      ul{
        &.best-seller{
          justify-content:center;
        }
      }
      .qty-box{
        justify-content:center;
      }
      .delivery-detail {
        .delivery-detail-contian{
          justify-content:center;
        }
        .delivery-lable{
          justify-content:center;
          margin-left:auto;
          margin-right:auto;
        }
      }
     
      .delivery-services{
        justify-content: center;
      }
    }
    .product-offer {
      .offer-contain{
          text-align: left;
          width:fit-content;
          margin-left: auto;
          margin-right: auto;
          .show-offer{
            text-align:center;
          }
      }
    }
  }
  .review-block {
    > div{
      &:nth-child(n+3){
        margin-top:20px;
      }
    }
  }
  .product-form-box{
    margin-top: 30px;
  }
  .product_img_scroll{
    display:flex;
    justify-content:center;
  }
  .collection-product-wrapper {
    .product-top-filter {
      .popup-filter {
        .sidebar-popup {
          width: 55%;
          padding-top: 10px;
          padding-bottom: 10px;
          text-align: center;
          margin-bottom: 20px;
          background: none $theme-color1;
          .open-popup {
            text-align: left;
          }
          > a {
            color: $white;
            font-weight: 700;
            letter-spacing: 0.05em;
          }
          .collection-filter {
            height: 100vh;
            left: -350px;
            transition: all 0.5s ease;
          }
        }
        .search-count {
          width: 100%;
          padding: 10px 0;
          text-align: center;
        }
        .collection-view, .collection-grid-view {
          display: none;
        }
        .product-page-per-view, .product-page-filter {
          width: 50%;
          select {
            padding-top: 10px;
            padding-bottom: 10px;
          }
          &:before {
            top:13px;
          }
        }
        .product-page-filter {
          &:before {
            left:unset;
            right: 35px;
          }
        }       
      }
    }
  }
  .tab-product {
    padding-top: 0;
    padding-bottom: 0;
  }
  .product-side-tab {
    margin-top: 25px !important;
  }
  .tab-product {
    .nav-material {
      &.nav-tabs {
        justify-content: center;
      }
    }
    .tab-content {
      &.nav-material  {
        p {
          padding: 20px 0 0 0;
        }
      }
    }
  }
  .pro_sticky_info {
    border: none;
    padding: 10px 0;
  }
  .dashboard-left {
    position: fixed;
    height: 100vh;
    top: 0;
    left: -350px;
    background-color: white;
    z-index: 99;
    padding: 0 35px 35px;
    max-width: 350px;
    transition: all 0.5s ease;
    box-shadow: 0 0 8px 0 $light-border;
    .block-title {
      h2 {
        display: none;
      }
    }
    .block-content {
      border:none;
      padding: 0;
      margin-top: 20px;
      ul {
        li {
          &:hover {
            padding-left: 0;
          }
        }
      }
    }
  }
  .account-sidebar {
    display:block;
    a{
      color:$white;
    }
  }
  .typography_section {
    .row {
      > div {
        &:first-child {
          .typography-box {
            &:last-child {
              margin-bottom: 30px;
            }
          }
        }
      }
    }
  }
  .product-slick, .rtl-product-slick,  .product-right-slick, .rtl-product-right-slick {
    .slick-prev, .slick-next {
      opacity: 1;
    }
    .slick-prev {
      left: 20px;
    }
    .slick-next {
      right: 20px;
    }
  }
  .order-up {
    order: -1;
  }
  .bundle {
    .bundle_img {
      justify-content: center;
    }
    .bundle_detail {
      .theme_checkbox {
        padding: 0 20%;
      }
    }
  }
  .checkout-page {
    .checkout-form {
      .checkout-details {
        margin-top: 30px;
      }
    }
  }

  .checkout-second {
    .checkout-box{
      width: 100%;
    }
  }
  



  // cateory //
  .collection-collapse-block {
    border-bottom: 1px solid lighten($black, 86.5%) ;
    padding: 15px 0;
  }

  // tools css //
  .tools-parallax-product {
    .tools-description {
      h3 {
        text-align: center;
      }
      .tools-form {
        text-align: center;
        .search-box {
          margin: 20px auto 20px;
        }
        .btn-find {
          background-size: 1100px;
        }
      }
    }
    .tools-grey {
      order: -1;
      margin-bottom: 30px;
    }
    &.full-banner {
      padding-top: 75px;
      padding-bottom: 75px;
    }
  }
  .tools_product {
    .multiple-slider {
      > div {
        &:nth-child(-n + 2) {
          margin-bottom: 0;
        }
      }
    }
    .tools-grey {
      margin-top: 30px;
    }
    .banner-tools {
      margin-left: 10px;
      margin-right: 10px;
    }
  }

  // vagytable //
  .full-box {
    .theme-card {
      .offer-slider {
        .product-box2 {
          .media {
            img {
              height: 160px;
            }
          }
        }
      }
    }
  }

  // modal //
  .theme-modal {
    &#exampleModal {
      .close {
        span {
          color: $dark;
        }
      }

      .newslatter-main{
        
        >div{
          &:last-child{
            display: none !important;
          }
        }
      }
      .offer-content {
        background-color: $white;
        background-image:url(../images/modal-popup/Genaral.png);
        justify-content: center;
        margin-left: 0;
        min-height: 400px;
        padding-right: 0 ;
        background-size:cover;
        &.vagi-offer-contant {
          background-image:url(../images/modal-popup/Genaral.png) ;
          background-size:cover;
        }
      }
    }
    &.cart-modal {
      .modal-dialog {
        .modal-content {
          .modal-body {
            .modal-bg {
              &.addtocart  {
                .media  {
                  .media-body  {
                    .buttons {
                      margin-bottom: 10px;
                      a {
                        margin: 5px 2px;
                      }
                    }
                    a {
                      h6 {
                        margin-bottom: 10px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .quick-view-img{
      height:400px;
      width:auto;
    }
    &.blackfriday-modal{
      &#exampleModal {
        .modal-dialog{
          max-width: 500px;
        }
        .modal-body{
          height:350px;
        }
        .offer-content{
          min-height:300px;
        }
      }
    }   
    &.cyber-monday{
      &#exampleModal {
        .modal-dialog{
          max-width:540px;
        }
        .offer-content{
          min-height:auto;
          h3{
            margin-bottom: 15px;
          }
        }
      }
    }   
  }

  // order-success //
  .product-order  {
    .final-total  {
      h3 {
        margin-bottom: 15px;
      }
    }
  }
  // masonory-banner //
  .masonory-banner {
    .masonary-banner-main {
      .masonory-banner-img {
        height: 300px;
        &.masonory-img1 {
          height: 615px;
        }
      }
      .masonary-banner-contant {
        padding: 30px 20px;
        h5 {
          letter-spacing: 5px;
        }
      }
    }
  }

  // portfolio //
  .portfolio-section  {
    .filter-button {
      padding: 8px 20px;
    }
  }

  // dashboard //
  .dashboard-left {
    padding: 20px;
  }


}
@media (max-width: 768px){
  .brand-panel {
    .brand-panel-box {
      .brand-panel-contain {
        ul {
          > li:not(first-child) {
            margin: 2px 1px;
            border: 1px solid #ddd;
          }
        }
      }
    }
  }
  // product box //
  .product {
    .product-box {
      .product-imgbox {
        .product-icon {
          transform: translateX(0px)!important;
          right:3px;
          &.icon-inline{
            opacity: 1;
          }
          &.icon-center{
            display:block;
            left:unset;
            right:0;
            margin-right:unset;
          }
           button{            
             width:35px;
             height:35px;
           }
           a{
            width:35px;
            height:35px;
             i{
               padding: 10px;
             }
           }
        }
      }
      .product-detail {
        &.detail-center {
          overflow: unset;
          .icon-detail {
            bottom: 70px;
          }
        }
        .detail-title {
          padding-top: 0;
        }
        &.detail-inline {
          padding-top: 0;
        }
      }
      &:hover{
        .product-icon {
          button {
            animation: none;
          }
          a {
            &:nth-child(2) i,&:nth-child(3) i,&:nth-child(4) i{
              animation: none;
            }
          }
          &.icon-inline {
            button {
              animation: none;
            }
            a {
              &:nth-child(2) i,&:nth-child(3) i,&:nth-child(4) i{
                animation: none;
              }
            }
          }
        }
        .product-detail {
          &.detail-center {
            .detail-title {
              opacity: 1;
            }
            .icon-detail {
              bottom:70px;
              button {
                animation: none;
              }
              a {
                &:nth-child(2) i,&:nth-child(3) i,&:nth-child(4) i{
                  animation: none;
                }
              }
            }
          }
        }
      }
    }
  }

  

  /// media banner //
  .media-banner {
    .media-banner-box {
      .media {
        .media-body{
          .media-contant{
            .cart-info{
              opacity: 1;
              visibility: visible;
              position: unset;
              margin-top: 5px;
            }
          }
        }
        &:hover{
          .media-body {
            .media-contant{
              .product-detail{
                visibility:visible;
                opacity: 1;
              }
              .cart-info{
                button{
                  animation:  none;
                }
                a{
                  &:nth-child(2){
                    animation:  none;
                  }
                  &:nth-child(3){
                    animation:  none;
                  }
                  &:nth-child(4){
                    animation:  none;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  // header start //
  .layout-header1 {
    .main-menu-block {
      .menu-right{
        .icon-nav {
          ul {
            li{
              &.cart-block{
                padding: 0;
                background-color:transparent;
                margin-left: 0;
                svg{
                  fill:$gray-2;
                }
                .item-count-contain{
                  color: $white;
                  background-color: $theme-color1;
                  top:-7px;
                  left: 12px; 
                }
              }
              .cart-item{
                display: none;
              }
            }
          }
        }
      }
    }
  }

  

  // home slider //
  .cosmetic-slide{
    .slide-main {
      background-position: 0 !important;
        .slide-contain{
          justify-content: center;
          background-color: rgba($black,0.5);         
          h3{
            color: $white;
            margin-bottom: 15px;
          }
          h4{
            color: $white;
            margin-bottom: 20px;
          }
        }
      }
  }
  .furniture-slide{
    .slide-main {
      background-position: 95% !important;
      .slide-contain{
        justify-content: center;
        background-color: rgba($black,0.5);
        text-align: center;
        h2{
          margin-bottom: 5px;
        }
        h3{
          color: $white;
        }       
        p{
          color: $white;
          width: 80%;
          margin-left: auto;
          margin-right: auto;        
          margin-bottom: 10px;
        }
      }
    }
    .animat-block{
      .animat1{
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
      }
      .animat3{
        right: 82%;
      }
      .animat4{
        right: 80%;
      }
    }
  }
  .farming-slide {
    .slide-main {
      .slide-contain{
        background-color: rgba($black,0.7);
        justify-content: center;
        height: 48vh;
        h3{
          margin-bottom:10px;
        }
        h2{
          margin-bottom: 10px;
          color: $theme-color2;
        }
        h4{
          margin-bottom: 15px;
        }
      }
      .animat-block{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        .animat1{
          left: 0;
          margin-left: auto;
          margin-right: auto;
          width: 70%;
        }
      }
    }
  }
  .digitalmark-slide {
    .slide-main {
      background-position:0 !important;     
      .slide-contain {
        justify-content: center;
        text-align: center;
        height: 49vh;
        background-color:rgba($black,0.5);
        .sub-contain{
          width:90%; 
          h2{
            margin-bottom: 8px;
            color: $white ;
          }       
          p{
            margin-bottom: 16px;
            color: $white ;
          }
          .input-group{
            margin-left: auto;
            margin-right: auto;
          }
        }
      }
      .animat-block{
        .animat1,.animat2{
          z-index: -1;
        }
      }

    }
  }
  .kids-slide {
    .slide-main {
      background-position: 90% !important;
      .slide-contain{
        justify-content: center;
        text-align: center;
        background-color:rgba($black,0.5);
        height: 56vh;
        h4{
          letter-spacing:0.05em;
          margin-bottom: 10px;
        }
        h3{
          color: $white;
          margin-bottom: 10px;
        }
        h2{
          margin-bottom: 15px;
        }
        p{
          color:$white;
          width: 80%;
          margin-left:auto;
          margin-right:auto;
          margin-bottom: 15px;
        }
      }
    }
    .animat-block {
      .animat1{
        width: 230px;
      }
      .animat5{
        width: 70px;
        bottom: -39px;
      }
      .animat6{
        display: none;
      }
    }
  }

  .tools-slide {
    .slide-main {
      .slide-contain {
        justify-content: center;
        text-align: center;
        background-color: rgba($black,0.5);
        height: 54vh;       
        h2{
          color: $theme-color1;                 
        }
        h3{
          color: $theme-color2;         
        }
        h4{         
          span{
            color: $theme-color2;
          }
        }
        p{
          width:80%;
          margin-left: auto;
          margin-right: auto;
          color: $white;
          margin-bottom: 10px;         
        }      
      }
      .animat-block {
        .animat1{
          width: 80%;
          left: 0;
          right: 0;
          margin-left: auto;
          margin-right: auto;
        }
      }
    }
  }
  .pets-slide {
    .slide-main {
      .slide-contain{
        height: 54vh;
        background-color: rgba($black,0.5);
        h2{
          color: $theme-color1;
        }
        h3{
          color: $white;
        }
        .form-control{
          width: 272px;
          height: 48px;
        }
      }
      .animat-block{
        .animat1{
          width: 250px;
        }
        .animat2{
          width: 250px;
        }
      }
    }
  }

  .grocery-slide {
    .slide-main {
      .slide-contain{
        position: relative;
        z-index: 1;
        justify-content: center;
        background-color: rgba($black,0.5);
        h3{
          color: $theme-color1;
          margin-bottom: 10px;
        }
        h2{
          margin-bottom: 7px;
        }
        h4{
          color: $theme-color1;
          margin-bottom:18px;
        }
      }
      .animat-block {
        .animat1{
          left: 0;
          right: 0;
          margin-left: auto;
          margin-right: auto;
          width: 70%;
        }
      }
    }
  }

  .megastore-slide {
    .slide-main {
      .slide-contain {
        h4{
          margin-bottom: 11px;
        }
        h2{
          margin-bottom: 12px;
        }
        h3{
          margin-bottom: 20px;
        }
      }
    }
  }
  
  // video banner //
  .video-banner {
    .video-banner-contain{
      height: 320px;
      h3{
        margin-top: 17px;
      }
      h2{
        margin-top: 15px;
      }
    }
  }
  
  // testimonial //
  .testimonial2 {
    .testimonial-box {
      .img-wrapper{
        margin-bottom: 17px;
      }
    }
  }

  // app download //
  .app-download {
    .app-download-main {
      background-position:28% !important; 
    }
  }

  // hot deal //
  .hotdeal-second {
    .hotdeal-box {
      .img-wrapper {
        a{
          height: 215px;
        }
      }
      .hotdeal-contain {
        padding: 15px;
        .timer2 {
          span{
            min-width: 52px;
            padding-top: 5px;
            padding-bottom: 5px;
          }
        }
      }
    }
  }


  // inner page //


  // order tracking // 
  .order-tracking {
    .order-tracking-box {
      .form-group {
        .form-control{
          height:40px;                  
        }
      }
    }
  }


  


}
@media(max-width:767px){
  .layout-header1 ~ .category-header .gift-dropdown.dropdown-menu {
    right: 0 !important;
    transform: translate3d(0px, 63px, 0px) !important;
  }

  
  .theme-slider{
    .slider-banner {
      &.slide-banner-1{
        .layout1-slide-1{
          li {
            left: -120px;
            &:nth-child(2) {
              right: -50px;

            }
          }
        }
        .layout1-slide-3{
          li{
            left: -100px;
            &:nth-child(2) {
              right: -30px;
            }
          }
        }
        .layout1-slide-2{
          li{
            left: -50px;
            &:nth-child(2) {
              right: -60px;
            }
          }
        }
      }
      &.slide-banner-2  {
        .slider-banner-contain  {
          .sub-contain {
            background-color: rgba($white ,0.5);
          }
        }
      }
    }
  }
  .layout-3-hotdeal>div:nth-child(2){
    padding: 0 15px;
    width: 100%;
    margin-top: 15px;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .blog-page .order-sec {
    padding-left: 15px;
  }
  .tab-product-main {
    .tab-prodcut-contain {
      ul {
        li{
          &:nth-child(n+2){
            margin-left: 20px;
          }
        }
      }
    }
  }
  .left-pro-img {
    margin-top: 30px !important;
  }
  .media-view{
    display: none;
  }
  .category-header-2 {
    .navbar-menu {
      .category-left {
        .icon-block {
          ul{
            li {
              .cart-item{
                display: none;
              }
            }
          }
        }
        .nav-block {
          .nav-left {
            .navbar{
              padding: 18px 0;
            }
          }
        }
        .menu-block {
          .toggle-nav{
            padding-top: 13px;
            padding-bottom: 13px;
          }
        }
      }
    }

    
  }
  
  .footer-title h5{
    color: $body-text;
  }
  .hot-deal .hot-deal-contain {   
    .hot-deal-subcontain>div:nth-child(3){
      position: relative;
      bottom: 0;
    }
    .hotdeal-right-slick {      
      img {
        width: 50%;
        margin: 0 auto;
      }
    }
  }
  .theme-pannel-main{
    display: none;
  }
  .theme-tab.product{
    margin-bottom: 0px;
  }
  .contact-page {
    .theme-form {
      padding: 15px;
      border-width: 15px;
    }
  }
  .testimonial .testimonial-contain .media .testimonial-img{
    width: 80px;
    height: 80px;
  }
  .testimonial3 {
    .testimonial-box {
      .testimonial-detail{
        h3{
          margin-bottom: 5px;
        }
        p{
          margin-bottom: 7px;
        }
        h6{
          margin-bottom: 15px;
        }
      }
      .img-wrapper{
        margin-bottom: 15px;
      }
    }
  }
  .testimonial4 {
    .testimonial-box {
      .img-wrapper{
        margin-bottom: 10px;
      }
    .testimonial-detail{
      p{
        margin-bottom: 9px;
      }
      h6{
        margin-bottom: 13px;
      }
    }
      
    }
  }
  .collection-banner {
    .collection-banner-main {
      .collection-img {
        height: 200px;
      }
      &.banner-11 {
        .collection-img{
          height: 240px;
        }
      }
      &.banner-14{
        .collection-img{
          height: 220px;
        }
      }
      &.banner-16{
        .collection-img{
          height: 250px;
        }
      }   
      &.p-top {
        .collection-banner-contain{
          align-items: center;
        }
      }



    }


    
     .collection-p1 {
      > div{
        &:nth-child(2){
          padding-left: 15px;
          padding-right: 15px;
        }

        &:nth-child(n+2){
          margin-top: 15px;
        }
      }
     }
    .collection-p2 {
      > div{
        &:first-child {
          > div {
            > div{
              &:first-child{
                padding-right: 15px;
              }
              &:nth-child(n+2){
                margin-top: 15px;
              }
            }
          }
        }
        &:last-child{
          padding-left: 15px;
          margin-top: 15px;
        }
      }



    }
    .collection-p3{
      >div{
        &:first-child{
          padding-right: 15px;
        }
        &:nth-child(n+2){
          margin-top: 15px;
          >div{
            >div{
              &:nth-child(n+2){
                margin-top: 15px;
              }
            }
          }
        }
        &:last-child {
          > div {
            > div{
              &:first-child{
                padding-right: 15px;
              }
            }
          }
        }
      }
    }
    .collection-p4{
      >div{
        &:nth-child(n+2){
          margin-top: 15px;
        }
        &:nth-child(2){
          padding-left: 15px;
          padding-right: 15px;
        }
      }
    }
    .collection-p5 {
      > div {
        > div {
          > div{
            &:nth-child(n+2){
              padding-left: 15px;
              margin-top: 15px;
            }
          }
        }
      }
    }

    .collection-p6{
      > div{
        &:nth-child(n+2){
          padding-left: 15px;
          padding-top: 15px;
        }
      }
    }


  }
  .collection-banner .pl-md-0{
    padding-right: 15px;
  }
  .layout-3-collection {
    >div {
      &:first-child {
        margin-bottom: 15px;
        padding-right: 15px;
      }
    }
  }
  .layout-3-hotdeal {
    >div {
      &:first-child {
        padding-right: 15px;
      }
    }
  }
  .collection-layout1 {
    >div {
      max-width: 100% !important;
    }
  }
  .offer-banner {
    display: none;
  }
  .offer-banner-img img {
    height: 300px;
  }
  

  // counter block//
  .counter-block{
    >div{
      &:nth-child(n+3){
        margin-top: 20px;
      }
    }
  }



  // ganral //
  .custom-container {
    padding-right: 15px;
    padding-left: 15px;
  }
  .section-big-pt-space {
    padding-top: 15px;
  }
  .section-big-pb-space {
    padding-bottom: 15px;
  }
  .section-big-py-space {   
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .section-big-mt-space {
    margin-top:30px;
  }
  .section-big-mb-space {
    margin-bottom:30px;
  }
  .section-big-my-space{
    margin-top:30px;
    margin-bottom:30px;
  }
  .small-section  {
    padding: 20px 0;
  }
  .theme-tab {
    .tab-title {
      &.media-tab {
        li {
          padding-top: 20px;
          padding-left: 10px;
          padding-right: 10px;
          &.current {
            &:before {
              height: 3px;
            }
          }
        }
      }
    }
  }

  // instagram //
  .instagram {
    .insta-contant {
      .insta-sub-contant {
        .insta-title {
          padding: 13px 20px;
        }
      }
    }
    .insta-contant1 {
      .insta-sub-contant1 {
        .insta-title {
          padding: 10px 18px;
        }
      }
    }
  }


  // key feature //
  .key-block {   
   
    > div{
      &:nth-child(n+5){
        margin-top: 15px;
      }
    }
  }


  // services //
  .services {
    .service-block {
      >div {
        padding-top: 0;
        &:nth-child(1){
          padding: 23px 0;
        }
        &:nth-child(2){
          padding-bottom: 23px;
        }
        &:last-child {
          .media {
            margin-left: 0;
          }
        }
      }
      .media {
        display: block;
        text-align: center;
      }
    }
    
  }
  .services1{
    .services-box {
      .media {
        .media-body {
          .btn{
            margin-top: 7px;
          }
        }
      }
    }
    &.style2{
      .services-box {
        .media {
          .icon-wrraper{
            margin-bottom: 15px;
          }
        }
      }
    }
  }
   


  // deal banner //
  // .deal-banner {
  //   .deal-banner-containe {
  //     h1 {
  //       margin-bottom: 13px;
  //     }
  //   }
  // }

  //descount banner //
  .discount-banner {
    padding-top: 35px;
    padding-bottom: 35px;
    .discount-banner-contain {
      h2 {
        margin-bottom: 10px;
      }
      h1 {
        line-height: 1.4;
        margin-bottom: 8px;
      }
      .rounded-contain {
        .rounded-subcontain {
          line-height: 1.4;
          padding: 12px 0;
        }
      }
    }
  }

  // collection banner //
  .collection-banner {
    &.layout-3{
      .collection3 {
        >div {
          &:first-child{
            padding-right: 15px;
          }
        }
      }
    }
    .collection {
      >div {
        &:nth-child(2){
          padding: 0;
        }
      }
    }
    .collection2 {
      >div {
        &:first-child,&:nth-child(2){
          margin-bottom: 15px;
        }
      }
    }
    .collection3 {
      >div {
        &:first-child{
          margin-bottom: 15px;
        }
      }
    }
    .collection5 {
      >div {
        margin-top: 0;
        &:last-child {
          margin-top: 15px;
          padding-left: 15px;
          padding-right: 15px;
        }
      }
    }
    .collection-banner-main {
      .collection-banner-contain {
        padding: 15px;
        h3 {
          font-size: 25px;
        }
        h4 {
          font-size: 25px;
        }
      }
      &.banner-1 {
        .collection-banner-contain {
          h3 {
            font-size: 22px;
          }
          h4 {
            font-size: 40px;
          }
        }
      }
      &.banner-2 {
        .collection-banner-contain {
          padding: 28px;
          h3 {
            font-size: 22px;
            margin-bottom: 5px;
          }
          h4 {
            font-size: 22px;
          }
          .shop{
            margin-top: 10px;
            a {
              font-size: 14px;
            }
          }
        }
      }
      &.banner-3 {
        .collection-banner-contain {
          h3 {
            font-size: 32px;
          }
          h4 {
            margin-top: 15px;
            font-size: 15px;
            margin-bottom: 90px;
          }
        }
      }
      &.banner-4 {
        .collection-banner-contain {
          h3 {
            font-size: 24px;
          }
          h4 {
            font-size: 30px;
          }
        }
        .collection-img {
          height: 265px;
        }
      }
      &.banner-5 {
        .collection-banner-contain {
          h3 {
            font-size: 14px;
          }
          h4 {
            font-size: 18px;
            margin-bottom: 10px;
          }
        }
      }
    }
  }
  .banner-3-padding {
    >div {
      &:nth-child(2){
        padding-right:15px !important;
      }
    }
  }
  .banner-4-padding {
    >div {
      padding-left:15px !important;
    }
  }
  .collection2 {
    >div {
      &:first-child,&:nth-child(2){
        padding-right: 15px;
        padding-left: 15px;
      }
    }
  }

  // contact banner //
  .contact-banner {
    padding: 20px 0 12px;
    .contact-banner-contain {
      display: unset;
      text-align: center;
      .contact-banner-img {
        margin-bottom: 15px;
        img {
          margin-right: 0;
        }
      }
      h3 {
        margin-bottom: 10px;
        margin-right: 0;
        font-size: 16px;
      }
    }
  }
  .subscribe-block1 {
    > div{
      &:nth-child(n+2){
        padding-left: 15px;
        margin-top: 15px;
      }
    }
  }
  .contact-banner2 {
    .subscribe-box {
      height: auto;
      h2{
        margin-bottom: 8px;
      }
    }
    .contact-bannerbox{
      height: auto;
    }
  }

  // product box //
  .product {
    .product-box {
      .product-imgbox {
        .new-label {
          padding: 5px;
          width: 80%;
          height: 80%;
          &:before {
            border-top-width: 50px;
            border-right-width:50px ;
          }
        }
        .new-label1 {
          width: 40px;
          height: 40px;
        }
        .new-label3 {
          padding: 5px;
          text-transform: capitalize;
        }
        .product-icon {
          button {           
            font-size:12px;
          }
          a {
            i {
              padding: 8px;
              font-size: 12px;
            }
          }
        }
      }
      .product-detail {
        .icon-detail {
          button {           
            margin: 0 3px;
            i {
              font-size: 12px;
            }
          }
          a {
            margin: 0 3px;
            i {
              padding: 8px;
              font-size: 12px;
            }
          }
        }
        &.detail-center1 {
          padding-bottom: 5px;
        }
      }
    }
  }

  // hot deal//
  .hot-deal {
    &.hotdeal-first{
      .hot-deal-contain {
        .hotdeal-right-slick {
          .img-wrraper{
            background-color: $white;
            >div{
              width: 50% !important;
            margin-left: auto;
            margin-right: auto;         
            position: relative;
            }         
          }
        }
      }
      
    }
    &.hotdeal-two {
      .hot-deal-contain{
        padding: 20px;
        .rating{
          margin-bottom: 10px;
        }

      }
    }
    &.hotdeal-third {
      .hot-deal-contain {
        .hotdeal-right-slick {
          .img-wrraper {
            background-color: $white;
            > div{
              width: 50%;
              height: 330px;
              margin-left: auto;
              margin-right: auto;
            }
          }
        }
      }
    }


    .hot-deal-contain {
      .hotdeal-right-nav {
        img {
          width: 115px;
        }
      }
      .hot-deal-subcontain {

        .deal-order-3{
          order: 3;
          margin-top: 8px;
        }
        >div {        
          &:last-child {
            margin-top:18px;
          }
        }
      }
      .hot-deal-center {
        margin-left: 0;
        text-align: center;      
        .timer {
          margin-top: 15px;
          span {
            padding: 15px;
          }
        }
        h6 {
          margin: 10px 0;
        }
        .btn{
          margin-top: 17px;
        }

      }
    }
    .hot-deal-contain1 {
      .hot-deal-subcontain {
        >div {
          &:nth-child(2),&:nth-child(3) {
            margin-top: 15px;
          }
        }            
      }
    }
    
  &.hotdeal-first,&.hotdeal-third {
    .hot-deal-contain {
      text-align: center;
      .hotdeal-right-nav {
        img{
          margin-right: auto;
          margin-left: auto;
        }
      }
      .btn{
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
  }


  
  .Jewellery-banner {
    a {
      font-size: 14px;
    }
    h6 {
      font-size: 30px;
    }
  }

  // footer //
 .footer-title{
   border-bottom: 1px solid $gray;
   padding-bottom:15px;
   margin-bottom: 15px;
   position: relative;
   &.active {
    .according-menu {
      &:before {
        content: "\f106";
        position: absolute;
        right:15px;
        top: 5px;
        color:$dark;
        font-size: 20px;
      }
    }
  }
  .according-menu {
    font-family: $font-awesome ;
    &:before {
      content:"\f107";
      position: absolute;
      right:15px ;
      top:9px;
      color: $dark;
      font-size: 20px;
      line-height: 0;
    }
  }
   &.mobile-title{
     display: block;
   }
 }
 .footer-contant{
   padding-bottom: 15px;
   .footer-logo{
     margin-bottom: 10px;
     img{
       height: 33px;
     }
   }
    ul{
      li{
        padding-bottom: 8px;
      }
    }
    ul.sosiyal{
      margin-top: 10px;
    }
    P{
      margin-bottom: 10px;     
    }
 }
  .footer1 {
    .footer-main{
      display:block;
      >div{
        max-width:100%;
        width:100% ;
        &:nth-child(n+3){
          margin-top: unset;
        }
      }     
      .footer-box{
        &:last-child{
          .footer-title{
            margin-bottom: 0;
          }
          .footer-contant{
            padding-bottom: 0;      
            padding-top: 15px;     
          }
        }
      }
    }
  }
  .subfooter{
    text-align: center;
    .footer-left,.footer-right{
      text-align: center;
      justify-content: center;
      height: auto;
    }
    .footer-right{
      margin-top: 5px;
    }
  }

  


  // slider //
  .theme-slider {
    .offset-xl-2 {
      max-width: 100%;
    }
    .slider-banner {
      .slider-img {
        height: 300px;
      }
      .slider-banner-contain {
        padding: 0 40px;
        h4 {
          font-size: 14px;
          padding-bottom: 5px;
        }
        h1 {
          font-size: 30px;
          padding-bottom: 4px;
        }
        h2 {
          font-size: 16px;
          padding-bottom: 7px;
        }
        .btn-rounded {
          padding: 8px 22px;
        }
      }

      &.slide-banner-3{
        .slider-img {
          height: 270px;
        }
      }
      &.slide-banner-4  {
        .slider-img {
          height: 300px;
        }
      }
      &.slide-banner-5 {
        .layout6-slide-1  {
          li {
            width: 300px;
            right: 0px;
          }
        }
      }
    }
  } 

  .megastore-slide {
    .mega-slide-block {
      > div{
        &:first-child {
          > div {
            > div:last-child{
                padding-left: 15px;
            }
          }
        }
      }
    }
  }

  // header //
  .top-header {
    .top-header-left {
      .shpping-order {
        display: none;
      }
    }
  }
  .top-header2 {
    .top-header-left{
      text-align: center;
    }
    .top-header-right{
      text-align: center;
    }
  }
  .layout-header1 {
    .main-menu-block {
      .menu-right {
        .icon-nav {
          ul {
            .mobile-user {
              a {
                i {
                  padding-right: 15px;
                }
              }
            }
          }
        }
      }
    }
  }
  .category-header {
    .navbar-menu {
      .category-left {
        width: 80%;
      }
      .category-right {
        .gift-block {
          .grif-icon {
            display: block;
          }
          .gift-offer {
            display: none;
          }
        }
      }
    }
  }
  .category-header-2 {
    .navbar-menu {
      .category-left {
        .nav-block {
          min-width: 200px;
          margin-left: 30px;
          .nav-left {
            width: 200px;
          }
        }
        .icon-block {
          ul {
            li {
              a {
                i {
                  font-size: 30px;
                }
              }
            }
            .mobile-user {
              a {
                i {
                  padding-right: 15px;
                }
              }
            }
          }
        }
        .menu-block {
          margin-left: 20px;
        }
      }
      .category-right {
        .contact-block {
          display: none;
        }
        .gift-block {
          padding: 10px 28px;
          .grif-icon {
            i {
              font-size: 28px;
            }
          }
        }
      }
    }
  }
  .layout-header2 {
    .main-menu-block {
      .input-block {
        .input-box {
          .big-deal-form {
            .input-group {
              span {
                display: none;
              }
              select {
                width: 115px;
                background-image: none;
              }
            }
          }
        }
      }
      
    }
  }

  // newslatter //
  .newsletter {
    display: block;
    padding: 30px 0;
    .news-leble {
      justify-content: center;
      padding-right: 0;
      img {
        height: 40px;
      }
    }
    .subscribe-block {
      margin-top: 25px;
      justify-content: center;
      .input-group {
        justify-content: center;
        width: auto;
      }
    }
  }


  // key feature//
  


  // inner pages //
  .blog-detail-page .comment-section li {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .product-pagination {
    .pagination {
      justify-content: center;
      border-left: 1px solid $light-border;
    }
  }
  .about-page {
    text-align: center;
    p {
      line-height: 1.5;
    }
  }
  .team {
    h2 {
      font-size: 25px;
    }
  }
  .product-wrapper-grid {
    &.list-view {     
      .product-box {
        display: block;
        border: 1px solid $light-border;
        padding: 15px;
        margin-top: 30px;
        .product-imgbox {
          margin: 0 auto 15px;
          width: 50% !important;
        }
        .product-detail {
          padding-left: 0;
        }
      }     
    }
  }
  .collection {
    .partition-collection {
      > div {
        &:nth-last-child(1),  &:nth-last-child(2), &:nth-last-child(3){
          margin-top: 30px;
        }
        &:nth-child(n+2) {
          margin-top: 15px;
        }
      }
    }
    .collection-block  {
      img,.bg-size {
        margin-bottom: 10px;
      }
    }
  }
  .collection-product-wrapper {
    .product-pagination {
      .pagination {
        .page-item {
          a {
            padding: 15px 18px;
          }
        }
      }
      .product-search-count-bottom {
        justify-content: center;
        padding-right: 0;
        h5 {
          padding: 10px 0;
        }
      }
      .theme-paggination-block {
        nav {
          border-right: 1px solid #ddd;
          border-bottom: 1px solid #ddd;
        }
      }
    }
  }
  .order-history {
    .responsive-data {
      display: block;
    }
  }
  .cart-section, .wishlist-section {
    tbody {
      tr {
        td {
          a {
            justify-content: center;
            display: flex;
            align-items: center;
          }
        }
      }
    }
  }
  .cart-section {
    .cart-table {
      thead {
        th {
          &:nth-last-child(-n+4) {
            display: none;
          }
        }
      }
    }
    tbody {
      tr {
        td {
          &:nth-last-child(-n+4) {
            display: none;
          }
          .mobile-cart-content {
            display: flex;
          }
        }
      }
    }
  }
  .wishlist-section {
    .cart-table {
      thead {
        th {
          &:nth-last-child(-n+3) {
            display: none;
          }
        }
      }
    }
    tbody {
      tr {
        td {
          &:nth-last-child(-n+3) {
            display: none;
          }
          .mobile-cart-content {
            display: flex;
          }
        }
      }
    }
  }
  .search-product {
    > div {
      &:nth-child(n+3) {
        margin-top: 15px;
      }
    }
  }
  .cart-section,.wishlist-section  {
    .wishlist-buttons {
      padding-top: 25px;
    }
  }
  .tab-pane {
    iframe {
      width: 100%;
    }
  }
  .product-order {
    .product-order-detail {
      img {
        height: auto;
      }
    }
  }
  .typography_section {
    .typography-box {
      .typo-content {
        &.product-pagination {
          .pagination {
            border-left: none;
          }
        }
        &.typo-buttons {
          a {
            &:last-child {
              margin-top: 5px;
            }
          }
        }
      }
    }
  }
  .related-pro {
    .product-box {
      padding-right: 0;
    }
  }
  .product-box3 {
    .media{
      display: block;
      .img-wrapper{
        img{
          width: 100%;
        }
      }
    }
    .product-detail{
      padding-left: unset;
      padding-top: 15px;
      .rating{
        margin-bottom: 10px;
      }
    }
  }

  .product-block4 {
    > div{
      padding-left: 10px;
      padding-right: 10px;
      &:nth-child(n+3){
        margin-top:15px;
      }
    }
  }

  

  // creative card //
  .collection-filter  {
    >div {
      &:first-child {
        padding-top: 15px !important;
      }
      &:last-child {
        padding-bottom: 15px !important;
      }
    }
    .creative-card {
      &.creative-inner {
        padding-right: 15px;
        padding-left: 15px;
        padding-top: 10px;
        padding-bottom: 10px;
      }
    }
  }

  // center-product //
  .center-product {
    .theme-card {
      margin-right: 0 ;
    }
  }

  // review page //
  .review-block {
    >div {
      &:nth-child(n+2) {
        margin-top: 15px;
      }
    }
  }



  // masonory banner //
  .masonory-banner {
    .masonary-banner-main {
      .masonory-banner-img {
        height: 250px;
        &.masonory-img1 {
          height: 515px;
        }
      }
      .masonary-banner-contant {
        background-color: rgba($black,  0.3);
        h5 {
          letter-spacing: 0.05em;
        }
        h2 {
          margin-bottom: 10px;
        }
        .btn-rounded {
          margin-top: 10px;
        }
      }
    }
  }


  // theme modal //
  .theme-modal  {
    &.cart-modal  {
      .product-section {
        display: none;
      }
    }
  }

  // blog page //
  .blog-detail-page  {
    .blog-detail  {
      text-align: center;
      h3 {
        text-align: center;
      }
      .post-social {
        text-align: center;
      }
    }
    .blog-advance {
      p {
        text-align: center;
      }
    }


  }
  .blog-page {
    .order-sec {
      order: -1;
    }
    .blog-media {
      border-width: 20px;
      padding:20px 0;
      text-align:center;
      &:last-child {
        margin-bottom: 30px;
      }
      &.media-change{
        text-align:center;
      }
    }
    .blog-sidebar {
      .theme-card{
        border-width: 20px;
        padding: 20px 15px;
      }
      ul {
        li {
          display: block;
        }
      }
    }
  }


  
  // checkout page //
  .checkout-page  {
    .checkout-form  {
      .checkout-details {
        margin-top: 15px;
      }
    }
  }

  // title //
  .title6 {
    h4 {
      padding: 0 10px;
    }
  }
  .title7 {
    .line{
      &:before{
        width: 10px;
        height: 10px;
        top: -4px;
      }
    }
  }
  .title8{
    margin-bottom: 15px;
  }

  //  order success //
  .product-order  {
    .final-total {
      h3 {
        margin-bottom: 10px;
      }
    }
  }

  //compare page //
  .compare-page  {
    .table-wrapper  {
      .table  {
        tbody  {
          tr {
            .product_price {
              margin: 10px 0;
            }
          }
        }
      }
    }
  }

  // product sidebar page //
  .product-side-tab {
    padding-top: 0 !important;
    margin-top: 0 !important;
  }
  .product-right {
    &.product-form-box {
      margin-bottom: 0;
    }

  }
  .tab-product {
    &.tab-exes {
      margin-top: 30px !important;
    }
  }
  .bottom-cart-sticky {
    .selection-section {
      .form-group{
        &:nth-child(2){
          margin-left:15px;
        }
        .form-control{
          font-size:14px;
        }
      }
    }
  }

  //portfolio //
  .portfolio-section  {
    .portfolio-2 {
      margin-bottom: -40px;
    }
    .portfolio-3 {
      margin-bottom: -20px;
    }
  }

  // dashboadr //
  .dashboard-right  {
    .dashboard {
      padding: 20px;
    }
  }
  .dashboard-left {
    padding: 20px;
  }

  // contacaat page //
  .contact-page  {
    .map  {
      .theme-card {
        border-width: 15px;
        padding: 0;
      }
    }
  }

  // login page //
  .login-page  {
    .theme-card {
      padding: 15px;
    }
  }

  // look book page //
  .lookbook-part {
    >div   {
      &:first-child {
        margin-bottom: 30px;
      }
    }
  }

  // facebook chat //
  #fb-root {
    display: none;
  }

  // order tracking //
  .order-tracking {     
    #progressbar{
      margin-bottom: 15px;
    }
    .order-tracking-box {
      .paymant-collapce {
        ul{
          &.upi-pay {
            li{
              display: block;       
              &:nth-child(n+2){
                margin-top:15px;
              }         
              >div{
                &:first-child{                 
                  width:fit-content;                 
                  margin-bottom:10px;
                }
                &:nth-child(n+2){
                  margin-left:unset;
                }
              }
            }
          }
         
          &.bank-pay {
            
            li {
              padding-top: 20px;
              padding-bottom: 20px;
              &:nth-child(n+2){
                margin-left:10px;
              }
              img{
                width:30px;
              }
            }
          }
        }
        .cash-pay{
          .captchabox {
            .reloadbtncapcha {
              svg{
                width:14px;
              }
            }
          }
          .btn{
            margin-top: 10px;
          }
        }
      }
    }
  }
}
@media(max-width: 600px){
// collection banner //
  .collection-layout1{
    >div {
      &:first-child {
        margin-bottom: 15px;
      }
      &:nth-child(2){
        margin-bottom: 0;
      }
      &:nth-child(3){
        padding-bottom: 0;
      }
    }
  }
  .layout-4-collection {
    >div {
      flex: 0 0 100%;
      max-width: 100%;
      &:nth-child(2) {
        padding-left: 15px;
        padding-top: 15px;
      }
      &:first-child {
        padding-right: 15px;
      }
    }
  }

  .theme-slider {
    .offset-xl-2{
      max-width: 100%;
    }
    .slider-banner  {
      .layout2-slide-1  {
        li {
          width: 400px;
        }
      }
      .layout2-slide-2 {
        li {
          width: 250px;
          bottom: 10px;
        }
      }
      .layout2-slide-3  {
        li {
          bottom: 10px;
          width: 250px;
        }
      }
    }
  }
  .offer-banner{
    display: none;
  }
  .collection-banner .offset-xl-2{
    max-width: 100%;
    flex: 0 0 100%;
  }
  .collection-banner {
    .collection>div:nth-child(2),.pl-md-0{
      padding-left: 15px;
      padding-bottom: 15px;
      max-width: 100%;
      flex: 0 0 100%;
    }
    .collection {
      >div {
        &:nth-child(2){
          padding-right: 15px;
        }
      }
    }
  }

  // hotdeal //
  .hot-deal {
    &.hotdeal-first{
      .hot-deal-contain {
        .hotdeal-right-slick {
          .img-wrraper{
            div{
              height: 250px;
            }
          }
        }
      }
    }
    &.hotdeal-third{
      .hot-deal-contain {
        .hotdeal-right-slick {
          .img-wrraper{
            div{
              height: 250px;
            }
          }
        }
      }
    }
  }

}
@media(max-width: 577px){
 
  // button //
  .btn-rounded, .btn-white, .btn-normal,.btn-solid {
    padding: 13px 15px;
  }

  // instagram //
  .instagram {
    .insta-contant {
      .insta-sub-contant {
        .insta-title {
          padding: 9px 13px;
        }
      }
    }
    .insta-contant2 {
      .insta-sub-contant2 {
        .insta-title {
          padding: 10px 15px;
        }
      }
    }
  }

  // collection banner //
  .collection-banner {
    .collection-banner-main {
      .collection-img {
        height: 190px;
      }
      &.banner-4 {
        .collection-img {
          height: 195px;
        }
      }
    }
  }

  // product box //
  .product {
    .product-box {
      .product-detail {
        .product-title {
          .price {
            font-size: 14px;
          }
        }
      }
      .product-imgbox {
        .new-label {
          padding: 3px;
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  
 

 

  // slider //
  .theme-slider {
    .slider-banner {
      .slider-img {
        height: 250px;
      }
      &.slide-banner-1 {
        .slider-img {
          height: 200px;
        }
        .slider-banner-contain {
          h1 {
            font-size: 20px;
          }
          h2 {
            font-size: 14px;
          }
        }
      }
      &.slide-banner-2 {
        .slider-img {
          height: 260px;
        }
        .slider-banner-contain {
          .sub-contain {
            padding: 20px;
            min-width: 220px;
            span {
              font-size: 14px;
            }
          }
        }
      }
      &.slide-banner-3 {
        .slider-banner-contain {
          padding: 0 30px;
          h5 {
            font-size: 14px;
          }
          h3 {
            font-size: 20px;
          }
          h1 {
            font-size: 28px;
          }
          h2 {
            font-size: 14px;
          }
          .btn-normal {
            padding: 7px 12px;
            font-size: 12px;
          }
        }
      }
      &.slide-banner-4 {
        .slider-img {
          height:300px;
        }
      }
      &.slide-banner-5 {
        .slider-banner-contain {
          padding: 0 30px;
          h5 {
            font-size: 14px;
          }
          h3 {
            font-size: 20px;
          }
          h1 {
            font-size: 28px;
          }
          h2 {
            font-size: 14px;
          }
          .btn-normal {
            padding: 7px 12px;
            font-size: 12px;
          }
        }
      }
    }
  }

  // header //
  .category-header {
    height: 0;
    .navbar-menu {
      padding: 0;
      .category-left {
        display: none;
      }
      .category-right {
        .gift-block {
          order: -1;
          margin-left: 0;
          display: none;
        }
        .contact-block {
          i {
            display: none;
          }
          span {
            font-size: 0;
          }
        }
      }
    }
  }
 

  // masonory banner //
  .masonory-banner {
    .masonary-banner-main {
      .masonory-banner-img {
        height: 207px;
        &.masonory-img1 {
          height: 430px;
        }
      }
    }
  }
  // .masonory-p1 {
  //   > div{
  //     &:nth-child(n+2){
  //       margin-top: 15px;
  //     }
  //   }
  // }

  /*=====================
     Inner pages CSS
==========================*/
  // inner pages //
  .template-password {
    #container {
      #login {
        margin-bottom: 0;
      }
    }
  }
  .error-section {
    padding: 100px 0;
    h1 {
      font-size: 100px;
    }
    h2 {
      margin: 20px 0;
      font-size: 18px;
    }
  }
  .about-page {
    .about-head {
      display: inline-block;
      width: 100%;
      text-align: center;
      .theme-breadcrumb {
        .breadcrumb {
          justify-content: center;
          padding-top: 10px;
          padding-bottom: 0;
        }
      }
    }
  }
  .blog-detail-page {
    .blog-detail {
      .post-social {
        li {
          display: block;
          & + li {
            padding-left: 0;
            margin-left: 0;
            border-left: none;
          }
        }
      }
    }
  }
  .collection {
    .collection-block {
      .collection-content {
        p {
          margin-bottom: 10px;
        }
        h4, h3 {
          margin-bottom: 5px;
        }
      }
    }
  }
  .collection-product-wrapper {
    .product-wrapper-grid {
      .product-box {
        margin-top: 30px;
      }
    }
    .product-top-filter {
      .product-filter-content {
        .search-count {
          padding: 10px 20px 10px 20px;
          h5 {
            font-size: 14px;
            line-height: 20px;
          }
        }
        .product-page-per-view, .product-page-filter {
          width: 100%;
          select {
            padding: 10px 20px 10px 20px;
          }
          &:before {
            top:11px;
          }
        }
        .product-page-per-view {
          border-right:1px solid $light-border !important;
          border-bottom: 1px solid $light-border;
        }
      }
      .popup-filter {
        .search-count {
          padding-top: 10px;
          padding-bottom: 10px;
        }
        .product-page-per-view {
          border-bottom: 1px solid $light-border;
          width: 100%;
          select {
            border-right: none;
            padding-top: 10px;
            padding-bottom: 10px;
          }
          &:before {
            top:14px;
          }
        }
        .product-page-filter {
          width: 100%;
          border-top: none;
          select {
            padding-top: 10px;
            padding-bottom: 10px;
          }
          &:before {
            left:unset;
            right: 35px !important;
            top:14px;
          }
        }
      }
    }
  }
  .video-product {
    video {  
      height:280px;     
    }
  }
  .cart-section {
    .cart-buttons {
      > div {
        &:last-child {
          padding-right: 15px
        }
      }
      .btn-solid {
        padding: 7px 8px;
      }
    }
  }
  .wishlist-section {
    .btn-solid {
      padding: 7px 8px;
    }
  }
  .collection-wrapper {
    .order-up {
      order:-1;
    }
    .tab-product {
      margin-top: 15px;
    }
  }
  .checkout-page {
    .checkout-form {
      .checkout-details {
        padding: 15px;
      }
    }
  }
  .product-box {
    .img-wrapper {
      .label-block {
        .label3 {
          font-size: 12px;
          padding: 13px 8px;
        }
      }
    }
    .img-block {
      .label-wrapper {
        .label1, .label2 {
          padding: 6px 9px 8px 15px;
          font-size: 12px;
        }
      }
    }
  }
  .tab-product, .product-full-tab {
    .tab-content {
      &.nav-material {
        p {
          padding: 15px 0;
        }
      }
    }
  }
  .tab-product {
    .tab-content {
      &.nav-material {
        p {
          line-height: 1.3;
        }
      }
    }
  }
  .slider-right-nav {
    .slick-slide {
      &:first-child {
        > div {
          margin-top: 15px;
        }
      }
    }
  }
  .lookbook {
    .lookbook-block {
      .lookbook-dot {
        .dot-showbox {
          width: 90px;
          top:-70px;
          .dot-info {
            h5 {
              line-height: 16px;
            }
          }
        }
      }
    }
  }
  .padding-cls {
    padding-left: 15px;
    padding-right: 15px;
  }
  .success-text {
    i {
      font-size: 40px;
    }
    p {
      font-size: 16px;
      margin-bottom: 10px;
      line-height: 1.2;
    }
    h2 {
      margin-bottom: 10px;
    }
  }
  .order-success-sec {
    >div  {
      margin-top: 10px;
    }
  }
  .delivery-sec {
    padding: 15px;
    margin-top: 0;
  }
  .product-order  {
    h3 {
      font-size: 20px;
    }
    .product-order-detail {
      .order_detail {
        h4, h5 {
          font-size: 14px;
          line-height: 1.2;
        }
      }
    }
    .total-sec {
      ul {
        li {
          font-size: 16px;
        }
      }
    }
    .final-total {
      h3 {
        font-size: 16px;
      }
    }
  }
  .beauty-about {
    .service {
      .service-block1 {
        margin-bottom: 20px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
  .main-menu {
    .menu-left {
      .navbar {
        i {
          font-size: 24px;
        }
      }
    }
  }
  .typography_section {
    .typography-box {
      .typo-content {
        &.typo-buttons {
          a {
            &:last-child {
              margin-top: 0;
            }
          }
          .btn-solid {
            &.btn-sm {
              padding: 3px 12px;
            }
          }
        }
      }
    }
  }
  .bundle {
    .bundle_img {
      .img-box {
        img {
          max-width: 70px;
        }
      }
    }
    .bundle_detail {
      .theme_checkbox {
        padding: 0;
      }
    }
  }

  // tools //
  .tools-parallax-product {
    &.full-banner {
      padding-top: 45px;
      padding-bottom: 45px;
    }
  }

 
}
@media(max-width:576px) {
  .sticky{
    .header7 .sm-horizontal .mobile-back {
      padding: 15px;
    }
    &.header-style2{
      .category-header7 {
        padding-top: 10px;
        padding-bottom: 10px;
      }
    }

  }
  // hot-deal //
  .hot-deal {
    .hot-deal-contain {
      padding: 25px;
      .hotdeal-right-nav {
        img {
          width: 90px;
        }
      }
      .hot-deal-center {
        .timer {
          span {
            font-size: 14px;
          }
        }
      }
    }
  }

  // media banner //

  .media-banner {
    .media-banner-box {
      .media {
        align-items: center;
        img {
          margin: 0 auto;
          width: 80px;
        }
        .media-body {
          .media-contant {
            height: auto;
            p {
              font-size: 13px;
            }
            h6 {
              font-size: 16px;
            }
          }
        }
      }
    }
    &:nth-last-child(2){
      margin-bottom: 0;
    }
  }

 

  // newslatter //
  .newsletter {
    .news-leble {
      .news-text {
        margin-left: 10px;
      }
    }
    .subscribe-block {
      display: inherit;
      text-align: center;
      .input-group {
        input {
          width: auto;
        }
      }
      .btn-normal {
        margin-top: 20px;
        padding: 13px 20px;
      }
    }
  }

  // product //
  .no-slider {
    .product-box {
      flex: 0 0 100%;
      max-width: calc(100% - 30px);
      margin: 0 15px 30px !important;
      &:nth-last-child(1) {
        margin: 0 15px 0 !important;
      }
      &:nth-last-child(2) {
        margin: 0 15px 30px !important;
      }
    }
    &.five-product {
      .product-box {
        flex: 0 0 100%;
        max-width: calc(100% - 30px);
        margin: 0 15px 30px !important;
        &:nth-last-child(1) {
          margin: 0 15px 0 !important;
        }
        &:nth-last-child(2) {
          margin: 0 15px 30px !important;
        }
      }
    }
  }

  //category page //
  .collection-product-wrapper {
    .product-wrapper-grid  {
      .product-box  {
        .product-detail {
          padding-top: 10px;
          .detail-title  {
            .detail-left  {
              .price-title {
                padding-top: 0;
              }
            }
          }
        }
      }
    }
  }
  .metro-block  {
    .product-box {
      .product-imgbox  {
        .product-detail {
          bottom: 15px;
          background-color: rgba($white,0.8);
          padding: 10px;
        }
      }
    }
  }

  .product-wrapper-grid {
    &.list-view  {     
      .product-box {
        display: block;
        .product-imgbox {
          margin:  0 auto;
        }
      }     
    }

  }


  // tap and top //
  .tap-top {
    width: 35px;
    height: 35px;
    padding: 2px;
    font-size: 19px;
  }
}
@media (max-width: 575px) {
  .discount-banner{
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .product-notification img{
    height: 60px;
  }
  .product-notification {
    width: 230px;
    padding: 10px;
    margin: 5px;
  }
  .theme-slider {
    .slider-banner {
      &.slide-banner-1 {
        .layout1-slide-1 {
          li {
            left: -170px;
            &:nth-child(2){
              right: -160px;
            }
          }
        }
      }
      &.slide-banner-2  {
        .layout3-slide-1 {
          li {
            width: 200px;
            &:last-child {
              width:200px;
            }
          }
        }
        .layout3-slide-2 {
          li {
            width: 180px;
            left: 10px;
            &:last-child {
              width: 180px;
              right: 10px;
            }
          }
        }
      }
      &.slide-banner-4 {
       .slider-banner-contain {
         justify-content: center;
       }
        .layout5-slide-1  {
         li{
           width:  80%;
         }
        }
        .layout5-slide-2 {
          li {
            width: 80%;
          }
        }
        .layout5-slide-3  {
          li {
            width: 90%;
          }
        }
      }
    }
  }
  
  .cosmetic-slide {
    .slide-main {
      .slide-contain {
       h3{
         margin-bottom: 8px;
       }
       h2{
         margin-bottom: 5px;
       }
       h4{
         margin-bottom: 10px;
       }
      }
      .animat-block{
        display: none;
      }
    }
  }
  .furniture-slide {
    .slide-main {
      .slide-contain {
        p{
          width: auto;         
        }
      }
    }
    .animat-block {
      .animat1{
        width: 410px;         
      }
      .animat2{
        width: 105px;
      }
      .animat3{
        width: 35px;
      }
      .animat4{
        width: 32px;
      }
    }
  }
  .farming-slide {
    .slide-main {
      .animat-block {
        .animat1{
          width:80%;
        }
        .animat2{
            height: 70px;
        }
        .animat3{
          height: 110px;
        }
      }
    }
  }
  .kids-slide {
    .slide-main {
      background-position: 80% !important;
      .slide-contain {
        p{
          width: auto;
        }
      }
    }
   
  }
  .digitalmark-slide{
    .slide-main {
      .slide-contain {
        .sub-contain{
          width: 100%;
          .input-group {
            width: 300px;
            .form-control{
                height:48px;
                padding-left: 18px;
            }
            .btn{
              padding: 10px 17px;
            }
          }
        }
      }
    }
  }
  .tools-slide {
    .slide-main {
      .slide-contain {
        h3{
          margin-bottom: 5px;
        }
        p{         
          width: auto;
        }
      }
    }
  }
  .pets-slide {
    .slide-main {
      .slide-contain {
      h3{
        margin-bottom: 10;
      }
      h2{
        margin-bottom: 10px;
      }
      .form-control{
        margin-bottom: 15px;
      }
        .form-control{
          width: 210px;
          height: 42px;
        }
      }
      .animat-block{
        .animat1{
          width: 200px;
        }
        .animat2{
          width: 200px;
        }
      }
    }
  }
  .grocery-slide {
    .slide-main {
      .slide-contain{
        height: 48vh;
      }
    }
  }
  .megastore-slide{
    .slide-main {
      background-position: 82% !important;
      .slide-contain{
        justify-content: center;
        text-align: center;
        background-color: rgba($black,0.5);
        h4{
          color: $white;
        }
        h3{
          color: $white;
        }
      }
    }
  }


  // header start//
  .mobile-fix-option {
    position: fixed;
    bottom: 0;
    left:0;
    width: 100vw;
    height:45px;
    z-index: 9;
    background-color:$black;          
  }
  .icon-block {
    .mobile-search,.mobile-wishlist,.mobile-cart,.mobile-setting,.mobile-user {
      position: fixed !important;
      bottom: 14px;
      //right: 10% ;
      right: 16%;
      padding: 0;
      z-index: 9 ;
      color: $white !important;
      text-align: center;
      svg {
        fill: $white !important;       
        width: 16px !important;
      }
      p {
        display: block !important;
        font-size: 8px !important;
        text-transform: capitalize;
        font-weight: 600;
        font-family: $font-1;
        margin-top: 3px !important;
      }
    }
    .mobile-search {
      //right: 90%;
      right: 80%;
    }
    .mobile-wishlist {
      //right: 70% ;
      right: 63%;
    }
    .mobile-cart {
      //right: 50% ;
      right: 48%;

    }
    .mobile-user {
      //right: 30% ;
      right: 32%;
    }
  }
  .item-count {
    .item-count-contain{
      width: 12px;
      height: 12px;
      &.item-md{
        width: 12px;
        height: 12px;
      }
    }
  }
  .searchbar-input{
     .input-group {
      .twitter-typeahead{
        width: calc(100%  - 84px);
      }
      .input-group-text {
        svg{
          width:16px;
        }
      }
    }
  }
  .layout-header2 {
    .main-menu-block {
      .sm-nav-block{
        margin-right: unset;
      }
    .brand-logo {
          img{
            height: 30px;
          }
        }     
      .icon-block {
        ul {
          li{
            svg{
              fill: $white;
              width: 16px;
            }
            .item-count-contain{
              top: -7px;
              left: 8px;
              width: 12px;
              height: 12px;
            }
            .cart-block {
              .cart-item {
                display: none;
              }
            }
          }
        }
      }

      .menu-nav{
        margin-left: unset;
      }
    }

    
  }
  .layout-header1 {
    .main-menu-block {
      .menu-right {
        .icon-nav {
          ul {
            li {
              .item-count-contain{
                left: 11px;
              }
              &.cart-block{
                .item-count-contain{
                  top: -7px;
                  left: 10px;
                }
              }
            }
          }
        }
         .toggle-nav{
           margin-left: unset;
         }
      }
      .menu-left {

        .brand-logo {
          img{
            height: 30px;
          }
        }
        .sm-nav-block{
          margin-right: unset;
        }
      }
    }

    
  }
  .layout-header3 {
    .main-menu{
      .icon-block {
        ul {
          li{
            &.mobile-wishlist {
              .cart-item{
                display: none;
              }
              .item-count-contain{
                top: -4px;
                left: 8px;
              }
            }
            &.mobile-cart {
              .item-count-contain{
                top: -7px;
                left: 6px;
              }
            }
            &.mobile-setting{
              display:inline-block;
            }
          }
        }
      }
      .menu-left{
        position: unset;
        .sm-nav-block{
          margin-right: unset;
        }

        .logo-block {
          img{
            height: 30px;
          }
        }
      }

      .icon-block {
        .toggle-nav-desc{
          margin-left: unset;
        }      
      }
    }
  }

  .layout-header4 {
    .icon-block {
     display: none;
    }   
  }
  .category-header-4 {
    .navbar-menu {
      .category-right {
        .icon-block {
          ul {
            li{
              &.mobile-setting{
                display: inline-block;
              }
            }
          }
          .toggle-nav{
            margin-left: unset;
          }
        }
      }
      .category-left {
        .sm-nav-btn{
          margin-right: unset;
        }
        .logo-block {
          img{
            height: 30px;
          }
        }
      }
    }
  } 
  .category-header7 {
    .category-contain {
      .category-left {
        .logo-block {                           
          .brand-logo {
            img{
              height: 30px;
            }
          }
        }
        
      }
    }
  }
  .sticky{
    &.header-style2{
      .category-header7 {
        .category-contain {
          .category-left {
            .header-category3{
              display: block;
            }
            .logo-block {
              display: flex;
              align-items: center;
              .mobilecat-toggle{
                display: block;
              }
            }
          }
        }
      }
    }

    
  }
  .logo-sm-center{
    position: absolute;   
    left: 50%;
    margin-right: -50%;
    transform: translateX(-50%);
  }

   // footer //
   .subfooter{
    margin-bottom: 45px;
  }


  

  // header //
  .top-header {
    height: auto;
    .top-header-left {
      justify-content: center;
    }
    .top-header-right {
      display: none;
    }
  }
  .top-header2{
     display: none;
  }
  .top-header2 .top-header-left
  .category-header {
    display: none;
  }
  .category-header-2 {
    background-color: transparent;
    .navbar-menu {
      .category-left {
        width: 100%;
        .icon-block {
          ul {
            li {
               .item-count-contain{
                top: -2px;
                left: 10px;
               }
              i {
                font-size: 18px !important;
              }
            }
            .mobile-setting,.mobile-search,.mobile-user,.mobile-wishlist,.mobile-cart {
              display: block;
              position: fixed;
              bottom: 14px;
              right: 16%;
              font-size: 22px;
            }
            .mobile-wishlist {
              right: 64%;
              .cart-item {
                display: none;
              }
              i {
                margin-right: 0 !important;
              }
            }
            .mobile-search {
              right:80%;
            }
            .mobile-user {
              right: 32%;
              padding-right: 0;
              a {
                i {
                  padding-right: 0;
                }
              }
            }
          }
        }
        .menu-block {
          width: 50%;
          text-align: right;
          .pixelstrap {
            .dark-menu-item {
              &:hover {
                color: $theme-color2;
              }
            }
          }
          .toggle-nav {
            display: none;
          }
        }
        .nav-block {
          width: 50%;
          margin-left: 0;
          .nav-left {
            display: none;
          }
        }
      }
      .category-right {
        .gift-block {
          display: none;
        }
      }
    }
  }
  .header7 {
    .header-contain {
      .logo-block{
        .brand-logo {
          img{
            height: 30px;
          }
        }
        .logo-sm-center{
          position: absolute;
          left: 50%;
          margin-right: -50%;
          transform: translateX(-50%);
        }
      }
      .icon-block {
        ul{
          li{
            .item-count-contain{
              top: -6px;
              right: -5px;
            }
            &.icon-desk-none{
              display: block;
            }
          }
          &.rounde-icon {
            li{
              width: auto;
              height: auto;
              border: none;
              border: none;
              svg{
                fill: $white;
                width: 18px;
              }
            }
          }
          &.theme-color {
            li{
              width: auto;
              height: auto;
              background-color:transparent;
              svg{
                width: 18px;
              }
            }
            &.color-full {
              li{
                background-color:transparent !important;
                &:nth-child(n+2){
                  margin-left: 0;
                }
              }
            }
          }
        }
      }
    }
  }
  .icon-lable{
    width: 12px;
    height: 12px;
  }



  // app downlod //
  .app-download {
    .app-download-main{
      background-position: 10% !important;
      position: relative;
    &:before{
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba($black,0.5);
    }
    .app-download-contian {    
      z-index: 1;
      position: relative;
      h2{
        color: $theme-color1;
        margin-bottom: 5px;
      } 
      h3{
        color: $theme-color2;
        margin-bottom: 10px;
      }    
      .btn{
        padding: 10px 25px;
      }
    }
    }
  }


  //video baner //
  .video-banner {
    .video-banner-contain {
      height: 245px;
      h3{
        margin-top: 9px;
      }
      h2{
        margin-top: 10px;
      }
    }
  }


  // key block //
  .key-block{
    .col-xs-4 {    
      flex: 0 0 25%;
      max-width: 25%;
    }
  }
  


  //sale banner //
  .sale-banenr {
    position: relative;
    &:before{
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color:rgba($black,0.6);
    }
    .sale-banenr-contain{
      justify-content: center;
      h4{
        margin-bottom: 5px; 
      }
      h2{
        margin-bottom: 10px;
      }
      h3{
        margin-bottom: 10px;
        color: $white;
      }
    }
    &.banner-style1 {
      .sale-banenr-contain {
        h2{
          margin-bottom: 10px;
        }
        h3{
          margin-bottom: 10px;
        }
      }
    }
    &.banner-style2{
      background-position:-19% !important; 
      .sale-banenr-contain{
        &.p-right{
          justify-content: center;
        }
        h4{
          color: $white;
        }
        h2{
          color: $white;
        }
        h3{
          color: $white;
        }
      }
      
    }
  }




  // subscribe //
  .subscribe1 {
    .subscribe-contain {
      .subscribe-right {
        .input-group{
          .form-control{
            width: auto;
          }
        }
      }
    }
  }
  .subscribe2 {
    .subscribe-contain {
      .form-control{
        width: 100%;
      }
    }
  }

  // deal banenr//
  .deal-banner {
    .deal-banner-containe {
      h2{
        margin-bottom: 10px;
      }
      h1{
        margin-bottom: 15px;
      }
    }
  }


  //testimonial //
  .testimonial2 {
    .testimonial-box {
      .img-wrapper{
        margin-bottom: 13px;
      }
      .testimonial-detail {
        p{
          margin-bottom: 9px;
        }
      }
    }
  }
  .testimonial5 {
    .testimonial-detail {
      h4{
        margin-bottom: 5px;
      }
      p{
        margin-bottom: 5px;
      }
    }
  }


// hot deal //
.hot-1 {
  >div {
    &:first-child {
      padding-right: 15px;
      padding-left: 15px;
    }
    &:nth-child(2){
      padding-left: 15px;
      padding-right: 15px;
      margin-top: 15px;
    }
    &:last-child {
      padding-right: 15px;
      padding-left: 15px;
    }
  }
}
.layout-3-hotdeal{
  >div{
    &:first-child{
      padding-right: 15px;
    }
    &:nth-child(4){
      padding-right: 15px;
      padding-bottom: 10px;
    }
  }
}
.hot-deal {

  &.hotdeal-two{
    .hot-deal-contain {
      .hotdeal-right-nav-1 {
        img{
          width: 70px;
          margin-left: auto;
          margin-right: auto;
        }
      }

      .hot-deal-subcontain {
        > div{
          &:nth-child(3){
            margin-top: 10px;
          }
        }
      }
      .btn{
        padding: 8px 15px;
      }
    }
    .hot-deal-contain1 {
      .hot-deal-subcontain{
        text-align: center;
        .btn{
          margin-left: auto;
          margin-right: auto;
        }
        > div{
          &:nth-child(2){
            order: 3;
            margin-top: 5px;
          }
        }
      }
    }
  }

  


  .hot-deal-contain1 { 
    .layout1-slide-2 {
      li {
        left: -140px;
        &:nth-child(2) {
          right: -140px;
        }
      }
    }
    .layout1-slide-3 {
      li {
        left: -160px;
        &:nth-child(2) {
          right: -130px;
        }
      }
    }
    .hotdeal-right-slick-1{
      text-align: center;
    }
  }
}

// creativer card //
.creative-card {
  border-width: 0;
  padding: 0;
}
.tab-product-main{
  padding: 20px 0 15px;
  .tab-prodcut-contain ul li {
    border: 1px solid;
    padding: 2px 12px;
    &:nth-child(n+2){
      margin-left: 5px;
      margin-top: 2px;
    }
    &.current {
      border-color: $theme-color1;
      &:before {
        display: none;
      }
    }
  }
  &.tab-four {
    padding: 0;
    .tab-prodcut-contain {
      ul {
        li{
          border: none;
          margin-top: 2px;
          margin-bottom: 2px;
        }
      }
    }
  }
}

// masonory-banner //
.login-page .theme-card .theme-form {
  padding: 10px;
  background-color: transparent;
}
.breadcrumb-main {
  padding: 30px 0;
}
.masonary-banner-block {
  > div {
    margin-bottom: 15px !important;
  }
  &.masonary-inner1 {
    >div  {
      &:last-child {
        margin-bottom: 0 !important;
      }
    }
  }
}
.masonary-banner-block2 {
  >div {
    &:first-child {
      margin-bottom: 15px;
    }
  }
}
.masonory-banner {
  .masonary-banner-main {
    .masonary-banner-contant {
      h5{
        width: fit-content;
        margin-left: auto;
        margin-right: auto;
      }
      .masonary-banner-subcontant {
        margin-right: 0 ;
        justify-content: center ;
        width: 100%;
      }
    }
  }
}
.masonary-banner-block1 {
  >div {
    &:first-child {
      margin-bottom: 15px;
    }
    &:last-child {
      padding-left: 0;
    }
  }
  .col-sm-6 {
    &.pl-3 {
      padding-left: 0 !important;
    }
  }
}
.masonory-p1 {
  > div{
    &:first-child{
        padding-right: 15px;
    }
    &:last-child {
      > div {
        > div {
          &:nth-child(n+2){
          >div{
            >div{
              &:last-child{
                margin-top: 15px;
              }
            }
          }
          }
          > div {
            > div{
              &:nth-child(n+2){
                padding-left: 15px;
              }
            }
          }
        }
      }
    }
    &:nth-child(n+2){
      margin-top: 15px;
    }
  }
}

// counter block//
.counter-block{
  >div{
    &:nth-child(n+3){
      margin-top: 15px;
    }
  }
}

// collection-banner //
.collection-banner {
  .collection-banner-main {
    &.banner-2 {
      .collection-img {
        height: 175px;
      }
    }
    &.banner-15 {
      .collection-img{
        height: 220px;
      }
    }
  }
  
}

// theme slider //
.theme-slider  {
  .slider-banner {
    &.slide-banner-5  {
      .layout6-slide-1  {
        li {
          width: 200px;
        }
      }
    }
    &.slide-banner-3  {
      .slider-banner-contain {
        background-color: rgba($white, 0.4);
      }
      .layout4-slide-1  {
        li {
          width:325px;
        }
      }
      .layout4-slide-2  {
        li {
          width:230px;
        }
      }
      .layout4-slide-3  {
        li {
          width: 250px;
        }
      }
    }
  }
}

// checkout page//
.checkout-second {
  .checkout-box {
    .checkout-body {
      .btn{
        padding: 10px 18px;;
      }
    }
  }
}


// multipal slider //
.multiple-slider {
  >div {
    &:nth-child(n+2){
      margin-top: 15px;
    }
  }
}

// blog page //
.blog-page  {
  .blog-media {
    .blog-left  {
      .date-label {
        padding: 5px 8px;
      }
    }
    &:hover {
      .blog-left {
        img  {
          transform:translateX(0) scale(1);
        }
      }
    }
  }
  .blog-sidebar  {
    .theme-card {
      border-width: 0;
      padding: 0;
      background-color: transparent;
    }
  }

}
.blog-detail-page  {
  .comment-section {
    padding: 0;
  }
}

// dashboard //
.dashboard  {
  .box-head  {
    h2 {
      margin-top: 5px;
    }
  }
  .box  {
    .box-title {
      padding: 9px 0;
    }
  }
}
.account-sidebar {
  margin-bottom: 11px;
}

//subscribe //
.subscribe2 {
  .subscribe-contain {
    h2{
      margin-bottom: 2px;
    }
    p{
      margin-bottom: 10px;
    }
    .form-control{
      margin-bottom: 17px;
    }
    ul{
      margin-bottom: 15px;
    }
  }
}

// login page //
.login-page  {
  .theme-card  {
    .btn {
      padding: 11px 15px;
    }
  }
}

// contacat //
.contact-page  {
  .btn {
    margin-top: 0;
  }
}

// key feature//


//about page //
.about-page {
  .creative-card {
    padding: 0 15px ;
  }
}


// search page //
.search-product {
  >div {
    &:nth-child(n+2) {
      margin-top: 15px;
    }
  }
}

// portfolio //
.portfolio-section  {
  .isotopeSelector {
    margin-bottom: 30px;
  }
}

// category page //
.collection-product-wrapper {
  .product-wrapper-grid  {
    .product-box {
      margin-top: 10px;
    }
    .col-6 {
      padding-left: 5px;
      padding-right: 5px;
    }
  }
}

// product page //
.product-right {
  .pro-group {
    .product-offer {
      .offer-contain {
        ul {
          &:nth-child(n+2){
            border-top: 1px dashed $theme-color1;
            margin-top: 10px;
          }
          li{
            display:block;
            text-align:center;
            .code-lable{
              display: block;
              margin-bottom: 5px;
              margin-left:auto;
              margin-right:auto;
            }
            &:nth-child(n+2){
              border-top: 1px dashed $theme-color1;
              padding-top: 10px;
            }
          }
        }
      }
    }
  }
}
.bottom-cart-sticky{
  &.open-cart{
    display: none;
  }
}

// cookie bar start//
.cookie-bar{
  display: block;
  text-align: center;
}


.theme-modal{
  &.blackfriday-modal{
    &#exampleModal {
      .modal-dialog{
        max-width:100%;
      }
    }
  }
  &.cyber-monday{
    &#exampleModal {
      .modal-dialog{
        max-width:100%;
      }
    }
  }
}


.order-tracking {
  #progressbar {
    li{
      &:nth-child(n+2){
        margin-left:30px;
      }
      .icon{
        width:35px;
        height:35px;
      }
    }
  }
  .order-tracking-box {
    .paymant-collapce {
      ul{
        &.bank-pay {
          display: block;
          li{
            width:calc(50% - 10px);
            display:inline-block;
            &:nth-child(n+2){
            margin-left:unset; 
            margin-top:unset;
            }
            &:nth-child(n+3){
              margin-top:10px;
            }
            &:nth-child(even){             
              margin-left: 10px;
            }
          }
        }
      }
    }
    .btn{
      padding:8px 11px;
    }
  } 
  .order-tracking-sidebar {
    .coupan-block {
      .btn{
        padding:5px 8px;
        font-size:12px;
      }
    }
  }
  .order-tracking-contain {
    .tracking-group {
      .delevery-code {
        .btn{
          padding:8px 10px;
          font-size:12px;
        }
      }
      ul{
        &.may-product {
          > li {
            .media {
              .media-body {
                .size-box {
                  ul {
                    li{
                      margin-left: unset;
                      width: 38px;
                      height: 30px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  } 


}


 



}
@media (max-width: 480px) {

  .product-slide-tab {
    padding-left: 7px;
  }
  .hot-4 {
    > div:last-child, > div:first-child {
      max-width: calc(100%);
      flex: 0 0 100%;
    }
  }
  .blog-page {
    .blog-sidebar {
      .theme-card {
        .popular-blog li .blog-date {
          height: 50px;
          width: 50px;
          padding: 5px;
        }
      }
    }
  }
  .product {
    .product-box {
      padding-right: 7px;
    }

    .pr-0 {
      padding-left: 7px;
    }
  }
  .layout-3-hotdeal .media-banner {
    padding-bottom: 1px;
  }
  .layout-5 {
    .slider-banner-contain {
      background-color: rgba(255, 255, 255, 0.3);
    }
  }
  .contact-page {
    .map {
      .theme-card {
        padding: 0;
        background-color: $white;
        border: 15px solid $white;
      }
    }

    .theme-form {
      padding: 10px;
      background-color: $white;
      border: 15px solid $white;

      textarea {
        padding: 17px 25px;
        margin-bottom: 20px;
        height: inherit;
      }
    }
  }
  .masonary-banner-block1 .col-sm-6.pl-3 {
    padding-left: 0 !important;
  }

  // discount banner //
  .discount-banner {
    .discount-banner-contain {
      h2 {
        margin-bottom: 7px;
      }

      .rounded-contain {
        .rounded-subcontain {
          padding: 15px 20px;
        }
      }
    }
  }

  // key block //
  .key-block{
    padding-left: 5px;
    padding-right: 5px;
    >div{
      padding-left: 5px;
      padding-right: 5px;
      &:nth-child(n+5){
        margin-top: 10px;
      }
    }   
  }

// subscribe //
.subscribe1 {
  .subscribe-contain {
    .subscribe-right {
      .input-group {
        .form-control{
          width: 100%;
          border-radius: 5px;
        }       
        .input-group-text{
          border-radius:5px !important;
          margin-left: auto !important;
          margin-right: auto;
          margin-top: 12px;
        }       
      }
    }
  }
}

  // collection banner //
  .collection-banner {
    .collection-banner-main {
      .collection-banner-contain {
        padding: 30px;

        h3 {
          font-size: 20px;
        }

        h4 {
          font-size: 20px;
        }
      }
      &.banner-1 {
        .collection-banner-contain {
          h3 {
            font-size: 18px;
          }

          h4 {
            font-size: 30px;
          }
        }
      }
      &.banner-2 {
        .collection-banner-contain {
          padding: 13px;

          h3 {
            font-size: 18px;
            margin-bottom: 8px;
          }

          h4 {
            font-size: 18px;
          }

          .shop {
            margin-top: 10px;

            a {
              font-size: 13px;
            }
          }
        }

        .collection-img {
          height: 185px;
        }
      }
      &.banner-3 {
        .collection-banner-contain {
          h3 {
            font-size: 25px;
          }

          h4 {
            margin-top: 10px;
            margin-bottom: 50px;
            font-size: 13px;
          }
        }

        .collection-img {
          height: 385px;
        }
      }
      &.banner-4 {
        .collection-banner-contain {
          h3 {
            font-size: 18px;
          }

          h4 {
            font-size: 20px;
          }
        }

        .collection-img {
          height: 160px;
        }
      }
      &.banner-5 {
        &.p-center {
          .collection-banner-contain {
            justify-content: flex-start;
            padding: 20px;
          }
        }

        .collection-img {
          background-position: right !important;
        }

        &.p-center {
          justify-content: left;
        }
      }
      &.banner-7 {
        .collection-banner-contain {
          padding: 10px;

          h3 {
            font-size: 14px;
          }

          h4 {
            font-size: 16px;
          }

          .shop {
            margin-top: 10px;
          }
        }
      }
      &.banner-10 {
        .collection-img{
          height: 190px;
        }
      }
       &.banner-11 {
        .collection-img{
          height: 190px;
        }
      }

       &.banner-12 {
        .collection-img{
          height: 190px;
        }
      }

      .collection-img {
        height: 190px;
      }


    }
  }

  // product box //
  .product {
    .product-box {
      .product-imgbox {

        .new-label {
          padding: 5px;
          width: 80%;
          height: 80%;
        }
        .new-label2 {
          top: 10px;
          left: 10px;
        }
        .new-label1 {
          top: 10px;
          left: 10px;
          width: 35px;
          height: 35px;
        }
        .on-sale1, .on-sale2 {
          top: 25px;
          right: -15px;
        }
        .product-icon {
          &.icon-center {
            display: unset;
            height: auto;
            width: auto;
          }
          button{
            width:30px;
            height:30px;
          }
          a{
            width:30px;
            height:30px;
          }
        }
      }
    }
  }
  

  



  //hot-deal //
  .hot-deal {
    &.space-abjust {
      .hot-deal-contain{
        padding: 20px;
      }

      
    }
    &.hotdeal-first{
      .hot-deal-contain {
        .hotdeal-right-slick {
          .img-wrraper{
            >div{
              height: 200px;
            }
          }
        }
      }
    }
    &.hotdeal-third{
      .hot-deal-contain {
        .hotdeal-right-slick {
          .img-wrraper{
            >div{
              height: 200px;
            }
          }
        }
      }
    }
    .hot-deal-contain {
      .hot-deal-center {
        p{
          margin-top: 0px;
          margin-bottom: 5px;
        }
      }
       .hot-deal-subcontain {
        > div{
          &:last-child{
            margin-top: 10px;
          }
        }
        
       }
      .hotdeal-right-nav {
        img {
          width: 100px;
        }
      }
     
    }
    .hot-deal-contain1 {
      .hot-deal-subcontain {
        .hotdeal-right-nav-1 {
          img {
            width: 80px;
          }
        }
      }
    }


    &.hotdeal-first,&.hotdeal-third{
      .hot-deal-contain {
        .timer {
          span{                      
            min-width: 49px;
          }
        }
      }
    }
  }

  

  //home slider //
  .theme-slider {
    .slider-banner {
      .slider-img {
        height: 200px;
      }

      .slider-banner-contain {
        padding: 0 25px;

        h3 {
          &:before, &:after {
            display: none;
          }
        }

        .btn-rounded {
          font-size: 12px;
          padding: 7px 12px;
        }

        h4 {
          font-size: 14px;
        }

        h1 {
          font-size: 14px;
        }

        h2 {
          font-size: 14px;
        }
      }

      &.slide-banner-2 {
        .slider-img {
          height: 220px;
          background-position: left !important;
        }

        .slider-banner-contain {
          .sub-contain {
            background-color: rgba(255, 255, 255, 0.7);
            min-width: 220px;
          }
        }
      }

      &.slide-banner-3 {
        .slider-img {
          height: 230px;
        }

        .slider-banner-contain {
          h3 {
            font-size: 16px;
          }

          h1 {
            font-size: 18px;
          }
        }
      }

      &.slide-banner-4 {
        .layout5-slide-1 {
          li {
            width: 250px;
          }
        }

        .layout5-slide-2 {
          li {
            width: 300px;
          }
        }

        .layout5-slide-3 {
          li {
            width: 250px;
          }
        }
        .slider-img {
          height: 280px;
        }
      }

      &.slide-banner-5 {
        .slider-banner-contain {
          h3 {
            font-size: 16px;
          }

          h1 {
            font-size: 18px;
          }
        }
      }
    }
  }
  .cosmetic-slide {
    .slide-main{
      background-position: 15% !important;
    }
  }

  .furniture-slide {

    .slide-main {
      background-position: 80% !important;
      .slide-contain {       
        h3{
          margin-bottom: 5px;
        }
        h4{
          margin-bottom: 5px;
        }                
      }
    }
  }

  .farming-slide {
    .slide-main {
      .slide-contain {
       
        h2{
          margin-bottom: 5px;
        }
        h4{
          margin-bottom: 10px;
        }
      }
    }
  }

  .digitalmark-slide {
    .slide-main {
      .slide-contain {
        .sub-contain {
          .input-group {
            width: 275px;           
          }
        }
      }
    }
  }

  .kids-slide {
    .slide-main {
      .slide-contain {
        h4{
          margin-bottom:5px;
        }
        h2{
          margin-bottom: 10px;
        }       
        h3{
          margin-bottom: 5px;
        }
        p{
          margin-bottom: 10px;
        }
      }
    }
  }

  .pets-slide {
    .slide-main {
      .animat-block{
        .animat1{
          left: -20px;
          width: 170px;
        }
        .animat2{
          right: -20px;
          width: 170px;
        }
      }
    }
  }

  .grocery-slide {
    .slide-main {
      .slide-contain {
        h3{
          margin-bottom: 2px;
        }
        h2{
          margin-bottom: 3px;
        }
        h4{
          margin-bottom: 10px;
        }
      }
    }
  }

  .megastore-slide {
    .slide-main {
      .slide-contain{
        height: 46vh;

         h4{
           margin-bottom: 6px;
         }
         h2{
           margin-bottom: 7px;
         }
         h3{
           margin-bottom: 13px;
         }
      }
    }
  }





  // header //
  .top-header {
    .top-header-right {
      .language-block {
        .language-dropdown {
          span {
            display: flex;
          }
        }

        .curroncy-dropdown {
          span {
            display: flex;
          }
        }
      }
    }
  }
  .category-header {
    .navbar-menu {
      .category-right {
        .gift-block {
          padding: 12px 15px;
        }

        .contact-block {
          margin-left: 20px;

          span {
            margin-left: 0;
          }
        }
      }
    }
  }
  .header7 {
    .header-contain {
      .logo-block {
        .brand-logo {
          img{
            height: 32px;
          }
        }
      }
    }
  }


  // inner pages //
  .select_input {
    select {
      padding: 12px 15px;
      margin-bottom: 18px;
    }
  }
  .about-page {
    p {
      line-height: 1.5;
    }
  }
  .blog-detail-page {
    .blog-contact {
      h2 {
        font-size: 30px;
        margin-bottom: 25px;
      }

      .theme-form {
        input, textarea {
          padding: 12px 20px;
          margin-bottom: 20px;
        }
      }
    }

    .blog-detail {
      h3 {
        margin-bottom: 15px;
      }

      img {
        margin-bottom: 25px;
      }
    }

    .blog-advance {
      ul {
        line-height: 1.5;
      }

      img {
        margin-bottom: 20px;
        margin-top: 10px;
      }

      p {
        line-height: 1.5;
      }
    }

    .comment-section {
      li {
        padding-top: 30px;
        padding-bottom: 30px;

        p {
          line-height: 1.5;
        }

        h6 {
          margin-top: 10px;
          margin-bottom: 10px;

          span {
            display: inherit;
            margin-left: 0;
          }
        }
      }

      .media {
        display: inline-block;
        text-align: center;
      }
    }
  }
  .pwd-page {
    h2 {
      font-size: 25px;
      margin-bottom: 15px;
    }
  }
  .login-page {
    .authentication-right {
      h6 {
        margin-bottom: 10px;
      }
    }

    .theme-card {
      padding: 25px;
    }
  }
  .search-block {
    .btn-solid {
      padding: 10px 15px;
    }
  }
  .contact-page {
    .map {

      iframe {
        height: 275px;
      }
    }

    .theme-form {
      input {
        padding: 8px 20px;
        margin-bottom: 15px;
      }
    }
  }
  .account-sidebar {
    width: 45%;
  }
  .success-text {
    h2 {
      font-size: 20px;
    }
    p {
      font-size: 14px;
    }
  }
  .delivery-sec {
    h3, h2 {
      font-size: 18px;
    }
  }
  .typography_section {
    .typography-box {
      .typo-content {
        &.typo-buttons {
          a {
            margin: 5px 0;
          }
        }
      }
    }
  }
  .search-product {
    > div {
      &:nth-last-child(5) {
        margin-top: 30px;
      }
    }
  }

  // product page //
  .size-box {
    ul {
      li{
        &:nth-child(n+2){
          margin-left:unset;
        }
      }
    }
  }
  .color-selector{
    &.inline {
      ul {
        li{
          &:nth-child(n+2){
            margin-left:0;
          }
        }
      }
    }
  }
  .product-right {
    .pro-group {
      .timer {
        p {
          span{
            width:50px;
            height:50px;
            font-size: 14px;
            padding-top: 10px;
            padding-bottom: 10px;
            .timer-cal{
              font-size:12px;
            }
            &:nth-child(n+2){
              margin-left:5px;
            }
          }
        }
      }
      .delivery-detail {
        .delivery-detail-contian {
          .input-group{
            height:40px;
            width:250px;
            .form-control{
              font-size:14px;
            }
          }
          .btn{
            padding:10px 20px;
          }
        }
        .delivery-lable{
          width:auto;
        }
      }
      .product-buttons {
        a{
          &:last-child{
            margin-left:5px;
          }
        }
      }
      ul{
        &.best-seller {
          display: block;
          width:fit-content;
          margin-left:auto;
          margin-right:auto;
          li{
            width:100%;
            justify-content:center;
            &:nth-child(n+2){
              margin-top:5px;
              margin-left:unset;
            }
          }
        }
        &.delivery-services{
          display:block;
          li{
            &:nth-child(n+2){
              margin-top:5px;
              margin-left:unset;
            }
          }
        }
      }     
    }
  }

  // theme modal //
  .theme-modal {
    &.cart-modal  {
      .modal-dialog {
        .modal-content {
          .modal-body  {
            .modal-bg {
              .media {
                display: block;
                text-align: center;
              }
            }
          }
        }
      }
    }
    &.blackfriday-modal{
      &#exampleModal {
        .offer-content {
          .timer {
            span{
              min-width:56px;
              font-size:20px;
              margin-left:2px;
              margin-right:2px;
              .timer-cal{
                font-size:14px;
              }
            }
          }
          .lable{
            margin-right:20px;
          }         
        }
      }
    }



  }

  // order track //
  .order-tracking {
    .order-tracking-box {
      .form-group {
        .form-control{
          height: 36px;
          padding-left: 10px;
          padding-right: 10px;
          &:nth-child(n+2){
            margin-left:10px;
          }
        }
      }
      .paymant-collapce {
        ul{
          &.upi-pay {
            li {
              .form-control{
                margin-bottom:10px;
                width: 90%;
                padding-left:10px;
                padding-right:10px;
              }
              >div{
                &:nth-child(n+2){
                  margin-left: unset;
                  display: block;               
                }
              }
              .btn{
                margin-left: unset;
              }
            }
          }
        }
        .cash-pay {
          .form-control{
            padding-left: 10px;
            padding-right: 10px;
            width: auto;
          }
        }
      }
      .card {
        .card-header {
          .payment-toggle{
            max-width:100%;
            white-space:nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
    .order-tracking-contain {
      .tracking-group {
        ul{
          &.may-product {
            > li{           
              .media {
                display:block;
                text-align:center;
                .media-body{
                  margin-left:unset;
                  .qty-box{
                    justify-content: center;
                  }
                }
              }
            }
          }
        }
      }
    }
  } 

  

  
  

  
  


}
@media(max-width:420px){
    // header //
  .category-header {
    .navbar-menu {
      .category-right {
        .gift-block {
          .grif-icon {
            i {
              display: none;
            }
          }
          .gift-offer {
            span {
              font-size: 16px;
            }
          }
        }
        .contact-block {
          span {
            span {
              font-size: 16px;
            }
          }
        }
      }
    }
  }

  // theme slider //
  .theme-slider  {
    .slider-banner {
      .layout2-slide-1  {
        li {
          width: 290px;
        }
      }
      .layout2-slide-2  {
        li {
          width: 160px;
        }
      }
      .layout2-slide-3  {
        li {
          width: 160px;
        }
      }
      &.slide-banner-2  {
        .layout3-slide-1  {
          li {
            width: 130px;
            left: 0;
            &:last-child {
              width: 130px;
              right: 0;
            }
          }
        }
        .layout3-slide-2  {
          li {
            width: 120px;
            left: 0;
            &:last-child {
              width: 120px;
              right: 0;
            }
          }
        }
      }
    }
  }

  // testimonial //
  .testimonial5 {
    .img-wrraper-mian{
      width: 100%;
    }
  }
  // product-box /







  /*=====================
     Inner pages CSS
==========================*/
  // blog pages //
  .blog-details {
    p {
      font-size: 14px;
      line-height: 1.3;
    }
  }
  .blog-details {
    p {
      font-size: 14px;
      line-height: 1.3;
    }
  }
  .product-right {
    .product-icon {
      .product-social {
        li {
          padding-right: 4px;
          a {
            i {
              font-size: 14px;
            }
          }
        }
      }
      .wishlist-btn {
        span {
          font-size: 14px;
        }
        i {
          font-size: 14px;
        }
      }
    }
  }
  .tab-product {
    .nav-material {
      &.nav-tabs {
        display: block;
        .nav-item {
          width: 100%;
          .nav-link {
            padding: 10px 20px;
            text-align: center;
          }
        }
      }
    }
  }
  .vertical-tab {
    &.tab-product, .product-full-tab {
      .nav-material {
        &.nav-tabs {
          .nav-item {
            .nav-link {
              padding: 10px 20px;
            }
          }
        }
      }
    }
  }
  .video-product {
    video {  
      height:200px;     
    }
  }
  .product-right {
    .product-buttons {
      .btn-normal{
        padding:10px 15px;
      }
    }
    &.product-form-box {
      .timer {
        span {
          min-width: 45px;
        }
      }
    }
    .timer {
      span {
        min-width: 55px;
        margin-right: 8px;
        margin-left: 0;
        &:last-child {
          margin-right: 0;
        }
      }
    }
    .product-icon {
      .product-social {
        margin-top: 0;
        li {
          padding-right: 5px;
          a {
            i {
              font-size: 14px;
            }
          }
        }
      }
      .wishlist-btn {
        i {
          font-size: 14px;
          padding-left: 10px;
          margin-left: 5px;
        }
        span {
          font-size: 14px;
        }
      }
    }
  }
  .tab-product {
    .theme-form {
      input, textarea {
        font-size: 12px;
        padding: 8px 15px;
      }
    }
  }
  .category-border {
    div {
      .category-banner {
        .category-box {
          h2 {
            font-size: 25px;
          }
        }
      }
    }
  }
  .full-banner {   
    .banner-contain {
      h2 {
        font-size: 55px;
      }
      h3 {
        font-size: 36px;
      }
    }
  }
  .theme-card {
    &.center-align {
      display: unset;
      height: unset;
      align-items: unset;
    }
    &.card-border {
      .offer-slider {
        img {
          padding: 0 15px 0 15px;
        }
      }
    }
  }
  .full-box {
    .center-slider {
      .offer-slider {
        .product-box {
          display: flex;
          border-bottom: 1px solid $light-border;
          border-top: 1px solid $light-border;
          .img-wrapper {
            img {
              height: 120px;
            }
          }
          .product-info {
            text-align: left;
            h6 {
              padding-top: 5px;
            }
            .color-variant {
              padding-top: 2px;
              li {
                height: 15px;
                width: 15px;
                padding-right: 3px;
              }
            }
          }
          .product-detail {
            padding-left: 0;
            h4 {
              font-size: 20px;
            }
            .color-variant {
              padding-top: 0;
            }
          }
          &:hover {
            .product-info {
              .btn-outline {
                display: none;
              }
              .color-variant {
                opacity: 1;
              }
            }
          }
        }
      }
    }
    .theme-card {
      .offer-slider {
        .sec-1 {
          display: inline;
          .product-box2 {
            border-bottom: 1px solid $light-border;
            &:last-child {
              border-bottom: none;
            }
            + .product-box2 {
              border-left: none;
            }
          }
        }
      }
    }
    &.center-align {
      .offer-slider {
        .product-box2 {
          padding-bottom: 15px;
        }
      }
    }
  }
  .center-slider {
    .offer-slider {
      .product-box {
        .product-detail {
          text-align: left !important;
          .rating {
            i {
              padding-right: 3px;
            }
          }
          h4 {
            padding-bottom: 0.5rem;
          }
          .color-variant {
            li {
              height: 18px;
              width: 18px;
              padding-right: 3px;
            }
          }
        }
        .img-wrapper {
          .cart-box {
            display: none;
          }
        }
      }
    }
  }
  .team {
    h6 {
      line-height: 15px;
    }
  }
  .theme-modal {
    .modal-dialog {
      .modal-content {
        .modal-body {
          .modal-bg {
            padding: 30px;
            .offer-content {
              h2 {
                font-size: 30px;
              }
            }
          }
        }
      }
    }
  }
  .login-page {
    .authentication-right {
      p {
        line-height: 1.5;
      }
    }
    .theme-card {
      .theme-form {
        input {
          padding: 10px 20px;
        }
      }
    }
  }
  .tab-product {
    .nav-material {
      &.nav-tabs {
        display: block;
        .nav-item {
          width: 100%;
          .nav-link {
            padding: 10px 20px;
          }
        }
      }
    }
  }
  .vertical-tab {
    &.tab-product, .product-full-tab {
      .nav-material {
        &.nav-tabs {
          .nav-item {
            .nav-link {
              padding: 10px 20px;
            }
          }
        }
      }
    }
  }
  .product-right {
    .product-buttons {
      .btn-solid, .btn-outline {
        padding: 7px 13px;
      }
    }
    &.product-form-box {
      .timer {
        span {
          min-width: 45px;
        }
      }
    }
    .timer {
      span {
        min-width: 45px !important;
      }
    }
    .product-icon {
      .product-social {
        margin-top: 0;
        li {
          padding-right: 5px;
          a {
            i {
              font-size: 14px;
            }
          }
        }
      }
      .wishlist-btn {
        i {
          font-size: 14px;
          padding-left: 10px;
          margin-left: 5px;
        }
        span {
          font-size: 14px;
        }
      }
    }
  }
  .tab-product {
    .theme-form {
      input, textarea {
        font-size: 12px;
        padding: 8px 15px;
      }
    }
  }
  .order-box {
    .sub-total {
      .shipping {
        width: unset;
        float: unset;
        display: flex;
        .shopping-option {
          &:last-child {
            padding-left: 20px;
          }
        }
      }
    }
  }
  .checkout-page {
    .checkout-form {
      .form-group {
        margin-bottom: 0;
      }
      input {
        &[type="text"], &[type="email"], &[type="password"], &[type="tel"], &[type="number"], &[type="url"] {
          height: 40px;
        }
      }
      select, textarea {
        height: 40px;
      }
    }
  }

  // tab //
  .theme-tab  {
    .tab-title {
      margin-bottom: 15px;
      &.media-tab  {
        li {
          display: block;
          width: fit-content;
          margin: 0 auto;
          padding-top: 10px;
          line-height: 1;
        }
      }
    }
  }
}
@media(max-width:360px){
  // product box //
  .product {
    .product-box {
      .product-imgbox {
        .product-icon {
          &.icon-inline  {
            button {
              margin:  0 1px;
            }
            a {
              margin: 0 1px;
            }
          }
        }
        .new-label {
          padding: 5px;
          width: 100%;
          height: 100%;
        }
      }
      .product-detail  {
        .icon-detail  {
          button {
            margin: 0 1px;
          }
          a {
            margin: 0 1px;
          }
        }
        &.detail-center {
          padding-top: 20px;
          .icon-detail {
            bottom: 78px;
          }
        }
      }
      &:hover {
        .product-detail {
          &.detail-center {
            .icon-detail {
              bottom: 78px;
            }
          }
        }
      }
    }
  }
  // collection banner //
  .collection-banner {
    .banner-5 {
      &.p-center {
        .collection-banner-contain {
          .sub-contain {
            background-color: rgba($white,0.4);
            padding: 15px;
          }
        }
      }
    }
    .collection-banner-main {
      .collection-img {
        height: 135px;
      }
      &.banner-2 {
        .collection-img {
          height: 135px;
        }
      }
      &.banner-3 {
        .collection-img {
          height: 280px;
        }
      }
      &.banner-4 {
        .collection-img {
          height: 115px;
        }
      }
    }
  }
  // hot-deal //
  .hot-deal {
    .hot-deal-contain {
      .hotdeal-right-nav {
        img {
          width: 65px;
        }
      }
      .hot-deal-center {
        .timer {
          span {
            padding: 9px;
          }
        }
      }
    }
    .hot-deal-contain1 {
      .hot-deal-subcontain {
        .hotdeal-right-nav-1 {
          img {
            width: 65px;
          }
        }
      }
    }
  }

  // modal //
  .theme-modal {
    &#exampleModal {
      .offer-content {
        min-height: 300px;
      }
    }
  }

  // theme slider  //
  .theme-slider  {
    .slider-banner {
      &.slide-banner-5  {
        .slider-banner-contain {
          background-color: rgba($white , 0.5);
        }
      }
    }
  }

  // key feature//
  .key-feature {
    .key-feature-box{
      img{
        width: 35px;
      }
    }
  }


  /*=====================
   Inner pages CSS
==========================*/
  .theme-tab {
    .tab-content {
      .product-tab {
        .tab-box {
          .product-box2 {
            .media {
              .media-body {
                a {
                  h6 {
                    font-size: 13px;
                  }
                }
                .color-variant {
                  li {
                    height: 12px;
                    width: 12px;
                  }
                }
              }
            }
            img {
              height: 115px;
            }
          }
        }
      }
    }
  }
  .blog-detail-page {
    .blog-detail {
      p {
        line-height: 1.5;
      }
    }
    .blog-contact {
      h2 {
        font-size: 20px;
        margin-bottom: 20px;
      }
    }
  }
  .about-page {
    p {
      line-height: 1.2;
    }
  }
  .theme-modal {
    .modal-dialog {
      .modal-content {
        .modal-body {
          padding: 10px;
          .modal-bg {
            padding: 20px;
            .close {
              right: 10px;
            }
            .offer-content {
              form {
                .form-group {
                  .form-control {
                    padding-top: 10px;
                    padding-bottom: 10px;
                  }
                }
              }
              h2 {
                font-size: 20px;
                margin-bottom: 10px;
              }
            }
          }
        }
      }
    }
  }
  .login-page {
    .theme-card {
      padding: 18px;
    }
  }
  .search-block {
    .btn-solid {
      padding: 10px 5px;
    }
    .form-header {
      .input-group {
        input {
          padding: 10px 12px;
        }
      }
    }
  }
  .contact-page {
    .map {
      iframe {
        height: 200px;
      }
    }
  }
  .collection-product-wrapper {
    .product-pagination {
      .pagination {
        .page-item {
          a {
            padding: 15px 13px;
          }
        }
      }
      .product-search-count-bottom {
        padding-left: 10px;
        h5 {
          line-height: 15px;
        }
      }
    }
    .product-top-filter {
      .popup-filter {
        .sidebar-popup {
          width: 80%;
        }
      }
    }
  }
  .cart-section, .wishlist-section {
    tbody {
      tr {
        td {
          min-width: 115px;
          .mobile-cart-content {
            .col-xs-3 {
              margin-left: 5px;
              margin-right: 5px;
            }
          }
          a {
            img {
              height: 80px;
            }
          }
        }
      }
    }
    tfoot {
      tr {
        td {
          padding-right: 27px;
          h2 {
            font-size: 21px;
          }
        }
      }
    }
  }
  .wishlist-section {
    .wishlist-buttons {
      &:last-child {
        a {
          margin-left: 0;
        }
      }
    }
  }
  .product-buttons {
    .btn-solid, .btn-outline {
      padding: 7px 16px;
    }
  }

  .single-product-tables{
    &.detail-section{
      table {
        width: 70%;
      }
    }
  }
  .product-right {
    .timer {
      span {
        .padding-l {
          padding-left: 10px;
        }
      }
    }
  }
  .product-form-box {
    .timer {
      span {
        min-width: 45px;
        .padding-l {
          padding-left: 9px;
        }
      }
    }
    .product-buttons {
      .btn-solid, .btn-outline {
        padding: 5px 5px;
      }
    }
  }
  .product-form-box, .border-product {
    .timer {
      span {
        min-width: 45px;
        .padding-l {
          padding-right: 5px;
        }
      }
    }
  }
  // tools //
  .tools-parallax-product  {
    .tools-description {
      .tools-form {
        .search-box {
          width: 92%;
        }
      }
    }
  }

  // vagitabl //
  .full-box {
    .center-slider {
      .offer-slider {
        .product-box {
          display: flex;
          border-bottom: 1px solid $light-border;
          border-top: 1px solid $light-border;
          .img-wrapper {
            img {
              height: 120px;
            }
          }
          .product-info {
            text-align: left;
            h6 {
              padding-top: 5px;
            }
            .color-variant {
              padding-top: 2px;
              li {
                height: 15px;
                width: 15px;
                padding-right: 3px;
              }
            }
          }
          .product-detail {
            padding-left: 0;
            h4 {
              font-size: 20px;
            }
            .color-variant {
              padding-top: 0;
            }
          }
          &:hover {
            .product-info {
              .btn-outline {
                display: none;
              }
              .color-variant {
                opacity: 1;
              }
            }
          }
        }
      }
    }
    .theme-card {
      .offer-slider {
        .sec-1 {
          display: inline;
          .product-box2 {
            border-bottom: 1px solid $light-border;
            &:last-child {
              border-bottom: none;
            }
            + .product-box2 {
              border-left: none;
            }
          }
        }
      }
    }
  }
  .center-slider {
    .offer-slider {
      .product-box {
        .product-detail {
          text-align: left !important;
          .rating {
            i {
              padding-right: 3px;
            }
          }
          h4 {
            padding-bottom: 0.5rem;
          }
          .color-variant {
            li {
              height: 18px;
              width: 18px;
              padding-right: 3px;
            }
          }
        }
        .img-wrapper {
          .cart-box {
            display: none;
          }
        }
      }
    }
  }

  // order tracking //
  .order-tracking {
    .order-tracking-box {
      .form-group{
        display: block;
        .form-control{
          &:nth-child(n+2){
            margin-left: unset;
            margin-top:15px;
          }
        }
      }
    }
  }

}
@media(max-width:320px){
  // rounded category //
  .rounded-category  {
    .category-contain  {
      .img-wrapper {
        width: 70px;
        height: 70px;
      }
    }
  }



  //deal banner //
  .deal-banner {
    .deal-banner-containe {
      h1 {
        line-height: 1.4;
      }
    }
  }
}

