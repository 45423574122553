@keyframes rotation{
    from {
        transform: rotateY(0deg) ;
    }
    to {
        transform: rotateY(365deg) ;
    }
}

@keyframes rotationxy{
    from {
        transform: rotate(0deg) ;
    }
    to {
        transform: rotate(365deg) ;
    }
}



@keyframes rotation1{
    from {
        transform: rotate(-4deg) ;
    }
    to {
        transform: rotate(4deg) ;
    }
}


@keyframes moveleft{
    0%{
        transform: translateX(0px);
    }
    50%{
        transform: translateX(80px);
    }
    100%{
        transform: translateX(0px);
    }
}

@keyframes moveboll{
    from {
        left: 0;
    }
    to {
        left: 100%;
    }
}

@keyframes bounce{
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(-10px);
    }
}


@keyframes bounce1 {
    0% {
        transform: translateY(0) ;
    }
    50% {
        transform: translateY(-30px) translateX(50px) rotateX(20deg) ;
    }
    100% {
        transform: translateY(-60px) translateX(90px)  rotateX(30deg);
    }
}


@keyframes move1 {
    0% {
        transform: rotate(0deg) translateX(10px) rotate(0deg);
    }
    100% {
        transform: rotate(260deg) translateX(10px) rotate(-260deg);
    }
}


@keyframes move2 {
    0% {
        transform: rotate(0deg) translateX(10px) rotate(0deg);
    }
    100% {
        transform: rotate(360deg) translateX(10px) rotate(-360deg);
    }
}

@keyframes move3 {
    0% {       
        transform: translateX(0px);
    }  
    100% {       
        transform: translateX(-300px) rotate(-365deg);
    }
}



@keyframes backanimat {
    0% {
        background-position: 0 0;
    }
    100% {
        background-position: 3000px 0;
    }
}


@keyframes ring {
    0% {
              transform: rotateZ(0);
     }
    1% {
              transform: rotateZ(15deg);
     }
    3% {
              transform: rotateZ(-14deg);
     }
    5% {
              transform: rotateZ(17deg);
     }
    7% {
              transform: rotateZ(-16deg);
     }
    9% {
              transform: rotateZ(15deg);
     }
    11% {
              transform: rotateZ(-14deg);
     }
    13% {
              transform: rotateZ(13deg);
     }
    15% {
              transform: rotateZ(-12deg);
     }
    17% {
              transform: rotateZ(12deg);
     }
    19% {
              transform: rotateZ(-10deg);
     }
    21% {
              transform: rotateZ(9deg);
     }
    23% {
              transform: rotateZ(-8deg);
     }
    25% {     
              transform: rotateZ(7deg);
         }
    27% {
              transform: rotateZ(-5deg);
     }
    29% {
              transform: rotateZ(5deg);
     }
    31% {
              transform: rotateZ(-4deg);
     }
    33% {
              transform: rotateZ(3deg);
     }
    35% {
              transform: rotateZ(-2deg);
     }
    37% {
              transform: rotateZ(1deg);
     }
    39% {
              transform: rotateZ(-1deg);
     }
    41% {
              transform: rotateZ(1deg);
     }
    43% {
              transform: rotateZ(0);
     }
    100% {
              transform: rotateZ(0);
     } 
}


@keyframes o-rotate-360 {
    0% { transform: rotate(0) }
  
    100% { transform: rotate(360deg) }
  }